import * as yup from "yup"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToastify } from "hooks/toastify/useToastify";
import { ExtrasDiscountsForm, ExtrasDiscountsFormInputs } from "components/Form/ExtrasDiscountsForm";
import { useHistory, useParams } from "react-router-dom";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { Button, Flex, Spinner } from "@chakra-ui/react";
import { Select } from "components/Inputs/SelectInput";
import { useExtraDiscount } from "hooks/extrasDiscounts/useExtraDiscount";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useCltDriverFunctions } from "hooks/cltDriver/useCltDriverFunctions";
import { useCltDrivers } from "hooks/cltDriver/useCltDrivers";
import { useAuth } from "hooks/auth/useAuth";

interface UpdateCltDriverExtraDiscountInputs extends Omit<ExtrasDiscountsFormInputs, 'extraDiscountAttachments'> {
  cltDriverId: string
  attachmentsIds: string[]
}

const schema = yup.object().shape({
  cltDriverId: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  serviceDate: yup.string().required('Campo obrigatório'),
  value: yup.string().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
  serviceType: yup.string().required('Campo obrigatório'),
  customerId: yup.mixed().when('serviceType', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório'),
  }),
  serviceRoute: yup.mixed().when('serviceType', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório'),
  }),
  servicesProtocols: yup.mixed().when('serviceType', {
    is: 'EXPRESS',
    then: yup.array()
      .of(
        yup.object().shape({
          protocol: yup.number()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório')
            .positive('O protocolo deve ser um número positivo')
            .integer('O protocolo deve ser um número inteiro'),
        })
      )
      .min(1, 'Você deve adicionar pelo menos um serviço'),
  })
})

interface Params {
  extraDiscountId: string
}

export function UpdateCltDriverExtraDiscount() {

  const { extraDiscountId } = useParams<Params>()
  const { push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userHasPermissionToUpdateCltDriverExtraDiscount =
    userLogged?.permissions.includes('update-clt-driver-extra-discount')

  const [attachmentsIds, setAttachmentsIds] = useState<string[]>([])

  useEffect(() => {
    if (!userHasPermissionToUpdateCltDriverExtraDiscount) {
      redirect('/')
    }
  }, [redirect, userHasPermissionToUpdateCltDriverExtraDiscount])

  const formMethods = useForm({
    resolver: yupResolver(schema)
  })

  const { setValue, register, handleSubmit, formState: { errors } } = formMethods

  const {
    data: cltDriversData,
    isFetching: isFetchingCltDriversData
  } = useCltDrivers()

  const cltDriversSelectOptions = cltDriversData?.cltDrivers.map((cltDriver) => {
    return {
      key: cltDriver.id,
      value: cltDriver.id,
      showOption: `${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`
    }
  })

  const {
    data: extraDiscountData,
    isFetching: isFetchingExtraDiscountData,
  } = useExtraDiscount({
    extraDiscountId,
    queryOptions: { enabled: !!extraDiscountId }
  })

  useEffect(() => {
    if (extraDiscountData) {
      const { extraDiscount } = extraDiscountData

      setValue('cltDriverId', extraDiscount.cltDriver.id)
      setValue('type', extraDiscount.type)
      setValue('serviceDate', extraDiscount.service_date)
      setValue('value', extraDiscount.value / 100)
      setValue('description', extraDiscount.description)
      setValue('serviceType', extraDiscount.services ? 'EXPRESS' : 'BUSINESS')
      setValue('serviceRoute', extraDiscount.service_route)

      if (extraDiscount?.additional_descriptions) {
        const descriptions = extraDiscount?.additional_descriptions?.map(additionalDescription => {
          return { description: additionalDescription }
        })
        setValue("additionalDescriptions", descriptions)
      }

      if (extraDiscount?.services.length) {
        const services = extraDiscount?.services?.map((service) => {
          return { protocol: service.service.protocol }
        })
        setValue('servicesProtocols', services)
      }
    }
  }, [setValue, extraDiscountData])

  const extraDiscountAttachments = extraDiscountData?.extraDiscount.attachments

  const { updateCltDriverExtraDiscount } = useCltDriverFunctions()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  async function handleUpdateCltDriverExtraDiscount(
    input: UpdateCltDriverExtraDiscountInputs
  ) {
    await promiseMessage(updateCltDriverExtraDiscount.mutateAsync({
      cltDriverId: input.cltDriverId,
      extraDiscountId,
      input: {
        ...input,
        value: transformStringToNumber(input.value),
        attachmentsIds: attachmentsIds,
        servicesProtocols: input.serviceType === 'EXPRESS' ? input.servicesProtocols.map(serviceProtocol => serviceProtocol.protocol) : null,
        additionalDescriptions: Boolean(input.additionalDescriptions) ? input.additionalDescriptions?.map(additionalDescription => additionalDescription.description) : null
      }
    }, {
      onSuccess: () => {
        redirect('/extras-descontos/motoristas-clt')
        queryClient.invalidateQueries('extraDiscount')
      }
    }), 'Extra/Desconto de motorista CLT atualizado com sucesso!')
  }

  const handleSetAttachmentsIds = (attachmentsIds: string[]) => setAttachmentsIds(attachmentsIds)

  return (
    <StandardBackgroundForm
      title='Editar Extra/Desconto de Motorista CLT'
      onSubmit={handleSubmit(handleUpdateCltDriverExtraDiscount)}
    >
      {(isFetchingCltDriversData || isFetchingExtraDiscountData) ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ExtrasDiscountsForm
            onSetAttachmentsIds={handleSetAttachmentsIds}
            extraDiscountAttachments={extraDiscountAttachments}
            slug='edit'
          >
            <Select
              {...register('cltDriverId')}
              name='cltDriverId'
              options={cltDriversSelectOptions}
              label='Motorista CLT'
              placeholder='Selecione uma opção...'
              error={errors.cltDriverId}
              required
            />
          </ExtrasDiscountsForm>
        </FormProvider>
      )}

      <Flex
        mt={4}
        gap={4}
        w='full'
        align='center'
        justifyContent='flex-end'
      >
        <Button
          type='submit'
          colorScheme='blue'
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
