import * as yup from "yup";
import { Button, Flex, Icon, Spinner } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useDriver } from "hooks/driver/useDriver";
import { FormProvider, useForm } from "react-hook-form";
import { CltDriversForm, CltDriversFormInputs } from "./components/CltDriversForm";
import { Link, useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaList, FaSave } from "react-icons/fa";
import { useCltDriverFunctions } from "hooks/cltDriver/useCltDriverFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useCltDriver } from "hooks/cltDriver/useCltDriver";
import { useEffect } from "react";
import { formatDate } from "utils/DateFunctions/formatDate";
import { useAuth } from "hooks/auth/useAuth";
import { useQueryClient } from "react-query";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";

interface Params {
  id: string
}

const registerCltDriverFormSchema = yup.object().shape({
  driver_id: yup.string().required('Campo obrigatório'),
  cellphone: yup.string().required('Campo obrigatório'),
  rg: yup.string().required('Campo obrigatório'),
  birth_date: yup.string().required('Campo obrigatório'),
  address: yup.string().required('Campo obrigatório'),
  days_of_work: yup.array().of(yup.string()).min(1, 'Campo obrigatório'),
  start_work_hour: yup.string().required('Campo obrigatório'),
  end_work_hour: yup.string().required('Campo obrigatório'),
  start_interval_hour: yup.string().required('Campo obrigatório'),
  end_interval_hour: yup.string().required('Campo obrigatório'),
  weekend_work: yup.string().required('Campo obrigatório'),
  start_weekend_work_hour: yup.string().when('weekend_work', {
    is: 'yes',
    then: yup.string().required('Campo Obrigatório'),
  }),
  end_weekend_work_hour: yup.string().when('weekend_work', {
    is: 'yes',
    then: yup.string().required('Campo Obrigatório'),
  }),
  salary: yup.string().required('Campo obrigatório'),
  family_salary: yup.string().required('Campo obrigatório'),
  admission_date: yup.string().required('Campo obrigatório'),
  regional: yup.string().required('Campo obrigatório'),
  office: yup.string().required('Campo obrigatório'),
  cost_center: yup.string().required('Campo obrigatório'),
  benefits: yup.array().of(yup.string()),
  email: yup.string().required('Campo obrigatório'),
  vr_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('VR'),
    then: yup.string().required('Campo obrigatório'),
  }),
  va_payment_type: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('VA'),
    then: yup.string().required('Campo obrigatório'),
  }),
  va_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('VA'),
    then: yup.string().required('Campo obrigatório'),
  }),
  motorcycle_location_value: yup.mixed().when('benefits', {
    is: (value: string[]) => value?.includes('ALUGUEL DE MOTO'),
    then: yup.string().required('Campo obrigatório'),
  }),
  home_work_monthly_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('COMBUSTÍVEL CASA-TRABALHO'),
    then: yup.string().required('Campo obrigatório'),
  }),
  business_days_daily_km: yup.string().when(['benefits', 'days_of_work'], {
    is: (benefitsValue: string[], daysOfWorkValue: string[]) => benefitsValue?.includes('COMBUSTÍVEL OPERACIONAL') && daysOfWorkValue?.some(day => Number(day) >= 1 && Number(day) <= 5),
    then: yup.string().required('Campo obrigatório'),
  }),
  business_days_fuel_price: yup.string().when(['benefits', 'days_of_work'], {
    is: (benefitsValue: string[], daysOfWorkValue: string[]) => benefitsValue?.includes('COMBUSTÍVEL OPERACIONAL') && daysOfWorkValue?.some(day => Number(day) >= 1 && Number(day) <= 5),
    then: yup.string().required('Campo obrigatório'),
  }),
  weekend_days_daily_km: yup.string().when(['benefits', 'days_of_work'], {
    is: (benefitsValue: string[], daysOfWorkValue: string[]) => benefitsValue?.includes('COMBUSTÍVEL OPERACIONAL') && daysOfWorkValue?.some(day => Number(day) === 0 || Number(day) === 6),
    then: yup.string().required('Campo obrigatório'),
  }),
  weekend_days_fuel_price: yup.string().when(['benefits', 'days_of_work'], {
    is: (benefitsValue: string[], daysOfWorkValue: string[]) => benefitsValue?.includes('COMBUSTÍVEL OPERACIONAL') && daysOfWorkValue?.some(day => Number(day) === 0 || Number(day) === 6),
    then: yup.string().required('Campo obrigatório')
  }),
  cellphone_cost_help: yup.mixed().when('benefits', {
    is: (value: string[]) => value?.includes('AJUDA DE CUSTO CELULAR'),
    then: yup.string().required('Campo obrigatório'),
  }),
  toll_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('PEDÁGIO'),
    then: yup.string().required('Campo obrigatório'),
  }),
  vt_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('VT'),
    then: yup.string().required('Campo obrigatório'),
  }),
  fuel_aid_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('AUXÍLIO COMBUSTÍVEL'),
    then: yup.string().required('Campo obrigatório'),
  }),
})

export function EditCltDriver() {
  const { push: redirect } = useHistory()
  const { id } = useParams<Params>()

  const { userLogged } = useAuth()

  const userHasUpdateCltDriverPermission = userLogged?.permissions.includes('edit-clt-driver')

  useEffect(() => {
    if (!userHasUpdateCltDriverPermission) {
      redirect('/')
    }
  }, [userHasUpdateCltDriverPermission, redirect])

  const {
    data: cltDriver,
    isFetching: isFetchingCltDriver
  } = useCltDriver(id, { queryOptions: { enabled: !!id } })

  const {
    drivers: {
      data: drivers,
      isFetching: isFetchingDrivers
    }
  } = useDriver(null, true)

  const formMethods = useForm<CltDriversFormInputs>({
    resolver: yupResolver(registerCltDriverFormSchema),
    defaultValues: {
      days_of_work: []
    }
  })

  const {
    updateCltDriver: {
      mutateAsync: updateCltDriver,
    }
  } = useCltDriverFunctions()

  const { setValue, handleSubmit } = formMethods

  useEffect(() => {
    if (cltDriver) {
      if (!isFetchingDrivers) {
        setValue('driver_id', cltDriver.driver.id)
      }
      setValue('rg', cltDriver.rg)
      setValue('cellphone', cltDriver.cellphone)
      setValue('admission_date', formatDate.handle(cltDriver.admission_date, 'DateWithoutHourToInput'))
      setValue('birth_date', formatDate.handle(cltDriver.birth_date, 'DateWithoutHourToInput'))
      setValue('salary', String(cltDriver.salary / 100))
      setValue('days_of_work', cltDriver.days_of_work.map(day => String(day)))
      setValue('address', cltDriver.address)
      setValue('start_work_hour', cltDriver.start_work_hour)
      setValue('end_work_hour', cltDriver.end_work_hour)
      setValue('start_interval_hour', cltDriver.start_interval_hour)
      setValue('end_interval_hour', cltDriver.end_interval_hour)
      setValue('weekend_work', cltDriver.start_weekend_work_hour !== null ? 'yes' : 'no')

      if (cltDriver.start_weekend_work_hour && cltDriver.end_weekend_work_hour) {
        setValue('start_weekend_work_hour', cltDriver.start_weekend_work_hour)
        setValue('end_weekend_work_hour', cltDriver.end_weekend_work_hour)
      }

      setValue('family_salary', cltDriver.family_salary ? 'yes' : 'no')
      setValue('regional', cltDriver.regional)
      setValue('office', cltDriver.office)
      setValue('cost_center', cltDriver.cost_center)
      setValue('email', cltDriver.email)
      setValue('route_description', cltDriver.route_description)
      setValue('benefits', cltDriver.benefits)

      if (cltDriver.vr_value) {
        setValue('vr_value', String(cltDriver.vr_value / 100))
      }

      if (cltDriver.va_payment_type) {
        setValue('va_payment_type', cltDriver.va_payment_type)
        setValue('va_value', String(cltDriver.vr_value / 100))
      }

      if (cltDriver.va_value) {
        setValue('va_value', String(cltDriver.va_value / 100))
      }

      if (cltDriver.motorcycle_location_value) {
        setValue('motorcycle_location_value', String(cltDriver.motorcycle_location_value / 100))
      }

      if (cltDriver.home_work_monthly_value_in_cents) {
        setValue('home_work_monthly_value', String(cltDriver.home_work_monthly_value_in_cents / 100))
      }

      if (cltDriver.business_days_daily_km_in_meters) {
        setValue('business_days_daily_km', String(cltDriver.business_days_daily_km_in_meters / 1000))
      }

      if (cltDriver.business_days_fuel_price_in_cents) {
        setValue('business_days_fuel_price', String(cltDriver.business_days_fuel_price_in_cents / 100))
      }

      if (cltDriver.weekend_days_daily_km_in_meters) {
        setValue('weekend_days_daily_km', String(cltDriver.weekend_days_daily_km_in_meters / 1000))
      }

      if (cltDriver.weekend_days_fuel_price_in_cents) {
        setValue('weekend_days_fuel_price', String(cltDriver.weekend_days_fuel_price_in_cents / 100))
      }

      if (cltDriver.cellphone_cost_help) {
        setValue('cellphone_cost_help', String(cltDriver.cellphone_cost_help / 100))
      }

      if (cltDriver.toll_value) {
        setValue('toll_value', String(cltDriver.toll_value / 100))
      }

      if (cltDriver.vt_value) {
        setValue('vt_value', String(cltDriver.vt_value / 100))
      }

      if (cltDriver.fuel_aid_value) {
        setValue('vt_value', String(cltDriver.fuel_aid_value / 100))
      }
    }
  }, [setValue, cltDriver, isFetchingDrivers])

  const defaultSelectedDaysOfWork = cltDriver?.days_of_work?.map(day => String(day))
  const defaultSelectedBenefits = cltDriver?.benefits

  const { promiseMessage } = useToastify()

  const queryClient = useQueryClient()

  async function handleUpdateCltDriver(values: CltDriversFormInputs) {
    await promiseMessage(
      updateCltDriver({
        id, input: {
          ...values,
          days_of_work: values.days_of_work.map(Number),
          motorcycle_location_value: values.motorcycle_location_value ? Math.ceil(transformStringToNumber(values.motorcycle_location_value) * 100) : undefined,
          business_days_daily_km_in_meters: values.business_days_daily_km ? Math.ceil(transformStringToNumber(values.business_days_daily_km) * 1000) : null,
          business_days_fuel_price_in_cents: values.business_days_fuel_price ? Math.ceil(transformStringToNumber(values.business_days_fuel_price) * 100) : null,
          weekend_days_daily_km_in_meters: values.weekend_days_daily_km ? Math.ceil(transformStringToNumber(values.weekend_days_daily_km) * 1000) : null,
          weekend_days_fuel_price_in_cents: values.weekend_days_fuel_price ? Math.ceil(transformStringToNumber(values.weekend_days_fuel_price) * 100) : null,
          cellphone_cost_help: values.cellphone_cost_help ? Math.ceil(transformStringToNumber(values.cellphone_cost_help) * 100) : null,
          home_work_monthly_value_in_cents: values.home_work_monthly_value ? Math.ceil(transformStringToNumber(values.home_work_monthly_value) * 100) : null
        }
      }, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['cltDrivers'])
          redirect('/motoristas-clt')
        }
      }),
      'Motorista clt atualizado com sucesso!'
    )
  }

  return (
    <StandardBackgroundForm
      title='Atualizar motorista clt'
      onSubmit={handleSubmit(handleUpdateCltDriver)}
    >
      {isFetchingDrivers || isFetchingCltDriver ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <CltDriversForm
            drivers={drivers}
            defaultSelectedDaysOfWork={defaultSelectedDaysOfWork}
            defaultSelectedBenefits={defaultSelectedBenefits}
          />
        </FormProvider>
      )}
      <Flex
        mt={4}
        gap={4}
        w='full'
        align='center'
        direction={['column', 'column', 'row']}
        justify={['center', 'center', 'flex-end']}
      >
        <Button
          w={['full', 'full', 'min']}
          as={Link}
          variant='ghost'
          to='/motoristas-clt'
          leftIcon={<Icon as={FaList} />}
        >
          Lista de motoristas clt
        </Button>
        <Button
          w={['full', 'full', 'min']}
          type='submit'
          colorScheme='blue'
          leftIcon={<Icon as={FaSave} />}
        >
          Atualizar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
