import { Box, Flex } from "@chakra-ui/react";
import { ExtraAndDiscountStatus } from "api/extrasAndDiscounts/_types/ExtraAndDiscount";
import { extrasAndDiscountsStatusMap } from "utils/extrasAndDiscountsMappers";


interface CustomerExtraDiscountStatusProps {
  status: ExtraAndDiscountStatus
}

export function CustomerExtraDiscountStatus({ status }: CustomerExtraDiscountStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {['closed', 'approved'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'requested' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}
      {status === 'rejected' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}
      {extrasAndDiscountsStatusMap[status]}

    </Flex>
  )
}
