import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { DocumentRequestTypes } from "./types/Document";

interface CreateDocumentRequestBody {
  requestType: DocumentRequestTypes
  name: string
  documentType: string
  version: string
  documentAttachmentId: string
  alterationDescription: string
}

interface CreateDocumentRequestProps {
  body: CreateDocumentRequestBody
}

export async function createDocumentRequest({
  body
}: CreateDocumentRequestProps) {
  try {
    const response = await api.post(`/documents`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
