import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Select, Stack, Table, Tbody, Text, Textarea } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createStandardization } from "api/standardizations/createStandardization";

import { set } from "date-fns";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useAuth } from "hooks/auth/useAuth";
import { useHub } from "hooks/hub/useHub";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { FaArrowLeft, FaFileImport } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { captalize } from "utils/captalize";
import { validateHasFile } from "utils/fileValidation";
import * as yup from "yup"
import { AttachmentRow } from "./components/AttachmentRow";
import { FileUpload } from "./components/FileUpload";

interface AttachmentUpload {
  [key: string]: {
    id: string
    link: string
    title: string
    progresss: number
  };
}

interface Attachments {
  attachment: FileList
}

interface StandardizationFormSchema {
  name: string
  email: string
  hubId: string
  sendedAt: string
  vehicle: string
  cnhPhotoId: FileList
  crlvPhotoId: FileList
  anttPhotoId: FileList
  cleaningRegistersPhotosIds: Attachments[]
  frontPhotoWithUniformAndBadgePhotoId: FileList
  vehicleFrontPhotoId: FileList
  vehicleLeftSidePhotoId: FileList
  vehicleRightSidePhotoId: FileList
  vehicleRearPhotoId: FileList
  vehicleInternPhotoId: FileList
  trunkLidOrCarWithEmergencyFormPhotoId: FileList
  tiresPhotosIds: Attachments[]
  observations: string
}


const createStandardizationSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().required(),
  hubId: yup.string().required(),
  sendedAt: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  vehicle: yup.string().required(),
  cnhPhotoId: yup.mixed().test(value => validateHasFile(value)).required(),
  crlvPhotoId: yup.mixed().test(value => validateHasFile(value)).required(),
  anttPhotoId: yup.mixed().test(value => validateHasFile(value)).required(),
  cleaningRegistersPhotosIds: yup.array().of(yup.object({
    attachment: yup.mixed().test('hasFile', 'Campo obrigatório.', value => validateHasFile(value))
  })).required(),
  frontPhotoWithUniformAndBadgePhotoId: yup.mixed().test(value => validateHasFile(value)).required(),
  vehicleFrontPhotoId: yup.mixed().test(value => validateHasFile(value)).required(),
  vehicleLeftSidePhotoId: yup.mixed().test(value => validateHasFile(value)).required(),
  vehicleRightSidePhotoId: yup.mixed().test(value => validateHasFile(value)).required(),
  vehicleRearPhotoId: yup.mixed().test(value => validateHasFile(value)).required(),
  vehicleInternPhotoId: yup.mixed().test(value => validateHasFile(value)).required(),
  trunkLidOrCarWithEmergencyFormPhotoId: yup.mixed().test(value => validateHasFile(value)).required(),
  tiresPhotosIds: yup.array().of(yup.object({
    attachment: yup.mixed().test('hasFile', 'Campo obrigatório.', value => validateHasFile(value))
  })).required(),
  observations: yup.string(),
})

export function CreateStandardization() {
  const history = useHistory()
  const { hubs: { data: hubs } } = useHub(null, true)

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const { userLogged } = useAuth()

  const userCanCreateStandardization = userLogged?.permissions.includes('create-standardization')

  useEffect(() => {
    if (!userCanCreateStandardization) history.push('/')
  }, [history, userCanCreateStandardization])


  const { mutateAsync: createStandardizationFn } = useMutation({
    mutationFn: createStandardization,
    onSuccess() {
      queryClient.invalidateQueries(['standardizations'])
      queryClient.invalidateQueries({ queryKey:'pending-standardizations' })
      queryClient.invalidateQueries({ queryKey: 'approved-standardizations' })
      queryClient.invalidateQueries({ queryKey: 'reproved-standardizations' })
      history.push('/padronizacoes')
    }
  })

  const { mutation: uploadAttachmentFn, uploadProggress: uploadProggressAttachentPhoto } = useAttachmentMutation()

  const [attachments, setAttachments] = useState<AttachmentUpload[]>([]);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<StandardizationFormSchema>({
    resolver: yupResolver(createStandardizationSchema),
    mode: "onSubmit",
    reValidateMode: 'onChange'
  })

  const handleUploadAttachment = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name.replace(/\.\d+\.attachment$/, '');

    const formData = new FormData();
    formData.append('attachment', event.target.files[0]);

    try {
      const result = await uploadAttachmentFn.mutateAsync(formData);

      const newAttachment = {
        id: result.attachment?.id || '',
        link: result.attachment?.link || '',
        title: result?.attachment?.title || '',
        progresss: uploadProggressAttachentPhoto,
      };

      setAttachments(prevAttachments => {
        if (fieldName.includes('cleaningRegistersPhotosIds') || fieldName === 'tiresPhotosIds') {
          return {
            ...prevAttachments,
            [fieldName]: [
              ...(prevAttachments[fieldName] || []),
              newAttachment
            ]
          };
        } else {

          return {
            ...prevAttachments,
            [fieldName]: newAttachment
          };
        }
      });

    } catch (error) {

    }
  };


  const [
    vehicle,
    hubId,
    cnhPhotoId,
    crlvPhotoId,
    anttPhotoId,
    frontPhotoWithUniformAndBadgePhotoId,
    vehicleFrontPhotoId,
    vehicleLeftSidePhotoId,
    vehicleRightSidePhotoId,
    vehicleRearPhotoId,
    vehicleInternPhotoId,
    trunkLidOrCarWithEmergencyFormPhotoId,
  ] = useWatch({
    control,
    name: [
      'vehicle',
      'hubId',
      'cnhPhotoId',
      'crlvPhotoId',
      'anttPhotoId',
      'cleaningRegistersPhotosIds',
      'frontPhotoWithUniformAndBadgePhotoId',
      'vehicleFrontPhotoId',
      'vehicleLeftSidePhotoId',
      'vehicleRightSidePhotoId',
      'vehicleRearPhotoId',
      'vehicleInternPhotoId',
      'trunkLidOrCarWithEmergencyFormPhotoId',
      'tiresPhotosIds'
    ]
  })

  const {
    fields: cleaningRegistersPhotosIdsFields,
    append: appendcleaningRegistersPhotosIdField,

  } = useFieldArray({
    control,
    name: 'cleaningRegistersPhotosIds'
  })

  const {
    fields: tiresPhotosIdsFields,
    append: appendTiresPhotosIdsField,

  } = useFieldArray({
    control,
    name: 'tiresPhotosIds'
  })

  const clearCleaningRegistersPhotosFields = () => {
    setValue('cleaningRegistersPhotosIds', []);
  };

  const handleAddCleaningRegistersPhotosFields = (hubId: string) => {
    clearCleaningRegistersPhotosFields()
    setValue('hubId', hubId)
    const hubSelected = hubs?.find(hub => hub.id === hubId)

    if (['SÃO PAULO', 'MINAS GERAIS'].includes(hubSelected?.name)) {
      appendcleaningRegistersPhotosIdField([{ attachment: undefined }, { attachment: undefined }, { attachment: undefined }, { attachment: undefined }]);
    } else {
      appendcleaningRegistersPhotosIdField([{ attachment: undefined }]);
    }
  };

  const clearTiresPhotosFields = () => {
    setValue('tiresPhotosIds', []);
  };

  const handleAddTiresPhotosFields = (vehicleType: string) => {
    clearTiresPhotosFields()
    setValue("vehicle", vehicleType)

    if (vehicleType === 'Moto') {
      appendTiresPhotosIdsField([{ attachment: undefined }, { attachment: undefined }]);
    } else if (vehicleType === 'Carro') {

      appendTiresPhotosIdsField([{ attachment: undefined }, { attachment: undefined }, { attachment: undefined }, { attachment: undefined }]);
    }
  };

  async function handleCreateStandardization(values: StandardizationFormSchema) {
    const cleaningRegistersPhotosAttachmentIds = attachments['cleaningRegistersPhotosIds']?.map(attachment => attachment.id);
    const tiresPhotosAttachmentIds = attachments['tiresPhotosIds']?.map(attachment => attachment.id);

    await promiseMessage(createStandardizationFn({
      body: {
        name: values.name,
        email: values.email,
        hubId: values.hubId,
        sendedAt: values.sendedAt,
        vehicle: values.vehicle,
        cnhPhotoId: attachments['cnhPhotoId'].id,
        crlvPhotoId: attachments['crlvPhotoId'].id,
        anttPhotoId: attachments['anttPhotoId'].id,
        cleaningRegistersPhotosIds: cleaningRegistersPhotosAttachmentIds,
        frontPhotoWithUniformAndBadgePhotoId: attachments['frontPhotoWithUniformAndBadgePhotoId'].id,
        vehicleFrontPhotoId: attachments['vehicleFrontPhotoId'].id,
        vehicleLeftSidePhotoId: attachments['vehicleLeftSidePhotoId'].id,
        vehicleRightSidePhotoId: attachments['vehicleRightSidePhotoId'].id,
        vehicleRearPhotoId: attachments['vehicleRearPhotoId'].id,
        vehicleInternPhotoId: attachments['vehicleInternPhotoId'].id,
        trunkLidOrCarWithEmergencyFormPhotoId: attachments['trunkLidOrCarWithEmergencyFormPhotoId'].id,
        tiresPhotosIds: tiresPhotosAttachmentIds,
        observations: values.observations,
      }
    }), 'Padronização criada! 🎉')
  }

  return (

      <Box
        p="6"
        bg="white"
        rounded="md"
        as="form"
        onSubmit={handleSubmit(handleCreateStandardization)}
      >
        <Flex align="center" gap="2">
          <IconButton
            icon={<FaArrowLeft />}
            aria-label="Voltar"
            size="sm"
            variant="ghost"
            onClick={() => history.goBack()}
          />
          <Heading letterSpacing="tight" fontSize="2xl">Criar padronização</Heading>
        </Flex>
        <Flex
          direction="column"
          gap="6"
          mt="6"
        >
          <Stack
            direction={["column", "column", "row"]}
            spacing='6'
            w="full"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors?.name}>
                <FormLabel fontSize="sm">Nome Completo
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>

                <Input
                  {...register('name')}
                  name="name"
                  placeholder="nome completo..."
                  size="md"
                  rounded='lg'
                />

              </FormControl>
            </Stack>
            <FormControl w='full' isInvalid={!!errors?.email}>
              <FormLabel fontSize="sm">Email
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Input
                {...register('email')}
                name="email"
                placeholder="email..."
                size="md"
                rounded='lg'
              />

            </FormControl>

          </Stack>
          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormLabel fontSize="sm">
                Hub
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <FormControl isInvalid={!!errors.hubId}>
                <Select
                  {...register("hubId")}
                  name="hubId"
                  placeholder="Selecione..."
                  size="md"
                  rounded="md"
                  onChange={(e) => handleAddCleaningRegistersPhotosFields(e.target.value)}
                >
                  {hubs?.map((hub) => {
                    return (
                      <option key={hub.id} value={hub.id}>{captalize(hub.name)}</option>
                    )
                  })}
                </Select>
              </FormControl>
            </Stack>

          </Stack>
          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <FormControl isInvalid={!!errors?.sendedAt}>
              <FormLabel fontSize="sm">Data envio
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Input
                {...register('sendedAt')}
                name="sendedAt"
                type="date"
                rounded="lg"
              />
            </FormControl>
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormLabel fontSize="sm">
                Veiculo
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <FormControl isInvalid={!!errors.vehicle}>
                <Select
                  {...register("vehicle")}
                  name="vehicle"
                  placeholder="Selecione..."
                  size="md"
                  rounded="md"
                  onChange={(e) => handleAddTiresPhotosFields(e.target.value)}
                >
                  <option value="Carro">Carro</option>
                  <option value="Moto">Moto</option>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <FileUpload<StandardizationFormSchema>
            label="CNH atual"
            fieldName="cnhPhotoId"
            register={register}
            errors={errors}
            handleUpload={handleUploadAttachment}
          />
          {cnhPhotoId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(cnhPhotoId).map(([key, file]) => {
                  return (
                    <AttachmentRow key={key} attachment={attachments['cnhPhotoId']} />
                  );
                })}
              </Tbody>
            </Table>
          )}

          <FileUpload<StandardizationFormSchema>
            label="CRLV atual"
            fieldName="crlvPhotoId"
            register={register}
            errors={errors}
            handleUpload={handleUploadAttachment}
          />
          {crlvPhotoId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(crlvPhotoId).map(([key, file]) => {
                  return (
                    <AttachmentRow key={key} attachment={attachments['crlvPhotoId']} />
                  );
                })}
              </Tbody>
            </Table>
          )}

          <FileUpload<StandardizationFormSchema>
            label="Curso de motofrete/ANTT"
            fieldName="anttPhotoId"
            register={register}
            errors={errors}
            handleUpload={handleUploadAttachment}
          />
          {anttPhotoId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(anttPhotoId).map(([key, file]) => {
                  return (
                    <AttachmentRow key={key} attachment={attachments['anttPhotoId']} />
                  );
                })}
              </Tbody>
            </Table>
          )}
          {hubId && (
            <Flex
              border="1px solid"
              borderColor="gray.200"
              p="4"
              rounded="lg"
              direction="column"
            >
              <Heading
                fontSize="md"
                letterSpacing="tight"
              >
                Anexe aqui seu registro de limpeza
              </Heading>

              <Box>
                {
                  cleaningRegistersPhotosIdsFields.map((field, index) => {
                    const attachment = attachments['cleaningRegistersPhotosIds']?.[index];

                    return (
                      <FormControl mt="3" key={field.id} isInvalid={errors?.cleaningRegistersPhotosIds ? !!errors?.cleaningRegistersPhotosIds[index]?.attachment : undefined}>


                        <Flex gap="3">
                          <Stack
                            direction="column"
                            w="full"
                            mt="3"
                          >
                            <Button
                              as={FormLabel}
                              htmlFor={`cleaningRegistersPhotosIds.${index}.attachment`}
                              lineHeight="1"
                              leftIcon={<FaFileImport />}
                              size="sm"
                              w="min"
                              cursor="pointer"
                              border={!!errors?.cleaningRegistersPhotosIds && '2px solid'}
                              borderColor={(!!errors?.cleaningRegistersPhotosIds) && 'red.500'}
                            >
                              Anexe aqui seu registro de limpeza
                            </Button>
                            <FormControl isInvalid={!!errors?.cleaningRegistersPhotosIds}>
                              <Input
                                {...register(`cleaningRegistersPhotosIds.${index}.attachment`)}
                                name={`cleaningRegistersPhotosIds.${index}.attachment`}
                                id={`cleaningRegistersPhotosIds.${index}.attachment`}
                                type="file"
                                hidden
                                onChangeCapture={handleUploadAttachment}
                              />
                            </FormControl>

                          </Stack>
                        </Flex>
                        {attachment !== undefined && (
                          <Table size="sm">
                            <Tbody>
                              <AttachmentRow attachment={attachment} />
                            </Tbody>
                          </Table>
                        )}
                      </FormControl>
                    )
                  })
                }
              </Box>

            </Flex>
          )}

          <FileUpload<StandardizationFormSchema>
            label="Foto frontal com uniforme e crachá"
            fieldName="frontPhotoWithUniformAndBadgePhotoId"
            register={register}
            errors={errors}
            handleUpload={handleUploadAttachment}
          />
          {frontPhotoWithUniformAndBadgePhotoId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(frontPhotoWithUniformAndBadgePhotoId).map(([key, file]) => {
                  return (
                    <AttachmentRow key={key} attachment={attachments['frontPhotoWithUniformAndBadgePhotoId']} />
                  );
                })}
              </Tbody>
            </Table>
          )}

          <FileUpload<StandardizationFormSchema>
            label="Foto do veículo (frente)"
            fieldName="vehicleFrontPhotoId"
            register={register}
            errors={errors}
            handleUpload={handleUploadAttachment}
          />
          {vehicleFrontPhotoId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(vehicleFrontPhotoId).map(([key, file]) => {
                  return (
                    <AttachmentRow key={key} attachment={attachments['vehicleFrontPhotoId']} />
                  );
                })}
              </Tbody>
            </Table>
          )}

          <FileUpload<StandardizationFormSchema>
            label="Foto do veículo (lateral 1)"
            fieldName="vehicleLeftSidePhotoId"
            register={register}
            errors={errors}
            handleUpload={handleUploadAttachment}
          />
          {vehicleLeftSidePhotoId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(vehicleLeftSidePhotoId).map(([key, file]) => {
                  return (
                    <AttachmentRow key={key} attachment={attachments['vehicleLeftSidePhotoId']} />
                  );
                })}
              </Tbody>
            </Table>
          )}

          <FileUpload<StandardizationFormSchema>
            label="Foto do veículo (lateral 2)"
            fieldName="vehicleRightSidePhotoId"
            register={register}
            errors={errors}
            handleUpload={handleUploadAttachment}
          />
          {vehicleRightSidePhotoId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(vehicleRightSidePhotoId).map(([key, file]) => {
                  return (
                    <AttachmentRow key={key} attachment={attachments['vehicleRightSidePhotoId']} />
                  );
                })}
              </Tbody>
            </Table>
          )}

          <FileUpload<StandardizationFormSchema>
            label="Foto do veículo (traseira com placa)"
            fieldName="vehicleRearPhotoId"
            register={register}
            errors={errors}
            handleUpload={handleUploadAttachment}
          />
          {vehicleRearPhotoId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(vehicleRearPhotoId).map(([key, file]) => {
                  return (
                    <AttachmentRow key={key} attachment={attachments['vehicleRearPhotoId']} />
                  );
                })}
              </Tbody>
            </Table>
          )}

          <FileUpload<StandardizationFormSchema>
            label="Foto do veículo (interna se carro, baú se moto - com KIT EPI)"
            fieldName="vehicleInternPhotoId"
            register={register}
            errors={errors}
            handleUpload={handleUploadAttachment}
          />
          {vehicleInternPhotoId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(vehicleInternPhotoId).map(([key, file]) => {
                  return (
                    <AttachmentRow key={key} attachment={attachments['vehicleInternPhotoId']} />
                  );
                })}
              </Tbody>
            </Table>
          )}

          <FileUpload<StandardizationFormSchema>
            label="Foto da tampa do baú ou carro com ficha de emergência"
            fieldName="trunkLidOrCarWithEmergencyFormPhotoId"
            register={register}
            errors={errors}
            handleUpload={handleUploadAttachment}
          />
          {trunkLidOrCarWithEmergencyFormPhotoId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(trunkLidOrCarWithEmergencyFormPhotoId).map(([key, file]) => {
                  return (
                    <AttachmentRow key={key} attachment={attachments['trunkLidOrCarWithEmergencyFormPhotoId']} />
                  );
                })}
              </Tbody>
            </Table>
          )}
          {vehicle && (
            <Flex
              border="1px solid"
              borderColor="gray.200"
              p="4"
              rounded="lg"
              direction="column"
            >
              <Heading
                fontSize="md"
                letterSpacing="tight"
              >
                Foto dos pneus
              </Heading>
              <Box>
                {
                  tiresPhotosIdsFields.map((field, index) => {
                    const attachment = attachments['tiresPhotosIds']?.[index];
                    return (
                      <FormControl mt="3" key={field.id} isInvalid={errors?.tiresPhotosIds ? !!errors?.tiresPhotosIds[index]?.attachment : undefined}>
                        <Flex gap="3">
                          <Stack
                            direction="column"
                            w="full"
                            mt="3"
                          >
                            <Button
                              as={FormLabel}
                              htmlFor={`tiresPhotosIds.${index}.attachment`}
                              lineHeight="1"
                              leftIcon={<FaFileImport />}
                              size="sm"
                              w="min"
                              cursor="pointer"
                              border={!!errors?.tiresPhotosIds && '2px solid'}
                              borderColor={(!!errors?.tiresPhotosIds) && 'red.500'}
                            >
                              Foto dos pneus
                            </Button>
                            <FormControl isInvalid={!!errors?.tiresPhotosIds}>
                              <Input
                                {...register(`tiresPhotosIds.${index}.attachment`)}
                                name={`tiresPhotosIds.${index}.attachment`}
                                id={`tiresPhotosIds.${index}.attachment`}
                                type="file"
                                hidden
                                onChangeCapture={handleUploadAttachment}
                              />
                            </FormControl>
                          </Stack>

                        </Flex>
                        {attachment !== undefined && (
                          <Table size="sm">
                            <Tbody>
                              <AttachmentRow attachment={attachment} />
                            </Tbody>
                          </Table>
                        )}
                      </FormControl>
                    )
                  })
                }
              </Box>

            </Flex>
          )}

          <FormControl w='full' isInvalid={!!errors?.observations}>
            <FormLabel fontSize="sm">Observações</FormLabel>
            <Textarea
              {...register('observations')}
              name="observations"
              placeholder="observações..."
              size="md"
              rounded='lg'
            />

          </FormControl>
        </Flex>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </Box>

  )

}
