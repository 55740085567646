import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Modal, ModalOverlay, Radio, RadioGroup, Select, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { dismissalFinancialInternClt } from "api/internClts/dismissalFinancialInternClt";
import { format, parseISO, set } from "date-fns";
import { InternClt } from "api/internClts/_types/internClt";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { CreateSupport } from "pages/Support/components/CreateSupport";


interface DismissalFinancialInternCltProps {
  internClt: InternClt
}

export interface DismissalFinancialInternCltSchema {
  hasCompletedContractTerminationTerm: string
  hasRemovedFromOccupationalMedicine: string
  hasRemovedFromHealthcare: string
  hasRemovedFromDentalPlan: string
  hasRemovedFromPharmacyAgreement: string
  dismissalRealDate: string
  shouldAdvanceStatus: string
}

const dismissalFinancialInternCltSchema = yup.object({
  hasCompletedContractTerminationTerm: yup.string(),
  hasRemovedFromOccupationalMedicine: yup.string(),
  hasRemovedFromHealthcare: yup.string(),
  hasRemovedFromDentalPlan: yup.string(),
  hasRemovedFromPharmacyAgreement: yup.string(),
})


export function DismissalFinancialInternClt({ internClt }: DismissalFinancialInternCltProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<DismissalFinancialInternCltSchema>({
    resolver: yupResolver(dismissalFinancialInternCltSchema)
  })

  useEffect(() => {
    if (internClt) {
      setValue("hasCompletedContractTerminationTerm", Boolean(internClt?.has_completed_contract_termination_term) ? internClt?.has_completed_contract_termination_term ? 'yes' : 'no' : '')
      setValue("hasRemovedFromOccupationalMedicine", Boolean(internClt?.has_removed_from_occupational_medicine) ? internClt?.has_removed_from_occupational_medicine : '')
      setValue("hasRemovedFromHealthcare", Boolean(internClt?.has_removed_from_healthcare) ? internClt?.has_removed_from_healthcare : '')
      setValue("hasRemovedFromDentalPlan", Boolean(internClt?.has_removed_from_dental_plan) ? internClt?.has_removed_from_dental_plan : '')
      setValue("hasRemovedFromPharmacyAgreement", Boolean(internClt?.has_removed_from_pharmacy_agreement) ? internClt?.has_removed_from_pharmacy_agreement : '')
      setValue("dismissalRealDate", internClt?.dismissal_real_date ? format(parseISO(internClt?.dismissal_real_date), 'yyyy-MM-dd') : '')
    }
  }, [internClt, setValue])

  const { mutateAsync: dismissalFinancialInternCltFn } = useMutation({
    mutationFn: dismissalFinancialInternClt,
    onSuccess(_data, { body, internCltId }) {
      queryClient.invalidateQueries({ queryKey: 'intern-clts' })
      queryClient.invalidateQueries({ queryKey: 'dismissed-intern-clts' })
      queryClient.invalidateQueries({ queryKey: 'dismissal-financial-intern-clts' })
      queryClient.invalidateQueries({ queryKey: ['intern-clt', internCltId] })
    }
  })

  async function handleDismissalFinancialInternClt(values: DismissalFinancialInternCltSchema) {
    let dismissalRealDate = null
    if (Boolean(values?.dismissalRealDate)) {
      const [year, month, day] = values?.dismissalRealDate?.split('-').map(Number)
      dismissalRealDate = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()
    }
    await promiseMessage(dismissalFinancialInternCltFn({
      body: {
        ...values,
        hasCompletedContractTerminationTerm: values.hasCompletedContractTerminationTerm === 'yes',
        shouldAdvanceStatus: values.shouldAdvanceStatus ? values.shouldAdvanceStatus === 'yes' : false,
        dismissalRealDate: dismissalRealDate
      },
      internCltId: internClt?.id
    }), 'Financeiro enviado!')
  }

  const {
    isOpen: isCreateSupportOpen,
    onOpen: onOpenCreateSupport,
    onClose: onCloseCreateSupport
  } = useDisclosure()


  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleDismissalFinancialInternClt)}
    >
      <Heading letterSpacing="tight" size='sm'>Demissão financeiro</Heading>
      <FormControl isInvalid={!!errors.hasCompletedContractTerminationTerm} mt={3}>
        <FormLabel>
          Termo de rescisão de contrato preenchido ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasCompletedContractTerminationTerm"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRemovedFromOccupationalMedicine} mt={3}>
        <FormLabel>
          Excluiu o funcionário da plataforma da Medicina Ocupacional?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRemovedFromOccupationalMedicine"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRemovedFromHealthcare} mt={3}>
        <FormLabel>
          Exclusão Plano de saúde ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRemovedFromHealthcare"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRemovedFromDentalPlan} mt={3}>
        <FormLabel>
          Exclusão plano odonto ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRemovedFromDentalPlan"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRemovedFromPharmacyAgreement} mt={3}>
        <FormLabel>
          Exclusão convenio farmácia ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRemovedFromPharmacyAgreement"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors?.dismissalRealDate}>
        <FormLabel fontSize="sm">
          Data real do desligamento
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('dismissalRealDate')}
          name="dismissalRealDate"
          type="date"
          rounded="md"
          size='sm'
        />
      </FormControl>
      <Flex mt={3} alignItems="baseline" justifyContent="space-between">
        <FormLabel fontSize='sm'>
          Solicitar pagamento - rescisão
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <IconButton
          aria-label="Abrir atendimento"
          icon={<FaPlus />}
          colorScheme="blue"
          size="sm"
          onClick={onOpenCreateSupport} />
      </Flex>
      <Modal
        isOpen={isCreateSupportOpen}
        onClose={onCloseCreateSupport}
        isCentered
        size="2xl"
      >
        <ModalOverlay />
        <CreateSupport
          onClose={onCloseCreateSupport}
          required={true} />
      </Modal>
      <FormControl isInvalid={!!errors.shouldAdvanceStatus} mt={3}>
        <FormLabel fontSize="sm">
          Passar para o próximo status?
        </FormLabel>
        <Select
          {...register('shouldAdvanceStatus')}
          name="shouldAdvanceStatus"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
