import { Box, Flex, Heading, IconButton } from "@chakra-ui/react"
import { getInternClts } from "api/internClts/getInternClts"
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query"
import { Link, useHistory } from "react-router-dom";
import { InternCltsKanbanColumn } from "./components/InternCltsKanbanColumn";
import { InternCltsTableFilters } from "./components/InternCltsTableFilters";

export function InternCltsKanban() {

  const { userLogged } = useAuth()
  const history = useHistory()
  const searchParams = useSearchParams()


  const userCanViewInternClts = userLogged?.permissions.includes('view-intern-clts')
  const userCanCreateInternClt = userLogged?.permissions.includes('create-intern-clt')

  useEffect(() => {
    if (!userCanViewInternClts) history.push('/')
  }, [userCanViewInternClts, history])

  const name = searchParams.get('name')

  const {
    data: inactiveInternCltsResult,
    isLoading: isInactiveInternCltsResultLoading,
    hasNextPage: hasNextPageInactiveInternClts,
    fetchNextPage: fetchNextPageInactiveInternClts,
    isFetchingNextPage: isFetchingNextPageInactiveInternClts,
  } = useInfiniteQuery({
    queryKey: ['inactive-intern-clts', name],
    queryFn: ({ pageParam = 1 }) => getInternClts({
      currentPage: pageParam,
      pageSize: 10,
      status: 'inactive',
      name
    }),
    getNextPageParam: (lastPage) => lastPage.meta.currentPage < lastPage.meta.totalPages
      ? lastPage.meta.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: hiringInternCltsResult,
    isLoading: isHiringInternCltsResultLoading,
    hasNextPage: hasNextPageHiringInternClts,
    fetchNextPage: fetchNextPageHiringInternClts,
    isFetchingNextPage: isFetchingNextPageHiringInternClts,
  } = useInfiniteQuery({
    queryKey: ['hiring-intern-clts', name],
    queryFn: ({ pageParam = 1 }) => getInternClts({
      currentPage: pageParam,
      pageSize: 10,
      status: 'hiring',
      name
    }),
    getNextPageParam: (lastPage) => lastPage.meta.currentPage < lastPage.meta.totalPages
      ? lastPage.meta.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: registeringInternCltsResult,
    isLoading: isRegisteringInternCltsResultLoading,
    hasNextPage: hasNextPageRegisteringInternClts,
    fetchNextPage: fetchNextPageRegisteringInternClts,
    isFetchingNextPage: isFetchingNextPageRegisteringInternClts,
  } = useInfiniteQuery({
    queryKey: ['pontomais-registering-intern-clts', name],
    queryFn: ({ pageParam = 1 }) => getInternClts({
      currentPage: pageParam,
      pageSize: 10,
      status: 'pontomais-registering',
      name
    }),
    getNextPageParam: (lastPage) => lastPage.meta.currentPage < lastPage.meta.totalPages
      ? lastPage.meta.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });


  const {
    data: sendingBankDetailsInternCltsResult,
    isLoading: isSendingBankDetailsInternCltsResultLoading,
    hasNextPage: hasNextPageSendingBankDetailsInternClts,
    fetchNextPage: fetchNextPageSendingBankDetailsInternClts,
    isFetchingNextPage: isFetchingNextPageSendingBankDetailsInternClts,
  } = useInfiniteQuery({
    queryKey: ['sending-bank-details-intern-clts', name],
    queryFn: ({ pageParam = 1 }) => getInternClts({
      currentPage: pageParam,
      pageSize: 10,
      status: 'sending-bank-details',
      name
    }),
    getNextPageParam: (lastPage) => lastPage.meta.currentPage < lastPage.meta.totalPages
      ? lastPage.meta.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });


  const {
    data: activeInternCltsResult,
    isLoading: isActiveInternCltsResultLoading,
    hasNextPage: hasNextPageActiveInternClts,
    fetchNextPage: fetchNextPageActiveInternClts,
    isFetchingNextPage: isFetchingNextPageActiveInternClts,
  } = useInfiniteQuery({
    queryKey: ['active-intern-clts', name],
    queryFn: ({ pageParam = 1 }) => getInternClts({
      currentPage: pageParam,
      pageSize: 10,
      status: 'active',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: dismissalDocsInternCltsResult,
    isLoading: isDismissalDocsInternCltsResultLoading,
    hasNextPage: hasNextPageDismissalDocsInternClts,
    fetchNextPage: fetchNextPageDismissalDocsInternClts,
    isFetchingNextPage: isFetchingNextPageDismissalDocsInternClts,
  } = useInfiniteQuery({
    queryKey: ['dismissal-docs-intern-clts', name],
    queryFn: ({ pageParam = 1 }) => getInternClts({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissal-docs',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: inNoticeInternCltsResult,
    isLoading: isInNoticeInternCltsResultLoading,
    hasNextPage: hasNextPageInNoticeInternClts,
    fetchNextPage: fetchNextPageInNoticeInternClts,
    isFetchingNextPage: isFetchingNextPageInNoticeInternClts,
  } = useInfiniteQuery({
    queryKey: ['in-notice-intern-clts', name],
    queryFn: ({ pageParam = 1 }) => getInternClts({
      currentPage: pageParam,
      pageSize: 10,
      status: 'in-notice',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: dismissalComunicationInternCltsResult,
    isLoading: isDismissalComunicationInternCltsResultLoading,
    hasNextPage: hasNextPageDismissalComunicationInternClts,
    fetchNextPage: fetchNextPageDismissalComunicationInternClts,
    isFetchingNextPage: isFetchingNextPageDismissalComunicationInternClts,
  } = useInfiniteQuery({
    queryKey: ['dismissal-comunication-intern-clts', name],
    queryFn: ({ pageParam = 1 }) => getInternClts({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissal-comunication',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: dismissalPaymentInternCltsResult,
    isLoading: isDismissalPaymentInternCltsResultLoading,
    hasNextPage: hasNextPageDismissalPaymentInternClts,
    fetchNextPage: fetchNextPageDismissalPaymentInternClts,
    isFetchingNextPage: isFetchingNextPageDismissalPaymentInternClts,
  } = useInfiniteQuery({
    queryKey: ['dismissal-payment-intern-clts', name],
    queryFn: ({ pageParam = 1 }) => getInternClts({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissal-payment',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: dismissalFinancialInternCltsResult,
    isLoading: isDismissalFinancialInternCltsResultLoading,
    hasNextPage: hasNextPageDismissalFinancialInternClts,
    fetchNextPage: fetchNextPageDismissalFinancialInternClts,
    isFetchingNextPage: isFetchingNextPageDismissalFinancialInternClts,
  } = useInfiniteQuery({
    queryKey: ['dismissal-financial-intern-clts', name],
    queryFn: ({ pageParam = 1 }) => getInternClts({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissal-financial',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: dismissedInternCltsResult,
    isLoading: isDismissedInternCltsResultLoading,
    hasNextPage: hasNextPageDismissedInternClts,
    fetchNextPage: fetchNextPageDismissedInternClts,
    isFetchingNextPage: isFetchingNextPageDismissedInternClts,
  } = useInfiniteQuery({
    queryKey: ['dismissed-intern-clts', name],
    queryFn: ({ pageParam = 1 }) => getInternClts({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissed',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })


  const columnsInternClts = [
    {
      title: 'Contratando',
      internClts: hiringInternCltsResult?.pages.flatMap((page) => page.internClts) || [],
      internCltsCount: hiringInternCltsResult?.pages[0]?.meta?.count,
      color: 'blue.300',
      hasNextPage: hasNextPageHiringInternClts,
      fetchNextPage: fetchNextPageHiringInternClts,
      isFetchingNextPage: isFetchingNextPageHiringInternClts,
      isLoading: isHiringInternCltsResultLoading,
    },
    {
      title: 'Registrando no iFractal',
      internClts: registeringInternCltsResult?.pages.flatMap((page) => page.internClts) || [],
      internCltsCount: registeringInternCltsResult?.pages[0]?.meta?.count,
      color: 'purple.300',
      hasNextPage: hasNextPageRegisteringInternClts,
      fetchNextPage: fetchNextPageRegisteringInternClts,
      isFetchingNextPage: isFetchingNextPageRegisteringInternClts,
      isLoading: isRegisteringInternCltsResultLoading,
    },
    {
      title: 'Enviando Detalhes Bancários',
      internClts: sendingBankDetailsInternCltsResult?.pages.flatMap((page) => page.internClts) || [],
      internCltsCount: sendingBankDetailsInternCltsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageSendingBankDetailsInternClts,
      fetchNextPage: fetchNextPageSendingBankDetailsInternClts,
      isFetchingNextPage: isFetchingNextPageSendingBankDetailsInternClts,
      isLoading: isSendingBankDetailsInternCltsResultLoading,
    },
    {
      title: 'Ativos',
      internClts: activeInternCltsResult?.pages.flatMap((page) => page.internClts) || [],
      internCltsCount: activeInternCltsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageActiveInternClts,
      fetchNextPage: fetchNextPageActiveInternClts,
      isFetchingNextPage: isFetchingNextPageActiveInternClts,
      isLoading: isActiveInternCltsResultLoading,
    },
    {
      title: 'Demissão Documentação',
      internClts: dismissalDocsInternCltsResult?.pages.flatMap((page) => page.internClts) || [],
      internCltsCount: dismissalDocsInternCltsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissalDocsInternClts,
      fetchNextPage: fetchNextPageDismissalDocsInternClts,
      isFetchingNextPage: isFetchingNextPageDismissalDocsInternClts,
      isLoading: isDismissalDocsInternCltsResultLoading,
    },
    {
      title: 'Aguardando fim de aviso previo',
      internClts: inNoticeInternCltsResult?.pages.flatMap((page) => page.internClts) || [],
      internCltsCount: inNoticeInternCltsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageInNoticeInternClts,
      fetchNextPage: fetchNextPageInNoticeInternClts,
      isFetchingNextPage: isFetchingNextPageInNoticeInternClts,
      isLoading: isInNoticeInternCltsResultLoading,
    },
    {
      title: 'Demissão Comunicação',
      internClts: dismissalComunicationInternCltsResult?.pages.flatMap((page) => page.internClts) || [],
      internCltsCount: dismissalComunicationInternCltsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissalComunicationInternClts,
      fetchNextPage: fetchNextPageDismissalComunicationInternClts,
      isFetchingNextPage: isFetchingNextPageDismissalComunicationInternClts,
      isLoading: isDismissalComunicationInternCltsResultLoading,
    },
    {
      title: 'Demissão Pagamento',
      internClts: dismissalPaymentInternCltsResult?.pages.flatMap((page) => page.internClts) || [],
      internCltsCount: dismissalPaymentInternCltsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissalPaymentInternClts,
      fetchNextPage: fetchNextPageDismissalPaymentInternClts,
      isFetchingNextPage: isFetchingNextPageDismissalPaymentInternClts,
      isLoading: isDismissalPaymentInternCltsResultLoading,
    },
    {
      title: 'Demissão Financeiro',
      internClts: dismissalFinancialInternCltsResult?.pages.flatMap((page) => page.internClts) || [],
      internCltsCount: dismissalFinancialInternCltsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissalFinancialInternClts,
      fetchNextPage: fetchNextPageDismissalFinancialInternClts,
      isFetchingNextPage: isFetchingNextPageDismissalFinancialInternClts,
      isLoading: isDismissalFinancialInternCltsResultLoading,
    },
    {
      title: 'Demitido',
      internClts: dismissedInternCltsResult?.pages.flatMap((page) => page.internClts) || [],
      internCltsCount: dismissedInternCltsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageDismissedInternClts,
      fetchNextPage: fetchNextPageDismissedInternClts,
      isFetchingNextPage: isFetchingNextPageDismissedInternClts,
      isLoading: isDismissedInternCltsResultLoading,
    },
    {
      title: 'Inativos',
      internClts: inactiveInternCltsResult?.pages.flatMap((page) => page.internClts) || [],
      internCltsCount: inactiveInternCltsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageInactiveInternClts,
      fetchNextPage: fetchNextPageInactiveInternClts,
      isFetchingNextPage: isFetchingNextPageInactiveInternClts,
      isLoading: isInactiveInternCltsResultLoading,
    },

  ]
  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Internos CLT</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/internos-clt')}
          />
          {userCanCreateInternClt && (
            <IconButton
              as={Link}
              aria-label="Criar Interno CLT"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              to="/interno-clt/criar"
            />
          )}
        </Flex>
      </Flex>
      <InternCltsTableFilters
        hasStatusFilter={false}
      />
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsInternClts?.map((internClt, index) => {
          return (
            <InternCltsKanbanColumn
              key={index}
              internClt={internClt}
            />
          )
        })}
      </Flex>
    </Box>
  )
}
