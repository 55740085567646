
import { Box, Button, Divider, Flex, Heading, Link, Spinner, Stack, Text } from "@chakra-ui/react"
import { Input } from "components/Inputs/Input"
import { TextArea } from "components/Inputs/TextInput"

import { useAuth } from "hooks/auth/useAuth"
import { useBranches } from "hooks/branch/useBranches"
import { useInput } from "hooks/input/useInput"
import { useOccurrence } from "hooks/occurrences/useOccurrence"
import { Fragment, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"
import { handleChangeUrl } from "utils/handleChangeUrl"

interface Params {
  id: string
}

interface OldInputsProps {
  input_id: string
  name: string
  quantity: number
  prev_quantity?: number
}

interface OccurrenceMaterials {
  input_id: string
  materialName: string
  quantity: number
}

export function OccurrenceDetailsPage() {
  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()
  const { id } = useParams<Params>()

  useEffect(() => {
    if (!userLogged.permissions.includes("view-occurrence-list")) {
      redirect("/")
    }
  }, [redirect, userLogged])
  const {
    occurrence: {
      data: occurrence,
      isFetching: isFetchingOccurrence
    },
    handleSetOccurrenceId
  } = useOccurrence(false)

  const { data: branches, isFetching: isFetchingBranches } = useBranches()

  const {
    inputs: { data: inputs, isLoading: isInputsLoading }
  } = useInput(null, true)


  const serviceNewSourceBranch = branches?.find((branch) => branch.id === occurrence?.service_new_source_branch_id)

  const serviceNewDestinationBranch = branches?.find((branch) => branch.id === occurrence?.service_new_destination_branch_id)

  const isAlterInputsOccurrence = occurrence?.intercurrence === "ALTERAÇÃO DE INSUMOS UTILIZADOS"

  const oldRegisteredInputs: OldInputsProps[] = occurrence !== undefined ? (occurrence?.prev_materials !== null ? JSON.parse(occurrence?.prev_materials) : []) : []

  const occurrenceMaterials = isAlterInputsOccurrence
    ? occurrence?.materials.reduce((acc, material) => {
      const inputFiltered = inputs?.find(input => input.id === material.input_id)

      if (material.input_id === "GELO SECO") {
        acc.push({
          input_id: material?.input_id,
          materialName: material?.input_id,
          quantity: material?.quantity
        })
      } else {
        acc.push({
          input_id: material?.input_id,
          materialName: inputFiltered?.name,
          quantity: material?.quantity
        })
      }


      return acc
    }, [] as OccurrenceMaterials[])
    : []

  const combineRegisteredInputsAndOccurrenceMaterials = (
    registeredInputs: OldInputsProps[],
    materials: OccurrenceMaterials[]
  ): OldInputsProps[] => {
    const newMaterialsArray: OldInputsProps[] = [];

    registeredInputs.forEach(input => {
      const matchingMaterial = materials?.find(
        material => input.input_id === material.input_id
      );

      if (matchingMaterial) {
        newMaterialsArray.push({
          input_id: input.input_id,
          name: input.name || matchingMaterial.materialName,
          prev_quantity: input.quantity,
          quantity: matchingMaterial.quantity,
        });
      }
    });

    return newMaterialsArray;
  };

  const updatedRegisteredInputs: OldInputsProps[] = combineRegisteredInputsAndOccurrenceMaterials(
    oldRegisteredInputs,
    occurrenceMaterials
  );

  useEffect(() => {
    if (id) {
      handleSetOccurrenceId(id)
    }
  }, [id, handleSetOccurrenceId])

  return (

    <Box
      p={8}
      bg='white'
      borderRadius='8px'
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Heading size='lg'>Detalhes da Ocorrência</Heading>

        <Divider />
        {isFetchingOccurrence || isFetchingBranches || isInputsLoading ? (
          <Spinner />
        ) : (
          <Stack w="full" spacing="24px" direction="column">
            <Stack w="full" spacing="24px" direction="column">
              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  label="Ocorrência"
                  name="occurrence_type"
                  value={occurrence?.type}
                  isDisabled={true}
                />

                <Input
                  label="Intercorrência"
                  name="intercurrence_type"
                  value={occurrence?.intercurrence}
                  isDisabled
                />
              </Stack>

              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  label="Protocolo Serviço"
                  name="service_protocol"
                  value={occurrence?.occurrenceIDService.protocol}
                  isDisabled
                />

                <Input
                  label="Cliente"
                  name="customer"
                  value={occurrence?.occurrenceIDCustomer.trading_firstname}
                  isDisabled
                />
              </Stack>
            </Stack>
            <Stack mt="4">
              <TextArea
                label="Observações"
                name="observation"
                value={
                  occurrence?.observation !== null
                    ? occurrence?.observation
                    : ''
                }
                isDisabled
              />
            </Stack>
            {occurrence?.intercurrence === 'TENTATIVA DE ENTREGA SEM SUCESSO' && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name='unsuccess_delivery_address_id'
                  value={`${occurrence?.unsuccessDeliveryAddress.trading_name} | ${occurrence?.unsuccessDeliveryAddress.branch} | ${occurrence?.unsuccessDeliveryAddress.street} | ${occurrence?.unsuccessDeliveryAddress.number} | ${occurrence?.unsuccessDeliveryAddress.neighborhood} | ${occurrence?.unsuccessDeliveryAddress.cityIDAddress.name} | ${occurrence?.unsuccessDeliveryAddress.cep}
            `.toUpperCase()}
                  label="Endereço"
                  isDisabled={true}
                />
              </Stack>
            )}

            {occurrence?.intercurrence === 'SERVIÇO EXTRA' && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  label="Valor Lançado"
                  name="occurrence_detail"
                  value={
                    occurrence?.occurrence_detail !== null
                      ? occurrence?.occurrence_detail
                      : ''
                  }
                  isDisabled={true}
                />
              </Stack>
            )}

            {occurrence?.intercurrence ===
              'DESVIO DE BUDGET DE TRANSFERÊNCIA' && (
                <Stack
                  w="full"
                  mt="4"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    label="Valor"
                    name="occurrence_detail"
                    value={
                      occurrence?.occurrence_detail !== null
                        ? occurrence?.occurrence_detail
                        : ''
                    }
                    isDisabled={true}
                  />
                </Stack>
              )}

            {occurrence?.intercurrence === 'ESPERA ACIMA DE 10 MIN' && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  label="Tempo"
                  name="occurrence_detail"
                  value={
                    occurrence?.occurrence_detail !== null
                      ? occurrence?.occurrence_detail
                      : ''
                  }
                  isDisabled={true}
                />
              </Stack>
            )}

            {occurrence?.intercurrence === 'INSUMOS ERRADOS' && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <TextArea
                  label="Descrição"
                  name="occurrence_detail"
                  value={
                    occurrence?.occurrence_detail !== null
                      ? occurrence?.occurrence_detail
                      : ''
                  }
                  isDisabled={true}
                />
              </Stack>
            )}

            {occurrence?.intercurrence === 'OUTRAS COBRANÇAS' && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  label="Valor"
                  name="occurrence_detail"
                  value={
                    occurrence?.occurrence_detail !== null
                      ? occurrence?.occurrence_detail
                      : ''
                  }
                  isDisabled={true}
                />
              </Stack>
            )}

            {(occurrence?.intercurrence === 'CANCELAMENTO DE VOO' ||
              occurrence?.intercurrence === 'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)') && (
                <Stack
                  w="full"
                  mt="4"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    label="Motivo do atraso"
                    name="occurrence_detail"
                    value={occurrence!.occurrence_detail ?? ''}
                    isDisabled
                  />
                  <Stack w="full" direction={['column', 'column', 'row']} spacing="24px">
                    <Input
                      label="Nova data prevista"
                      name="occurrence_date"
                      value={formatDate.handle(occurrence!.occurrence_date, "DateWithoutHourToShow")}
                      isDisabled={true}
                    />
                    <Input
                      label="Novo horário previsto"
                      name="occurrence_hour"
                      value={formatDate.handle(occurrence!.occurrence_hour, "DateOnlyWithHourMinute")}
                      isDisabled={true}
                    />
                  </Stack>
                </Stack>
              )}

            {(occurrence?.intercurrence === 'ATRASO NA COLETA' ||
              occurrence?.intercurrence === 'ATRASO NA ENTREGA') && (
                <Stack
                  w="full"
                  mt="4"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Stack
                    w="full"
                    spacing="24px"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      label="Previsão de Coleta/Entrega (Data)"
                      name="occurrence_date"
                      value={
                        occurrence?.occurrence_date !== null
                          ? formatDate.handle(occurrence?.occurrence_date, "DateWithoutHourToShow")
                          : ''
                      }
                      type="date"
                      isDisabled
                    />
                    <Input
                      label="Previsão de Coleta/Entrega (Horário)"
                      name="occurrence_hour"
                      value={
                        occurrence?.occurrence_hour !== null
                          ? formatDate.handle(occurrence?.occurrence_hour, "DateOnlyWithHourMinute")
                          : ''
                      }
                      type="time"
                      isDisabled
                    />
                  </Stack>

                  <Input
                    label="Motivo do Atraso"
                    name="occurrence_detail"
                    value={
                      occurrence?.occurrence_detail !== null
                        ? occurrence.occurrence_detail
                        : ''
                    }
                    isDisabled
                  />
                </Stack>
              )}
            {occurrence?.shipping_ticket_number && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name='shipping_ticket_number'
                  defaultValue={occurrence.shipping_ticket_number}
                  label='Número do chamado/ticket'
                  isDisabled
                />
              </Stack>
            )}
            {occurrence?.collector_fine_value && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name='collector_fine_value'
                  defaultValue={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(occurrence?.collector_fine_value / 100)
                  }
                  label='Valor da multa'
                  isDisabled
                />
              </Stack>
            )}
            {occurrence?.service_new_source_branch_id && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name='service_new_source_branch_id'
                  defaultValue={serviceNewSourceBranch?.nickname}
                  label='Nova Base de Origem'
                  isDisabled
                />
              </Stack>
            )}
            {occurrence?.service_new_destination_branch_id && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name='service_new_destination_branch_id'
                  defaultValue={serviceNewDestinationBranch?.nickname}
                  label='Nova Base de Origem'
                  isDisabled
                />
              </Stack>
            )}
            {occurrence?.intercurrence === 'RECLAMAÇÃO DE CLIENTE' && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <TextArea
                  label="Reclamação"
                  name="occurrence_detail"
                  value={
                    occurrence?.occurrence_detail !== null
                      ? occurrence?.occurrence_detail
                      : ''
                  }
                  isDisabled={true}
                />
              </Stack>
            )}

            {occurrence?.cause && (
              <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
                <TextArea
                  name="cause"
                  label="Causa Raiz"
                  value={occurrence?.cause}
                  isDisabled={true}
                />
              </Stack>
            )}
            {occurrence?.corrective && (
              <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
                <TextArea
                  name="corrective"
                  label="Ação Corretiva"
                  isDisabled={true}
                  value={occurrence?.corrective}
                />
              </Stack>
            )}
            {occurrence?.preventive && (
              <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
                <TextArea
                  name="preventive"
                  label="Ação Preventiva"
                  isDisabled={true}
                  value={occurrence?.preventive}
                />
              </Stack>
            )}
            {occurrence?.attachment_photo && (
              <Stack
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Button
                  as={Link}
                  href={handleChangeUrl(occurrence?.attachment_photo)}
                  isExternal
                >
                  Visualizar anexo da ocorrência
                </Button>
              </Stack>
            )}
            {(occurrence.materials && occurrence?.prev_materials) && updatedRegisteredInputs.map((input) => (
              <Stack
                mt="4"
                key={input.input_id}
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name={'name'}
                  label="Insumo"
                  defaultValue={input.name}
                  isDisabled={true}
                />
                <Input
                  name={`quantity`}
                  label="Quantidade"
                  defaultValue={input.quantity}
                  isDisabled={true}
                />
                <Input
                  name={`prev_quantity`}
                  label="Quantidade anterior"
                  defaultValue={input.prev_quantity}
                  isDisabled={true}
                />
              </Stack>
            ))}
            {occurrence.proceed !== null && (
              <>
                <Text as='b'>Informações de validação</Text>
                <Stack
                  w="full"
                  mt="4"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    name='proceed'
                    defaultValue={occurrence?.proceed}
                    label='Procede?'
                    isDisabled
                  />
                  {occurrence?.reason && (
                    <Input
                      name='reason'
                      defaultValue={occurrence.proceed}
                      label='Motivo'
                      isDisabled
                    />
                  )}
                </Stack>

              </>
            )}
            {occurrence?.is_alignment_required !== null && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name='isAlignmentRequired'
                  defaultValue={occurrence?.is_alignment_required
                    ? 'SIM' : 'NÃO'}
                  label='Será necessário alinhamento?'
                  isDisabled
                />
              </Stack>
            )}
            <Divider />
            {occurrence?.alignments.length > 0 && (
              <>
                <Text as='b'>Informações de alinhamento</Text>
                <Input
                  name='alignment_indicated_user'
                  defaultValue={`${occurrence.occurrenceIDAlignmentIndicatedUser?.firstname} ${occurrence.occurrenceIDAlignmentIndicatedUser?.lastname}`}
                  label='Usuário indicado no alinhamento'
                  isDisabled
                />
              </>
            )}

            {occurrence?.alignments?.map(alignment => {
              const alignmentRegistedAttachments = alignment?.attachments
                ? typeof alignment?.attachments !== 'string'
                  ? alignment?.attachments
                  : JSON.parse(alignment?.attachments)
                : []
              return (
                <Fragment key={alignment?.id}>
                  <Stack
                    w="full"
                    spacing="24px"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name='preventive_responsible'
                      defaultValue={`${alignment.preventiveResponsible.firstname} ${alignment.preventiveResponsible.lastname}`}

                      label='Responsável pela preventiva'
                      isDisabled
                    />
                    <Input
                      name='preventive_limit_date'
                      defaultValue={formatDate.handle(alignment.preventive_limit_date, "DateWithoutHourToInput")}

                      type='date'
                      label='Data limite da ação preventiva'
                      isDisabled
                    />
                  </Stack>

                  <TextArea
                    name='alignment_actions'
                    defaultValue={alignment.alignment_actions}

                    label='Ações de alinhamento'
                    mb={2}
                    isDisabled
                  />
                  {alignmentRegistedAttachments?.map((attachment) => {
                    return (
                      <Button
                        as={Link}
                        href={handleChangeUrl(attachment.key)}
                        isExternal
                      >
                        Visualizar anexo
                      </Button>

                    )
                  })}

                </Fragment>
              )
            })}
          </Stack>
        )}
      </Flex>
    </Box>

  )
}
