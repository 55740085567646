import { Box, Button, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Table, Tbody, Text } from "@chakra-ui/react";
import { useCollectors } from "hooks/collector/useCollectors";
import { useState } from "react";
import ReactInputMask from "react-input-mask";
import { handleSwitchMask } from "utils/handleChangeMask";
import { CarrierAttachmentField } from "./CarrierAttachmentField";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { createCarrier } from "api/carriers/createCarrier";
import { useToastify } from "hooks/toastify/useToastify";
import { useForm } from "react-hook-form";

export interface CreateCarrierSchema {
  name: string
  cpf: string
  email: string
  collectorId: string
  dtVaccineDoseAttachment: FileList
  hepatiteBVaccineDoseAttachment: FileList
  tripliceViralVaccineDoseAttachment: FileList
  cnhAttachment: FileList
  crlvAttachment: FileList
  anttMotorFreightAttachment: FileList
  vehicleSidePhotoAttachment: FileList
  vehicleRearPhotoAttachment: FileList
  vehicleInternPhotoAttachment: FileList
  trainingCertificateAttachmentId: FileList
  vehicle: string
}


export const carrierAttachmentsFields = {
  'Cartão de vacina - dose DT (difteria e tétano)': {
    fieldname: 'dtVaccineDoseAttachment',
    attachmentId: undefined
  },
  'Cartão de vacina - dose Hepatite B': {
    fieldname: 'hepatiteBVaccineDoseAttachment',
    attachmentId: undefined
  },
  'Cartão de vacina - dose tríplice viral': {
    fieldname: 'tripliceViralVaccineDoseAttachment',
    attachmentId: undefined
  },
  'CNH atual': {
    fieldname: 'cnhAttachment',
    attachmentId: undefined
  },
  'CRLV atual': {
    fieldname: 'crlvAttachment',
    attachmentId: undefined
  },
  'Curso de motofrete/ANTT': {
    fieldname: 'anttMotorFreightAttachment',
    attachmentId: undefined
  },
  'Foto da lateral da moto/carro': {
    fieldname: 'vehicleSidePhotoAttachment',
    attachmentId: undefined
  },
  'Foto da traseira da moto/carro': {
    fieldname: 'vehicleRearPhotoAttachment',
    attachmentId: undefined
  },
  'Foto interna do baú/carroceria': {
    fieldname: 'vehicleInternPhotoAttachment',
    attachmentId: undefined
  },
  'Certificado de treinamento': {
    fieldname: 'trainingCertificateAttachmentId',
    attachmentId: undefined
  },
}

const createCarrierSchema = yup.object({
  name: yup.string().required(),
  cpf: yup.string().required(),
  email: yup.string().required(),
  collectorId: yup.string().required(),
  dtVaccineDoseAttachment: yup.mixed().notRequired(),
  hepatiteBVaccineDoseAttachment: yup.mixed().notRequired(),
  tripliceViralVaccineDoseAttachment: yup.mixed().notRequired(),
  cnhAttachment: yup.mixed().test((value: FileList) => value?.length > 0),
  crlvAttachment: yup.mixed().notRequired(),
  anttMotorFreightAttachment: yup.mixed().notRequired(),
  vehicleSidePhotoAttachment: yup.mixed().notRequired(),
  vehicleRearPhotoAttachment: yup.mixed().notRequired(),
  vehicleInternPhotoAttachment: yup.mixed().notRequired(),
  trainingCertificateAttachmentId: yup.mixed().test((value: FileList) => value?.length > 0),
  vehicle: yup.string().required(),
})

interface CreateCarrierProps {
  onClose: () => void
}

export function CreateCarrier({ onClose }: CreateCarrierProps) {
  const [carrierAttachments, setCarrierAttachments] = useState(
    carrierAttachmentsFields
  )

  function handleSetCarrierAttachmentId(key: string, attachmentId: string) {
    const prevCarrierAttachments = { ...carrierAttachments }

    prevCarrierAttachments[key].attachmentId = attachmentId

    setCarrierAttachments(prevCarrierAttachments)
  }

  const formMethods = useForm<CreateCarrierSchema>({
    resolver: yupResolver(createCarrierSchema)
  })

  const {
    register,
    handleSubmit,
    formState: {
      isSubmitting,
      errors
    }
  } = formMethods

  const {
    data: collectorsResult,
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO',
    }
  })

  const queryClient = useQueryClient()

  const { mutateAsync: createCarrierFn } = useMutation({
    mutationFn: createCarrier,
    onSuccess(_data, _variables) {
      queryClient.invalidateQueries({ queryKey: ['carriers'] })

      onClose()
    },
  })

  const { promiseMessage } = useToastify()

  async function handleCreateCarrier(values: CreateCarrierSchema) {
    await promiseMessage(createCarrierFn({
      body: {
        collectorId: values.collectorId,
        cpf: values.cpf,
        email: values.email,
        name: values.name,
        vehicle: values.vehicle,
        anttMotorFreightAttachmentId: carrierAttachments['Curso de motofrete/ANTT'].attachmentId,
        cnhAttachmentId: carrierAttachments['CNH atual'].attachmentId,
        crlvAttachmentId: carrierAttachments['CRLV atual'].attachmentId,
        dtVaccineDoseAttachmentId: carrierAttachments['Cartão de vacina - dose DT (difteria e tétano)'].attachmentId,
        hepatiteBVaccineDoseAttachmentId: carrierAttachments['Cartão de vacina - dose Hepatite B'].attachmentId,
        tripliceViralVaccineDoseAttachmentId: carrierAttachments['Cartão de vacina - dose tríplice viral'].attachmentId,
        vehicleInternPhotoAttachmentId: carrierAttachments['Foto interna do baú/carroceria'].attachmentId,
        vehicleSidePhotoAttachmentId: carrierAttachments['Foto da lateral da moto/carro'].attachmentId,
        vehicleRearPhotoAttachment: carrierAttachments['Foto da traseira da moto/carro'].attachmentId,
        trainingCertificateAttachmentId: carrierAttachments['Certificado de treinamento'].attachmentId,
      }
    }), 'Portador criado com sucesso! 🎉')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Criar portador
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody
        maxH='600px'
        overflowY='scroll'
      >
        <Box
          w="full"
          as="form"
          onSubmit={handleSubmit(handleCreateCarrier)}
        >
          <Stack
            w="full"
            mt={3}
            spacing={3}
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>
                Nome
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Input
                {...register('name')}
                name="name"
              />
            </FormControl>

            <FormControl isInvalid={!!errors.cpf}>
              <FormLabel>
                CPF
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Input
                {...register("cpf")}
                as={ReactInputMask}
                mask={handleSwitchMask('cpf')}
                name="cpf"
                alwaysShowMask={true}
              />
            </FormControl>
          </Stack>

          <Stack
            mt={3}
            spacing={3}
            w="full"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl isInvalid={!!errors.email}>
              <FormLabel>
                Email
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('email')}
                name="email"
              />
            </FormControl>

            <FormControl isInvalid={!!errors.vehicle}>

              <FormLabel>
                Veículo
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Select
                {...register("vehicle")}
                name="vehicle"
                placeholder="Selecione uma opção..."
              >
                <option value="Carro">Carro</option>
                <option value="Moto">Moto</option>
              </Select>
            </FormControl>
          </Stack>
          <FormControl isInvalid={!!errors.collectorId}>

            <FormLabel mt={3}>
              Coletador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("collectorId")}
              name="collectorId"
              placeholder="Selecione uma opção..."
            >
              {collectorsResult?.map((collector) => {
                return <option key={collector.id} value={collector.id}>{collector.trading_name}</option>
              })}
            </Select>
          </FormControl>

          <Table mt={3} size="sm">
            <Tbody>
              {Object.entries(carrierAttachmentsFields).map(([label, field]) => {
                return (
                  <CarrierAttachmentField
                    key={label}
                    register={register}
                    errors={errors}
                    label={label}
                    fieldname={field.fieldname}
                    onSetCarrierAttachmentId={handleSetCarrierAttachmentId}
                  />
                )
              })}
            </Tbody>
          </Table>


          <Stack w="full" mt={3}>
            <Button
              type="submit"
              colorScheme="blue"
              alignSelf="end"
              isLoading={isSubmitting}
            >
              Criar
            </Button>
          </Stack>
        </Box>
      </ModalBody>
    </ModalContent>
  )
}
