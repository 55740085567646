import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, IconButton, Input, InputGroup, InputLeftAddon, Modal, ModalOverlay, Stack, Table, Tbody, Td, Text, Textarea, Tr, useDisclosure } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { GetVacationsResponse } from "api/vacations/getVacations";
import { payVacation } from "api/vacations/payVacation";

import { useToastify } from "hooks/toastify/useToastify";
import { CreateSupport } from "pages/Support/components/CreateSupport";
import { Fragment, useCallback, useState } from "react";
import { useCurrency } from "react-hook-currency";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FaDollarSign, FaPlus } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import { validateHasFile } from "utils/fileValidation";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import * as yup from "yup"
import { PaymentAttachmentField } from "./PaymentAttachmentField";

export interface PayVacationSchema {
  paymentDate: string
  paymentValue: string
  costCenter: string
  description: string
  paymentAttachments: {
    attachment: FileList
  }[]
}

interface PayVacationProps {
  vacationId: string
}

const payVacationSchema = yup.object({
  paymentDate: yup.string().required(),
  paymentValue: yup.string().required(),
  costCenter: yup.string().required(),
  description: yup.string().required(),
  paymentAttachments: yup
    .array()
    .min(1)
    .of(yup.object({
      attachment: yup.mixed().test(value => validateHasFile(value))
    }))
})

export interface PaymentAttachment {
  id: string
  attachmentId: string
}

export function PayVacation({ vacationId }: PayVacationProps) {
  const [paymentAttachments, setPaymentAttachments] = useState<PaymentAttachment[]>([])



  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<PayVacationSchema>({
    defaultValues: {
      paymentAttachments: [{ attachment: undefined }]
    },
    resolver: yupResolver(payVacationSchema)
  })

  const {
    fields: paymentAttachmentsFields,
    append: appendPaymentAttachmentField,
    remove: removePaymentAttachmentField,
  } = useFieldArray({
    control,
    name: 'paymentAttachments'
  })

  const { format: currencyFormat, onChange: onCurrencyChange } = useCurrency({
    style: 'decimal'
  })

  const queryClient = useQueryClient()

  const { mutateAsync: payVacationFn } = useMutation({
    mutationFn: payVacation,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetVacationsResponse>({
        queryKey: ['vacations']
      })
      queryClient.invalidateQueries({ queryKey: 'active-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planning-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planned-vacations' })
      queryClient.invalidateQueries({ queryKey: 'paying-vacations' })
      queryClient.invalidateQueries({ queryKey: 'waiting-vacations' })
      queryClient.invalidateQueries({ queryKey: 'on-vacations' })
      queryClient.invalidateQueries({ queryKey: 'done-vacations' })
      queryClient.invalidateQueries({ queryKey: 'dismissed-vacations' })
      queryClient.invalidateQueries({ queryKey: ['vacation', vacationId] })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          vacations: cachedValue.vacations.map((vacation) => {
            if (vacation.id === variables.vacationId) {
              return { ...vacation, status: 'waiting' }
            }

            return vacation
          })
        })
      })
    },
  })

  const { promiseMessage } = useToastify()

  async function handlePayVacation(values: PayVacationSchema) {
    await promiseMessage(payVacationFn({
      body: {
        ...values,
        paymentValueInCents: transformStringToNumber(values.paymentValue) * 100,
        paymentAttachmentsIds: paymentAttachments.map(attachment => attachment.attachmentId)
      },
      vacationId,
    }), 'Pagamento realizado com sucesso!')
  }

  const handleAddPaymentAttachment = useCallback((values: PaymentAttachment) => {
    setPaymentAttachments(prevAttachments => Array.from(new Set([...prevAttachments, values])))
  }, [])

  function handleRemovePaymentAttachment(index: number, fieldId: string) {
    setPaymentAttachments(prevAttachments => prevAttachments.filter(attachment => attachment.id !== fieldId))

    removePaymentAttachmentField(index)
  }

  const {
    isOpen: isCreateSupportOpen,
    onOpen: onOpenCreateSupport,
    onClose: onCloseCreateSupport
  } = useDisclosure()

  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      px={2}
      onSubmit={handleSubmit(handlePayVacation)}
    >
      <Heading letterSpacing="tight" size='sm'>Pagar férias</Heading>
      <FormControl isInvalid={!!errors.paymentDate} mt={3}>
        <FormLabel fontSize="sm">
          Data de pagamento
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Input
          {...register("paymentDate")}
          name="paymentDate"
          type="date"
          size="sm"
          rounded="md"
        />
      </FormControl>

      <Stack
        spacing={3}
        direction={{ base: 'column', lg: 'row' }}
      >
        <FormControl isInvalid={!!errors.paymentDate} mt={3}>
          <FormLabel fontSize="sm">
            Valor
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Controller
            control={control}
            name="paymentValue"
            render={({ field }) => {
              return (
                <InputGroup size="sm">
                  <InputLeftAddon>
                    <FaDollarSign />
                  </InputLeftAddon>
                  <Input
                    {...register("paymentValue")}
                    name={field.name}
                    value={field.value}
                    onChange={event => field.onChange(onCurrencyChange(event))}
                    defaultValue={currencyFormat('000')}
                    rounded="md"
                  />
                </InputGroup>
              )
            }}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.paymentDate} mt={3}>
          <FormLabel fontSize="sm">
            Centro de custo
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Input
            {...register("costCenter")}
            name="costCenter"
            size="sm"
            rounded="md"
          />
        </FormControl>

      </Stack>

      <FormControl isInvalid={!!errors.description} mt={3}>
        <FormLabel fontSize="sm">
          Descrição
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Textarea
          {...register("description")}
          name="description"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <Flex mt={3} alignItems="baseline" justifyContent="space-between">
        <FormLabel fontSize='sm'>
          Solicitar pagamento de férias
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <IconButton
          aria-label="Abrir atendimento"
          icon={<FaPlus />}
          colorScheme="blue"
          size="sm"
          onClick={onOpenCreateSupport} />
      </Flex>
      <Modal
        isOpen={isCreateSupportOpen}
        onClose={onCloseCreateSupport}
        isCentered
        size="2xl"
      >
        <ModalOverlay />
        <CreateSupport
          onClose={onCloseCreateSupport}
          required={true} />
      </Modal>

      <FormLabel fontSize="sm" mt={3}>
        Anexos de pagamento
        <Text as="sup" color="red.500">*</Text>
      </FormLabel>
      <Table w="full" size="sm">
        <Tbody>
          {paymentAttachmentsFields.map((field, index) => {
            return (
              <Fragment key={field.id}>
                <PaymentAttachmentField
                  fieldId={field.id}
                  key={field.id}
                  errors={errors}
                  fieldIndex={index}
                  register={register}
                  onAddPaymentAttachment={handleAddPaymentAttachment}
                  onRemovePaymentAttachment={handleRemovePaymentAttachment}
                />
                <Tr>
                  <Td border="none"></Td>
                  <Td border="none"></Td>
                  <Td border="none"></Td>
                  <Td border="none"></Td>
                  <Td border="none"></Td>
                </Tr>
              </Fragment>
            )
          })}
        </Tbody>
      </Table>

      <HStack justify="end">
        <IconButton
          aria-label="Adicionar anexo"
          size="sm"
          icon={<FaPlus />}
          colorScheme="blue"
          onClick={() => appendPaymentAttachmentField({ attachment: undefined })}
        />
      </HStack>

      <HStack
        w="full"
        justify="end"
        mt={3}
      >
        <Button
          type="submit"
          colorScheme="blue"
          ml="auto"
          size="sm"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Salvar
        </Button>
      </HStack>
    </Box>
  )
}
