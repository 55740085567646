import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { CltIntercurrence, CltIntercurrenceStatus } from "./types/CltIntercurrence";

export interface GetCltIntercurrenceParams {
  pageSize: string
  currentPage: string
  status?: CltIntercurrenceStatus | 'all',
  startDate?: string
  endDate?: string
  type?: string
  collaboratorName?: string
  intercurrenceId?: string
}

export interface GetCltIntercurrencesResponse {
  cltIntercurrences: CltIntercurrence[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
    count: number
  }
}


export async function getCltIntercurrences({
  currentPage,
  pageSize,
  status,
  startDate,
  endDate,
  type,
  collaboratorName,
  intercurrenceId
}: GetCltIntercurrenceParams) {
  try {
    const response = await api.get<GetCltIntercurrencesResponse>('/clt-intercurrences', {
      params: {
        pageSize,
        currentPage,
        status,
        startDate,
        endDate,
        type,
        collaboratorName,
        intercurrenceId
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
