import { Button, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deletePatrimonyType } from "api/patrimonies/deletePatrimonyType";
import { PatrimonyType } from "api/patrimonies/patrimonyType";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaArrowRight, FaTrash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { CreateEditPatrimonyType } from "./CreateEditPatrimonyType";

interface PatrimonyTypeTableRowProps {
  patrimonyType: PatrimonyType
}
export function PatrimonyTypeTableRow({ patrimonyType }: PatrimonyTypeTableRowProps) {
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const userCanEditPatrimonyType = userLogged?.permissions.includes('edit-patrimony-type')
  const userCanDeletePatrimonyType = userLogged?.permissions.includes('delete-patrimony-type')

  const { mutateAsync: deletePatrimonyTypeFn } = useMutation({
    mutationFn: deletePatrimonyType,
    onSuccess() {
      queryClient.invalidateQueries(['patrimonyTypes'])
    },
  })


  const {
    isOpen: isEditPatrimonyTypeModalOpen,
    onOpen: onOpenEditPatrimonyTypeModal,
    onClose: onCloseEditPatrimonyTypeModal
  } = useDisclosure()


  async function handleDeletePatrimonyType(patrimonyTypeId: string) {
    await promiseMessage(deletePatrimonyTypeFn({
      patrimonyTypeId: patrimonyTypeId
    }), 'Tipo de patrimônio excluído!')
  }

  return (
    <Tr>
      <Td>{patrimonyType.type}</Td>
      <Td>{patrimonyType.isControlledByIdentifier ? 'Sim' : 'Não'}</Td>
      <Td isNumeric>
        {userCanEditPatrimonyType && (
          <>
            <Modal
              isOpen={isEditPatrimonyTypeModalOpen}
              onClose={onCloseEditPatrimonyTypeModal}
              isCentered
            >
              <ModalOverlay />

              <CreateEditPatrimonyType
                onCloseModal={onCloseEditPatrimonyTypeModal}
                slug='edit'
                patrimonyTypeId={patrimonyType.id}
                patrimonyType={patrimonyType.type}
                isControlledByIdentifier={patrimonyType?.isControlledByIdentifier}
              />

            </Modal>
            <Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              onClick={onOpenEditPatrimonyTypeModal}
              mr={2}
            >
              Editar
            </Button>
          </>
        )}
        {userCanDeletePatrimonyType && (
          <Button
            lineHeight="1"
            leftIcon={<FaTrash />}
            size="xs"
            onClick={() => handleDeletePatrimonyType(patrimonyType.id)}
          >
            Deletar
          </Button>
        )}
      </Td>

    </Tr>
  )
}
