import { useContext, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import swal from 'sweetalert'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { RequestedServiceContext } from '../../contexts/ServiceContext'
import {
  add,
  compareAsc,
  differenceInMinutes,
  format,
  formatDistanceStrict,
  getDay,
  getHours,
  getMinutes,
  set,
} from 'date-fns'
import { ServiceEditDetailValidateForm } from './ServiceEditDetailValidateForm'
import { serviceFormatDataToBackEdit } from '../../utils/ServiceFunctions/serviceFormatDataToBackEdit'
import { useCity } from '../../hooks/city/useCity'
import { useBudget } from '../../hooks/budget/useBudget'
import { LogContext } from '../../contexts/LogContext'
import { useService } from '../../hooks/services/service'
import { useStock } from '../../hooks/stock/useStock'
import { useHoliday } from '../../hooks/holiday/useHoliday'
import { useSwal } from '../../hooks/swal/useSwal'
import { useAuth } from '../../hooks/auth/useAuth'
import { validateSourceCollectorVehicleHasCost } from '../../utils/ServiceFunctions/Validations/validateSourceCollectorVehicleHasCost'
import { validateDestinationCollectorVehicleHasCost } from '../../utils/ServiceFunctions/Validations/validateDestinationCollectorVehicleHasCost'
import { validateHolidayByDeliveryDate } from '../../utils/ServiceFunctions/Validations/validateHolidayByDeliveryDate'
import { validateHolidayByCollectDate } from '../../utils/ServiceFunctions/Validations/validateHolidayByCollectDate'
import { useAddresses } from '../../hooks/address/useAddresses'
import { GeloSecoAddressesProps } from 'utils/RequestFunctions/Service/Request/requestRequestServiceFunctions'
import { useCollectorCosts } from 'hooks/cost/useCollectorCosts'

interface IAttachments {
  attachment: FileList
}


interface IFormInputProps {
  customer_id: string
  budget_id: string
  owner: string
  source_address_id: string[]
  destination_address_id: string[]
  destination_addresses_input:
  | {
    address_id: string
    quantity: number
  }[]
  | null
  gelo_seco_addresses: GeloSecoAddressesProps[]

  is_unique_collect_address_service: boolean
  is_edit_page: boolean
  has_crossdocking: string
  shipping_id: string

  crossdocking_collector_id: string | null
  source_crossdocking_branch_id: string | null
  destination_crossdocking_branch_id: string | null
  source_collector_id: string | null
  destination_collector_id: string | null
  source_branch_id: string | null
  destination_branch_id: string | null
  provider_id: string | null
  deadline: number

  crossdocking_planned_flight: string | null
  planned_flight: string
  crossdocking_board_date: string | null
  crossdocking_board_hour: string | null
  board_date: string
  board_hour: string

  crossdocking_availability_forecast_day: string | null
  crossdocking_availability_forecast_time: string | null

  availability_forecast_day: string
  availability_forecast_time: string
  service_type: string
  franchising: number

  planned_connection_flight: string | null
  crossdocking_planned_connection_flight: string | null

  modal: string
  crossdocking_modal: string

  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  collect_date: string
  collect_hour_start: string
  collect_hour_end: string
  delivery_date: string
  delivery_hour: string
  material_type: string
  attachments?: IAttachments[]
  observation: string
  observations_for_validation_planning?: string
}

interface QueryParams {
  slug: string
  flag: string
  id: string
}

export function ServiceEditDetailValidate() {
  const [isLoading, setIsLoading] = useState(false)
  const [hasCancelled, setHasCancelled] = useState(false)
  const [hasValidate, setHasValidate] = useState(false)
  const [hasCurrent, setHasCurrent] = useState(false)

  const {
    editRequestedService,
    validateRequestedService,
    editCurrentRequestedService,
  } = useContext(RequestedServiceContext)
  const { userLogged } = useAuth()
  const { createLog } = useContext(LogContext)

  const history = useHistory()
  const { slug, flag, id }: QueryParams = useParams()
  const { standardMessage, confirmMessage } = useSwal()

  const {
    service: { data: serviceById },
  } = useService(!flag ? id : null, false, false)
  const {
    cities: { data: cities },
  } = useCity(null, true, false)
  const {
    budgets: { data: budgets },
  } = useBudget(null, true, false)
  const {
    stocks: { data: stocks },
  } = useStock(null, true, false)
  const {
    holidays: { data: holidays },
  } = useHoliday(null, true)
  const {
    data: collectorCosts,
    isLoading: isCollectorCostsLoading,
  } = useCollectorCosts()
  const { data: addresses } = useAddresses()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'visualizar') {
            if (!permissions?.includes('view-requested-service')) {
              history.push('/')
            }
          }
          if (slug === 'editar') {
            if (!permissions?.includes('edit-requested-service')) {
              history.push('/')
            }
          }
          if (slug === 'validar') {
            if (!permissions?.includes('valid-requested-service')) {
              history.push('/')
            }
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  async function handleEditService(values: IFormInputProps) {
    // EDITAR SERVIÇO RECORRENTE

    // CANCELAR SERVIÇO
    if (hasCancelled === true) {
      return;
    }

    // VALIDAR SERVIÇO

    if (hasValidate === true && budgets && holidays) {
      const dayOfWeekByCollect = getDay(add(new Date(values.collect_date), { days: 1 }))
      const [collectStartHour, collectStartMinutes] = values.collect_hour_start.split(':')
      const [collectEndHour, collectEndMinutes] = values.collect_hour_end.split(':')

      const collectStartHourAsDate = set(new Date(), {
        hours: Number(collectStartHour),
        minutes: Number(collectStartMinutes),
        seconds: 0
      })

      const collectEndHourAsDate = set(new Date(), {
        hours: Number(collectEndHour),
        minutes: Number(collectEndMinutes),
        seconds: 0
      })

      const invalidSourceAddressesByDay: string[] = []
      const invalidSourceAddressesByHour: string[] = []

      if (dayOfWeekByCollect >= 1 && dayOfWeekByCollect <= 5) {

        addresses
          ?.filter(address => values.source_address_id.includes(address.id))
          .forEach(address => {
            if (address.business_open === null || address.business_close === null) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              return invalidSourceAddressesByDay
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }

            const [, addressBusinessOpenTime] = format(new Date(address.business_open), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressBusinessOpenHour, addressBusinessOpenMinutes,] = addressBusinessOpenTime.split(':')
            const [, addressBusinessCloseTime] = format(new Date(address.business_close), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressBusinessCloseHour, addressBusinessCloseMinutes,] = addressBusinessCloseTime.split(':')

            const differenceAddressOpenToCollectStart = differenceInMinutes(
              collectStartHourAsDate,
              set(new Date(), {
                hours: Number(addressBusinessOpenHour),
                minutes: Number(addressBusinessOpenMinutes),
                seconds: 0
              })
            )

            const differenceAddressCloseToCollectEnd = differenceInMinutes(
              collectEndHourAsDate,
              set(new Date(), {
                hours: Number(addressBusinessCloseHour),
                minutes: Number(addressBusinessCloseMinutes),
                seconds: 0
              })
            )

            if (differenceAddressOpenToCollectStart < 0 || differenceAddressCloseToCollectEnd > 0) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              invalidSourceAddressesByHour
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }
          })
      }

      if (dayOfWeekByCollect === 6) {

        addresses
          ?.filter(address => values.source_address_id.includes(address.id))
          .forEach(address => {
            if (address.saturday_open === null || address.saturday_close === null) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              return invalidSourceAddressesByDay
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }

            const [, addressSaturdayOpenTime] = format(new Date(address.saturday_open), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressSaturdayOpenHour, addressSaturdayOpenMinutes,] = addressSaturdayOpenTime.split(':')
            const [, addressSaturdayCloseTime] = format(new Date(address.saturday_close), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressSaturdayCloseHour, addressSaturdayCloseMinutes,] = addressSaturdayCloseTime.split(':')

            const differenceAddressOpenToCollectStart = differenceInMinutes(
              collectStartHourAsDate,
              set(new Date(), {
                hours: Number(addressSaturdayOpenHour),
                minutes: Number(addressSaturdayOpenMinutes),
                seconds: 0
              })
            )

            const differenceAddressCloseToCollectEnd = differenceInMinutes(
              collectEndHourAsDate,
              set(new Date(), {
                hours: Number(addressSaturdayCloseHour),
                minutes: Number(addressSaturdayCloseMinutes),
                seconds: 0
              })
            )

            if (differenceAddressOpenToCollectStart < 0 || differenceAddressCloseToCollectEnd > 0) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              invalidSourceAddressesByHour
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }
          })
      }

      if (dayOfWeekByCollect === 0) {

        addresses
          ?.filter(address => values.source_address_id.includes(address.id))
          .forEach(address => {
            if (address.sunday_open === null || address.sunday_close === null) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              return invalidSourceAddressesByDay
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }

            const [, addressSundayOpenTime] = format(new Date(address.sunday_open), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressSundayOpenHour, addressSundayOpenMinutes,] = addressSundayOpenTime.split(':')
            const [, addressSundayCloseTime] = format(new Date(address.sunday_close), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressSundayCloseHour, addressSundayCloseMinutes,] = addressSundayCloseTime.split(':')

            const differenceAddressOpenToCollectStart = differenceInMinutes(
              collectStartHourAsDate,
              set(new Date(), {
                hours: Number(addressSundayOpenHour),
                minutes: Number(addressSundayOpenMinutes),
                seconds: 0
              })
            )

            const differenceAddressCloseToCollectEnd = differenceInMinutes(
              collectEndHourAsDate,
              set(new Date(), {
                hours: Number(addressSundayCloseHour),
                minutes: Number(addressSundayCloseMinutes),
                seconds: 0
              })
            )

            if (differenceAddressOpenToCollectStart < 0 || differenceAddressCloseToCollectEnd > 0) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              invalidSourceAddressesByHour
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }
          })
      }

      if (invalidSourceAddressesByDay.length) {
        return swal(`
        Os seguintes endereços de origem não possuem atendimento na data solicitada
        ${invalidSourceAddressesByDay.map(address => address).join("\n")}
        `)
      }

      if (invalidSourceAddressesByHour.length) {
        return swal(`
        Os seguintes endereços de origem não possuem atendimento dentro do bloco de horário selecionado para coleta
        ${invalidSourceAddressesByHour.map(address => address).join("\n")}
        `)
      }

      const dayOfWeekByDelivery = getDay(add(new Date(values.delivery_date), { days: 1 }))
      const [deliveryHour, deliveryMinutes] = values.delivery_hour.split(':')

      const deliveryHourAsDate = set(new Date(), {
        hours: Number(deliveryHour),
        minutes: Number(deliveryMinutes),
        seconds: 0
      })

      const invalidDestinationAddressesByDay: string[] = []
      const invalidDestinationAddressesByHour: string[] = []

      if (dayOfWeekByDelivery >= 1 && dayOfWeekByDelivery <= 5) {
        addresses
          ?.filter(address => values.destination_address_id.includes(address.id))
          .forEach(address => {
            if (address.business_open === null || address.business_close === null) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              return invalidDestinationAddressesByDay
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }

            const [, addressBusinessOpenTime] = format(new Date(address.business_open), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressBusinessOpenHour, addressBusinessOpenMinutes,] = addressBusinessOpenTime.split(':')
            const [, addressBusinessCloseTime] = format(new Date(address.business_close), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressBusinessCloseHour, addressBusinessCloseMinutes,] = addressBusinessCloseTime.split(':')

            const differenceAddressOpenToDeliveryHour = differenceInMinutes(
              deliveryHourAsDate,
              set(new Date(), {
                hours: Number(addressBusinessOpenHour),
                minutes: Number(addressBusinessOpenMinutes),
                seconds: 0
              })
            )

            const differenceAddressCloseToDelivery = differenceInMinutes(
              deliveryHourAsDate,
              set(new Date(), {
                hours: Number(addressBusinessCloseHour),
                minutes: Number(addressBusinessCloseMinutes),
                seconds: 0
              })
            )

            if (differenceAddressOpenToDeliveryHour < 0 || differenceAddressCloseToDelivery > 0) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              return invalidDestinationAddressesByHour
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }
          })
      }

      if (dayOfWeekByDelivery === 6) {
        addresses
          ?.filter(address => values.destination_address_id.includes(address.id))
          .forEach(address => {
            if (address.saturday_open === null || address.saturday_close === null) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              return invalidDestinationAddressesByDay
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }

            const [, addressSaturdayOpenTime] = format(new Date(address.saturday_open), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressSaturdayOpenHour, addressSaturdayOpenMinutes,] = addressSaturdayOpenTime.split(':')
            const [, addressSaturdayCloseTime] = format(new Date(address.saturday_close), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressSaturdayCloseHour, addressSaturdayCloseMinutes,] = addressSaturdayCloseTime.split(':')

            const differenceAddressOpenToDeliveryHour = differenceInMinutes(
              deliveryHourAsDate,
              set(new Date(), {
                hours: Number(addressSaturdayOpenHour),
                minutes: Number(addressSaturdayOpenMinutes),
                seconds: 0
              })
            )

            const differenceAddressCloseToDelivery = differenceInMinutes(
              deliveryHourAsDate,
              set(new Date(), {
                hours: Number(addressSaturdayCloseHour),
                minutes: Number(addressSaturdayCloseMinutes),
                seconds: 0
              })
            )

            if (differenceAddressOpenToDeliveryHour < 0 || differenceAddressCloseToDelivery > 0) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              return invalidDestinationAddressesByHour
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }
          })
      }

      if (dayOfWeekByDelivery === 0) {
        addresses
          ?.filter(address => values.destination_address_id.includes(address.id))
          .forEach(address => {
            if (address.sunday_open === null || address.sunday_close === null) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              return invalidDestinationAddressesByDay
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }

            const [, addressSundayOpenTime] = format(new Date(address.sunday_open), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressSundayOpenHour, addressSundayOpenMinutes,] = addressSundayOpenTime.split(':')
            const [, addressSundayCloseTime] = format(new Date(address.sunday_close), "yyyy-MM-dd'T'HH:mm:ss").split('T')
            const [addressSundayCloseHour, addressSundayCloseMinutes,] = addressSundayCloseTime.split(':')

            const differenceAddressOpenToDeliveryHour = differenceInMinutes(
              deliveryHourAsDate,
              set(new Date(), {
                hours: Number(addressSundayOpenHour),
                minutes: Number(addressSundayOpenMinutes),
                seconds: 0
              })
            )

            const differenceAddressCloseToDelivery = differenceInMinutes(
              deliveryHourAsDate,
              set(new Date(), {
                hours: Number(addressSundayCloseHour),
                minutes: Number(addressSundayCloseMinutes),
                seconds: 0
              })
            )

            if (differenceAddressOpenToDeliveryHour < 0 || differenceAddressCloseToDelivery > 0) {
              const addressHasTradingName = address.trading_name && address.trading_name !== ''

              return invalidDestinationAddressesByHour
                .push(`${addressHasTradingName ? address.trading_name : '-'} | ${address.branch} | ${address.street}`)
            }
          })
      }

      if (invalidDestinationAddressesByDay.length) {
        return swal(`
        Os seguintes endereços de destino não possuem atendimento na data solicitada!
        ${invalidDestinationAddressesByDay.map(address => address).join("\n")}
        `)
      }

      if (invalidDestinationAddressesByHour.length) {
        return swal(`
        Os seguintes endereços de destino não possuem atendimento dentro do bloco de horário selecionado para entrega
        ${invalidDestinationAddressesByHour.map(address => address).join("\n")}
        `)
      }


      const budgetFilteredById = budgets.filter(
        (budget) => budget.id === values.budget_id,
      )
      const sourceCitiesOfBudgetFilter = budgetFilteredById.flatMap(
        (budget) => budget.source_cities,
      )
      const destinationCitiesOfBudgetFilter = budgetFilteredById.flatMap(
        (budget) => budget.destination_cities,
      )

      const hasValidateHolidayByCollectDateMessage =
        await validateHolidayByCollectDate.handle(
          holidays,
          values.collect_date,
          sourceCitiesOfBudgetFilter,
        )
      const hasValidateHolidayByDeliveryDateMessage =
        await validateHolidayByDeliveryDate.handle(
          holidays,
          values.delivery_date,
          destinationCitiesOfBudgetFilter,
        )
      const hasValidadeSourceCollectorVehicleHasCostMessage =
        await validateSourceCollectorVehicleHasCost.handle(
          collectorCosts!,
          values.source_collector_id!,
          values.vehicle,
        )
      const hasValidadeDestinationCollectorVehicleHasCostMessage =
        await validateDestinationCollectorVehicleHasCost.handle(
          collectorCosts!,
          values.destination_collector_id!,
          values.vehicle,
        )

      const hasCreateServiceInHolidayByCollectDate =
        hasValidateHolidayByCollectDateMessage !== ''
          ? await confirmMessage({
            title: hasValidateHolidayByCollectDateMessage,
          })
          : true

      if (hasCreateServiceInHolidayByCollectDate) {
        const hasCreateServiceInHolidayByDeliveryDate =
          hasValidateHolidayByDeliveryDateMessage !== ''
            ? await confirmMessage({
              title: hasValidateHolidayByDeliveryDateMessage,
            })
            : true
        if (hasCreateServiceInHolidayByDeliveryDate) {
          const hasConfirmSourceCollectorVehicleValidation =
            hasValidadeSourceCollectorVehicleHasCostMessage
              ? await confirmMessage({
                title: hasValidadeSourceCollectorVehicleHasCostMessage,
              })
              : true
          if (hasConfirmSourceCollectorVehicleValidation) {
            const hasConfirmDestinationCollectorVehicleValidation =
              hasValidadeDestinationCollectorVehicleHasCostMessage
                ? await confirmMessage({
                  title: hasValidadeDestinationCollectorVehicleHasCostMessage,
                })
                : true
            if (hasConfirmDestinationCollectorVehicleValidation) {
              await swal({
                title: 'Deseja validar um serviço?',
                text: 'Essa ação não poderá ser desfeita!',
                icon: 'warning',
                buttons: ['Cancelar', 'Confirmar'],
                dangerMode: false,
              }).then(async (willEdit) => {
                if (willEdit && stocks && serviceById) {
                  const stockByCollectorId = stocks
                    .filter(
                      (stock) =>
                        stock.collector_id === values.source_collector_id,
                    )
                    .filter((stock) => stock.current_stock > 0)
                    .map((stock) => ({
                      current: stock.current_stock,
                      name: stock.inputIDStock.name,
                    }))

                  const requestedMaterials = [
                    {
                      name: 'ALMOFADA ABSORVENTE',
                      quantity: values.embalagem_secundaria,
                    },
                    {
                      name: 'EMBALAGEM SECUNDÁRIA ZIPLOCK',
                      quantity: values.embalagem_secundaria,
                    },
                    { name: 'LACRE', quantity: values.embalagem_secundaria },
                    {
                      name: 'GELOX',
                      quantity: values.caixa_termica > 0 ? 0 : values.gelox,
                    },
                    { name: 'ISOPOR 3L', quantity: values.isopor3l },
                    { name: 'ISOPOR 7L', quantity: values.isopor7l },
                    { name: 'TERCIÁRIA 3L', quantity: values.terciaria3l },
                    { name: 'TERCIÁRIA 8L', quantity: values.terciaria8l },
                  ]

                  const stockByCollectorIdAllNamesArray =
                    stockByCollectorId.map((stock) => stock.name)

                  const materialsWithSameNameStock = requestedMaterials
                    .filter((material) => material.quantity > 0)
                    .filter((material) =>
                      stockByCollectorIdAllNamesArray.includes(material.name),
                    )

                  const materialsWithQuantityUpZero = requestedMaterials.filter(
                    (material) => material.quantity > 0,
                  )

                  if (
                    materialsWithSameNameStock.length <
                    materialsWithQuantityUpZero.length
                  ) {
                    return swal(
                      'Erro!',
                      'O coletador não tem estoque para um ou mais materiais!',
                      'error',
                    )
                  }

                  if (materialsWithSameNameStock !== undefined) {
                    for (
                      let i = 0;
                      i < materialsWithSameNameStock.length;
                      i++
                    ) {
                      if (materialsWithSameNameStock[i].name)
                        if (
                          materialsWithSameNameStock[i].name ===
                          stockByCollectorId[i].name
                        ) {
                          if (
                            materialsWithSameNameStock[i].quantity >
                            stockByCollectorId[i].current
                          ) {
                            return swal(
                              'Erro!',
                              'O estoque atual do coletador não supre a quantidade solicitada de um ou mais materiais!',
                              'error',
                            )
                          }
                        }
                    }
                  }

                  const formatedData = serviceFormatDataToBackEdit(values)
                  const response = await validateRequestedService(
                    id,
                    formatedData,
                  )
                  if (response && !!userLogged && !!serviceById) {
                    await createLog({
                      user_id: userLogged.id,
                      action: `VALIDAR SERVIÇO - PROTOCOLO ${serviceById.protocol}`,
                    })
                    setIsLoading(true)
                    setTimeout(() => {
                      history.push('/servicos/solicitados')
                    }, 1500)
                  }
                } else {
                  swal('Ação cancelada com êxito!')
                }
              })
            } else {
              standardMessage('Ação cancelada com êxito!')
            }
          } else {
            standardMessage('Ação cancelada com êxito!')
          }
        } else {
          standardMessage('Ação cancelada com êxito!')
        }
      } else {
        standardMessage('Ação cancelada com êxito!')
      }
      return
    }

    // EDITAR EM ANDAMENTO

    if (hasCurrent === true) {
      await swal({
        title: 'Deseja editar um serviço?',
        text: 'Essa ação não poderá ser desfeita!',
        icon: 'warning',
        buttons: ['Cancelar', 'Confirmar'],
        dangerMode: false,
      }).then(async (willCreate) => {
        if (willCreate) {
          const formatedData = serviceFormatDataToBackEdit(values)
          const response = await editCurrentRequestedService(id, formatedData)
          if (response && userLogged) {
            await createLog({
              user_id: userLogged.id,
              action: `EDITAR SERVIÇO EM ANDAMENTO - PROTOCOLO ${serviceById?.protocol}`,
            })
            setIsLoading(true)
            setTimeout(() => {
              history.push('/servicos/solicitados')
            }, 1500)
          }
        } else {
          swal('Ação cancelada com êxito!')
        }
      })
      return
    }

    // EDITAR SERVIÇO SOLICITADO

    await swal({
      title: 'Deseja editar um serviço?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willCreate) => {
      if (willCreate) {
        try {
          const splitedCollectDate = values.collect_date.split('-')
          const splitedDeliveryDate = values.delivery_date.split('-')
          const splitedCollectHourStart = values.collect_hour_start.split(':')
          const splitedCollectHourEnd = values.collect_hour_end.split(':')

          const distanceToNow = compareAsc(
            new Date(),
            set(new Date(), {
              year: Number(splitedCollectDate[0]),
              month: Number(splitedCollectDate[1]) - 1,
              date: Number(splitedCollectDate[2]),
            }),
          )

          const differenceBetweenHours = formatDistanceStrict(
            new Date(
              Number(splitedDeliveryDate[0]),
              Number(splitedDeliveryDate[1]) - 1,
              Number(splitedDeliveryDate[2]),
              Number(splitedCollectHourEnd[0]),
              Number(splitedCollectHourEnd[1]),
            ),
            new Date(
              Number(splitedCollectDate[0]),
              Number(splitedCollectDate[1]) - 1,
              Number(splitedCollectDate[2]),
              Number(splitedCollectHourStart[0]),
              Number(splitedCollectHourStart[1]),
            ),
            { unit: 'minute' },
          )

          if (distanceToNow === 0 && !!budgets && !!cities) {
            const budgetsByCustomer = budgets.filter(
              (budget) => budget.customer_id === values.customer_id,
            )

            const sourceCitiesOfBudgetFilter = budgetsByCustomer
              .filter((budget) => budget.id === values.budget_id)
              .flatMap((budget) => budget.source_cities)
            const hourByCity = cities
              .filter((city) => sourceCitiesOfBudgetFilter?.includes(city.name))
              .map((city) => city.schedule_deadline)
              .toString()

            const cityHour = getHours(new Date(hourByCity)).toString()
            const cityMinute = getMinutes(new Date(hourByCity)).toString()

            const differenceBetweenDateNowAndCityHour = differenceInMinutes(
              new Date(),
              set(new Date(), {
                hours: Number(cityHour),
                minutes: Number(cityMinute),
              }),
            )

            if (differenceBetweenDateNowAndCityHour > 0) {
              return swal(
                'Erro!',
                'O horário de agendamento deve respeitar o limite estipulado para cada cidade!',
                'error',
              )
            } else {
              const formatedData = serviceFormatDataToBackEdit(values)
              const response = await editRequestedService(id, formatedData)

              if (response && userLogged) {
                await createLog({
                  user_id: userLogged.id,
                  action: `EDITAR SERVIÇO - PROTOCOLO ${serviceById?.protocol}`,
                })
                setIsLoading(true)
                setTimeout(() => {
                  history.push('/servicos/solicitados')
                }, 1500)
              }
            }

          } else {
            if (Number(differenceBetweenHours.split(' ')[0]) > 60) {
              const formatedData = serviceFormatDataToBackEdit(values)
              const response = await editRequestedService(id, formatedData)
              if (response && userLogged) {
                await createLog({
                  user_id: userLogged.id,
                  action: `EDITAR SERVIÇO - PROTOCOLO ${serviceById?.protocol}`,
                })
                setIsLoading(true)
                setTimeout(() => {
                  history.push('/servicos/solicitados')
                }, 1500)
              }
            }
          }
        } catch (error) {
          swal(`${error}`)
        }
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  if (isLoading || isCollectorCostsLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'andamento') {
    return (
      <ServiceEditDetailValidateForm
        slug={slug}
        id={id}
        action="Salvar"
        href=""
        submit={handleEditService}
        setHasCurrent={() => setHasCurrent(true)}
        title="Editar Serviço"
      />
    )
  } else if (slug === 'visualizar') {
    return (
      <ServiceEditDetailValidateForm
        slug={slug}
        id={id}
        action="Editar"
        href={`/servicos/solicitado/editar/${id}`}
        submit={async () => { }}
        title="Visualizar Serviço"
        isDisabled={true}
      />
    )
  } else if (slug === 'editar') {
    return (
      <ServiceEditDetailValidateForm
        slug={slug}
        id={id}
        action="Salvar"
        href=""
        submit={handleEditService}
        setHasCancelled={() => setHasCancelled(true)}
        title="Editar Serviço"
      />
    )
  } else {
    return (
      <ServiceEditDetailValidateForm
        slug={slug}
        id={id}
        action="Salvar"
        href=""
        submit={handleEditService}
        setHasValidate={() => setHasValidate(true)}
        title="Validar Serviço"
      />
    )
  }
}
