import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { CltIntercurrenceReportStatus, CltIntercurrenceReportTypes, CltIntercurrenceType } from "api/cltIntercurrences/types/CltIntercurrence";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaFileExport, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { cltIntercurrenceReportTypesMap, cltIntercurrenceTypeMap } from "utils/cltIntercurrenceMappers";

interface CltIntercurrencesReportFiltersSchema {
  status: CltIntercurrenceReportStatus
  initialDate: string
  finalDate: string
  benefits: CltIntercurrenceReportTypes
  type: CltIntercurrenceType
}

interface CltIntercurrencesReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: any[]
    filename: string
  }
}

export function CltIntercurrencesReportFilters({
  csvProps
}: CltIntercurrencesReportFiltersProps) {


  const searchParams = useSearchParams()

  const history = useHistory()

  const status = searchParams.get('status')
  const initialDate = searchParams.get('initialDate')
  const finalDate = searchParams.get('finalDate')
  const benefits = searchParams.get('type')

  const { register, handleSubmit } = useForm<CltIntercurrencesReportFiltersSchema>({
    defaultValues: {
      status: status as CltIntercurrenceReportStatus ?? undefined,
      initialDate: initialDate ?? '',
      finalDate: finalDate ?? '',
      benefits: benefits as CltIntercurrenceReportTypes ?? undefined
    }
  })

  function handleFilterStandardizationReport(values: CltIntercurrencesReportFiltersSchema) {
    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }
    if (values.benefits) {
      searchParams.set('benefits', values.benefits)
    } else {
      searchParams.delete('benefits')
    }
    if (values.type) {
      searchParams.set('type', values.type)
    } else {
      searchParams.delete('type')
    }

    if (values.initialDate) {
      searchParams.set('initialDate', values.initialDate)
    } else {
      searchParams.delete('initialDate')
    }

    if (values.finalDate) {
      searchParams.set('finalDate', values.finalDate)
    } else {
      searchParams.delete('finalDate')
    }

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleFilterStandardizationReport)}
    >
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        gap="2"
      >
        <Select
          {...register("benefits")}
          name="benefits"
          placeholder="Selecione o benefício..."
          size="sm"
          rounded="md"
          w={{ base: "full", md: "200px" }}
        >
          {Object.entries(cltIntercurrenceReportTypesMap).map(([key, value]) => {
            return (
              <option key={key} value={key}>{value}</option>
            )
          })}
        </Select>
        <Select
          {...register("type")}
          name="type"
          placeholder="Selecione o tipo..."
          size="sm"
          rounded="md"
          w={{ base: "full", md: "200px" }}
        >
          {Object.entries(cltIntercurrenceTypeMap).map(([key, value]) => {
            return (
              <option key={key} value={key}>{value}</option>
            )
          })}
        </Select>

        <Select
          {...register("status")}
          name="status"
          placeholder="Selecione um status..."
          size="sm"
          rounded="md"
          w={{ base: "full", md: "200px" }}
        >
          <option value="requested">Solicitado</option>
          <option value="approved">Aprovado</option>
          <option value="reproved">Reprovado</option>
        </Select>


        <Input
          {...register("initialDate")}
          placeholder="Data inicial"
          type='date'
          size="sm"
          rounded="md"
          w={{ base: "full", md: "200px" }}
        />

        <Input
          {...register("finalDate")}
          placeholder="Data final"
          type='date'
          size="sm"
          rounded="md"
          w={{ base: "full", md: "200px" }}
        />
      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min' }}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min' }}
        >
          Gerar relatório
        </Button>
      </Flex>

    </Flex>
  )
}
