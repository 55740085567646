import { Box, FormControl, FormLabel, Input, Select, Stack, Text } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";

import { CreateSupportSchema } from "../CreateSupport";

export function LLMEmployeePromotionForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const {
    data: llmUsersData
  } = useLLMUsers({
    queryParams: {
      situation: 'ATIVO'
    }
  })


  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      employee: 'Colaborador(a)',
      newPositionAndLevel: 'Novo cargo e nível',
      newSalary: 'Novo salário',
      changeStartDate: 'Início da mudança ',
      newLeadershipBonus: 'Nova gratificação de liderança',
      isSignatureAndMicrosoftPositionChangeNeeded: 'Necessário troca de assinatura e cargo Microsoft?',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };



  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors?.formData?.employee} mt={3}>
        <FormLabel fontSize="sm">
          Colaborador(a)
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register("formData.employee.value")}
          name="formData.employee.value"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('employee')}
        >
          {llmUsersData?.users?.map((user) => {
            return <option
              key={user.id}
              value={`${user.firstname} ${user.lastname}`}>
              {`${user.firstname} ${user.lastname}`}
            </option>
          })}
        </Select>
      </FormControl>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.newPositionAndLevel}>
            <FormLabel fontSize='sm'>
              Novo cargo e nível
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.newPositionAndLevel.value')}
              name='formData.newPositionAndLevel.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('newPositionAndLevel')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.newSalary}>
            <FormLabel fontSize='sm'>
              Novo salário
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.newSalary.value')}
              name='formData.newSalary.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('newSalary')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.changeStartDate} mt={3}>
        <FormLabel fontSize='sm'>
          Início da mudança
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.changeStartDate.value')}
          name='formData.changeStartDate.value'
          type="date"
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('changeStartDate')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.newLeadershipBonus} mt={3}>
        <FormLabel fontSize='sm'>
          Nova gratificação de liderança
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.newLeadershipBonus.value')}
          name='formData.newLeadershipBonus.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('newLeadershipBonus')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.isSignatureAndMicrosoftPositionChangeNeeded} mt={3}>
        <FormLabel fontSize="sm">
          Necessário troca de assinatura e cargo microsoft ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register("formData.isSignatureAndMicrosoftPositionChangeNeeded.value")}
          name="formData.isSignatureAndMicrosoftPositionChangeNeeded.value"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('isSignatureAndMicrosoftPositionChangeNeeded')}
        >
          <option value='Sim'>Sim</option>
          <option value='Não'>Não</option>
          <option value='N.A'>N.A</option>
        </Select>
      </FormControl>
    </Box>
  )
}
