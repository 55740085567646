import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface EditSupportSubjectBody {
  supportType: 'Motoristas' | 'Coletadores' | 'Funcionários LLM'
  category?: string
  name: string
  deadlineInMinutes: number
  responsiblesIds: string[]
}

interface EditSupportSubjectParams {
  supportSubjectId: string
  body: EditSupportSubjectBody

}

export async function editSupportSubject({
  supportSubjectId,
  body
}: EditSupportSubjectParams) {
  try {
    const response = await api.put(`/supports/subjects/${supportSubjectId}`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
