import axios from "axios";
import { Collector } from "hooks/collector/dtos/Collector";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Carrier, CarrierStatus } from "./_types/Carrier";

interface Attachment {
  document_validity: string
  expired: boolean
  IDriverProps: string
}

export interface GetCarriersParams {
  pageSize: number
  currentPage: number
  name?: string
  status: CarrierStatus | 'all'
  partnerLoglife?: string
}

export interface GetCarriersResponse {
  carriers: Array<Carrier & {
    collector: Collector
    dtVaccineDoseAttachment: Attachment | null
    hepatiteBVaccineDoseAttachment: Attachment | null
    tripliceViralVaccineDoseAttachment: Attachment | null
    cnhAttachment: Attachment | null
    crlvAttachment: Attachment | null
    anttMotorFreightAttachment: Attachment | null
    vehicleSidePhotoAttachment: Attachment | null
    vehicleRearPhotoAttachment: Attachment | null
    vehicleInternPhotoAttachment: Attachment | null
    trainingCertificateAttachmentId: Attachment | null
  }>
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}


export async function getCarriers({
  currentPage,
  pageSize,
  status,
  name,
  partnerLoglife
}: GetCarriersParams) {
  try {
    const response = await api.get<GetCarriersResponse>('/carriers', {
      params: {
        pageSize,
        currentPage,
        status,
        name,
        partnerLoglife
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
