import { Button, Popover, PopoverTrigger, Portal, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deleteSpecialBudgetCost } from "api/budgets/deleteSpecialBudgetCost";
import { useAuth } from "hooks/auth/useAuth";
import { Collector } from "hooks/collector/dtos/Collector";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import { EditSpecialBudgetCost } from "./EditSpecialBudgetCost";


interface SpecialBudgetCostTableRowProps {
  specialCost: {
    id: string;
    valueInCents: number;
    collectors: {
      collector: Collector
    }[];
  },
  budgetId: string
}

export function SpecialBudgetCostTableRow({ specialCost, budgetId }: SpecialBudgetCostTableRowProps) {

  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const doesUserCanEditSpecialBudgetCost = userLogged?.permissions.includes('edit-special-budget-cost')

  const doesUserCanDeleteSpecialBudgetCost = userLogged?.permissions.includes('delete-special-budget-cost')

  const {
    isOpen: isOpenEditSpecialBudgetCost,
    onClose: onCloseEditSpecialBudgetCost,
    onOpen: onOpenEditSpecialBudgetCost
  } = useDisclosure()

  const { mutateAsync: deleteSpecialBudgetCostFn } = useMutation({
    mutationFn: deleteSpecialBudgetCost,
    onSuccess() {
      queryClient.invalidateQueries(['special-budget-costs'])
    },
  })
  async function handleDeleteSpecialBudgetCost() {
    await promiseMessage(deleteSpecialBudgetCostFn({
      budgetSpecialCostId: specialCost.id
    }), 'Custo especial excluído com sucesso!')
  }


  return (
    <Tr key={specialCost.id}>
      <Td>{specialCost?.collectors?.map((collector) => collector?.collector?.trading_name).join(", ")}</Td>
      <Td> {
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(specialCost?.valueInCents / 100)
      }</Td>
      {doesUserCanEditSpecialBudgetCost && (
        <Td>
          <Popover
            isOpen={isOpenEditSpecialBudgetCost}
            onOpen={onOpenEditSpecialBudgetCost}
            onClose={onCloseEditSpecialBudgetCost}
          >
            <PopoverTrigger>
              <Button size="xs" variant="unstyled">
                ✏️
              </Button>
            </PopoverTrigger>
            <Portal>
              <EditSpecialBudgetCost
                budgetId={budgetId}
                specialCost={specialCost}
                onToggleEditSpecialBudgetCost={onCloseEditSpecialBudgetCost}
              />
            </Portal>
            {doesUserCanDeleteSpecialBudgetCost && (
              <Button
                size="xs"
                variant="unstyled"
                onClick={handleDeleteSpecialBudgetCost}
              >
                🗑️
              </Button>
            )}
          </Popover>
        </Td>
      )}
    </Tr>
  )
}
