import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { CltIntercurrenceReportStatus, CltIntercurrenceReportTypes, CltIntercurrenceType, CltIntercurrencePartnerTypeType } from "./types/CltIntercurrence";

interface GetCltIntercurrencesReportParams {
  benefits: CltIntercurrenceReportTypes
  initialDate: string
  finalDate: string
  status: CltIntercurrenceReportStatus
  type: CltIntercurrenceType
}

export interface GetCltIntercurrencesResponse {
  id: string
  type: CltIntercurrenceType
  date: string
  description: string
  regional: string
  partner_type: CltIntercurrencePartnerTypeType
  collaborator_name: string
  has_generate_extra_discount: string
  with_motorcycle_rental: boolean
  with_telephony: boolean
  with_fuel: boolean
  with_va_vr: boolean
  with_vt: boolean
  with_toll: boolean
  with_extras: boolean
  with_payroll: boolean
  status: CltIntercurrenceReportStatus
  created_at: string
  updated_at: string
  createdAt: string
  updatedAt: string
}

export async function getCltIntercurrencesReport({
  benefits,
  initialDate,
  finalDate,
  status,
  type
}: GetCltIntercurrencesReportParams) {
  try {
    const response = await api.get<GetCltIntercurrencesResponse[]>(`/report/clt-interccurrence-benefits`, {
      params: {
        initialDate,
        finalDate,
        status,
        benefits,
        type
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
