import { Flex, Box, Heading, Button, Icon, Stack, TableContainer, Table, Thead, Th, Tr, Tbody, Spinner, Td } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import { useCollectorCosts } from 'hooks/cost/useCollectorCosts'
import { useAuth } from 'hooks/auth/useAuth'
import { useFilterOptions } from 'hooks/filterOptions/useFilterOptions'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { FaEye, FaPen, FaPlus, FaTrash } from 'react-icons/fa'
import { TableFilterButton } from 'components/Filters/TableFilterButton'
import { SearchBox } from 'components/SearchBox/SearchBox'
import { searchBoxFilter } from 'utils/searchBoxFilter'
import { usePagination } from '@ajna/pagination'
import { useCollectorCostFunctions } from 'hooks/cost/useCollectorCostFunctions'
import { useToastify } from 'hooks/toastify/useToastify'
import { Pagination } from 'components/Pagination/Pagination'
import { GenerateExcelReportButton } from 'components/Buttons/GenerateExcelReportButton'
import { Collector } from 'hooks/collector/dtos/Collector'
import { CitiesProps } from 'services/getFunctions/city/getCity'
import { formatDateToFrontTable } from 'utils/GeneralFunctions/DateFunctions/formatDateToFrontTable'



interface CollectorCostFilterOptionsInputs {
  collector: string
  city: string
  costMotorcycle: string
  additionalCostMotorcycle: string
  costCar: string
  additionalCostCar: string
  primaryCityCollectorCost: string
  created_at: string
  updated_at: string
}

interface CollectorCostCsvProps {
  id: string
  collector_id: string
  city_id: string
  cost_motorcycle: string
  additional_cost_motorcycle: string
  cost_car: string
  additional_cost_car: string
  cost_truck: string
  additional_cost_truck: string
  observation: string
  collectorIDCollectorCost: Collector
  cityIDCollectorCost: CitiesProps
  created_at: string
  updated_at: string
  status: string
}

function formatValuesToGenerateCollectorCostCsv(values: CollectorCostCsvProps[]) {
  return values?.map((value) => ({
    ...value,
    city: value.cityIDCollectorCost.name,
    collector: value.collectorIDCollectorCost.trading_name,
    created_at: formatDateToFrontTable(value.created_at),
    updated_at: formatDateToFrontTable(value.updated_at),
    collectorIDCollectorCost: value?.cityIDCollectorCost?.hubIDCity.name,
    status: value.status === 'active' ? 'Ativo' : 'Inativo'
  }))
}

const headers = [
  { label: 'COLETADOR', key: 'collector' },
  { label: 'CIDADE', key: 'city' },
  { label: 'SITUAÇÃO', key: 'status' },
  { label: 'CUSTO MOTO', key: 'cost_motorcycle' },
  { label: 'CUSTO ADICIONAL MOTO', key: 'additional_cost_motorcycle' },
  { label: 'CUSTO CARRO', key: 'cost_car' },
  { label: 'CUSTO ADICIONAL CARRO', key: 'additional_cost_car' },
  { label: 'CUSTO CAMINHÃO', key: 'cost_truck' },
  { label: 'CUSTO ADICIONAL CAMINHÃO', key: 'additional_cost_truck' },
  { label: 'HUB', key: 'collectorIDCollectorCost' },
  { label: 'CRIADO EM', key: 'created_at' },
  { label: 'ÚLTIMA ATUALIZAÇÃO', key: 'updated_at' },
  { label: 'OBSERVAÇÃO', key: 'observation' },
]

const collectorCostFilterOptions = [
  { key: 'collector', value: 'Coletador', checked: false },
  { key: 'city', value: 'Cidade', checked: false },
  { key: 'costMotorcycle', value: 'Custo motos', checked: false },
  { key: 'additionalCostMotorcycle', value: 'Custo adicional motos', checked: false },
  { key: 'costCar', value: 'Custo carros', checked: false },
  { key: 'additionalCostCar', value: 'Custo adicional carros', checked: false },
  { key: 'primaryCityCollectorCost', value: 'Coletador Principal', checked: false },
  { key: 'created_at', value: 'Criado em', checked: false },
  { key: 'updated_at', value: 'Última atualização', checked: false }
]

const collectorCostOrderOptions = [
  { key: 'collector', value: 'Coletador', checked: false },
  { key: 'city', value: 'Cidade', checked: false },
]

export function CollectorCostList() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()

  const {
    orderOptionSelected,
    filterOptions,
    onLoadSetOrderOptions,
    onLoadSetFilterOptions,
  } = useFilterOptions()

  const { promiseMessage } = useToastify()

  const {
    deleteCollectorCost: { mutateAsync: deleteCollectorCost }
  } = useCollectorCostFunctions()

  useEffect(() => {
    onLoadSetFilterOptions(collectorCostFilterOptions)
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    onLoadSetOrderOptions(collectorCostOrderOptions)
  }, [onLoadSetOrderOptions])

  const { control, register } = useForm<CollectorCostFilterOptionsInputs>()

  const [
    collectorFiltered,
    cityFiltered,
    costMotorcycleFiltered,
    additionalCostMotorcycleFiltered,
    costCarFiltered,
    additionalCostCarFiltered,
    primaryCityCollectorCostFiltered,
    createdAtFiltered,
    updatedAtFiltered,
  ] = useWatch<CollectorCostFilterOptionsInputs, Array<keyof CollectorCostFilterOptionsInputs>>({
    control,
    name: ['collector', 'city', 'costMotorcycle', 'additionalCostMotorcycle', 'costCar', 'additionalCostCar', 'primaryCityCollectorCost', 'created_at', 'updated_at']
  })

  const {
    data: collectorCosts, isLoading: isCollectorCostsLoading,
  } = useCollectorCosts({ queryParams: { situation: 'ATIVO' } })

  const collectorCostsFiltered = collectorCosts?.filter(collectorCost => {
    const collectorFilter = collectorFiltered
      ? searchBoxFilter(collectorCost.collectorIDCollectorCost.trading_name, collectorFiltered)
      : collectorCost.collectorIDCollectorCost.trading_name
    const cityFilter = cityFiltered
      ? searchBoxFilter(collectorCost.cityIDCollectorCost.name, cityFiltered)
      : collectorCost.cityIDCollectorCost.name
    const costMotorcycleFilter = costMotorcycleFiltered
      ? searchBoxFilter(String(collectorCost.cost_motorcycle.toFixed(2)).replace('.', ','), costMotorcycleFiltered)
      : String(collectorCost.cost_motorcycle.toFixed(2)).replace('.', ',')
    const additionalCostMotorcycleFilter = additionalCostMotorcycleFiltered
      ? searchBoxFilter(String(collectorCost.additional_cost_motorcycle.toFixed(2)).replace('.', ','), additionalCostMotorcycleFiltered)
      : String(collectorCost.additional_cost_motorcycle.toFixed(2)).replace('.', ',')
    const costCarFilter = costCarFiltered
      ? searchBoxFilter(String(collectorCost.cost_car.toFixed(2)).replace('.', ','), costCarFiltered)
      : String(collectorCost.cost_car.toFixed(2)).replace('.', ',')
    const additionalCostCarFilter = additionalCostCarFiltered
      ? searchBoxFilter(String(collectorCost.additional_cost_car.toFixed(2)).replace('.', ','), additionalCostCarFiltered)
      : String(collectorCost.additional_cost_car.toFixed(2)).replace('.', ',')

    const createdAtFilter = createdAtFiltered ? searchBoxFilter(
      `${formatDateToFrontTable(collectorCost.created_at)}`, createdAtFiltered,
    ) : collectorCost.created_at

    const updatedAtFilter = updatedAtFiltered ? searchBoxFilter(
      `${formatDateToFrontTable(collectorCost.updated_at)}`, updatedAtFiltered,
    ) : collectorCost.updated_at

    const cityCollectorCosts = collectorCosts?.filter(cost => cost.city_id === collectorCost.city_id)
    const primaryCityCollectorCost = cityCollectorCosts?.find(cost => cost.is_primary_collector)?.collectorIDCollectorCost.trading_name

    // If no primary collector exists, use the existing collector
    const collectorToUse = primaryCityCollectorCost || (cityCollectorCosts?.length === 1 ? cityCollectorCosts[0].collectorIDCollectorCost.trading_name : '')

    const primaryCityCollectorCostFilter = primaryCityCollectorCostFiltered && collectorToUse
      ? searchBoxFilter(collectorToUse, primaryCityCollectorCostFiltered)
      : collectorToUse

    return collectorFilter && cityFilter && costMotorcycleFilter && additionalCostMotorcycleFilter && costCarFilter && additionalCostCarFilter && primaryCityCollectorCostFilter && createdAtFilter && updatedAtFilter

  })?.sort((a, b) => {
    const orderByCollector = orderOptionSelected.includes('collector')
      ? b.collectorIDCollectorCost.trading_name.localeCompare(a.collectorIDCollectorCost.trading_name)
      : 0

    const orderByCity = orderOptionSelected.includes('city')
      ? a.cityIDCollectorCost.name.localeCompare(b.cityIDCollectorCost.name)
      : 0

    return orderByCollector || orderByCity
  })?.map(collectorCost => {
    return {
      ...collectorCost,
      cost_motorcycle: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(collectorCost.cost_motorcycle),
      additional_cost_motorcycle: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(collectorCost.additional_cost_motorcycle),
      cost_car: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(collectorCost.cost_car),
      additional_cost_car: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(collectorCost.additional_cost_car),
      cost_truck: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(collectorCost.cost_truck),
      additional_cost_truck: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(collectorCost.additional_cost_truck),
    }
  })

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: collectorCostsFiltered?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setCurrentPage(1)
    }, 500)

    return () => clearTimeout(debounceTimeout)
  }, [
    collectorFiltered,
    cityFiltered,
    costMotorcycleFiltered,
    additionalCostMotorcycleFiltered,
    costCarFiltered,
    additionalCostCarFiltered,
    primaryCityCollectorCostFiltered,
    createdAtFiltered,
    updatedAtFiltered,
    setCurrentPage
  ])

  const handlePageChange = (page: number) => setCurrentPage(page)

  const csvReportProps = {
    data: formatValuesToGenerateCollectorCostCsv(collectorCostsFiltered) ?? [],
    headers,
    filename: `relatório-cadastro-custo-coletador.csv`,
  }


  async function handleDeleteCollectorCost(id: string) {
    await promiseMessage(
      deleteCollectorCost(id),
      'Custo de coletador excluído com sucesso!'
    )
  }

  return (

    <Box
      p={['2', '4']}
      bg='white'
      borderRadius='8px'
    >
      <Flex
        direction='column'
        gap={4}
      >
        <Flex
          align='center'
          direction={['column', 'column', 'row']}
          justify={['center', 'center', 'space-between']}
          gap={2}
        >
          <Heading size='md'>Custos de coletadores</Heading>
          {userLogged?.permissions.includes('add-collector-cost') && (
            <Button
              as={Link}
              w={['full', 'full', 'min']}
              size='sm'
              to='/custos/coletador/adicionar'
              colorScheme='gray'
              leftIcon={<Icon as={FaPlus} />}
            >
              Novo custo
            </Button>
          )}
        </Flex>

        {isCollectorCostsLoading ? (
          <Spinner />
        ) : (
          <>
            <Flex
              align='center'
              direction={['column', 'column', 'row']}
              justify={['center', 'center', 'space-between']}
              gap={2}
            >
              <Flex alignSelf='flex-start'>
                <TableFilterButton />
              </Flex>

              <Stack alignSelf='flex-end' spacing={2} w={['full', 'full', 'min']}>
                {filterOptions.filter(option => option.checked).map(option => {
                  return (
                    <SearchBox
                      {...register(option.key as keyof CollectorCostFilterOptionsInputs)}
                      name={option.key}
                      size="sm"
                      placeholder={'Buscar ' + option.value}
                      handleSearch={() => { }}
                    />
                  )
                })}
              </Stack>
            </Flex>

            <TableContainer>
              <Table size='sm' variant='striped'>
                <Thead>
                  <Tr>
                    {collectorCostFilterOptions.map(option => {
                      return (
                        <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                      )
                    })}
                    <Th>HUB</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    collectorCostsFiltered
                      ?.slice(offset, offset + Number(itemLimit))
                      ?.map(collectorCost => {

                        const primaryCityCollectorCost = collectorCosts?.find(
                          (cost) => cost.city_id === collectorCost.city_id && cost.is_primary_collector
                        )

                        return (
                          <Tr key={collectorCost.id}>
                            <Td>{collectorCost.collectorIDCollectorCost.trading_name}</Td>
                            <Td>{collectorCost.cityIDCollectorCost.name}</Td>
                            <Td>{collectorCost.cost_motorcycle}</Td>
                            <Td>{collectorCost.additional_cost_motorcycle}</Td>
                            <Td>{collectorCost.cost_car}</Td>
                            <Td>{collectorCost.additional_cost_car}</Td>
                            <Td>{primaryCityCollectorCost ? primaryCityCollectorCost?.collectorIDCollectorCost.trading_name : '-'}</Td>
                            <Td>{formatDateToFrontTable(collectorCost.created_at)}</Td>
                            <Td>{formatDateToFrontTable(collectorCost.updated_at)}</Td>
                            <Td>{collectorCost?.cityIDCollectorCost?.hubIDCity?.name}</Td>
                            <Td isNumeric>
                              {userLogged?.permissions.includes('edit-collector-cost') && (
                                <Button
                                  as={Link}
                                  to={`/custos/coletador/editar/${collectorCost.id}`}
                                  variant='ghost'

                                >
                                  <Icon fontSize='xl' as={FaPen} />
                                </Button>
                              )}
                              {userLogged?.permissions.includes('view-collector-cost') && (
                                <Button
                                  as={Link}
                                  to={`/custos/coletador/visualizar/${collectorCost.id}`}
                                  variant='ghost'

                                >
                                  <Icon fontSize='xl' as={FaEye} />
                                </Button>
                              )}
                              {userLogged?.permissions.includes('remove-collector-cost') && (
                                <Button
                                  variant='ghost'
                                  onClick={() => handleDeleteCollectorCost(collectorCost.id)}
                                >
                                  <Icon fontSize='xl' as={FaTrash} />
                                </Button>
                              )}
                            </Td>
                          </Tr>
                        )
                      })
                  }
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )}

        <GenerateExcelReportButton csvReportProps={csvReportProps} />

        <Pagination
          handlePageChange={handlePageChange}
          pagesQuantity={pagesCount}
          pages={pages}
          currentPage={currentPage}
        />
      </Flex>

    </Box>

  )
}
