import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ReadAllNotificationsParams {
  userId: string
}

export async function readAllNotifications({
  userId
}: ReadAllNotificationsParams) {
  try {
    await api.patch(`/user/notification/read-all/${userId}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
