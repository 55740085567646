import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { TrainingStatus, TrainingCollaboratorTypes, TrainingTypes } from "api/trainings/training";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { trainingCollaboratorTypeMap, trainingTypesMap } from "utils/trainingMappers";

interface TrainingsTableFilterProps {
  hasStatusFilter?: boolean
}

interface TrainingsTableFiltersSchema {
  collaborator: string
  status: TrainingStatus | 'all'
  collaboratorType: TrainingCollaboratorTypes | 'all'
  type: TrainingTypes | 'all'
}

export function TrainingsTableFilter({ hasStatusFilter = true }: TrainingsTableFilterProps) {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const collaborator = searchParams.get('collaborator')
  const status = searchParams.get('status') ?? 'all'
  const collaboratorType = searchParams.get('collaboratorType') ?? 'all'
  const type = searchParams.get('type') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<TrainingsTableFiltersSchema>({
    defaultValues: {
      collaborator: collaborator ?? '',
      status: status as TrainingStatus | 'all',
      collaboratorType: collaboratorType as TrainingCollaboratorTypes | 'all',
      type: type as TrainingTypes | 'all'
    }
  })

  async function handleFilterTrainings(data: TrainingsTableFiltersSchema) {
    if (data.collaborator) {
      searchParams.set('collaborator', data.collaborator)
    } else {
      searchParams.delete('collaborator')
    }
    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }
    if (data.collaboratorType) {
      searchParams.set('collaboratorType', data.collaboratorType)
    } else {
      searchParams.delete('collaboratorType')
    }
    if (data.type) {
      searchParams.set('type', data.type)
    } else {
      searchParams.delete('type')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('collaborator')
    searchParams.delete('status')
    searchParams.delete('collaboratorType')
    searchParams.delete('type')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterTrainings)}
    >
      <Input
        {...register("collaborator")}
        placeholder="Colaborador"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Select
        {...register("type")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos treinamentos</option>
        {Object.entries(trainingTypesMap).map(([key, value]) => {
          return (
            <option key={key} value={key}>{value}</option>
          )
        })}
      </Select>
      <Select
        {...register("collaboratorType")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos colaboradores</option>
        {Object.entries(trainingCollaboratorTypeMap).map(([key, value]) => {
          return (
            <option key={key} value={key}>{value}</option>
          )
        })}
      </Select>

      {hasStatusFilter && (
        <Select
          {...register("status")}
          defaultValue="all"
          size="sm"
          rounded="md"
          w={["full", "full", "200px"]}
        >
          <option value="all">Todos status</option>
          <option value="active">Ativos</option>
          <option value="pending">Pendentes</option>
          <option value="inactive">Inativos</option>
        </Select>
      )}


      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
