import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreatePatrimonyBody {
  brand: string
  identifier?: number | null
  valueInCents: number
}

interface CreatePatrimonyParams {
  body: CreatePatrimonyBody
  patrimonyTypeId: string
}

export async function createPatrimony({
  body,
  patrimonyTypeId
}: CreatePatrimonyParams) {
  try {
    await api.post(`patrimony-types/${patrimonyTypeId}/patrimonies`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

