import axios from "axios"
import { User } from "hooks/user/dtos/User"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

export interface ExtraDiscount {
  id: string
  type: 'EXTRA' | 'DESCONTO'
  service_date: string
  value: number
  description: string
  service_route: string | null
  service_protocol: number | null
  approved_at: string
  rejected_at: string
  effective_at: string
  created_at: string
  attachments: {
    attachmentId: string
    link: string
  }[]
  services: number[]
  additional_descriptions: string[] | null
  aggregate_id: string
}

interface GetAggregateExtraDiscountLogsParams {
  extraDiscountId: string
  pageSize: number
  currentPage: number
}

type LogDetail = {
  [Property in keyof ExtraDiscount]: {
    prev: ExtraDiscount[Property]
    new: ExtraDiscount[Property]
  }
}

export interface AggregateExtraDiscountLogs {
  id: string
  logDetail: LogDetail
  updatedById: Pick<User, 'id' | 'firstname' | 'lastname'>
  createdAt: string
}

export interface GetAggregateExtraDiscountLogsResponse {
  logs: AggregateExtraDiscountLogs[]
  meta: {
    count: number
    totalPages: number
  }
}

export async function getAggregateExtraDiscountLogs({
  extraDiscountId,
  currentPage,
  pageSize
}: GetAggregateExtraDiscountLogsParams) {
  try {
    const { data } = await api.get<GetAggregateExtraDiscountLogsResponse>(
      `/aggregates/extras-discounts/logs/${extraDiscountId}`, {
      params: {
        pageSize,
        currentPage
      }
    }
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
