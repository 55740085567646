import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Refund } from "./_types/Refund";

export interface GetRefundsParams {
  pageSize: number
  currentPage: number
  status : 'approved' | 'reproved' | 'refunding' | 'done'
}

export interface GetRefundsResponse {
  refunds: Refund[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
    count: number
  }
}

export async function getRefunds({
  currentPage,
  pageSize,
  status
}: GetRefundsParams) {
  try {
    const response = await api.get<GetRefundsResponse>('/refunds', {
      params: {
        pageSize,
        currentPage,
        status
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
