import { Box, Button, Flex, HStack, Icon, IconButton, Input, Link, Progress, Text, Tooltip, VStack } from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";
import { FaFile, FaImage, FaPlus } from "react-icons/fa6";
import { useAttachmentFunctions } from "hooks/attachment/AttachmentFunctions";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { ChangeEvent, MouseEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useToastify } from "hooks/toastify/useToastify";

interface AttachmentTableRowFieldProps {
  isFilledField: boolean
  onSetAttachmentId: (attachmentId: string) => void
  title: string
}

function switchIconByFileType(fileType: string) {
  return fileType?.startsWith("image") ? FaImage : FaFile;
}

export function AttachmentTableRowField({
  isFilledField,
  onSetAttachmentId,
  title
}: AttachmentTableRowFieldProps) {
  const { mutation: createAttachmentMutation, uploadProggress } = useAttachmentMutation();
  const { deleteAttachment } = useAttachmentFunctions();
  const { register, control } = useFormContext();

  const { warnMessage } = useToastify()

  const attachmentFile = useWatch({
    control,
    name: `reportServiceAttachment.file`
  });

  const fileName = attachmentFile ? attachmentFile[0]?.name : "";
  const fileType = attachmentFile ? attachmentFile[0]?.type : "";

  async function handleSendAttachment(event: ChangeEvent<HTMLInputElement>) {
    const fileInput = event.target
    const file = fileInput.files?.[0]

    if (!file) return

    const MAX_FILE_SIZE = 25 * 1024 * 1024
    if (file.size > MAX_FILE_SIZE) {
      warnMessage("O arquivo deve ter no máximo 25MB.")

      fileInput.value = ""

      return
    }

    const formData = new FormData();
    formData.append("attachment", event.target.files[0]);

    const data = await createAttachmentMutation.mutateAsync(formData);
    onSetAttachmentId(data.attachment.id);
  }

  async function handleRemoveAttachment(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.stopPropagation();

    const attachmentId = createAttachmentMutation.data?.attachment.id;

    if (attachmentId) {
      await deleteAttachment.mutateAsync(attachmentId);
      onSetAttachmentId("");

    }
  }

  return (
    <VStack w="full" align="start">
      {isFilledField && (
        <Flex
          as={Link}
          align="center"
          w="full"
          role="group"
          justify="space-between"
          href={createAttachmentMutation?.data?.attachment?.link}
          _hover={{ textDecoration: "none", bg: "blackAlpha.50" }}
          py={2}
          px={1}
          rounded="md"
          position="relative"
          isExternal
        >
          <HStack spacing={2}>
            <Box
              position="relative"
              w="8"
              h="8"
              rounded="md"
              bg="blue.500"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                position="absolute"
                as={switchIconByFileType(fileType)}
                color="white"
                h="5"
                w="5"
              />
            </Box>
            <VStack align="start" justify="start">
              <Tooltip fontSize="xs" label={fileName}>
                <Text fontSize="sm" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxW="350px">{fileName}</Text>
              </Tooltip>
              {uploadProggress && <Progress size="sm" w="200px" rounded="md" value={uploadProggress} />}
            </VStack>
          </HStack>

          <Tooltip fontSize="xs" label="Excluir anexo">
            <IconButton
              aria-label=""
              icon={<FaTimes />}
              size="xs"
              variant="ghost"
              rounded="full"
              position="absolute"
              right={2}
              onClick={handleRemoveAttachment}
            />
          </Tooltip>
        </Flex>
      )}

      <Input
        {...register(`reportServiceAttachment.file`)}
        id={`reportServiceAttachment.file`}
        type="file"
        hidden
        onChangeCapture={handleSendAttachment}
      />

      {!isFilledField && !createAttachmentMutation.isLoading && (
        <Button as="label" htmlFor={`reportServiceAttachment.file`} leftIcon={<FaPlus />} variant="ghost" size="sm">
          Anexe aqui o seu relatório de serviços
        </Button>
      )}
    </VStack>
  );
}
