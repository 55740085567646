import { useEffect } from 'react'
import { Flex, Box, Heading, Spinner } from '@chakra-ui/react'
import { useBudget } from '../../../hooks/budget/useBudget'
import { useOccurrences } from 'hooks/occurrences/useOccurrences'
import { useContentLoading } from '../../../hooks/loading/useContentLoading'

import { OccurrenceTable } from '../../../components/Tables/Occurrence/OccurrenceTable'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { useQueryClient } from 'react-query'
import { useAuth } from 'hooks/auth/useAuth'

export function OccurrenceListOperational() {
  const queryClient = useQueryClient()
  const { userLogged } = useAuth()

  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()

  const {
    data: occurrences,
    isFetching: isOccurrencesFetching
  } = useOccurrences({
    queryParams: {
      step: 'AWAITING-PROCEDURE',
      type: '!CLIENTE',
      customer_id: userLogged?.customer_id,
    }
  })

  const {
    budgets: { data: budgets, isLoading: isBudgetLoading },
  } = useBudget(null, true)

  useEffect(() => {
    function run() {
      if (!isOccurrencesFetching && !isBudgetLoading) {
        handleSetIsContentLoadingFalse()
      }
    }
    run()
  }, [isOccurrencesFetching, handleSetIsContentLoadingFalse, isBudgetLoading])

  const handleRefetchTableData = async () => {
    await queryClient.invalidateQueries(['occurrences'])
  }

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Painel de Ocorrências Operacionais
            </Heading>
          </Flex>
          {isOccurrencesFetching ? (
            <Spinner />
          ) : (
            occurrences && budgets && (
              <OccurrenceTable
                occurrences={occurrences}
                budgets={budgets}
                handleRefetchTableData={handleRefetchTableData}
              />
            )
          )}
        </Box>
      </Flex>

  )
}
