import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

export interface GetBusinessBudgetsByCollectorResponse {
  businessBudgets: {
    id: string
    route_nickname: string
    drivers: {
      [driver: string]: {
        attendance_days?: string[]
        start_hour?: string
        end_hour?: string,
        value_in_cents?: number
      }
    }
    customerIDBusinessBudget: {
      id: string
      trading_firstname: string
      company_lastname: string
    }
  }[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}

interface GetBusinessBudgetsByCollectorParams {
  collectorId: string
  pageSize: string
  currentPage: string
}

export async function getBusinessBudgetsByCollector({
  collectorId,
  currentPage,
  pageSize
}: GetBusinessBudgetsByCollectorParams) {
  try {
    const response = await api.get<GetBusinessBudgetsByCollectorResponse>(`/collectors/${collectorId}/business-budgets`, {
      params: {
        currentPage,
        pageSize
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
