import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import swal from 'sweetalert'
import { Alignment } from 'hooks/occurrences/dtos/Occurrence'
import { Address } from 'hooks/address/dtos/Address'
import { User } from 'hooks/user/dtos/User'

interface OccurrenceProviderProps {
  children: ReactNode
  pathname?: string
}

export interface OccurrenceProps {
  id: string
  occurrence_protocol: number
  attachment_photo: string | null
  customer_id: string
  responsible_collector_id: string | null
  source_collector_id: string | null
  destination_collector_id: string | null
  source_city: string[] | null
  destination_city: string[] | null
  shipping_id: string | null
  service_id: string
  service_step: string
  materials: {
    input_id: string
    quantity: number
  }[]
  owner: string
  type: string
  intercurrence: string
  occurrence_detail: string | null
  occurrence_delay: number | null
  occurrence_date: string | null
  occurrence_hour: string | null
  proceed: string
  reason: string | null
  cause: string | null
  corrective: string | null
  preventive: string | null
  createdAt: string
  step: string
  service_new_source_branch_id: string
  service_new_destination_branch_id: string
  unsuccess_delivery_address_id: string
  observation: string | null
  occurrenceIDService: {
    protocol: string
    serviceIDRequested: {
      budget_id: string
    }
  }
  occurrenceIDUser: {
    firstname: string
    lastname: string
  }
  occurrenceIDCustomer: {
    trading_firstname: string
  }
  alignments: Alignment[]
  unsuccessDeliveryAddress: Address
  occurrenceIDAlignmentIndicatedUser: User
  shipping_ticket_number: number | null
  prev_materials: string | null
  email_to_reply: string | null
  collector_fine_value: number | null
  is_alignment_required: boolean
}

export type OccurrenceAddEditInputProps = Omit<
  OccurrenceProps,
  | 'id'
  | 'occurrence_protocol'
  | 'proceed'
  | 'reason'
  | 'cause'
  | 'corrective'
  | 'preventive'
  | 'createdAt'
  | 'occurrenceIDService'
  | 'occurrenceIDUser'
  | 'occurrenceIDCustomer'
  | 'step'
>
export type OccurrenceValidateInputProps = Pick<
  OccurrenceProps,
  'proceed' | 'reason' | 'cause' | 'corrective' | 'preventive'
>
export type OccurrenceValidateToCustomerInputProps = {
  proceed: string
  reason: string | null
  cause: string | null
  corrective: string | null
  preventive: string | null
  provider_id: string
}

  interface OccurenceContextProps {
  createOccurrence: (
    occurrenceInput: OccurrenceAddEditInputProps,
  ) => Promise<boolean>
  deleteOccurrence: (occurrenceId: string) => Promise<boolean | undefined>
  editOccurrence: (
    occurrenceId: string,
    occurrenceInput: OccurrenceAddEditInputProps,
  ) => Promise<boolean | undefined>
  validateOccurrence: (
    occurrenceId: string,
    occurrenceInput: OccurrenceValidateInputProps,
  ) => Promise<boolean | undefined>
  validateOccurrenceToCustomer: (
    occurrenceId: string,
    occurrenceInput: OccurrenceValidateToCustomerInputProps,
  ) => Promise<boolean | undefined>
}

export const OccurrenceContext = createContext<OccurenceContextProps>(
  {} as OccurenceContextProps,
)

export function OccurrenceProvider({
  children,
  pathname,
}: OccurrenceProviderProps) {
  async function createOccurrence(
    occurrenceInput: OccurrenceAddEditInputProps,
  ) {
    try {
      await api.post('/occurrence', occurrenceInput)

      setTimeout(() => {
        swal('Poof! A ocorrência foi criada com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteOccurrence(occurrenceId: string) {
    try {
      await api.delete(`/occurrence/${occurrenceId}`)
      swal('Poof! A ocorrência foi excluída com suceso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro!', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editOccurrence(
    occurrenceId: string,
    occurrenceInput: OccurrenceAddEditInputProps,
  ) {
    try {
      await api.put(`/occurrence/${occurrenceId}`, occurrenceInput)
      setTimeout(() => {
        swal('Poof! A ocorrência foi atualizada com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function validateOccurrence(
    occurrenceId: string,
    occurrenceInput: OccurrenceValidateInputProps,
  ) {
    try {
      await api.put(`/occurrence/${occurrenceId}`, occurrenceInput)
      swal('Poof! A ocorrência foi validada com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function validateOccurrenceToCustomer(
    occurrenceId: string,
    occurrenceInput: OccurrenceValidateToCustomerInputProps,
  ) {
    try {
      await api.put(`/occurrence/${occurrenceId}`, {
        ...occurrenceInput,
        hasValidate: true,
      })
      swal('Poof! A ocorrência foi validada com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <OccurrenceContext.Provider
      value={{
        createOccurrence,
        deleteOccurrence,
        editOccurrence,
        validateOccurrence,
        validateOccurrenceToCustomer,
      }}
    >
      {children}
    </OccurrenceContext.Provider>
  )
}
