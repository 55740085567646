import { Box, Button, FormControl, FormLabel, IconButton, Input, Link, Progress, Stack, Text } from "@chakra-ui/react";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useState, useEffect, ChangeEvent } from "react";
import { DeepMap, FieldError, UseFormRegister } from "react-hook-form";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaFileImport } from "react-icons/fa6";
import { CreateCarrierSchema } from "./CreateCarrier";

interface CarrierAttachmentFieldProps {
  fieldname: string
  label: string
  register: UseFormRegister<CreateCarrierSchema>
  errors: DeepMap<CreateCarrierSchema, FieldError>
  onSetCarrierAttachmentId: (key: string, attachmentId: string) => void
}

export function CarrierAttachmentField({
  fieldname,
  label,
  errors,
  register,
  onSetCarrierAttachmentId,
}: CarrierAttachmentFieldProps) {
  const { mutation, uploadProggress } = useAttachmentMutation()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedDate, setSelectedDate] = useState<string>("")
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    async function handleUploadAttachment() {
      if (!selectedFile || !selectedDate || isUploading) return;
      setIsUploading(true)

      const formData = new FormData()
      formData.append("attachment", selectedFile)
      formData.append("documentValidity", selectedDate)

      const data = await mutation.mutateAsync(formData)
      onSetCarrierAttachmentId(label, data.attachment.id)
      setSelectedFile(null)
      setIsUploading(false)
    }

    if (selectedFile && selectedDate && !isUploading) {
      handleUploadAttachment()
    }
  }, [selectedFile, selectedDate, isUploading, mutation, onSetCarrierAttachmentId, label])

  async function handleCaptureEvent(
    event: ChangeEvent<HTMLInputElement>
  ) {
    setSelectedFile(event.target.files[0])
  }

  return (
    <Box p={4} borderWidth={1} borderRadius="md" w="full" mt={3}>
      <Text fontSize="md" fontWeight="bold" mb={2}>{label}</Text>
      <Text fontSize="sm" color="gray.600" mb={2}>
        O upload será feito automaticamente quando a data de vencimento e o anexo forem informados.
      </Text>
      <Stack direction="column" spacing={3}>
        <FormControl isInvalid={!!errors[fieldname]}>
          <Text fontSize="sm" fontWeight="bold">Data de Vencimento</Text>
          <Input
            type="date"
            value={selectedDate}
            onChange={(event) => setSelectedDate(event.target.value)}
            placeholder="Selecione a data"
          />
        </FormControl>
        <FormControl isInvalid={!!errors[fieldname]}>
          <Text fontSize="sm" fontWeight="bold">Anexo</Text>
          <Button
            as={FormLabel}
            htmlFor={fieldname}
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={errors[fieldname] && "2px solid"}
            borderColor={errors[fieldname] && "red.500"}
          >
            Anexar
          </Button>
          <FormControl isInvalid={!!errors[fieldname]}>

          </FormControl>

          <Input
            {...register(fieldname as keyof CreateCarrierSchema)}
            name={fieldname}
            id={fieldname}
            type="file"
            hidden
            onChangeCapture={handleCaptureEvent}

          />
        </FormControl>
      </Stack>
      {uploadProggress > 0 && <Progress size="sm" rounded="md" value={uploadProggress} mt={3} />}
      {mutation.data && (
        <IconButton
          aria-label="Visualizar anexo"
          as={Link}
          size="sm"
          icon={<FaExternalLinkAlt />}
          href={mutation.data.attachment.link}
          isExternal
          mt={3}
        />
      )}
    </Box>
  );
}
