import { Button, Flex, Input, Select } from "@chakra-ui/react"
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";


interface CustomersExtrasDiscountsTableFiltersSchema {
  status: 'requested' | 'closed' | 'approved' | 'rejected' | 'all'
  customer: string
  startDate: string
  endDate: string
  protocol: string

}

export function CustomersExtrasDiscountsTableFilter() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const customer = searchParams.get('customer')
  const status = searchParams.get('status') ?? 'all'
  const protocol = searchParams.get('protocol')
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')


  const {
    register,
    handleSubmit,
    reset,
  } = useForm<CustomersExtrasDiscountsTableFiltersSchema>({
    defaultValues: {
      customer,
      startDate,
      endDate,
      protocol,
      status: status as 'requested' | 'closed' | 'approved' | 'rejected' | 'all'
    }
  })

  async function handleFilterCustomersExtrasAndDiscounts(data: CustomersExtrasDiscountsTableFiltersSchema) {
    if (data.customer) {
      searchParams.set('customer', data.customer)
    } else {
      searchParams.delete('customer')
    }
    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    if (data.startDate) {
      searchParams.set('startDate', data.startDate)
    } else {
      searchParams.delete('startDate')
    }

    if (data.endDate) {
      searchParams.set('endDate', data.endDate)
    } else {
      searchParams.delete('endDate')
    }

    if (data.protocol) {
      searchParams.set('protocol', data.protocol)
    } else {
      searchParams.delete('protocol')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('status')
    searchParams.delete('customer')
    searchParams.delete('startDate')
    searchParams.delete('endDate')
    searchParams.delete('protocol')

    reset({
      customer: '',
      startDate: '',
      endDate: '',
      protocol: '',
      status: 'all'
    })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterCustomersExtrasAndDiscounts)}
    >
      <Input
        {...register("customer")}
        placeholder="Cliente"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Input
        {...register("protocol")}
        placeholder="Protocolo"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Input
        {...register("startDate")}
        type="date"
        size="sm"
        rounded="md"
        w={{ base: 'full', md: 'fit-content' }}
      />

      <Input
        {...register("endDate")}
        type="date"
        size="sm"
        rounded="md"
        w={{ base: 'full', md: 'fit-content' }}
      />

      <Select
        {...register("status")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="requested">Solicitados</option>
        <option value="approved">Aprovados</option>
        <option value="rejected">Recusados</option>
        <option value="closed">Finalizados</option>

      </Select>


      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>


    </Flex>
  )
}
