import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Sporadic, SporadicStatus } from "./_types/Sporadic";

export interface GetSporadicsParams {
  pageSize: number
  currentPage: number
  status: SporadicStatus | 'all'
  protocol?: string
  startDate?: string
  finalDate?: string
}

export interface GetSporadicsResponse {
  sporadics: Array<Sporadic & {
    regional: Regional
    emailRequestAttachment: Attachment
  }
  >
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
    count: number
  }
}

export async function getSporadics({
  currentPage,
  pageSize,
  status,
  protocol,
  startDate,
  finalDate
}: GetSporadicsParams) {
  try {
    const response = await api.get<GetSporadicsResponse>('/sporadics', {
      params: {
        pageSize,
        currentPage,
        status,
        protocol,
        startDate,
        finalDate
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
