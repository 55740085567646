import { Box, Circle, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { format, formatDistanceToNowStrict, isFuture } from "date-fns";
import { FaCalendarAlt, FaExclamationCircle, FaTrash } from "react-icons/fa";
import { ptBR } from "date-fns/locale";
import { ActiveFeedback, ActiveFeedbackStatus } from "api/feedbacks/_types/ActiveFeedback";
import { ActiveFeedbackDetails } from "./ActiveFeedbackDetails";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "hooks/auth/useAuth";
import { deleteActiveFeedback } from "api/feedbacks/deleteActiveFeedback";


interface KanbanActiveFeedbackCardProps {
  activeFeedback: ActiveFeedback
}

export const feedbackQueryKeys: Record<ActiveFeedbackStatus, string> = {
  scheduled: 'scheduled-actives-feedbacks',
  'direct-leader-approving': 'direct-leader-approving-feedbacks',
  'executive-leader-approving': 'executive-leader-approving-feedbacks',
  'awaiting-leader-feedback': 'awaiting-leader-actives-feedbacks',
  done: 'done-actives-feedbacks',
};

export function KanbanActiveFeedbackCard({ activeFeedback }: KanbanActiveFeedbackCardProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()
  const { userLogged } = useAuth()

  const userCanDeleteActiveFeedback = userLogged?.permissions.includes(
    'delete-active-feedback'
  )

  const {
    isOpen: isActiveFeedbackDetailModalOpen,
    onToggle: onToggleActiveFeedbackDetailModal,
  } = useDisclosure()

  const activeFeedbackFinalDate = new Date(activeFeedback?.deadline);
  const formattedActiveFeedbackDate = format(activeFeedbackFinalDate, "dd MMM", { locale: ptBR });

  const relativeDate = formatDistanceToNowStrict(activeFeedbackFinalDate, { locale: ptBR });
  const isDateInFuture = isFuture(activeFeedbackFinalDate);
  const relativeDateText = isDateInFuture ? `em ${relativeDate}` : `${relativeDate} atrás`;


  const { mutateAsync: deleteActiveFeedbackFn } = useMutation({
    mutationFn: deleteActiveFeedback,
    onSuccess(_data) {
      queryClient.invalidateQueries({ queryKey: 'actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: feedbackQueryKeys[activeFeedback?.status] })
      queryClient.invalidateQueries({ queryKey: ['active-feedback', activeFeedback?.id] })
    },
  })

  async function handleDeleteActiveFeedback() {
    await promiseMessage(deleteActiveFeedbackFn({
      activeFeedbackId: activeFeedback?.id,
    }), 'Feedback excluído com sucesso!')
  }

  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      cursor='pointer'
      onClick={onToggleActiveFeedbackDetailModal}
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {activeFeedback?.cltDriverId ? activeFeedback?.cltDriver?.driver?.firstname : activeFeedback?.internClt ? activeFeedback?.internClt?.name : '-'}
        </Text>
        {userCanDeleteActiveFeedback && (
          <Circle
            size="5"
            color="white"
            onClick={(e) => {
              e.stopPropagation()
              handleDeleteActiveFeedback()
            }}
          >
            <Icon as={FaTrash} boxSize={"3.5"} color='blue.900' />
          </Circle>
        )}
      </HStack>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        {['scheduled', 'direct-leader-approving', 'executive-leader-approving', 'awaiting-leader-feedback'].includes(activeFeedback?.status) && (
          <HStack spacing={2}>
            {isDateInFuture ? (
              <>
                <Icon as={FaCalendarAlt} />
                <Text fontWeight="bold">{`Venc ${formattedActiveFeedbackDate}`}</Text>
              </>
            ) : (
              <>
                <Icon as={FaExclamationCircle} color="red.500" />
                <Text color="red.500" fontWeight="bold">{`Venc ${formattedActiveFeedbackDate}`}</Text>
              </>
            )}
            <Text>{relativeDateText}</Text>
          </HStack>
        )}

      </VStack>
      <Modal
        isOpen={isActiveFeedbackDetailModalOpen}
        onClose={onToggleActiveFeedbackDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <ActiveFeedbackDetails
          activeFeedbackId={activeFeedback.id}
        />
      </Modal>
    </Box>
  )
}
