import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getCltIntercurrences } from "api/cltIntercurrences/getCltIntercurrences";
import { CltIntercurrence, CltIntercurrenceStatus } from "api/cltIntercurrences/types/CltIntercurrence";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { cltIntercurrencePartnerTypeMap, cltIntercurrenceStatusMap, cltIntercurrenceTypeMap } from "utils/cltIntercurrenceMappers";
import { CltIntercurrenceTableFilters } from "./components/CltIntercurrenceTableFilters";
import { CltIntercurrenceTableRow } from "./components/CltIntercurrenceTableRow";

const headers = [
  { label: 'COLABORADOR', key: 'collaborator_name' },
  { label: 'TIPO DE INTERCORRÊNCIA', key: 'type' },
  { label: 'DATA DA INTERCORRÊNCIA', key: 'date' },
  { label: 'REGIONAL', key: 'regional' },
  { label: 'DESCRIÇÃO', key: 'description'},
  { label: 'STATUS', key: 'status' },
  { label: 'TIPO DE PARCEIRO', key: 'partner_type' },
]

function formatValuesToReport(values: CltIntercurrence[]) {
  return values?.map((cltIntercurrence) => ({
    ...cltIntercurrence,
    regional: cltIntercurrence.regional.toUpperCase(),
    date: cltIntercurrence.date
      ? format(new Date(cltIntercurrence.date), 'dd/MM/yyyy')
      : '-',
    type: cltIntercurrenceTypeMap[cltIntercurrence.type],
    description: cltIntercurrence.description,
    status: cltIntercurrenceStatusMap[cltIntercurrence?.status],
    partner_type: cltIntercurrencePartnerTypeMap[cltIntercurrence?.partner_type]
  }))
}


export function CltIntercurrences() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanCreateCltIntercurrence = userLogged?.permissions?.includes('create-clt-intercurrence')

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status')
  const endDate = searchParams.get('endDate')
  const startDate = searchParams.get('startDate')
  const type = searchParams.get('type')
  const collaboratorName = searchParams.get('collaboratorName')

  const {
    data: cltIntercurrencesResult
  } = useQuery({
    queryKey: ['clt-intercurrences',
      page,
      status,
      startDate,
      endDate,
      type,
      collaboratorName
    ],
    queryFn: () => getCltIntercurrences({
      currentPage: page,
      pageSize: '10',
      status: status as CltIntercurrenceStatus | 'all',
      startDate,
      endDate,
      type,
      collaboratorName
    })
  })

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: cltIntercurrencesDataReportResult,
    isLoading: isLoadingcltIntercurrencesDataReporTResult
  } = useQuery({
    queryKey: ['clt-intercurrences-report',
      page,
      status,
       cltIntercurrencesResult?.meta?.count,
       startDate,
       endDate,
       type,
       collaboratorName
      ],
    queryFn: () => getCltIntercurrences({
      currentPage: '1',
      pageSize: String(cltIntercurrencesResult?.meta?.count),
      status: ["requested", "approved", "rejected"].includes(status) ? status as CltIntercurrenceStatus : 'all',
      startDate,
      endDate,
      type,
      collaboratorName
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })



  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: cltIntercurrencesResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: cltIntercurrencesResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const csvReportProps = {
    data: cltIntercurrencesDataReportResult ? formatValuesToReport(cltIntercurrencesDataReportResult?.cltIntercurrences) : [],
    headers,
    filename: `intercorrências-clt.csv`,
  }


  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Intercorrências CLT</Heading>

        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            as={Link}
            to="/intercorrencias-clt/kanban"

          />
          {userCanCreateCltIntercurrence && (
            <IconButton
              aria-label="Criar intercorrência clt"
              as={Link}
              to="/intercorrencia-clt/criar"
              icon={<FaPlus />}
              size="sm"
              colorScheme="blue"
            />
          )}
        </Flex>
      </Flex>

      <CltIntercurrenceTableFilters />

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th></Th>
              <Th color="white">Protocolo</Th>
              <Th color="white">Tipo de intercorrência</Th>
              <Th color="white">Data da intercorrência</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Nome do Colaborador</Th>
              <Th color="white">Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {cltIntercurrencesResult?.cltIntercurrences.map((cltIntercurrence) => {
              return (
                <CltIntercurrenceTableRow
                  key={cltIntercurrence.id}
                  cltIntercurrence={cltIntercurrence}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!cltIntercurrencesDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingcltIntercurrencesDataReporTResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />

    </Box>
  )
}
