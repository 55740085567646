import { Flex, HStack, Stack } from "@chakra-ui/react";
import { GeneralCheckbox } from "components/Inputs/GeneralCheckbox";
import { Input } from "components/Inputs/Input";
import { InputMaskCustom } from "components/Inputs/InputMask";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { useEffect } from "react";
import { useCurrency } from "react-hook-currency";
import { Controller, NestedValue, useFormContext, useWatch } from "react-hook-form";
import { IDriverProps } from "services/getFunctions/driver/getDrivers";
import { daysOfWeekOptions } from "utils/CustomLists/daysOfWeekOptions";
import { handleSwitchMask } from "utils/handleChangeMask";

export interface CltDriversFormInputs {
  cellphone: string
  rg: string
  birth_date: string
  address: string
  days_of_work: NestedValue<string[]>
  start_work_hour: string
  end_work_hour: string
  start_interval_hour: string
  end_interval_hour: string
  weekend_work: 'yes' | 'no'
  start_weekend_work_hour: string | null
  end_weekend_work_hour: string | null
  salary: string
  family_salary: 'yes' | 'no'
  admission_date: string
  regional: string
  office: string
  cost_center: string
  benefits: NestedValue<string[]>
  vr_value: string
  va_payment_type: string
  va_value: string
  motorcycle_location_value: string
  business_days_daily_km: string
  business_days_fuel_price: string
  weekend_days_daily_km: string
  weekend_days_fuel_price: string
  cellphone_cost_help: string
  toll_value: string
  vt_value: string
  fuel_aid_value: string
  driver_id: string
  email: string
  home_work_monthly_value: string
  route_description: string
}

interface CltDriversFormProps {
  isEditPage?: boolean
  drivers: IDriverProps[]
  defaultSelectedDaysOfWork?: string[]
  defaultSelectedBenefits?: string[]
}

const familySalarySelectOptions = [
  { key: '0', value: 'no', showOption: 'Não' },
  { key: '1', value: 'yes', showOption: 'Sim' },
]

const weekendWorkSelectOptions = [
  { key: '0', value: 'no', showOption: 'Não' },
  { key: '1', value: 'yes', showOption: 'Sim' },
]

const regionalSelectOptions = [
  { key: '0', value: 'BH', showOption: 'BH' },
  { key: '1', value: 'SP', showOption: 'SP' },
  { key: '2', value: 'RJ', showOption: 'RJ' },
  { key: '3', value: 'DF', showOption: 'DF' },
  { key: '4', value: 'VCP', showOption: 'VCP' },
]

const officeSelectOptions = [
  { key: '0', value: 'MOTORISTA', showOption: 'Motorista' },
  { key: '1', value: 'MOTOFRETISTA', showOption: 'Motofretista' },
]

const cltBenefitsCheckboxOptions = [
  { key: '0', value: 'VR', showOption: 'Vale refeição' },
  { key: '1', value: 'VA', showOption: 'Vale alimentação' },
  { key: '2', value: 'ALUGUEL DE MOTO', showOption: 'Aluguel de moto' },
  { key: '3', value: 'COMBUSTÍVEL CASA-TRABALHO', showOption: 'Combustível casa-trabalho' },
  { key: '4', value: 'COMBUSTÍVEL OPERACIONAL', showOption: 'Combustível operacional' },
  { key: '5', value: 'AJUDA DE CUSTO CELULAR', showOption: 'Ajuda de custo celular' },
  { key: '6', value: 'PEDÁGIO', showOption: 'Pedágio' },
  { key: '7', value: 'VT', showOption: 'Vale transporte' },
  { key: '8', value: 'AUXÍLIO COMBUSTÍVEL', showOption: 'Auxílio combustível' },
  { key: '9', value: 'ADIANTAMENTO SALARIAL', showOption: 'Adiantamento salarial' },
]

const vaPaymentTypeSelectOptions = [
  { key: '0', value: 'daily', showOption: 'Diário' },
  { key: '1', value: 'monthly', showOption: 'Mensal' },
]

export function CltDriversForm({
  drivers,
  isEditPage,
  defaultSelectedDaysOfWork,
  defaultSelectedBenefits,
}: CltDriversFormProps) {

  const {
    format: formatCurrency,
    onChange: onChangeCurrency
  } = useCurrency({
    style: 'decimal'
  })

  const {
    control,
    register,
    unregister,
    formState: {
      errors
    }
  } = useFormContext<CltDriversFormInputs>()

  const [
    driverSelected,
    benefitsSelected,
    daysOfWorkSelected,
    vaPaymentTypeSelected,
    weekendWorkSelected
  ] = useWatch({
    control,
    name: [
      'driver_id',
      'benefits',
      'days_of_work',
      'va_payment_type',
      'weekend_work'
    ]
  })

  useEffect(() => {
    if (weekendWorkSelected === 'no') {
      unregister('start_weekend_work_hour')
      unregister('end_weekend_work_hour')
    }
  }, [unregister, weekendWorkSelected])


  useEffect(() => {
    if (benefitsSelected) {
      if (!benefitsSelected.includes('VR')) {
        unregister('vr_value')
      }
      if (!benefitsSelected.includes('VA')) {
        unregister(['va_value', 'va_payment_type'])
      }
      if (!benefitsSelected.includes('ALUGUEL DE MOTO')) {
        unregister('motorcycle_location_value')
      }
      if (!benefitsSelected.includes('COMBUSTÍVEL CASA-TRABALHO')) {
        unregister('home_work_monthly_value')
      }
      if (!benefitsSelected.includes('COMBUSTÍVEL OPERACIONAL')) {
        unregister(['business_days_daily_km', 'business_days_fuel_price', 'weekend_days_daily_km', 'weekend_days_fuel_price'])
      }
      if (!benefitsSelected.includes('AJUDA DE CUSTO CELULAR')) {
        unregister('cellphone_cost_help')
      }
      if (!benefitsSelected.includes('PEDÁGIO')) {
        unregister('toll_value')
      }
      if (!benefitsSelected.includes('VT')) {
        unregister('vt_value')
      }
      if (!benefitsSelected.includes('AUXÍLIO COMBUSTÍVEL')) {
        unregister('fuel_aid_value')
      }
    }
  }, [benefitsSelected, unregister])

  const driversSelectOptions = drivers?.map(driver => {
    return {
      key: driver.id,
      value: driver.id,
      showOption: `${driver.firstname} ${driver.lastname}`
    }
  })

  const driverSelectedInfo = drivers?.find(driver => {
    return driver.id === driverSelected
  })

  const hasBusinessDaysOnDaysOfWork = daysOfWorkSelected?.some(
    (day) => Number(day) >= 1 && Number(day) <= 5
  )

  const hasWeekendDaysOnDaysOfWork = daysOfWorkSelected?.some(
    (day) => Number(day) === 0 || Number(day) === 6
  )


  return (
    <Flex direction='column' gap={4}>
      <Select
        {...register('driver_id')}
        name='driver_id'
        label='Motorista'
        placeholder='Selecione uma opção...'
        options={driversSelectOptions}
        error={errors.driver_id}
        required
      />

      {driverSelectedInfo && (
        <>
          <Controller
            name='full_name'
            render={({ field }) => (
              <Input
                name='full_name'
                value={`${driverSelectedInfo?.firstname
                  } ${driverSelectedInfo?.lastname}`}
                label='Nome completo do colaborador'
                isDisabled
              />
            )}
          />

          <Stack
            spacing={4}
            direction={['column', 'column', 'row']}
          >
            <Controller
              name='cpf'
              render={({ field }) => (
                <Input
                  name='cpf'
                  value={`${driverSelectedInfo?.cpf}`}
                  label='Cpf'
                  isDisabled
                />
              )}
            />

            <Input
              {...register('email')}
              name='email'
              defaultValue={`${isEditPage ? driverSelectedInfo?.email : ''}`}
              label='E-mail do colaborador'
              placeholder='email@exemplo.com'
              error={errors.email}
              required
            />
          </Stack>
        </>
      )}

      <Controller
        control={control}
        name='rg'
        render={({ field: { onChange, value } }) => (
          <InputMaskCustom
            {...register('rg')}
            name='rg'
            defaultValue={value}
            onChange={onChange}
            mask={handleSwitchMask('rg')}
            label='Rg'
            error={errors.rg}
            required
          />
        )}
      />

      <Stack
        spacing={4}
        direction={['column', 'column', 'row']}
      >
        <Controller
          control={control}
          name='cellphone'
          render={({ field: { onChange, value } }) => (
            <InputMaskCustom
              {...register('cellphone')}
              name='cellphone'
              defaultValue={value}
              onChange={onChange}
              mask={handleSwitchMask('cellphone')}
              label='Celular'
              error={errors.cellphone}
              required
            />
          )}
        />

        <Input
          {...register('birth_date')}
          name='birth_date'
          type='date'
          label='Data de nascimento'
          error={errors.birth_date}
          required
        />
      </Stack>

      <TextArea
        {...register('address')}
        name='address'
        placeholder='Ex: Ladeira dos Tabajaras, 1014, CA 50, Botafogo, Rio de Janeiro, RJ - CEP 22281-132'
        label='Endreço completo'
        required
      />

      <Controller
        control={control}
        name='days_of_work'
        render={({ field }) => (
          <GeneralCheckbox
            {...register('days_of_work')}
            checkboxOptions={daysOfWeekOptions.map(option => {
              return {
                key: option.key,
                value: String(option.value),
                showOption: option.showOption
              }
            })}
            defaultCheckedOptions={defaultSelectedDaysOfWork}
            name='days_of_work'
            label='Dias de trabalho'
            error={errors.days_of_work}
            onCheckboxChange={field.onChange}
            required
          />
        )}
      />

      <Stack
        spacing={4}
        direction={['column', 'column', 'row']}
      >
        <Input
          {...register('start_work_hour')}
          name='start_work_hour'
          type='time'
          error={errors.start_work_hour}
          label='Horário inicial de trabalho'
          required
        />
        <Input
          {...register('end_work_hour')}
          name='end_work_hour'
          type='time'
          error={errors.end_work_hour}
          label='Horário final de trabalho'
          required
        />

      </Stack>

      <Stack
        spacing={4}
        direction={['column', 'column', 'row']}
      >
        <Input
          {...register('start_interval_hour')}
          name='start_interval_hour'
          type='time'
          error={errors.start_interval_hour}
          label='Horário inicial de intervalo'
          required
        />
        <Input
          {...register('end_interval_hour')}
          name='end_interval_hour'
          type='time'
          error={errors.end_interval_hour}
          label='Horário final de intervalo'
          required
        />
      </Stack>

      <Stack
        spacing={4}
        mt="4"
        direction={['column', 'column', 'row']}
      >
        <Select
          {...register('weekend_work')}
          name='weekend_work'
          options={weekendWorkSelectOptions}
          placeholder='Selecione uma opção...'
          label='Trabalha aos finais de semana'
          error={errors.weekend_work}
          required
        />
        {weekendWorkSelected === 'yes' && (
          <HStack w="100%">
            <Input
              {...register('start_weekend_work_hour')}
              name='start_weekend_work_hour'
              type='time'
              error={errors.start_weekend_work_hour}
              label='Horário inicial de trabalho final de semana'
              required
            />
            <Input
              {...register('end_weekend_work_hour')}
              name='end_weekend_work_hour'
              type='time'
              error={errors.end_weekend_work_hour}
              label='Horário final de trabalho final de semana'
              required
            />

          </HStack>)}
      </Stack>

      <Stack
        spacing={4}
        direction={['column', 'column', 'row']}
      >
        <Input
          {...register('salary')}
          name='salary'
          label='Salário'
          error={errors.salary}
          defaultValue={formatCurrency('000')}
          onChange={onChangeCurrency}
          required
        />

        <Select
          {...register('family_salary')}
          name='family_salary'
          options={familySalarySelectOptions}
          placeholder='Selecione uma opção...'
          label='Salário família'
          error={errors.family_salary}
          required
        />
      </Stack>

      <Input
        {...register('admission_date')}
        name='admission_date'
        type='date'
        label='Data de admissão'
        error={errors.admission_date}
        required
      />

      <Stack
        spacing={4}
        direction={['column', 'column', 'row']}
      >

        <Select
          {...register('regional')}
          name='regional'
          label='Regional'
          placeholder='Selecione uma opção...'
          options={regionalSelectOptions}
          error={errors.regional}
          required
        />

        <Select
          {...register('office')}
          name='office'
          label='Cargo'
          placeholder='Selecione uma opção...'
          options={officeSelectOptions}
          error={errors.office}
          required
        />
      </Stack>

      <Input
        {...register('cost_center')}
        name='cost_center'
        label='Centro de custo'
        error={errors.cost_center}
        required
      />
      <TextArea
        {...register("route_description")}
        name="route_description"
        label="Descrição da rota"
        error={errors.route_description}
      />

      <Controller
        control={control}
        name='benefits'
        render={({ field }) => (
          <GeneralCheckbox
            name='benefits'
            label='Benefícios'
            checkboxOptions={cltBenefitsCheckboxOptions}
            defaultCheckedOptions={defaultSelectedBenefits}
            onCheckboxChange={field.onChange}
            error={errors.benefits}
            required
          />
        )}
      />

      {benefitsSelected?.includes('VR') && (
        <Input
          {...register('vr_value')}
          name='vr_value'
          label='Valor diário (Vale refeição)'
          error={errors.vr_value}
          defaultValue={formatCurrency('000')}
          onChange={onChangeCurrency}
          required
        />
      )}

      {benefitsSelected?.includes('VA') && (
        <Stack
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Select
            {...register('va_payment_type')}
            name='va_payment_type'
            options={vaPaymentTypeSelectOptions}
            placeholder='Selecione uma opção...'
            label='Tipo de pagamento (VA)'
            error={errors.va_payment_type}
            required
          />
          {vaPaymentTypeSelected && (
            <Input
              {...register('va_value')}
              name='va_value'
              label={`${vaPaymentTypeSelected === 'monthly'
                ? 'Valor mensal'
                : 'Valor Diário'
                } (VA)`}
              error={errors.vr_value}
              defaultValue={formatCurrency('000')}
              onChange={onChangeCurrency}
              required
            />
          )}
        </Stack>
      )}

      {benefitsSelected?.includes('ALUGUEL DE MOTO') && (
        <Input
          {...register('motorcycle_location_value')}
          name='motorcycle_location_value'
          label='Valor mensal (Aluguel de moto)'
          error={errors.motorcycle_location_value}
          defaultValue={formatCurrency('000')}
          onChange={onChangeCurrency}
          required
        />
      )}

      {benefitsSelected?.includes('COMBUSTÍVEL CASA-TRABALHO') && (
        <Input
          {...register('home_work_monthly_value')}
          name='home_work_monthly_value'
          label='Valor mensal (casa-trabalho)'
          error={errors.home_work_monthly_value}
          defaultValue={formatCurrency('000')}
          onChange={onChangeCurrency}
          required
        />
      )}

      {benefitsSelected?.includes('COMBUSTÍVEL OPERACIONAL') && (
        <Stack
          spacing={4}
          direction="column"
        >
          {hasBusinessDaysOnDaysOfWork && (
            <Stack
              w="full"
              spacing={4}
              direction={{ base: 'column', lg: 'row' }}
            >
              <Input
                {...register('business_days_daily_km')}
                name='business_days_daily_km'
                label='Km diário (Seg-Sex ou 12x36)'
                error={errors.business_days_daily_km}
                defaultValue={formatCurrency('000')}
                onChange={onChangeCurrency}
                required
              />

              <Input
                {...register('business_days_fuel_price')}
                name='business_days_fuel_price'
                label='Combustível operacional diário (Seg-Sex ou 12x36)'
                error={errors.business_days_fuel_price}
                defaultValue={formatCurrency('000')}
                onChange={onChangeCurrency}
                required
              />

            </Stack>
          )}

          {hasWeekendDaysOnDaysOfWork && (
            <Stack
              w="full"
              spacing={4}
              direction={{ base: 'column', lg: 'row' }}
            >
              <Input
                {...register('weekend_days_daily_km')}
                name='weekend_days_daily_km'
                label='Km diário (Sab/Dom)'
                error={errors.weekend_days_daily_km}
                defaultValue={formatCurrency('000')}
                onChange={onChangeCurrency}
                required
              />

              <Input
                {...register('weekend_days_fuel_price')}
                name='weekend_days_fuel_price'
                label='Combustível operacional diário (Sab/Dom)'
                error={errors.weekend_days_fuel_price}
                defaultValue={formatCurrency('000')}
                onChange={onChangeCurrency}
                required
              />

            </Stack>
          )}
        </Stack>
      )}

      {benefitsSelected?.includes('AJUDA DE CUSTO CELULAR') && (
        <Input
          {...register('cellphone_cost_help')}
          name='cellphone_cost_help'
          label='Valor mensal (ajuda de custo celular)'
          error={errors.cellphone_cost_help}
          defaultValue={formatCurrency('000')}
          onChange={onChangeCurrency}
          required
        />
      )}

      {benefitsSelected?.includes('PEDÁGIO') && (
        <Input
          {...register('toll_value')}
          name='toll_value'
          label='Valor diário (Pedágio)'
          error={errors.toll_value}
          defaultValue={formatCurrency('000')}
          onChange={onChangeCurrency}
          required
        />
      )}

      {benefitsSelected?.includes('VT') && (
        <Input
          {...register('vt_value')}
          name='vt_value'
          label='Valor diário (VT)'
          error={errors.vt_value}
          defaultValue={formatCurrency('000')}
          onChange={onChangeCurrency}
          required
        />
      )}

      {benefitsSelected?.includes('AUXÍLIO COMBUSTÍVEL') && (
        <Input
          {...register('fuel_aid_value')}
          name='fuel_aid_value'
          label='Valor mensal (Auxílio combustível)'
          error={errors.fuel_aid_value}
          defaultValue={formatCurrency('000')}
          onChange={onChangeCurrency}
          required
        />
      )}
    </Flex>
  )
}
