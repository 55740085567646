import { Button, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deletePatrimony } from "api/patrimonies/deletePatrimony";
import { Patrimony } from "api/patrimonies/patrimony";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaArrowRight, FaTrash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { EditPatrimony } from "./EditPatrimony";
import { PatrimonyStatus } from "./PatrimonyStatus";

interface PatrimonyTableRowProps {
  patrimony: Patrimony

}
export function PatrimonyTableRow({ patrimony }: PatrimonyTableRowProps) {

  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const userCanEditPatrimony = userLogged?.permissions.includes('edit-patrimony')
  const userCanDeletePatrimony = userLogged?.permissions.includes('delete-patrimony')

  const { mutateAsync: deletePatrimonyFn } = useMutation({
    mutationFn: deletePatrimony,
    onSuccess() {
      queryClient.invalidateQueries(['patrimonies'])
    },
  })

  async function handleDeletePatrimony(patrimonyId: string) {
    await promiseMessage(deletePatrimonyFn({
      patrimonyId: patrimonyId
    }), 'Patrimônio excluído!')
  }

  const {
    isOpen: isEditPatrimonyModalOpen,
    onOpen: onOpenEditPatrimonyModal,
    onClose: onCloseEditPatrimonyModal
  } = useDisclosure()

  return (
    <Tr>
      <Td>{patrimony?.patrimonyType.type}</Td>
      <Td>{patrimony?.identifier ?? '-'}</Td>
      <Td>{patrimony.brand}</Td>
      <Td>{new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(patrimony.value_in_cents / 100)}</Td>
      <Td>
        <PatrimonyStatus status={patrimony.status} />
      </Td>
      <Td>
        {userCanEditPatrimony && (
          <>
            <Modal
              isOpen={isEditPatrimonyModalOpen}
              onClose={onCloseEditPatrimonyModal}
              isCentered
            >
              <ModalOverlay />

              <EditPatrimony
                onCloseModal={onCloseEditPatrimonyModal}
                patrimonyId={patrimony.id}

              />

            </Modal>
            <Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              onClick={onOpenEditPatrimonyModal}
              mr={2}
            >
              Editar
            </Button>
          </>
        )}
        {userCanDeletePatrimony && (
          <Button
            lineHeight="1"
            leftIcon={<FaTrash />}
            size="xs"
            onClick={() => handleDeletePatrimony(patrimony.id)}
          >
            Excluir
          </Button>
        )}
      </Td>
    </Tr>
  )
}
