import { usePagination } from "@ajna/pagination"
import { Box, Button, Flex, Heading, IconButton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import { getCollectorsExtrasDiscounts } from "api/collectors/getCollectorsExtrasDiscounts"
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton"
import { Pagination } from "components/Pagination/Pagination"
import { format } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { ExtraDiscount, ExtraDiscountStatus } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts"
import { useSearchParams } from "hooks/useSearchParams"
import { useEffect, useState } from "react"
import { FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa"
import { useQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import { CollectorsExtrasDiscountsTableFilter } from "./components/CollectorExtrasDiscountsTableFilter"
import { CollectorsExtraDiscountTableRow } from "./components/CollectorsExtrasDiscountsTableRow"


const headers = [
  { label: 'COLETADOR', key: 'collector' },
  { label: 'TIPO', key: 'type' },
  { label: 'DATA DO SERVIÇO', key: 'service_date' },
  { label: 'VALOR', key: 'value' },
  { label: 'ROTA', key: 'route' },
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'DESCRIÇÃO', key: 'description' },
  { label: 'DESCRIÇÃO', key: 'additionalDescription' },
  { label: 'DESCRIÇÃO', key: 'additionalDescriptionComplementary' },
  { label: 'CRIADO POR', key: 'created_by' },
]

function formatValuesToReport(values: ExtraDiscount[]) {
  return values?.map((extraDiscount) => ({
    ...extraDiscount,
    collector: extraDiscount.collector.trading_name,
    service_date: format(new Date(`${extraDiscount.service_date} 12:00`), 'dd/MM/yyy'),
    value: extraDiscount.value / 100,
    route: extraDiscount.service_route ?? '-',
    protocol: extraDiscount.services ? extraDiscount?.services?.map((service) => service.service.protocol).join(', ') : '-',
    created_by: extraDiscount?.createdBy ? `${extraDiscount?.createdBy.firstname} ${extraDiscount?.createdBy.lastname}` : '-',
    additionalDescription: extraDiscount?.additional_descriptions && extraDiscount.additional_descriptions[0]?.trim()
      ? extraDiscount.additional_descriptions[0]
      : '-',
    additionalDescriptionComplementary: extraDiscount?.additional_descriptions && extraDiscount.additional_descriptions[1]?.trim()
      ? extraDiscount.additional_descriptions[1]
      : '-'
  }))
}

export function CollectorsExtrasDiscounts() {
  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  const searchParams = useSearchParams()
  const { replace, push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userCanViewCollectorExtraDiscounts =
    userLogged?.permissions.includes('view-collector-extra-discount')
  const userCanCreateCollectorExtraDiscount = userLogged?.permissions?.includes('create-collector-extra-discount')

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }


  useEffect(() => {
    if (!userCanViewCollectorExtraDiscounts) {
      redirect('/')
    }
  }, [userCanViewCollectorExtraDiscounts, redirect])


  const status = searchParams.get('status')
  const page = searchParams.get('page') ?? '1'
  const collector = searchParams.get('collector')
  const endDate = searchParams.get('endDate')
  const startDate = searchParams.get('startDate')

  const {
    data: collectorsExtrasDiscountsResult,
  } = useQuery({
    queryKey: [
      'collectors-extras-discounts',
      page,
      status,
      collector,
      startDate,
      endDate,
    ],
    queryFn: () => getCollectorsExtrasDiscounts({
      currentPage: Number(page),
      pageSize: 10,
      status: ['requested', 'approved', 'effectivated', 'rejected'].includes(status) ? status as ExtraDiscountStatus : null,
      collector,
      endDate,
      startDate,
    }),
    keepPreviousData: false,
  })

  const {
    data: collectorsExtrasDiscountsReportResult,
    isLoading: isLoadingCollectorsExtrasDiscountsReportResult
  } = useQuery({
    queryKey: [
      'collectors-extras-discounts-report',
      status,
      collector,
      startDate,
      endDate,
      collectorsExtrasDiscountsResult?.count,
    ],
    queryFn: () => getCollectorsExtrasDiscounts({
      currentPage: 1,
      pageSize: collectorsExtrasDiscountsResult?.count,
      status: ['requested', 'approved', 'effectivated', 'rejected'].includes(status) ? status as ExtraDiscountStatus : null,
      collector,
      endDate,
      startDate,
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: collectorsExtrasDiscountsResult?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: 10
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const csvReportProps = {
    data: collectorsExtrasDiscountsReportResult ? formatValuesToReport(collectorsExtrasDiscountsReportResult?.extrasDiscounts) : [],
    headers,
    filename: `extras-e-descontos-coletadores.csv`,
  }

  return (
    <Box
      rounded="md"
      p="6"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Extras/Descontos de Coletadores</Heading>
        {userCanCreateCollectorExtraDiscount && (
          <IconButton
            aria-label="Criar extra/desconto"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            as={Link}
            to="/extra-desconto/coletador/criar"
          />
        )}
      </Flex>
      <CollectorsExtrasDiscountsTableFilter />
      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Coletador</Th>
              <Th color="white">Tipo</Th>
              <Th color="white">Data do serviço</Th>
              <Th color="white">Valor</Th>
              <Th color="white">Rota</Th>
              <Th color="white">Protocolo</Th>
              <Th color="white">Criado por</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {collectorsExtrasDiscountsResult?.extrasDiscounts?.map(extraDiscount => {
              return (
                <CollectorsExtraDiscountTableRow
                  key={extraDiscount?.id}
                  extraDiscount={extraDiscount}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {!collectorsExtrasDiscountsReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingCollectorsExtrasDiscountsReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}

      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
