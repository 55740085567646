import axios from "axios"
import { User } from "hooks/user/dtos/User"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

export interface RecurrentService {
  situation: string
  budgetId: number
  customerId: number
  daysOfWeek: string[]
  sourceAddressId: number
  destinationAddressId: number
  sourceCollectorId: number
  destinationCollectorId: number
  providerId: number
  sourceBranchId: number
  destinationBranchId: number
  deadline: string
  serviceType: string
  franchising: string
  modal: string
  vehicle: string
  caixaTermica: number
  embalagemSecundaria: number
  geloSeco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  collectDate: string
  collectHourStart: string
  collectHourEnd: string
  deliveryDate: string
  deliveryHour: string
  availabilityForecastDeadline: string
  availabilityForecastDay: string
  availabilityForecastTime: string
  boardHour: string
  boardDate: string
  plannedFlight: string
  materialType: string
  destinationAddressesInput: string
  driverAddressAssign: string
  driverProviderAssign: string
  observation: string
}

interface GetRecurrentServiceLogsParams {
  recurrentServiceId: string
  pageSize: number
  currentPage: number
}

type LogDetail = {
  [Property in keyof RecurrentService]: {
    prev: RecurrentService[Property]
    new: RecurrentService[Property]
  }
}

export interface RecurrentServiceLogs {
  id: string
  logDetail: LogDetail
  updatedBy: Pick<User, 'id' | 'firstname' | 'lastname'>
  createdAt: string
}

export interface GetRecurrentServiceLogsResponse {
  logs: RecurrentServiceLogs[]
  meta: {
    count: number
    totalPages: number
  }
}

export async function getRecurrentServiceLogs({
  recurrentServiceId,
  currentPage,
  pageSize
}: GetRecurrentServiceLogsParams) {
  try {
    const { data } = await api.get<GetRecurrentServiceLogsResponse>(
      `/services/recurrent/${recurrentServiceId}/logs`, {
      params: {
        pageSize,
        currentPage
      }
    }
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
