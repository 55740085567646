import { useHistory, useParams } from 'react-router'
import { useEffect } from 'react'
import { CollectorForm } from './CollectorForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { collectorFormatDataToBack } from '../../utils/CollectorsFunctions/collectorFormatDataToBack'
import { useAuth } from 'hooks/auth/useAuth'
import { useCollectorsFunctions } from 'hooks/collector/useCollectorsFunctions'
import { useSwal } from 'hooks/swal/useSwal'
import { Collector } from 'hooks/collector/dtos/Collector'
import { useCollector } from 'hooks/collector/useCollector'
import { Stock } from 'api/stocks/types/Stock'

interface QueryParams {
  id: string
  slug: string
}

interface CollectorContacts {
  name: string
  email: string
  phone: string
  description: string
  type: string[]
  canReceiveAutomaticallyMessages: string
}


interface FormInputs {
  situation: string
  company_name: string
  trading_name: string
  cnpj: string
  hub_list: string[]
  operational_email: string
  financial_email: string
  delay_cost: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  state: string
  city: string
  municipal_register: string
  payment_type: string
  day_expiration: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  has_driver_assign: string | boolean
  attachments?: FileList,
  observation: string
  has_maintenance: 'yes' | 'no'
  state_register: string
  responsible_name: string
  responsible_email: string
  responsible_cpf: string
  documents: {
    name: string
    dueDate: string
    attachmentId: string
    file: File
  }[]
  doesUseSystem: 'yes' | 'no'
  contacts: CollectorContacts[]
}

function isStockEmpty(stocks: Stock[]): boolean {
  if (stocks.length === 0) return true

  return stocks.every(stock => stock.current_stock === 0)
}


export function CollectorAddDetailEdit() {
  const { userLogged } = useAuth()

  const {
    createCollector: { mutateAsync: createCollector, isLoading: isCreateCollectorsLoading },
    updateCollector: { mutateAsync: updateCollector, isLoading: isUpdateCollectorsLoading },
    updateCollectorAttachment: { mutateAsync: updateCollectorAttachment, isLoading: isUpdateCollectorAttachmentLoading }
  } = useCollectorsFunctions()

  const { confirmMessage, standardMessage } = useSwal()
  const { id, slug }: QueryParams = useParams()


  const {
    data: collector
  } = useCollector(id, { enabled: !!id })

  const history = useHistory()

  const userLoggedHasCreateCollectorPermission =
    userLogged?.permissions.includes("add-collector")

  const userLoggedHasUpdateCollectorPermission =
    userLogged?.permissions.includes("edit-collector")

  const userLoggedHasViewCollectorPermission =
    userLogged?.permissions.includes("view-collector")

  useEffect(() => {
    if (slug === 'adicionar') {
      if (!userLoggedHasCreateCollectorPermission) {
        history.push('/')
      }
    }
    if (slug === 'editar') {
      if (!userLoggedHasUpdateCollectorPermission) {
        history.push('/')
      }
    }
    if (slug === 'visualizar') {
      if (!userLoggedHasViewCollectorPermission) {
        history.push('/')
      }
    }
  }, [
    slug,
    history,
    userLoggedHasCreateCollectorPermission,
    userLoggedHasUpdateCollectorPermission,
    userLoggedHasViewCollectorPermission
  ])

  async function handleCreateCollector(values: FormInputs) {
    const hasCreateCollector = await confirmMessage({ title: "Deseja criar um coletador?" })

    if (hasCreateCollector) {
      const formatedData = collectorFormatDataToBack(values)

      await createCollector(formatedData, {
        onSuccess: async (data: Collector) => {
          const formData = new FormData()
          Object.entries(values.attachments).forEach(([key, attachment]) => {
            formData.append('attachments', attachment)
          })
          await updateCollectorAttachment({ input: formData, collector_id: data.id })

          history.push('/coletadores')
        }
      })
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  async function handleUpdateCollector(values: FormInputs) {
    const isCollectorStockEmpty = isStockEmpty(collector.stocks)

    if (!isCollectorStockEmpty && values.situation === 'INATIVO') {

      const stockAvailable = collector?.stocks?.map(stock => `${stock.inputIDStock.name}: ${stock.current_stock}`)
        .join("\n")

      confirmMessage({ title: 'Coletadores só podem ser inativados se tiverem com seus insumos zerados', text: stockAvailable ?? '-', buttons: ['Cancelar', 'Ok'] })
      return

    } else {
      const hasUpdateCollector = await confirmMessage({ title: "Deseja atualizar um coletador?" })

      if (hasUpdateCollector) {
        const formattedData = collectorFormatDataToBack(values)

        await updateCollector({ input: formattedData, collector_id: id }, {
          onSuccess: async () => {
            const formData = new FormData()
            Object.entries(values.attachments).forEach(([key, attachment]) => {
              formData.append('attachments', attachment)
            })

            await updateCollectorAttachment({ input: formData, collector_id: id })
            history.push("/coletadores")
          }
        })
      } else {
        standardMessage("Ação cancelada com êxito!")
      }
    }

  }

  if (isCreateCollectorsLoading || isUpdateCollectorsLoading || isUpdateCollectorAttachmentLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'adicionar') {
    return (
      <CollectorForm
        slug={slug}
        id={id}
        title="Adicionar Coletador"
        action="Salvar"
        submit={handleCreateCollector}
        href=""
      />
    )
  } else if (slug === 'editar') {
    return (
      <CollectorForm
        slug={slug}
        id={id}
        title="Editar Coletador"
        action="Salvar"
        submit={handleUpdateCollector}
        href=""
      />
    )
  } else {
    return (
      <CollectorForm
        slug={slug}
        id={id}
        title="Editar Coletador"
        action="Editar"
        submit={async () => { }}
        isDisabled={true}
        href={`/coletador/editar/${id}`}
      />
    )
  }
}
