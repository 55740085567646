import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  Grid,
  GridItem,
  Checkbox,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import { TextArea } from '../../components/Inputs/TextInput'
import { situation } from '../../utils/customLists'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { handleSwitchMask } from '../../utils/handleChangeMask'
import { InputMaskCustom } from '../../components/Inputs/InputMask'
import { driverSchema } from '../../validations/driverSchema'
import { useAuth } from '../../hooks/auth/useAuth'
import { useDriver } from '../../hooks/driver/useDriver'
import { useCollectors } from 'hooks/collector/useCollectors'
import { Collector } from 'hooks/collector/dtos/Collector'
import { positiveNegativeOptions } from 'utils/CustomLists/positiveNegativeOptions'
import { getWeekDays } from 'utils/getWeekDays'
import { convertMinutesToStringTime } from 'utils/convertMinutesToStringTime'
import { getBusinessBudgetsByDriver } from 'api/drivers/getBusinessBudgetsByDriver'
import { useQuery } from 'react-query'
import { useSearchParams } from 'hooks/useSearchParams'
import { DriverBusinessBudgets } from './components/DriverBusinessBudgets'

interface FormInputProps {
  situation: string
  collector_id: string
  firstname: string
  lastname: string
  cpf: string
  email: string
  observation: string
  has_interval: string
  intervals: {
    startTime: string
    endTime: string
    weekDay: number
    enabled: boolean
  }[]
}

interface DriverFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: FormInputProps) => Promise<void>
}

export function DriverForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
}: DriverFormProps) {
  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const [collectorsFiltered, setCollectorsFiltered] = useState<
    Collector[]
  >([])

  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()
  const {
    driver: { data: driver, isLoading: isDriverLoading },
  } = useDriver(id || null, false, false)

  const { userLogged } = useAuth()

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(driverSchema),
    defaultValues: {
      intervals: [
        { weekDay: 0, enabled: false, startTime: '08:00', endTime: '18:00' },
        { weekDay: 1, enabled: true, startTime: '08:00', endTime: '18:00' },
        { weekDay: 2, enabled: true, startTime: '08:00', endTime: '18:00' },
        { weekDay: 3, enabled: true, startTime: '08:00', endTime: '18:00' },
        { weekDay: 4, enabled: true, startTime: '08:00', endTime: '18:00' },
        { weekDay: 5, enabled: true, startTime: '08:00', endTime: '18:00' },
        { weekDay: 6, enabled: false, startTime: '08:00', endTime: '18:00' },
      ],
    }
  })

  useEffect(() => {
    const setLoadingState = () => {
      if (!isCollectorsLoading && !isDriverLoading) {
        setIsLoadingPage(false)
      }
    }
    setLoadingState()
  }, [isCollectorsLoading, isDriverLoading])

  useEffect(() => {
    const setCollectors = () => {
      if (userLogged) {
        if (collectors) {
          if (userLogged.user_type === 'COLETADOR') {
            const collectorsFiltered = collectors.filter(
              (collector) => collector.id === userLogged.collector_id,
            )
            setCollectorsFiltered(collectorsFiltered)
          } else {
            setCollectorsFiltered(collectors)
          }
        }
      }
    }
    setCollectors()
  }, [collectors, userLogged])

  useEffect(() => {
    const setFieldValues = () => {
      if (slug === 'adicionar') setValue('situation', 'ATIVO')

      if (slug !== 'adicionar' && driver) {
        setValue('collector_id', driver.collector_id)
        setValue('situation', driver.situation)
        setValue('firstname', driver.firstname)
        setValue('lastname', driver.lastname)
        setValue('cpf', driver.cpf)
        setValue('email', driver.email)
        setValue('observation', driver.observation)

        if (driver.intervals.length) {
          setValue('has_interval', 'yes')
        } else {
          setValue('has_interval', 'no')
        }
      }
    }
    setFieldValues()
  }, [setValue, slug, driver])

  const { fields, update } = useFieldArray({
    name: 'intervals',
    control,
  })

  const hasIntervalValue = useWatch({ control, name: 'has_interval' })

  const hasInterval = hasIntervalValue === 'yes'

  const weekDays = getWeekDays()

  useEffect(() => {
    if (driver && hasInterval) {
      driver.intervals.sort((a, b) => a.week_day - b.week_day).forEach((interval, index) => {
        update(interval.week_day, {
          startTime: convertMinutesToStringTime(interval.time_start_in_minutes),
          endTime: convertMinutesToStringTime(interval.time_end_in_minutes),
          weekDay: interval.week_day,
          enabled: true
        })
      })
    }
  }, [driver, update, hasInterval])

  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'



  const { data: driverBusinessBudgets } =
    useQuery({
      queryKey: ['driver-business-budgets', driver, page],
      queryFn: () => getBusinessBudgetsByDriver({
        driverName: `${driver?.firstname} ${driver.lastname}`,
        currentPage: page,
        pageSize: '10',
      }),
      refetchOnWindowFocus: false,
      enabled: !!id && !!driver
    })

  const {
    isOpen: isOpenBusinessBudgetsModal,
    onOpen: onOpenBusinessBudgetsModal,
    onClose: onCloseBusinessBudgetsModal
  } = useDisclosure()

  if (isLoadingPage) {
    return <GeneralContentLoading />
  }

  return (

    <Flex>
      <Box
        as="form"
        flex="1"
        borderRadius="8px"
        bg="white"
        p={['6', '8']}
        onSubmit={handleSubmit(submit)}
        noValidate
      >
        <Flex justify='space-between'>
          <Heading letterSpacing="tight">{title}</Heading>
          {((slug === 'editar' || slug === 'visualizar') && Boolean(driverBusinessBudgets)) && (
            <Flex gap={4}>
              {driverBusinessBudgets?.businessBudgets?.length !== 0 && (
                <Button colorScheme='blue' onClick={onOpenBusinessBudgetsModal}>
                  Orçamentos business
                </Button>
              )}
              <Modal
                isOpen={isOpenBusinessBudgetsModal}
                onClose={onCloseBusinessBudgetsModal}
              >
                <ModalOverlay />
                <DriverBusinessBudgets
                  driverName={`${driver?.firstname} ${driver.lastname}`}
                />
              </Modal>
            </Flex>
          )}
        </Flex>

        <Divider my="6" borderColor="gray.700" />

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Select
            {...register('collector_id')}
            name="collector_id"
            label="Pertence ao Coletador"
            error={errors.collector_id}
            isDisabled={isDisabled}
            collectors={collectorsFiltered}
            placeholder="Selecione uma opção..."
            required
          />

          {slug === 'adicionar' ? (
            <Input
              {...register('situation')}
              name="situation"
              label="Situação"
              isDisabled
              required
            />
          ) : (
            <Select
              {...register('situation')}
              name="situation"
              label="Situação"
              error={errors.situation}
              isDisabled={isDisabled}
              situations={situation}
              placeholder="Selecione a situação"
              required
            />
          )}
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('firstname')}
            isDisabled={isDisabled}
            name="firstname"
            label="Nome"
            error={errors.firstname}
            required
          />
          <Input
            {...register('lastname')}
            isDisabled={isDisabled}
            name="lastname"
            label="Sobrenome"
            error={errors.lastname}
            required
          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Controller
            control={control}
            name="cpf"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('cpf')}
                isDisabled={isDisabled}
                name="cpf"
                label="CPF"
                mask={handleSwitchMask('cpf')}
                defaultValue={value}
                onChange={onChange}
                error={errors.cpf}
                required
              />
            )}
          />
          <Input
            {...register('email')}
            isDisabled={isDisabled}
            placeholder="email@exemplo.com"
            name="email"
            label="E-mail"
          />
        </Stack>

        <Stack mt="4">
          <Select
            {...register('has_interval')}
            name='has_interval'
            label='Possui agendamento?'
            placeholder='Selecione uma opção...'
            options={positiveNegativeOptions}
          />
        </Stack>

        {hasInterval && (
          <Stack w="full" mt="4">
            <Grid m="0 auto" w="full" maxW="800px" templateColumns="repeat(1, 1fr)">
              {fields?.map((field, index) => {
                return (
                  <GridItem
                    w="full"
                    key={field.id}
                    border="1px solid"
                    _first={{ borderTopRadius: 'lg' }}
                    _last={{ borderBottomRadius: 'lg' }}
                    borderColor="blackAlpha.100"
                    p="2"
                  >
                    <Flex align="center" justify="space-between">
                      <Flex gap="3">
                        <Controller
                          control={control}
                          name={`intervals.${index}.enabled`}
                          render={({ field: currentField }) => (
                            <Checkbox
                              // value={currentField.value}
                              onChange={currentField.onChange}
                              defaultChecked={field.enabled}
                              size="lg"
                              rounded="2xl"
                            />
                          )}
                        />

                        <Text>{weekDays[field.weekDay]}</Text>
                      </Flex>
                      <Flex gap="3">
                        <Input
                          {...register(`intervals.${index}.startTime`)}
                          name={`intervals.${index}.startTime`}
                          type="time"
                          step={3600}
                          defaultValue={field.startTime}
                        />
                        <Input
                          {...register(`intervals.${index}.endTime`)}
                          name={`intervals.${index}.endTime`}
                          type="time"
                          step={3600}
                          defaultValue={field.endTime}
                        />
                      </Flex>
                    </Flex>
                  </GridItem>
                )
              })}
            </Grid>

          </Stack>
        )}


        <Stack mt="4">
          <TextArea
            {...register('observation')}
            name="observation"
            label="Observações"
            isDisabled={isDisabled}
          />
        </Stack>

        <Flex mt="8" justify="flex-end">
          <HStack>
            {slug === 'visualizar' ? (
              <Link to={href}>
                <Button
                  type="button"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              </Link>
            ) : (
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                loadingText="Carregando"
              >
                {action}
              </Button>
            )}

            <Link to="/motoristas">
              <Button type="button" colorScheme="gray">
                Lista de Motoristas
              </Button>
            </Link>
          </HStack>
        </Flex>
      </Box>
    </Flex>

  )
}
