import axios from "axios";
import { useAuth } from "hooks/auth/useAuth";
import { useSwal } from "hooks/swal/useSwal";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { RequestError } from "utils/errors/RequestErrors";
import { AggregateAccountTypes } from "./dtos/Aggregate";

interface DaysOfWorkInput {
  day: 0 | 1 | 2 | 3 | 4 | 5 | 6,
  price: string
}

export interface AggregateInput {
  company_name: string
  trading_name: string
  cnpj: string
  driver_id: string
  rg: string
  cellphone: string
  birth_date: string
  cep: string
  street: string
  state: string
  city: string
  neighborhood: string
  number: number
  complement: string
  contract_start_date: string
  payment_type: string
  due_date: number
  price: string
  days_of_work: DaysOfWorkInput[],
  rider_backup: string
  bank: string
  cpf: string
  account_type: AggregateAccountTypes
  agency: string
  account: string
  observation: string
  cities: {
    cityId: string
    motorcycleValueInCents: string
    carValueInCents: string
  }[]
  route_description?: string | null
}

interface UpdateAggregate {
  aggregate_id: string
  input: AggregateInput
}


async function createAggregateReqFunction(input: AggregateInput) {
  try {
    const { data } = await api.post("/aggregates", input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function updateAggregateReqFunction({ aggregate_id, input }: UpdateAggregate) {
  try {
    await api.put(`/aggregates/${aggregate_id}`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}



interface UpdateAggregateAttachmentsReqFunctionProps {
  aggregateId: string
  input: FormData
}

async function updateAggregateAttachmentsReqFunction({
  aggregateId,
  input
}: UpdateAggregateAttachmentsReqFunctionProps) {
  try {
    await api.patch(`/aggregates/${aggregateId}/attachments`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

interface DeleteAggregateAttachmentReqFunctionProps {
  aggregateId: string
  filename: string
}

async function deleteAggregateAttachmentReqFunction({
  aggregateId,
  filename
}: DeleteAggregateAttachmentReqFunctionProps) {
  try {
    await api.patch(`/aggregates/${aggregateId}/attachments/${filename}/delete`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function deleteAggregateReqFunction(aggregate_id: string) {
  try {
    await api.delete(`/aggregates/${aggregate_id}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

interface CreateAggregateExtraDiscountInput {
  type: 'EXTRA' | 'DESCONTO'
  serviceDate: string
  value: number
  description: string
  serviceType: 'EXPRESS' | 'BUSINESS'
  serviceRoute: string
  servicesProtocols: number[]
  attachmentsIds: string[]
}

interface CreateAggregateExtraDiscountReqFunctionProps {
  aggregateId: string
  input: CreateAggregateExtraDiscountInput
}

async function createAggregateExtraDiscountReqFunction({
  aggregateId,
  input
}: CreateAggregateExtraDiscountReqFunctionProps) {
  try {
    await api.post(`/aggregates/${aggregateId}/extras-discounts/`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

type UpdateAggregateExtraDiscountInput = CreateAggregateExtraDiscountInput & {
  additionalDescriptions: string[] | null
}

interface UpdateAggregateExtraDiscountReqFunctionProps {
  aggregateId: string
  extraDiscountId: string
  input: UpdateAggregateExtraDiscountInput
}

async function updateCollectorExtraDiscountReqFunction({
  aggregateId,
  extraDiscountId,
  input
}: UpdateAggregateExtraDiscountReqFunctionProps) {
  try {
    await api.put(`/aggregates/${aggregateId}/extras-discounts/${extraDiscountId}`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function approveAggregateExtraDiscountReqFunction(
  cltDriverExtraDiscountId: string
) {
  try {
    await api.patch(`/aggregates/extras-discounts/${cltDriverExtraDiscountId}/approve`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function effectiveAggregateExtraDiscountReqFunction(
  cltDriverExtraDiscountId: string
) {
  try {
    await api.patch(`/aggregates/extras-discounts/${cltDriverExtraDiscountId}/effective`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function rejectAggregateExtraDiscountReqFunction(
  cltDriverExtraDiscountId: string
) {
  try {
    await api.patch(`/aggregates/extras-discounts/${cltDriverExtraDiscountId}/reject`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function deleteAggregateExtraDiscountReqFunction(
  cltDriverExtraDiscountId: string
) {
  try {
    await api.delete(`/aggregates/extras-discounts/${cltDriverExtraDiscountId}`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

interface ActivateAggregateReqFunctionProps {
  aggregateId: string
  input: FormData
}

async function activateAggregateReqFunction({ aggregateId, input }: ActivateAggregateReqFunctionProps) {
  try {
    await api.patch(`/aggregates/${aggregateId}/activate`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

interface DismisslAggregateInput {
  hasLogisticsDirectorApproved: string
  endContractDate: string
}

interface DismissalAggregateReqFunctionProps {
  aggregateId: string
  input: DismisslAggregateInput
}

async function dismissalAggregateReqFunction({ aggregateId, input }: DismissalAggregateReqFunctionProps) {
  try {
    await api.patch(`/aggregates/${aggregateId}/dismiss`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

interface InactivateAggregateReqFunctionProps {
  aggregateId: string
  input: FormData
}

async function inactivateAggregateReqFunction({ aggregateId, input }: InactivateAggregateReqFunctionProps) {
  try {
    await api.patch(`/aggregates/${aggregateId}/inactivate`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

export function useAggregateFunctions() {
  const { successMessage, errorMessage } = useSwal()
  const queryClient = useQueryClient()
  const { handleLogout, } = useAuth()

  const onRequestSuccess = async (message: string) => {
    await queryClient.invalidateQueries("aggregate")
    await queryClient.invalidateQueries("aggregates")
    return successMessage(message)
  }

  const onRequestError = async (error: RequestError, message: string) => {
    errorMessage(message)
    if (error.httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const createAggregate = useMutation('createAggregate', createAggregateReqFunction, {
    onSuccess: () => onRequestSuccess("Agregado criado com sucesso!"),
    onError: (error: any) => onRequestError(error.status, error.message)
  })

  const updateAggregate = useMutation('updateAggregate', updateAggregateReqFunction, {
    onSuccess: () => onRequestSuccess("Agregado atualizado com sucesso!"),
    onError: (error: any) => onRequestError(error.status, error.message)
  })

  const deleteAggregate = useMutation('deleteAggregate', deleteAggregateReqFunction, {
    onSuccess: () => onRequestSuccess("Agregado excluído com sucesso!"),
    onError: (error: any) => onRequestError(error.status, error.message)
  })

  const updateAggregateAttachments = useMutation('updateAggregateAttachments', updateAggregateAttachmentsReqFunction, {
    onError: (error: any) => onRequestError(error.status, error.message)
  })

  const deleteAggregateAttachment = useMutation('deleteAggregateAttachment', deleteAggregateAttachmentReqFunction, {
    onError: (error: any) => onRequestError(error.status, error.message)
  })

  const createAggregateExtraDiscount = useMutation(
    'createAggregateExtraDiscount', createAggregateExtraDiscountReqFunction
  )

  const updateCollectorExtraDiscount = useMutation(
    'updateCollectorExtraDiscount', updateCollectorExtraDiscountReqFunction
  )

  const approveAggregateExtraDiscount = useMutation(
    'approveAggregateExtraDiscount', approveAggregateExtraDiscountReqFunction
  )

  const effectiveAggregateExtraDiscount = useMutation(
    'effectiveAggregateExtraDiscount', effectiveAggregateExtraDiscountReqFunction
  )

  const rejectAggregateExtraDiscount = useMutation(
    'rejectAggregateExtraDiscount', rejectAggregateExtraDiscountReqFunction
  )

  const deleteAggregateExtraDiscount = useMutation(
    'deleteAggregateExtraDiscount', deleteAggregateExtraDiscountReqFunction
  )

  const activateAggregate = useMutation('activateAggregate', activateAggregateReqFunction)

  const dismissalAggregate = useMutation('dismissalAggregate', dismissalAggregateReqFunction)

  const inactivateAggregate = useMutation('inactivateAggregate', inactivateAggregateReqFunction)

  return {
    createAggregate,
    updateAggregate,
    deleteAggregate,
    updateAggregateAttachments,
    deleteAggregateAttachment,
    createAggregateExtraDiscount,
    updateCollectorExtraDiscount,
    approveAggregateExtraDiscount,
    effectiveAggregateExtraDiscount,
    rejectAggregateExtraDiscount,
    deleteAggregateExtraDiscount,
    activateAggregate,
    dismissalAggregate,
    inactivateAggregate
  }
}
