import { Button, Flex, FormLabel, Icon, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Link as ChakraLink, Text, Spinner, TableContainer, Table, Tbody, Tr, Td, useDisclosure, IconButton, Modal, ModalOverlay } from "@chakra-ui/react"
import { useFieldArray, useForm, useWatch } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { validateHasFile } from "utils/imageValidation"
import { buyPurchaseOrder } from "api/purchaseOrders/buyPurchaseOrder"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { GetPurchaseOrdersResponse } from "api/purchaseOrders/getPurchaseOrders"
import { useToastify } from "hooks/toastify/useToastify"
import { Select } from "components/Inputs/SelectInput"
import { Input } from "components/Inputs/Input"
import { Radio } from "components/Inputs/RadioInput"
import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions"
import { FiFile } from "react-icons/fi"
import { FaPlus, FaTrash } from "react-icons/fa"
import { getPurchaseOrder } from "api/purchaseOrders/getPurchaseOrder"
import { useEffect } from "react"
import { useGeneralProviders } from "hooks/generalProvider/useGeneralProviders"
import { CreateSupport } from "pages/Support/components/CreateSupport"

interface BuyPurchaseOrderProps {
  purchaseOrderId: string
  onCloseModal: () => void
}

export interface BuyPurchaseOrderFormInputs {
  providerId: string
  financialClassificationId: string
  providerCostCenter: string
  approvedQuoteAttachments: {
    attachment: FileList
  }[]
  purchaseDueDate: string
  purchaseHasInvoice: 'yes' | 'no'
  paymentType: string
  installmentsQuantity: number
}

export const paymentTypeOptions = [
  { key: '0', value: 'Boleto', showOption: 'Boleto' },
  { key: '1', value: 'Transferência', showOption: 'Transferência' },
  { key: '2', value: 'PIX', showOption: 'PIX' },
  { key: '3', value: 'Cartão de crédito(fechamento dia 09)', showOption: 'Cartão de crédito(fechamento dia 09)' },
]

const buyPurchaseOrderSchema = yup.object().shape({
  providerId: yup.string().required('Campo obrigatório'),
  financialClassificationId: yup.string().required('Campo obrigatório'),
  providerCostCenter: yup.string().required('Campo obrigatório'),
  approvedQuoteAttachments: yup
    .array()
    .of(yup.object({
      attachment: yup
        .mixed()
        .test('validateHasFile', 'Campo obrigatório', (value: FileList) => validateHasFile(value)),
    }))
    .min(1, 'Campo obrigatório'),
  paymentType: yup.string().required('Campo obrigatório'),
  purchaseDueDate: yup.string().required('Campo obrigatório'),
  purchaseHasInvoice: yup.mixed().required('Campo obrigatório'),
  installmentsQuantity: yup.mixed().when('paymentType', {
    is: 'Cartão de crédito(fechamento dia 09)',
    then: yup.number().required('Campo obrigatório').typeError('Insira apenas números!'),
  })
})

export function BuyPurchaseOrder({
  purchaseOrderId,
  onCloseModal
}: BuyPurchaseOrderProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { mutateAsync: buyPurchaseOrderFn } = useMutation({
    mutationFn: buyPurchaseOrder,
    onSuccess(_data, { input, purchaseOrderId }) {
      const cachedPurchaseOrders = queryClient.getQueriesData<GetPurchaseOrdersResponse>({
        queryKey: ['purchaseOrders']
      })
      queryClient.invalidateQueries(['purchaseOrders'])
      cachedPurchaseOrders.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          purchaseOrders: cachedData.purchaseOrders.map((purchaseOrder) => {
            if (purchaseOrder.id === purchaseOrderId) {
              return {
                ...purchaseOrder,
              }
            }

            return purchaseOrder
          })
        })
      })

      onCloseModal()
    }
  })

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<BuyPurchaseOrderFormInputs>({
    resolver: yupResolver(buyPurchaseOrderSchema)
  })

  const { data: purchaseOrderData, isLoading: isPurchaseOrderDataLoading } = useQuery({
    queryKey: ['purchaseOrder', purchaseOrderId],
    queryFn: () => getPurchaseOrder({ purchaseOrderId })
  })

  const {
    data: providersData,
    isFetching: isFetchingProvidersData
  } = useGeneralProviders()


  const purchaseOrder = purchaseOrderData?.purchaseOrder

  const [providerSelected, approvedQuoteAttachments, paymentType] = useWatch({
    control,
    name: ['providerId', 'approvedQuoteAttachments', 'paymentType']
  })

  const isCreditCardPaymentType = paymentType === 'Cartão de crédito(fechamento dia 09)'

  const {
    fields: approvedQuoteAttachmentsFields,
    append: appendApprovedQuoteAttachmentField,
    remove: removeApprovedQuoteAttachmentField
  } = useFieldArray({
    control,
    name: 'approvedQuoteAttachments',
  })

  const handleRemoveApprovedQuoteAttachmentField = (index: number) => removeApprovedQuoteAttachmentField(index)

  const handleAppendApprovedQuoteAttachmentField = () => appendApprovedQuoteAttachmentField({
    attachment: undefined
  })

  const isPurchaseOrderTypePmc = purchaseOrderData?.purchaseOrder?.purchase_description === 'PMC'

  const isProviderSelectEnabled =
    !isPurchaseOrderTypePmc || (isPurchaseOrderTypePmc && !purchaseOrder?.provider)

  useEffect(() => {
    if (isPurchaseOrderTypePmc && purchaseOrderData) {
      setValue('providerId', purchaseOrderData?.purchaseOrder?.provider.id)
    }
  }, [isPurchaseOrderTypePmc, purchaseOrderData, setValue])

  const providersOptions = providersData?.generalProviders?.map((provider) => {
    return {
      key: provider.id,
      value: provider.id,
      showOption: provider.trading_name
    }
  })

  const providerSelectedInfo = providersData?.generalProviders?.find(
    (provider) => provider.id === providerSelected
  )

  const financialClassificationsOptions = providerSelectedInfo
    ? providerSelectedInfo
      ?.financialClassifications.map((financialClassification) => {
        return {
          key: financialClassification.id,
          value: financialClassification.id,
          showOption: financialClassification.type
        }
      })
    : []

  const costCentersOptions = providerSelectedInfo
    ? JSON.parse(providerSelectedInfo
      ?.cost_centers)?.map((costCenter: { name: string }, index: number) => {
        return {
          key: String(index),
          value: costCenter.name,
          showOption: costCenter.name.toUpperCase()
        }
      })
    : []



  async function handleBuyPurchaseOrder(values: BuyPurchaseOrderFormInputs) {
    const formData = new FormData()

    formData.append('financialClassificationId', values.financialClassificationId)
    formData.append('providerCostCenter', values.providerCostCenter)
    values.approvedQuoteAttachments.forEach((attachment) => {
      formData.append('approvedQuoteAttachments', attachment.attachment[0])
    })
    formData.append('purchaseDueDate', values.purchaseDueDate)
    formData.append('purchaseHasInvoice', values.purchaseHasInvoice)
    formData.append('paymentType', values.paymentType)
    if (values.installmentsQuantity) formData.append('installmentsQuantity', values.installmentsQuantity.toString())


    await promiseMessage(buyPurchaseOrderFn({
      providerId: values.providerId,
      purchaseOrderId,
      input: formData
    }), "Compra realizada com sucesso! 🎉")
  }

  const {
    isOpen: isCreateSupportOpen,
    onOpen: onOpenCreateSupport,
    onClose: onCloseCreateSupport
  } = useDisclosure()

  return (
    <ModalContent maxW='800px'>
      <ModalHeader letterSpacing="tight">Finalizar Compra</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as='form'
        onSubmit={handleSubmit(handleBuyPurchaseOrder)}
        maxH='500px'
        overflowY='scroll'
      >
        {isFetchingProvidersData || isPurchaseOrderDataLoading ? (
          <Spinner />
        ) : (
          <>
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Protocolo</Td>
                    <Td isNumeric>{purchaseOrder.protocol}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo</Td>
                    <Td isNumeric>{purchaseOrder.type}</Td>
                  </Tr>
                  <Tr>
                    <Td>Descrição da compra</Td>
                    <Td isNumeric>{purchaseOrder?.purchase_description}</Td>
                  </Tr>

                  {!isPurchaseOrderTypePmc && (
                    <Tr>
                      <Td>Motivo da compra</Td>
                      <Td isNumeric alignSelf='flex-end' display="flex" justifyContent="flex-end">
                        <Text w='220px' whiteSpace="pre-line">{purchaseOrder?.purchase_reason}</Text>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex mt='4' direction="column" gap={6} align="center">
              <Select
                {...register('providerId')}
                name='providerId'
                error={errors.providerId}
                options={providersOptions}
                label='Fornecedor'
                placeholder='Selecione uma opção...'
                isDisabled={!isProviderSelectEnabled}
                required
              />

              {providerSelected && (
                <>
                  <Select
                    {...register('financialClassificationId')}
                    name='financialClassificationId'
                    error={errors.financialClassificationId}
                    options={financialClassificationsOptions}
                    label='Classificação financeira'
                    placeholder='Selecione uma opção...'
                    required
                  />

                  <Select
                    {...register('providerCostCenter')}
                    name='providerCostCenter'
                    error={errors.providerCostCenter}
                    options={costCentersOptions}
                    label='Centro de custo'
                    placeholder='Selecione uma opção...'
                    required
                  />
                </>
              )}

              <Input
                {...register("purchaseDueDate")}
                name="purchaseDueDate"
                label="Data de vencimento"
                error={errors.purchaseDueDate}
                type="date"
                required
              />
              <Radio
                {...register('purchaseHasInvoice')}
                name='purchaseHasInvoice'
                radioOptions={positiveNegativeOptions}
                label='Possui nota fiscal?'
                error={errors.purchaseHasInvoice}
                required
              />
              <Radio
                {...register('paymentType')}
                name='paymentType'
                radioOptions={paymentTypeOptions}
                label='Forma de pagamento?'
                error={errors.purchaseHasInvoice}
                required
              />

              {isCreditCardPaymentType && (
                <Input
                  {...register("installmentsQuantity")}
                  name="installmentsQuantity"
                  type="number"
                  label="Quantidade de parcelas"
                  error={errors.installmentsQuantity}
                  required
                />
              )}

              {approvedQuoteAttachmentsFields.map((field, index) => {
                return (
                  <Flex key={field.id} w="full" direction="column">
                    <Flex align="center" w="full">
                      <Input
                        {...register(`approvedQuoteAttachments.${index}.attachment`)}
                        name={`approvedQuoteAttachments.${index}.attachment`}
                        error={
                          errors?.approvedQuoteAttachments
                            ? errors?.approvedQuoteAttachments[index]?.attachment
                            : undefined
                        }
                        type='file'
                        label={`Anexo ${index + 1}`}
                        hidden
                        required
                      >
                        <Button
                          leftIcon={<Icon as={FiFile} />}
                          as={FormLabel}
                          htmlFor={`approvedQuoteAttachments.${index}.attachment`}
                          h='48px'
                          _hover={{
                            cursor: 'pointer'
                          }}
                          size='sm'
                        >
                          Escolha um arquivo
                        </Button>
                      </Input>

                      {approvedQuoteAttachments && approvedQuoteAttachments.length > 0 && (
                        approvedQuoteAttachments[index]?.attachment[0] && (
                          <ChakraLink
                            href={URL.createObjectURL(approvedQuoteAttachments[index]?.attachment[0])}
                            isExternal
                          >
                            {approvedQuoteAttachments[index]?.attachment[0].name} (Visualizar)
                          </ChakraLink>
                        )
                      )}
                    </Flex>

                    <Button
                      leftIcon={<Icon as={FaTrash} />}
                      variant="outline"
                      colorScheme="red"
                      onClick={() => handleRemoveApprovedQuoteAttachmentField(index)}
                    >
                      Remover
                    </Button>
                  </Flex>
                )
              })}
              <Flex
                w="full"
                direction="column"
                justify="flex-start"
              >
                <Button
                  leftIcon={<Icon as={FaPlus} />}
                  onClick={handleAppendApprovedQuoteAttachmentField}
                >
                  Adicionar anexo
                </Button>
                {errors?.approvedQuoteAttachments && (
                  <Text
                    color="red.500"
                    fontSize="sm"
                    mt="2"
                  >
                    {errors?.approvedQuoteAttachments.message}
                  </Text>
                )}
              </Flex>

            </Flex>
            <Flex mt={3} alignItems="baseline" justifyContent="space-between">
              <FormLabel fontSize='sm'>
                Solicitar pagamento
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <IconButton
                aria-label="Abrir atendimento"
                icon={<FaPlus />}
                colorScheme="blue"
                size="sm"
                onClick={onOpenCreateSupport} />
            </Flex>
            <Modal
              isOpen={isCreateSupportOpen}
              onClose={onCloseCreateSupport}
              isCentered
              size="2xl"
            >
              <ModalOverlay />
              <CreateSupport
                onClose={onCloseCreateSupport}
                required={true} />
            </Modal>
            <Flex
              mt="6"
              w="full"
              justify="flex-end"
            >
              <Button
                type="submit"
                size="sm"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Finalizar
              </Button>
            </Flex>
          </>
        )}

      </ModalBody>
    </ModalContent>
  )

}
