import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { VacationStatus } from "./_types/Vacation";

interface EditVacationBody {
  collaborator_name: string
  acquisition_start_date: string
  acquisition_end_date: string
  deadline: string
  planning_start_date: string
  regional: string
  collaborator_email: string
  status: VacationStatus
}

interface EditVacationProps {
  vacationId: string
  body: EditVacationBody
}

export async function editVacation({
  vacationId,
  body
}: EditVacationProps) {
  try {
    await api.patch(`/public/vacation/${vacationId}`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
