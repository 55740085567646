import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Popover, PopoverContent, PopoverTrigger, Select, Stack, Text, Textarea, useDisclosure, useRadioGroup } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCltIntercurrence } from "api/cltIntercurrences/createCltIntercurrence";
import { set } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useCltDrivers } from "hooks/cltDriver/useCltDrivers";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { captalize } from "utils/captalize";
import { validateHasFile } from "utils/fileValidation";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import * as yup from "yup"
import { CollaboratorRadioItem } from "./components/CollaboratorRadioItem";
import { ExtraHoursCltIntercurrenceForm } from "./components/ExtraHoursCltIntercurrenceForm";
import { MissWorkCltIntercurrenceForm } from "./components/MissWorkCltIntercurrenceForm";
import { PatrimonyWithoutReturnCltIntercurrenceForm } from "./components/PatrimonyWithoutReturnCltIntercurrenceForm";
import { TicketLogAdditionalValueCltIntercurrenceForm } from "./components/TicketLogAdditionalValueCltIntercurrenceForm";
import { WorkAccidentCltIntercurrenceForm } from "./components/WorkAccidentCltIntercurrenceForm";

interface CreateCltIntercurrenceSchema {
  type: string
  date: string
  hour: string
  test: string
  description: string
  regional: string
  otherRegional: string
  partnerType: string
  collaboratorName: string

  isTrajectoryOrOccupationalAccident: string
  accidentAddress: string
  bodyPart: string
  medicalCareTime: string
  medicalCertificateCid: string
  medicalName: string
  medicalCrm: string

  missWorkType: string
  attachment: FileList

  discountTotalValue: string

  minutesQuantity: string

  employeeCarLicensePlate: string
}

function convertTimeToMinutes(time: string): number {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
}

const createCltIntercurrenceSchema = yup.object({
  type: yup.string().required(),
  date: yup.string().required(),
  hour: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  description: yup.string().required(),
  regional: yup.string().required(),
  otherRegional: yup.mixed().when('regional', {
    is: 'other',
    then: yup.string().required()
  }),
  partnerType: yup.string().required(),
  collaboratorName: yup.string().required(),

  isTrajectoryOrOccupationalAccident: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  accidentAddress: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  bodyPart: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  medicalCareTime: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  medicalCertificateCid: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  medicalName: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  medicalCrm: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),

  missWorkType: yup.mixed().when('type', {
    is: 'miss-work',
    then: yup.string().required()
  }),
  attachment: yup.mixed().when('missWorkType', {
    is: 'justified',
    then: yup.mixed().test(validateHasFile)
  }),

  discountTotalValue: yup.mixed().when('type', {
    is: 'patrimony-without-return',
    then: yup.string().required()
  }),

  minutesQuantity: yup.mixed().when('type', {
    is: (value: string) => ['late-or-early-departure', 'extra-hours'].includes(value),
    then: yup.string().required().typeError('')
  }),

  employeeCarLicensePlate: yup.mixed().when('type', {
    is: 'ticket-log-additional-value',
    then: yup.string().required()
  }),
})

export function CreateCltIntercurrence() {
  const [attachmentId, setAttachmentId] = useState<string | null>(null)

  const [collaboratorNameDebounced, setCollaboratorNameDebounced] = useState<string | null>(null)

  const history = useHistory()

  const { userLogged } = useAuth()

  const userCanCreateCltIntercurrence = userLogged?.permissions?.includes('create-clt-intercurrence')

  useEffect(() => {
    if (!userCanCreateCltIntercurrence) history.push('/')
  }, [history, userCanCreateCltIntercurrence])

  const handleReturn = () => history.goBack()

  const formProps = useForm<CreateCltIntercurrenceSchema>({
    resolver: yupResolver(createCltIntercurrenceSchema)
  })

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: {
      errors,
      isSubmitting,
    }
  } = formProps

  const partnerType = useWatch({
    control,
    name: 'partnerType'
  })

  const collaboratorName = useWatch({
    control,
    name: 'collaboratorName'
  })

  useEffect(() => {
    const debounce = setTimeout(() => setCollaboratorNameDebounced(collaboratorName), 500)

    return () => clearTimeout(debounce)
  }, [collaboratorName])

  const isCltDriverPartnerType = partnerType === 'clt-driver'

  const {
    isOpen: isCollaboratorPopOpen,
    onToggle: onToggleCollaboratorPop,
    onClose: onCloseCollaboratorPop,
  } = useDisclosure()

  useEffect(() => {
    if (collaboratorName?.length >= 3 && !isCollaboratorPopOpen) {
      onToggleCollaboratorPop()
    }

    if (collaboratorName?.length < 3 && isCollaboratorPopOpen) {
      onToggleCollaboratorPop()
    }
  }, [collaboratorName, onToggleCollaboratorPop, isCollaboratorPopOpen])

  const { data: cltDriversResult } = useCltDrivers({
    queryOptions: {
      enabled: isCltDriverPartnerType && !!collaboratorNameDebounced && isCollaboratorPopOpen,
      refetchOnWindowFocus: false
    },
    queryParams: {
      currentPage: 1,
      pageSize: 5,
      search: collaboratorNameDebounced
    }
  })

  const intercurrrenceType = useWatch({
    control,
    name: 'type'
  })

  const regional = useWatch({
    control,
    name: 'regional'
  })

  const isWorkAccidentIntercurrence = intercurrrenceType === 'work-accident'
  const isMissWorkdIntercurrence = intercurrrenceType === 'miss-work'
  const isPatrimonyWithoutReturnIntercurrence = intercurrrenceType === 'patrimony-without-return'
  const isExtraHoursIntercurrence = intercurrrenceType === 'extra-hours'
  const isLateOrEarlyDeparture = intercurrrenceType === 'late-or-early-departure'
  const isTicketLogAdditionalValueIntercurrence = intercurrrenceType === 'ticket-log-additional-value'

  const isOtherRegional = regional === 'other'

  const triggerRef = useRef<HTMLDivElement | null>(null);
  const [triggerWidth, setTriggerWidth] = useState<string | number>("auto");

  useEffect(() => {
    if (triggerRef.current) {
      setTriggerWidth(triggerRef.current.offsetWidth);
    }
  }, []);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'test',
  })

  const group = getRootProps()

  function setCollaboratorName(name: string) {
    setValue('collaboratorName', name)
  }

  const queryClient = useQueryClient()

  const { mutateAsync: createCltIntercurrenceFn } = useMutation({
    mutationFn: createCltIntercurrence,
    onSuccess: () => {
      history.push('/intercorrencias-clt')

      queryClient.invalidateQueries({ queryKey: 'requested-cltintercurrences' })

    }
  })

  const { promiseMessage } = useToastify()

  async function handleCreateCltIntercurrenceFn(values: CreateCltIntercurrenceSchema) {
    const [year, month, day] = values.date.split('-').map(Number)

    let intercurrenceDate = set(new Date(), {
      year: year,
      month: month - 1,
      date: day,
    })

    if (values.hour) {
      const [hours, minutes] = values.hour.split(':').map(Number)

      set(intercurrenceDate, {
        hours,
        minutes
      })
    }

    const body = {
      type: values.type,
      date: intercurrenceDate,
      collaboratorName: values.collaboratorName,
      description: values.description,
      partnerType: values.partnerType,
      regional: values.regional === 'other' ? values.otherRegional : values.regional,
    }

    if (values.type === 'work-accident') {
      const [hours, minutes] = values.medicalCareTime.split(':').map(Number)

      body['isTrajectoryOrOccupationalAccident'] = values.isTrajectoryOrOccupationalAccident === 'yes'
      body['accidentAddress'] = values.accidentAddress
      body['bodyPart'] = values.bodyPart
      body['medicalCareTime'] = set(new Date(), { hours, minutes })
      body['medicalCertificateCid'] = values.medicalCertificateCid
      body['medicalCrm'] = values.medicalCrm
      body['medicalName'] = values.medicalName
    }

    if (values.type === 'miss-work') {
      body['attachmentId'] = attachmentId
    }

    if (values.type === 'patrimony-without-return') {
      const discountTotalValueInCents = Math.ceil(transformStringToNumber(values.discountTotalValue) * 100)

      body['discountTotalValueInCents'] = discountTotalValueInCents
    }

    if (values.type === 'extra-hours' || values.type === 'late-or-early-departure') {
      body['minutesQuantity'] = convertTimeToMinutes(values.minutesQuantity)
    }

    if (values.type === 'ticket-log-additional-value') {
      body['employeeCarLicensePlate'] = values.employeeCarLicensePlate
    }

    await promiseMessage(createCltIntercurrenceFn({ body }), 'Intercorrência clt criada!')
  }

  function handleSetAttachmentId(attachmentId: string) {
    setAttachmentId(attachmentId)
  }

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex align="center" gap="3">

        <IconButton
          aria-label="Voltar"
          icon={<FaArrowLeft />}
          onClick={handleReturn}
          variant="ghost"
          size="sm"
        />

        <Heading letterSpacing="tight">Criar Intercorrência CLT</Heading>
      </Flex>

      <Flex
        direction="column"
        gap="4"
        as="form"
        onSubmit={handleSubmit(handleCreateCltIntercurrenceFn)}
        mt="4"
      >
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing="3"
        >
          <FormControl isInvalid={!!errors.type}>
            <FormLabel>
              Tipo de intercorrência
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('type')}
              placeholder="Selecione um tipo..."
            >
              <option value="work-accident">Acidente de trabalho</option>
              <option value="miss-work">Falta</option>
              <option value="vacation">Férias</option>
              <option value="home-office">Home office</option>
              <option value="patrimony-without-return">Patrimônio sem devolução</option>
              <option value="extra-hours">HE autorizada</option>
              <option value="turn-change">Troca de turno</option>
              <option value="late-or-early-departure">Atraso ou saída antecipada</option>
              <option value="ticket-log-additional-value">Valor adicional ticket log</option>
              <option value="other">Outra</option>
            </Select>
          </FormControl>

          <Stack
            w="full"
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.type}>
              <FormLabel>
                Data da intercorrência
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('date')}
                type="date"
              />
            </FormControl>

            {isWorkAccidentIntercurrence && (
              <FormControl isInvalid={!!errors.type}>
                <FormLabel>
                  Horário da intercorrência
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('hour')}
                  type="time"
                />
              </FormControl>
            )}
          </Stack>
        </Stack>


        <FormControl isInvalid={!!errors.description}>
          <FormLabel>
            Descrição
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Textarea
            {...register('description')}
            placeholder="Descreva a intercorrência..."
          />
        </FormControl>

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing="3"
        >
          <Stack
            w="full"
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.type}>
              <FormLabel>
                Regional
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('regional')}
                placeholder="Selecione uma regional..."
              >
                <option value="mg">MG</option>
                <option value="rj">RJ</option>
                <option value="df">DF</option>
                <option value="sp">SP</option>
                <option value="other">Outro</option>
              </Select>
            </FormControl>
            {isOtherRegional && (
              <FormControl isInvalid={!!errors.type}>
                <FormLabel>
                  Outra regional
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('otherRegional')}
                />
              </FormControl>
            )}
          </Stack>

          <Stack
            w="full"
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.type}>
              <FormLabel>
                Tipo de parceiro
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('partnerType')}
                placeholder="Selecione um tipo de parceiro..."
              >
                <option value="rh-managment">RH Gerencial</option>
                <option value="clt-driver">Motorista CLT</option>
              </Select>
            </FormControl>


            <FormControl isInvalid={!!errors.type}>
              <FormLabel>
                Nome do colaborador
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Popover
                isOpen={isCollaboratorPopOpen}
                closeOnBlur={false}
                onClose={onCloseCollaboratorPop}
                autoFocus={false}
              >

                <PopoverTrigger>
                  <Box ref={triggerRef}>
                    <Input
                      {...register('collaboratorName')}
                    />
                  </Box>
                </PopoverTrigger>

                {isCltDriverPartnerType && (
                  <PopoverContent w={triggerWidth} py="2">
                    <Box
                      {...register('test')}
                      {...group}
                    >
                      {cltDriversResult?.cltDrivers?.map((cltDriver) => {
                        const radio = getRadioProps({ value: captalize(`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`) })

                        radio.isChecked = radio.value === collaboratorName


                        return (
                          <CollaboratorRadioItem
                            key={cltDriver.id}
                            props={radio}
                            radio={{ value: `${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`, styles: {} }}
                            onClosePop={onCloseCollaboratorPop}
                            onSetCollaboratorName={setCollaboratorName}
                          />
                        )
                      })}
                    </Box>
                  </PopoverContent>
                )}
              </Popover>
            </FormControl>
          </Stack>
        </Stack>

        {isWorkAccidentIntercurrence && (
          <FormProvider {...formProps}>
            <WorkAccidentCltIntercurrenceForm />
          </FormProvider>
        )}

        {isMissWorkdIntercurrence && (
          <FormProvider {...formProps}>
            <MissWorkCltIntercurrenceForm
              onSetAttachmentId={handleSetAttachmentId}
            />
          </FormProvider>
        )}

        {isPatrimonyWithoutReturnIntercurrence && (
          <FormProvider {...formProps}>
            <PatrimonyWithoutReturnCltIntercurrenceForm />
          </FormProvider>
        )}

        {(isExtraHoursIntercurrence || isLateOrEarlyDeparture) && (
          <FormProvider {...formProps}>
            <ExtraHoursCltIntercurrenceForm />
          </FormProvider>
        )}

        {isTicketLogAdditionalValueIntercurrence && (
          <FormProvider {...formProps}>
            <TicketLogAdditionalValueCltIntercurrenceForm />
          </FormProvider>
        )}

        <Button
          type="submit"
          colorScheme="blue"
          w="min"
          alignSelf="end"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Criar
        </Button>
      </Flex>

    </Box >
  )
}
