import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Link, Progress, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { GetAsosResponse } from "api/asos/getAsos"
import { sendAsoAttachment } from "api/asos/sendAsoAttachment"
import { set } from "date-fns"
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation"
import { useToastify } from "hooks/toastify/useToastify"
import { ChangeEvent, useState } from "react"
import { useForm } from "react-hook-form"
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa"
import { useMutation, useQueryClient } from "react-query"

import * as yup from "yup"

interface SendAsoAttachmentProps {
  asoId: string
  onCloseModal: () => void
}

interface SendAttachmentSchema {
  attachment: FileList
  dueDate: string
}

const sendAttachmentSchema = yup.object({
  attachment: yup.mixed()
    .test(function (value) {
      const { hasAttachmentId } = this.options.context;
      return value && hasAttachmentId;
    }),
  dueDate: yup.string().required().transform((value: string) => {
    if (value) {
      const [year, month, day] = value.split('-').map(Number)

      return set(new Date(), {
        year,
        month: month - 1,
        date: day,
        hours: 12,
        minutes: 0,
      }).toISOString()
    }
    return ''
  })
})


export function SendAsoAttachment({ asoId, onCloseModal }: SendAsoAttachmentProps) {
  const [attachment, setAttachment] = useState<FileList | null>(null)

  const { mutation, uploadProggress } = useAttachmentMutation()
  const { warnMessage } = useToastify()

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<SendAttachmentSchema>({
    resolver: yupResolver(sendAttachmentSchema),
    context: {
      hasAttachmentId: !!mutation?.data?.attachment.id
    }
  })



  async function handleSendAttachment(event: ChangeEvent<HTMLInputElement>) {

    const fileInput = event.target
    const file = fileInput.files?.[0]

    if (!file) return

    const MAX_FILE_SIZE = 25 * 1024 * 1024
    if (file.size > MAX_FILE_SIZE) {
      warnMessage("O arquivo deve ter no máximo 25MB.")

      fileInput.value = ""

      return
    }


    const formData = new FormData()

    setAttachment(event.target.files)

    formData.append('attachment', event.target.files[0])

    await mutation.mutateAsync(formData)

  }

  const queryClient = useQueryClient()

  const { mutateAsync: sendAsoAttachmentFn } = useMutation({
    mutationFn: sendAsoAttachment,
    onSuccess(_data, { params: { asoId, attachmentId }, body }) {
      const cachedAsos = queryClient.getQueriesData<GetAsosResponse>({
        queryKey: ['asos']
      })
      queryClient.invalidateQueries({ queryKey: 'pending-asos' })
      queryClient.invalidateQueries({ queryKey: 'active-asos' })

      cachedAsos.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          asos: cachedData.asos.map((aso) => {
            if (aso.id === asoId) {
              return {
                ...aso,
                status: 'active',
                attached_at: new Date().toISOString(),
                attachment_id: attachmentId,
                due_date: body.dueDate
              }
            }

            return aso
          })
        })
      })

      onCloseModal()
    }
  })

  const { promiseMessage } = useToastify()

  async function handleSendAsoAttachment(values: SendAttachmentSchema) {
    await promiseMessage(sendAsoAttachmentFn({
      params: {
        asoId,
        attachmentId: mutation.data.attachment.id
      },
      body: {
        dueDate: values.dueDate
      }
    }), 'Anexo salvo com sucesso! 🎉')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendAsoAttachment)}
      maxH='500px'
      overflowY='scroll'
    >
      <Heading letterSpacing="tight" size='sm'>Enviar anexo de ASO</Heading>

      <Button
        as={FormLabel}
        htmlFor="attachment"
        leftIcon={<FaFileImport />}
        lineHeight="1"
        size="sm"
        mt="6"
      >
        {attachment ? 'Alterar anexo' : 'Anexar'}
      </Button>


      <Input
        {...register('attachment')}
        id="attachment"
        name="attachment"
        type="file"
        onChange={handleSendAttachment}
        hidden
      />


      <Table size="sm">
        <Tbody>
          {(attachment && attachment.length > 0) && (
            Object.entries(attachment).map(([key, value]) => {
              return (
                <Tr key={key}>
                  <Td maxW="100px" overflow="hidden" fontSize="xs">{value.name}</Td>
                  <Td w="200px">
                    <Progress w="full" size="xs" rounded="md" value={uploadProggress} />
                  </Td>
                  <Td isNumeric>
                    {mutation.data && (
                      <IconButton
                        as={Link}
                        aria-label="Abrir imagem"
                        icon={<FaExternalLinkAlt />}
                        isExternal
                        href={mutation.data.attachment.link}
                        size="sm"
                      />
                    )}
                  </Td>
                </Tr>
              )
            })
          )}
        </Tbody>
      </Table>

      <Stack
        direction="column"
        w="full"
        mt="3"
      >
        <FormControl isInvalid={!!errors?.dueDate}>
          <FormLabel fontSize="sm">
            Data de realização
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('dueDate')}
            name="dueDate"
            type="date"
            size="sm"
            rounded="md"
          />
        </FormControl>
      </Stack>

      <Flex
        w="full"
        justify="flex-end"
        mt="6"
      >
        <Button size="sm" colorScheme="blue" type="submit">Enviar</Button>
      </Flex>

    </Box>
  )
}
