import { FormControl, FormLabel, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { set } from "date-fns";
import { CreateTrainingLlmManuallyForm } from "./CreateTrainingLlmManuallyForm";
import { validateHasFile } from "utils/fileValidation";
import { useEffect } from "react";
import { CreateTrainingLlmCsvForm } from "./CreateTrainingLlmCsvForm";

interface CreateTrainingLlmProps {
  onCloseModal: () => void
}

export interface CreateTrainingLlmSchema {
  type: 'manually' | 'csv'
  collaboratorId?: string
  training?: string
  dueDate?: string
  csv?: FileList
}

const createTrainingLlmSchema = yup.object({
  type: yup.string().required('Campo Obrigatório'),
  collaboratorId: yup.mixed().when('type', {
    is: 'manually',
    then: yup.string().required('Campo Obrigatório')
  }),
  training: yup.mixed().when('type', {
    is: 'manually',
    then: yup.string().required('Campo Obrigatório')
  }),
  dueDate: yup.mixed().when('type', {
    is: 'manually',
    then: yup.string().required('Campo Obrigatório').transform((value: string) => {
      const [year, month, day] = value.split('-').map(Number)
      return set(new Date(), {
        year,
        month: month - 1,
        date: day,
        hours: 12,
        minutes: 0,
      }).toISOString()
    })
  }),
  csv: yup.mixed().when('type', {
    is: 'csv',
    then: yup.mixed().required('Campo Obrigatório').test(value => validateHasFile(value))
  }),
})

export function CreateTrainingLlm({ onCloseModal }: CreateTrainingLlmProps) {

  const formMethods = useForm<CreateTrainingLlmSchema>({
    resolver: yupResolver(createTrainingLlmSchema)
  })

  useEffect(() => {
    if (formMethods.formState.isSubmitSuccessful) {
      onCloseModal()
    }
  }, [formMethods.formState.isSubmitSuccessful, onCloseModal])

  const type = useWatch({
    control: formMethods.control,
    name: 'type'
  })

  const isManuallyForm = type === 'manually'

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Solicitar Treinamento Complementar</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
          pb='4'
        >
          <FormLabel fontSize='sm'>
            Tipo de Criação
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <FormControl isInvalid={!!formMethods.formState.errors.type}>
            <Controller
              control={formMethods.control}
              name='type'
              render={({ field }) => {
                return (
                  <RadioGroup
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  >
                    <Stack direction={{ base: 'column', md: 'row' }}>
                      <Radio size='sm' rounded='md' value='manually'>Manual</Radio>
                      <Radio size='sm' rounded='md' value='csv'>Por upload csv</Radio>
                    </Stack>
                  </RadioGroup>
                )
              }}
            />
          </FormControl>
        </Stack>

        <FormProvider {...formMethods} >
          {type && (
            <>
              {isManuallyForm ? (
                <CreateTrainingLlmManuallyForm />
              ) : (
                <CreateTrainingLlmCsvForm />
              )}
            </>
          )}
        </FormProvider>
      </ModalBody>
    </ModalContent>
  )
}
