import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, HStack, IconButton, Skeleton, SkeletonText, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getDispatchStocks } from "api/dispachStocks/getDispatchStocks";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { DispatchStockTableFilters } from "./components/DispatchStockTableFilters";
import { DispatchStocksTableRow } from "./components/DispatchTableRow";

export function DispatchStocks() {
  const { userLogged } = useAuth()
  const history = useHistory()
  const searchParams = useSearchParams()
  const userCanCreateDispatchStock = userLogged?.permissions.includes('create-dispatch-stock')
  const userCanViewDispatchStocks = userLogged?.permissions.includes('view-dispatch-stocks')

  useEffect(() => {
    if (!userCanViewDispatchStocks) history.push('/')
  }, [history, userCanViewDispatchStocks])

  const page = searchParams.get('page') ?? '1'
  const protocol = searchParams.get('protocol')
  const branch = searchParams.get('branch')
  const dispatchInvoice = searchParams.get('dispatchInvoice')
  const destinationCollector = searchParams.get('destinationCollector')
  const step = searchParams.get('step') ?? 'all'

  const {
    data: dispatchStocksData,
    isLoading: isLoadingDispatchStocksData
  } = useQuery({
    queryKey: [
      'dispatch-stocks',
      page,
      step,
      protocol,
      branch,
      dispatchInvoice,
      destinationCollector
    ],
    queryFn: () => getDispatchStocks({
      currentPage: page,
      pageSize: '10',
      step: step,
      protocol,
      branch,
      dispatchInvoice,
      destinationCollector
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: dispatchStocksData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: dispatchStocksData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }


  return (

    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Despachos de Estoque</Heading>

        {userCanCreateDispatchStock && (
          <IconButton
            aria-label="Criar Dispacho de Estoque"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={() => history.push("/despachos/criar")}
          />
        )}

      </Flex>

      <DispatchStockTableFilters />

      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm" w="full">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th></Th>
              <Th color="white">Protocolo</Th>
              <Th color="white">Coletador de Origem</Th>
              <Th color="white">Coletador de Destino</Th>
              <Th color="white">Transportadora</Th>
              <Th color="white">Base</Th>
              <Th color="white">Data Embarque</Th>
              <Th color="white">Previsão de Chegada</Th>
              <Th color="white">Nº da nota fiscal</Th>
              <Th color="white">Cte</Th>
              <Th color="white">Etapa</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoadingDispatchStocksData ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={String(index)}>
                    <Td>
                      <HStack spacing={3}>
                        <Skeleton w={8} h={8} />
                        <Skeleton w={8} h={8} />
                      </HStack>
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="180px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="30px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <Skeleton w={8} h={8} />
                    </Td>
                  </Tr>
                )
              })
            ): (
              dispatchStocksData?.dispatchStocks?.map((dispatchStock) => {
                return (
                  <DispatchStocksTableRow
                    key={dispatchStock.id}
                    dispatchStock={dispatchStock}
                  />
                )
              })
            )}
            {}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>

  )
}
