import { VacationStatus } from "api/vacations/_types/Vacation"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { format, set } from "date-fns"
import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Stack, Text, VStack } from "@chakra-ui/react"
import { useFieldArray, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useToastify } from "hooks/toastify/useToastify"
import { getVacation } from "api/vacations/getVacation"
import { useEffect } from "react"
import { editVacation } from "api/vacations/editVacation"
import { getInputDateYearsMonthAndDateSplited } from "utils/getInputDateYearsMonthAndDateSplited"
import { vacationQueryKeys } from "./VacationsKanbanCard"
import { editPeriodsVacation } from "api/vacations/editPeriodsVacation"

interface EditVacationProps {
  onCloseModal: () => void
  vacationId: string
}

interface EditVacationSchema {
  collaborator_name: string
  acquisition_start_date: string
  acquisition_end_date: string
  deadline: string
  planning_start_date: string
  regional: string
  collaborator_email: string
  status: VacationStatus
  periods: {
    period: number
    startDate: string
    endDate: string
  }[]
}

const editVacationSchema = yup.object({
  collaborator_name: yup.string().required(),
  acquisition_start_date: yup.string().required().transform((value: string) => {
    const [year, month, day] = value.split('-').map(Number)

    return set(new Date(), {
      year,
      month: month - 1,
      date: day,
      hours: 12,
      minutes: 0,
    }).toISOString()

  }),
  acquisition_end_date: yup.string().required().transform((value: string) => {
    const [year, month, day] = value.split('-').map(Number)

    return set(new Date(), {
      year,
      month: month - 1,
      date: day,
      hours: 12,
      minutes: 0,
    }).toISOString()

  }),
  deadline: yup.string().required().transform((value: string) => {
    const [year, month, day] = value.split('-').map(Number)

    return set(new Date(), {
      year,
      month: month - 1,
      date: day,
      hours: 12,
      minutes: 0,
    }).toISOString()

  }),
  planning_start_date: yup.string().required().transform((value: string) => {
    const [year, month, day] = value.split('-').map(Number)

    return set(new Date(), {
      year,
      month: month - 1,
      date: day,
      hours: 12,
      minutes: 0,
    }).toISOString()

  }),
  regional: yup.string().required(),
  collaborator_email: yup.string().required(),
})

export function EditVacation({
  onCloseModal,
  vacationId
}: EditVacationProps) {


  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<EditVacationSchema>({
    resolver: yupResolver(editVacationSchema),
  })


  const {
    data: vacationResult,
    isLoading: isLoadingVacationResult
  } = useQuery({
    onSuccess(data) {
      setValue('collaborator_name', data?.vacation?.collaborator_name)
      setValue('collaborator_email', data?.vacation?.collaborator_email)
      setValue('regional', data?.vacation?.regional)
      setValue('acquisition_start_date', format(new Date(data?.vacation?.acquisition_start_date), 'yyyy-MM-dd'))
      setValue('acquisition_end_date', format(new Date(data?.vacation?.acquisition_end_date), 'yyyy-MM-dd'))
      setValue('deadline', format(new Date(data?.vacation?.deadline), 'yyyy-MM-dd'))
      setValue('planning_start_date', format(new Date(data?.vacation?.planning_start_date), 'yyyy-MM-dd'))

      if (!['active', 'awaiting'].includes(data?.vacation?.status)) {
        setValue(
          'periods',
          data?.vacation?.periods?.map((period, index) => ({
            period: index + 1,
            startDate: format(new Date(period?.start_date ?? ''), 'yyyy-MM-dd'),
            endDate: format(new Date(period?.end_date ?? ''), 'yyyy-MM-dd'),
            withAllowance: period?.with_allowance ? 'yes' : 'no',
          })) ?? []
        );
      }

    },
    queryKey: ['vacation', vacationId],
    queryFn: () => getVacation({ vacationId }),
    refetchOnWindowFocus: false
  })




  const {
    fields: periodsFields,
    append: appendPeriodField,
  } = useFieldArray({
    control,
    name: 'periods'
  })



  useEffect(() => {
    if (vacationResult?.vacation?.periods.length) {

      if (vacationResult?.vacation?.periods.length) {
        setValue('periods', []);

        vacationResult.vacation.periods.forEach((period, index) => {
          appendPeriodField({
            period: index + 1,
            startDate: format(new Date(period?.start_date ?? ''), 'yyyy-MM-dd'),
            endDate: format(new Date(period?.end_date ?? ''), 'yyyy-MM-dd'),
          });
        });
      }
    }

  }, [vacationResult, appendPeriodField, setValue])


  const { mutateAsync: editVacationFn } = useMutation({
    mutationFn: editVacation,
    onSuccess(_data, { vacationId }) {
      queryClient.invalidateQueries(['vacations'])
      queryClient.invalidateQueries({ queryKey: vacationQueryKeys[vacationResult?.vacation?.status] })
      queryClient.invalidateQueries({
        queryKey: ['vacation', vacationId]
      })

      onCloseModal()
    }
  })

  const { mutateAsync: editPeriodsVacationFn } = useMutation({
    mutationFn: editPeriodsVacation,
    onSuccess(_data, { vacationId }) {
      queryClient.invalidateQueries(['vacations'])
      queryClient.invalidateQueries({ queryKey: vacationQueryKeys[vacationResult?.vacation?.status] })
      queryClient.invalidateQueries({
        queryKey: ['vacation', vacationId]
      })

      onCloseModal()
    }
  })


  async function handleEditVacation(values: EditVacationSchema) {
    await promiseMessage(editVacationFn({
      body: {
        ...values,
        status: vacationResult?.vacation?.status
      },
      vacationId
    }), 'Férias editada com sucesso!')

    await promiseMessage(editPeriodsVacationFn({
      body: {
        periods: values.periods.map(period => {
          return {
            ...period,
            startDate: set(new Date(), {
              ...getInputDateYearsMonthAndDateSplited(period.startDate)
            }),
            endDate: set(new Date(), {
              ...getInputDateYearsMonthAndDateSplited(period.endDate)
            }),
          }
        })
      },
      vacationId
    }),'Férias editada com sucesso!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Editar Férias</ModalHeader>
      <ModalCloseButton />
      {isLoadingVacationResult ? (
        <Spinner />
      ) : (
        <ModalBody
          as="form"
          onSubmit={handleSubmit(handleEditVacation)}
          maxH='600px'
          overflowY='scroll'
        >
          <FormControl isInvalid={!!errors.collaborator_name} mt={3}>
            <FormLabel fontSize="sm">
              Nome do colaborador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Input
              {...register("collaborator_name")}
              name="collaborator_name"
              size="sm"
              rounded="md"
              isDisabled
            />
          </FormControl>
          <FormControl isInvalid={!!errors.collaborator_email} mt={3}>
            <FormLabel fontSize="sm">
              E-mail do colaborador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Input
              {...register("collaborator_email")}
              name="collaborator_email"
              size="sm"
              rounded="md"
              isDisabled
            />
          </FormControl>
          <FormControl isInvalid={!!errors.regional} mt={3}>
            <FormLabel fontSize="sm">
              Regional
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Input
              {...register("regional")}
              name="regional"
              size="sm"
              rounded="md"
              isDisabled
            />
          </FormControl>
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.acquisition_start_date} mt={3}>
                <FormLabel fontSize="sm">
                  Início aquisitivo
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>

                <Input
                  {...register("acquisition_start_date")}
                  name="acquisition_start_date"
                  type="date"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
            </Stack>
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.acquisition_end_date} mt={3}>
                <FormLabel fontSize="sm">
                  Fim aquisitivo
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>

                <Input
                  {...register("acquisition_end_date")}
                  name="acquisition_end_date"
                  type="date"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
            </Stack>
          </Stack>
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.deadline} mt={3}>
                <FormLabel fontSize="sm">
                  Data limite para gozo
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>

                <Input
                  {...register("deadline")}
                  name="deadline"
                  type="date"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
            </Stack>
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.planning_start_date} mt={3}>
                <FormLabel fontSize="sm">
                  Inicio para planejamento
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>

                <Input
                  {...register("planning_start_date")}
                  name="planning_start_date"
                  type="date"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
            </Stack>
          </Stack>
          {!['active', 'awaiting'].includes(vacationResult?.vacation?.status) && (
            <>
              <FormLabel fontSize="sm" mt={3}>
                Períodos
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              {periodsFields.map((field, index) => {
                return (
                  <VStack
                    spacing={3}

                    outline="1px solid"
                    rounded="lg"
                    key={field.id}
                    outlineColor="gray.100"
                    p={2}
                    _notFirst={{
                      mt: 3
                    }}
                    position="relative"
                  >
                    <Text
                      position="absolute"
                      top={-1}
                      outline="4px solid"
                      outlineColor="white"
                      left={-2}
                      rounded="full"
                      bg="blue.500"
                      w="20px"
                      h="20px"
                      fontSize="sm"
                      as="b"
                      textColor="white"
                      textAlign="center"
                    >
                      {field.period}
                    </Text>

                    <Stack
                      spacing={3}
                      direction={{ base: 'column', lg: 'row' }}
                      w="full"
                    >
                      <FormControl
                        isInvalid={
                          errors.periods ? !!errors.periods[index].startDate : false
                        }
                        mt={3}
                      >
                        <FormLabel fontSize="sm">
                          Data inicial
                          <Text as="sup" color="red.500">*</Text>
                        </FormLabel>

                        <Input
                          {...register(`periods.${index}.startDate`)}
                          name={`periods.${index}.startDate`}
                          type="date"
                          size="sm"
                          rounded="md"
                        />
                      </FormControl>

                      <FormControl
                        isInvalid={
                          errors.periods ? !!errors.periods[index].endDate : false
                        }
                        mt={3}
                      >
                        <FormLabel fontSize="sm">
                          Data final
                          <Text as="sup" color="red.500">*</Text>
                        </FormLabel>

                        <Input
                          {...register(`periods.${index}.endDate`)}
                          name={`periods.${index}.endDate`}
                          type="date"
                          size="sm"
                          rounded="md"
                        />
                      </FormControl>
                    </Stack>
                  </VStack>
                )
              })}
            </>
          )}
          <Flex
            mt="6"
            w="full"
            justify="flex-end"
          >
            <Button
              type="submit"
              size="sm"
              colorScheme="blue"
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
            >
              Editar
            </Button>
          </Flex>
        </ModalBody>
      )}
    </ModalContent>
  )
}
