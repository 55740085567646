import { Button, Flex, Icon, Image, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addDays, format, set, subDays } from 'date-fns'
import { useUsers } from 'hooks/user/useUsers'
import { useEffect, useRef, useState } from 'react'
import { useCurrency } from 'react-hook-currency'
import { useForm } from 'react-hook-form'
import { FiFile } from 'react-icons/fi'
import * as yup from 'yup'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useContentLoading } from '../../../hooks/loading/useContentLoading'
import { direction_options } from '../../../utils/customLists'
import { ListButton } from '../../Buttons/ListButton'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { StandardBackgroundForm } from '../../Form/StandardBackgroundForm'
import { Input } from '../../Inputs/Input'
import { Select } from '../../Inputs/SelectInput'
import { TextArea } from '../../Inputs/TextInput'
import { GeneralContentLoading } from '../../Loading/GeneralContentLoading'

interface IFormInputProps {
  direction: string
  date: string
  is_date_full: boolean
  intercurrence_description: string
  loss_value: number | null
  opened_by: string
  attachment_photo: File | null
  intercurrence_responsible: string
}

interface IRncFormProps {
  title: string
  submit: (values: IFormInputProps) => Promise<void>
}

const dateMin = subDays(set(new Date(), { date: 1 }), 1)

const schema = yup.object().shape({
  direction: yup.string().required('Campo Obrigatório'),
  date: yup
    .date()
    .typeError('Campo Obrigatório')
    .min(
      dateMin,
      `A data deve ser dia ${format(
        addDays(new Date(dateMin), 1),
        'dd/MM/yyyy',
      )} ou após esta data`,
    ),
  intercurrence_description: yup.string().required('Campo Obrigatório'),
  intercurrence_responsible: yup.string().required('Campo Obrigatório'),
})

export function CreateRncForm({ title, submit }: IRncFormProps) {
  const [selectedFileAttachmentPhoto, setSelectedFileAttachmentPhoto] =
    useState<File | null>(null)
  const [previewAttachmentPhoto, setPreviewAttachmentPhoto] = useState<
    string | null
  >(null)

  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()
  const { userLogged } = useAuth()

  const { data: users, isLoading: isUserLoading } = useUsers()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      opened_by: userLogged?.id,
    },
  })

  const directionTypeSelected = watch("direction")

  const usersFilteredByDirectionTypeOptions = users?.filter(user => {
    if (directionTypeSelected === "DIRETORIA COMERCIAL") {
      const directionsUserTypes = [
        "SUCESSO DO CLIENTE - GERÊNCIA",
        "SUCESSO DO CLIENTE - DIRETORIA",
        "SUCESSO DO CLIENTE - COMERCIAL"
      ]
      return directionsUserTypes.includes(user.user_type)
    }
    if (directionTypeSelected === "DIRETORIA DE LOGÍSTICA") {
      const directionsUserTypes = [
        "SUCESSO DO CLIENTE - LAST MILE",
        "SUCESSO DO CLIENTE - TORRE DE CONTROLE",
        "OPERAÇÃO - GERÊNCIA",
        "SUCESSO DO CLIENTE - GERÊNCIA",
        "SUCESSO DO CLIENTE - DIRETORIA",
        "SUCESSO DO CLIENTE - TORRE DE CONTROLE"
      ]
      return directionsUserTypes.includes(user.user_type)
    }
    if (directionTypeSelected === "DIRETORIA DE QUALIDADE DE REGULAÇÃO") {
      const directionsUserTypes = [
        "REGULAÇÃO E QUALIDADE - ANALISTA",
        "REGULAÇÃO E QUALIDADE - DIRETORIA",
      ]
      return directionsUserTypes.includes(user.user_type)
    }

    if (directionTypeSelected === "DIRETORIA DE TECH E LEADS") {
      const directionsUserTypes = [
        'ADMIN',
        'TECNOLOGIA - GERÊNCIA'
      ]
      return directionsUserTypes.includes(user.user_type)
    }

    if (directionTypeSelected === "DIRETORIA FINANCEIRA") {
      const directionsUserTypes = [
        "ADMINISTRATIVO FINANCEIRO - DIRETORIA",
        "FINANCEIRO - GERAL",
        "ADMINISTRATIVO FINANCEIRO - GERÊNCIA",
        "ADMINISTRATIVO FINANCEIRO - RH",
        "ADMINISTRATIVO FINANCEIRO - COMPRAS",
        "ADMINISTRATIVO FINANCEIRO - JOVEM APRENDIZ",
        "ADMINISTRATIVO FINANCEIRO - ALMOXARIFADO",
        "ADMINISTRATIVO FINANCEIRO - FINANCEIRO",
      ]
      return directionsUserTypes.includes(user.user_type)
    }

    if (directionTypeSelected === "DIRETORIA DE GENTE") {
      const directionsUserTypes = [ 
        'GENTE - GERAL'
      ]
      return directionsUserTypes.includes(user.user_type)
    }

    return user.user_type.includes(directionTypeSelected)
  })



  const { onChange, format: currencyFormat } = useCurrency({
    style: 'decimal',
  })

  const inputRefAttachmentPhoto = useRef<HTMLInputElement | null>(null)
  const handleInputRefAttachmentPhoto = () =>
    inputRefAttachmentPhoto.current?.click()

  function handleSelectFileAttachmentPhoto(e: { target: HTMLInputElement }) {
    if (!e.target.files || e.target.files.length === 0) {
      setValue('attachment_photo', null)
      setSelectedFileAttachmentPhoto(null)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setValue('attachment_photo', e.target.files[0])
    setSelectedFileAttachmentPhoto(e.target.files[0])
  }

  useEffect(() => {
    if (!selectedFileAttachmentPhoto) {
      setPreviewAttachmentPhoto(null)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFileAttachmentPhoto)
    setPreviewAttachmentPhoto(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFileAttachmentPhoto])

  useEffect(() => {
    if (!isUserLoading) {
      handleSetIsContentLoadingFalse()
    }
  }, [isUserLoading, handleSetIsContentLoadingFalse])

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm onSubmit={handleSubmit(submit)} title={title}>
      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Select
          {...register('direction')}
          direction={direction_options}
          placeholder="Selecione uma opção..."
          name="direction"
          label="Diretoria"
          error={errors.direction}
          required
        />

        <Input
          {...register('date')}
          name="date"
          min={format(new Date(dateMin), 'yyyy-MM-dd')}
          label="Data"
          type="date"
          error={errors.date}
          required
        />
      </Stack>
      <Stack
        w="full"
        mt="4"
        spacing="24px"
        direction={['column', 'column', 'row']}
      >
        <TextArea
          {...register('intercurrence_description')}
          name="intercurrence_description"
          label="Descrição da Intercorrência"
          error={errors.intercurrence_description}
          required
        />
      </Stack>
      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Input
          {...register('loss_value')}
          name="loss_value"
          addChildren="R$"
          onChange={onChange}
          defaultValue={currencyFormat('000')}
          label="Valor do Prejuízo"
        />

        <Select
          {...register('intercurrence_responsible')}
          users={usersFilteredByDirectionTypeOptions}
          placeholder="Selecione uma opção..."
          name="intercurrence_responsible"
          label="Responsável pela Intercorrência"
          error={errors.intercurrence_responsible}
          required
        />
      </Stack>
      <Stack mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register('attachment_photo')}
          name="attachment_photo"
          ref={inputRefAttachmentPhoto}
          label="Foto (Opcional)"
          type="file"
          accept="image/*"
          onChange={handleSelectFileAttachmentPhoto}
          hidden
        >
          <Button
            onClick={handleInputRefAttachmentPhoto}
            leftIcon={<Icon as={FiFile} />}
          >
            Upload
          </Button>
        </Input>
        {!!previewAttachmentPhoto && (
          <Image
            boxSize="100px"
            objectFit="cover"
            src={previewAttachmentPhoto}
          />
        )}
      </Stack>
      <Flex align="center" justify="flex-end" mt="4">
        <Stack direction="row">
          <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          <ListButton
            href="/rnc/pendentes-resposta"
            name="Lista de Rnc Pendentes"
          />
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}
