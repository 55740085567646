import { Box, Button, FormControl, FormLabel, IconButton, Input, Link, Progress, Select, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react";
import { getSupportSubject } from "api/supports/getSupportSubject";
import { CreateAttachmentResponse } from "hooks/attachment/useAttachmentMutation";
import { ChangeEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import ReactInputMask from "react-input-mask";
import { UseMutationResult, useQuery } from "react-query";
import { handleSwitchMask } from "utils/handleChangeMask";
import { CreateSupportSchema } from "./CreateSupport";


interface ExternalSupportFormProps {
  uploadRequestAttachmentFn: UseMutationResult<CreateAttachmentResponse, unknown, FormData, unknown>
  uploadProggressRequestAttachment: number
}


export function ExternalSupportForm({ uploadProggressRequestAttachment, uploadRequestAttachmentFn }: ExternalSupportFormProps) {

  const { register, control, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const [requestAttachmentId, supportSubjectId] = useWatch({
    control,
    name: ['requestAttachmentId', 'subject'],
  })

  const {
    data: supportSubjectResult
  } = useQuery({
    queryFn: () => getSupportSubject({ supportSubjectId }),
    queryKey: ['support-subject', supportSubjectId],
    enabled: !!supportSubjectId
  })


  const isPaymentAccountChange = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Alteração de conta de pagamento'.toLowerCase()

  const typeRequiresAttachment = [
    'Alteração de dados cadastrais',
    'Envio de atestados',
    'Envio de documentos operacionais',
    'Envio de documentos pessoais',
    'Envio de registro de limpeza'
  ].map(item => item.toLowerCase()).includes(supportSubjectResult?.supportSubject?.name?.toLowerCase());

  async function handleUploadRequestAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadRequestAttachmentFn.mutateAsync(formData)

  }
  return (
    <Box w="full">

      {isPaymentAccountChange && (
        <>
          <FormControl isInvalid={!!errors.accountOwner} mt={3}>
            <FormLabel fontSize="sm">
              Titular da conta
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('accountOwner')}
              name="accountOwner"
              size="sm"
              rounded="md"
            />
          </FormControl>
          <FormControl isInvalid={!!errors.bank} mt={3}>
            <FormLabel fontSize="sm">
              Banco
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('bank')}
              name="bank"
              size="sm"
              rounded="md"
            />
          </FormControl>
          <FormControl isInvalid={!!errors.accountType} mt={3}>
            <FormLabel fontSize="sm">
              Tipo de conta
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register("accountType")}
              name="accountType"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              <option value='Conta corrente'>Conta corrente</option>
              <option value='Conta poupança'>Conta poupança</option>

            </Select>
          </FormControl>
          <FormControl isInvalid={!!errors.agency} mt={3}>
            <FormLabel fontSize="sm">
              Agência (com dígito)
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('agency')}
              name="agency"
              size="sm"
              rounded="md"
            />
          </FormControl>
          <FormControl isInvalid={!!errors.accountNumber} mt={3}>
            <FormLabel fontSize="sm">
              Conta (com dígito)
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('accountNumber')}
              name="accountNumber"
              size="sm"
              rounded="md"
            />
          </FormControl>
          <FormControl isInvalid={!!errors.cpf} mt={3}>
            <FormLabel fontSize="sm">
              CPF
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('cpf')}
              name="cpf"
              as={ReactInputMask as any}
              mask={handleSwitchMask('cpf')}
              size="sm"
              rounded="md"
            />
          </FormControl>
        </>
      )}
      {!isPaymentAccountChange && (
        <FormControl isInvalid={!!errors.description} mt={3}>
          <FormLabel fontSize="sm">
            Escreva aqui a sua solicitação
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Textarea
            {...register("description")}
            name="description"
            size="sm"
            rounded="md"
          />
        </FormControl>
      )}
      {typeRequiresAttachment && (
        <>
          <Stack
            direction="column"
            w="full"
            mt="3"
          >
            <Button
              as={FormLabel}
              htmlFor="requestAttachmentId"
              lineHeight="1"
              leftIcon={<FaFileImport />}
              size="sm"
              w="min"
              cursor="pointer"
              border={!!errors?.requestAttachmentId && '2px solid'}
              borderColor={(!!errors?.requestAttachmentId) && 'red.500'}
            >
              Anexar Documento
            </Button>
            <FormControl isInvalid={!!errors?.requestAttachmentId}>
              <Input
                {...register('requestAttachmentId')}
                name="requestAttachmentId"
                id="requestAttachmentId"
                type="file"
                hidden
                onChangeCapture={handleUploadRequestAttachment}
              />
            </FormControl>
          </Stack>

          {requestAttachmentId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(requestAttachmentId).map(([key, file]) => {
                  return (
                    <Tr key={key}>
                      <Td fontSize="xs" maxW="100px">{file.name}</Td>
                      <Td fontSize="xs" w="200px">
                        <Progress size="sm" rounded="md" value={uploadProggressRequestAttachment} />
                      </Td>
                      <Td fontSize="xs" isNumeric>
                        {uploadRequestAttachmentFn.data && (
                          <IconButton
                            aria-label="Visualizar anexo"
                            as={Link}
                            size="sm"
                            icon={<FaExternalLinkAlt />}
                            href={uploadRequestAttachmentFn?.data?.attachment.link}
                            isExternal
                          />
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          )}
        </>
      )}
    </Box>
  )
}
