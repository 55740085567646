import { useEffect } from "react";
import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, HStack, IconButton, Modal, ModalOverlay, Skeleton, SkeletonText, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getPatrimonyRequests } from "api/patrimonies/getPatrimonyRequests";
import { PatrimonyRequestStatus } from "api/patrimonies/patrimonyRequest";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateRequestPatrimony } from "./components/CreateRequestPatrimony";
import { PatrimonyRequestsTableFilters } from "./components/PatrimonyRequestsTableFilters";
import { PatrimonyRequestTableRow } from "./components/PatrimonyRequestTableRow";


export function PatrimonyRequests() {

  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const { replace, push: redirect } = useHistory()

  const userHasViewPatrimonyRequestsPermission = userLogged?.permissions.includes('view-patrimony-requests')
  const userHasCreatePatrimonyPermission = userLogged?.permissions.includes('create-patrimony-request')

  useEffect(() => {
    if (!userHasViewPatrimonyRequestsPermission) {
      redirect('/')
    }
  }, [userHasViewPatrimonyRequestsPermission, redirect])

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const patrimonyType = searchParams.get('patrimonyType')
  const identifier = searchParams.get('identifier')
  const responsibleName = searchParams.get('responsibleName')
  const protocol = searchParams.get('protocol')

  const {
    data: requestsPatrimonyData,
    isLoading: isFetchingRequestsPatrimonyData
  } = useQuery({
    queryKey: ['requestsPatrimony',
      page,
      status,
      patrimonyType,
      identifier,
      responsibleName,
      protocol
    ],
    queryFn: () => getPatrimonyRequests({
      currentPage: page,
      pageSize: '10',
      patrimonyType,
      status: status as PatrimonyRequestStatus | 'all',
      identifier,
      responsibleName,
      protocol
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: requestsPatrimonyData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: requestsPatrimonyData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateRequestPatrimonyModalOpen,
    onOpen: onOpenCreateRequestPatrimonyModal,
    onClose: onCloseCreateRequestPatrimonyModal
  } = useDisclosure()

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Patrimônios Solicitados</Heading>
        {userHasCreatePatrimonyPermission && (
          <IconButton
            aria-label="Criar solicitação de patrimônio"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateRequestPatrimonyModal}
          />
        )}
        <Modal
          isOpen={isCreateRequestPatrimonyModalOpen}
          onClose={onCloseCreateRequestPatrimonyModal}
          isCentered
        >
          <ModalOverlay />
          <CreateRequestPatrimony
            onCloseModal={onCloseCreateRequestPatrimonyModal}
          />
        </Modal>
      </Flex>
      <PatrimonyRequestsTableFilters />
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Protocolo</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Responsável</Th>
              <Th color="white">Tipo de Patrimônio</Th>
              <Th color="white">Data Limite</Th>
              <Th color="white">Localização do Patrimônio</Th>
              <Th color="white">Número de Patrimônio</Th>
              <Th color="white">Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {isFetchingRequestsPatrimonyData ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={String(index)}>
                    <Td>
                      <HStack spacing={3}>
                        <Skeleton w={8} h={8} />
                        <Skeleton w={8} h={8} />
                      </HStack>
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="180px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="30px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                  </Tr>
                )
              })
            ) : (
              requestsPatrimonyData?.patrimoniesRequests.map(requestPatrimony => {
                return (
                  <PatrimonyRequestTableRow
                    key={requestPatrimony.id}
                    patrimonyRequest={requestPatrimony}
                  />
                )
              })
            )}

          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
