import { Box, Button, Circle, Flex, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaArrowRight, FaCalendarAlt, FaTag, FaTimes, FaTrash } from "react-icons/fa";
import { TrainingLlmDetail } from "./TrainingLlmDetail";

import { useAuth } from "hooks/auth/useAuth";
import { TrainingLlm } from "api/trainingLlm/trainingLlm";
import { format } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import { approveTrainingLlm } from "api/trainingLlm/approveTrainingLlm";
import { GetTrainingsLlmResponse } from "api/trainingLlm/getTrainingsLlm";
import { reproveTrainingLlm } from "api/trainingLlm/reproveTrainingLlm";
import { deleteLlmTraining } from "api/trainingLlm/deleteLlmTraining";


interface TrainingsLlmKanbanCardProps {
  trainingLlm: TrainingLlm
}

export function TrainingsLlmKanbanCard({ trainingLlm }: TrainingsLlmKanbanCardProps) {
  const { userLogged } = useAuth()

  const { promiseMessage } = useToastify()

  const userCanApproveLlmTraining = userLogged?.permissions.includes('approve-llm-training')

  const userCanReproveLlmTraining = userLogged?.permissions.includes('reprove-llm-training')

  const userCanDeleteLlmTraining = userLogged?.permissions.includes('delete-llm-training')

  const {
    isOpen: isTrainingLlmDetailModalOpen,
    onToggle: onToggleTrainingLlmDetailModal,
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { mutateAsync: approveTrainingLlmFn } = useMutation({
    mutationFn: approveTrainingLlm,
    onSuccess(_data, { trainingLlmId }) {
      const cachedTraining = queryClient.getQueriesData<GetTrainingsLlmResponse>({
        queryKey: ['trainingsLlm']
      })
      queryClient.invalidateQueries(['pending-trainings-llm'])
      queryClient.invalidateQueries({ queryKey: 'approved-trainings-llm' })
      queryClient.invalidateQueries({ queryKey: 'reproved-trainings-llm' })

      queryClient.invalidateQueries({
        queryKey: ['trainingLlm', trainingLlmId]
      })

      cachedTraining.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          llmTrainings: cachedData.llmTrainings.map((training) => {
            if (training.id === trainingLlmId) {
              return {
                ...training,
                status: 'approved',
                approved_at: new Date().toISOString(),
              }
            }

            return training
          })
        })
      })
    },
  })

  const { mutateAsync: reproveTrainingLlmFn } = useMutation({
    mutationFn: reproveTrainingLlm,
    onSuccess(_data, { trainingLlmId }) {
      const cachedTraining = queryClient.getQueriesData<GetTrainingsLlmResponse>({
        queryKey: ['trainingsLlm']
      })
      queryClient.invalidateQueries(['pending-trainings-llm'])
      queryClient.invalidateQueries({ queryKey: 'approved-trainings-llm' })
      queryClient.invalidateQueries({ queryKey: 'reproved-trainings-llm' })

      queryClient.invalidateQueries({
        queryKey: ['trainingLlm', trainingLlmId]
      })


      cachedTraining.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          trainings: cachedData.llmTrainings.map((training) => {
            if (training.id === trainingLlmId) {
              return {
                ...training,
                status: 'reproved',
                reproved_at: new Date().toISOString(),
              }
            }

            return training
          })
        })
      })
    },
  })

  const { mutateAsync: deleteLlmTrainingFn } = useMutation({
    mutationFn: deleteLlmTraining,
    onSuccess(_data, { trainingId }) {
      const cachedTraining = queryClient.getQueriesData<GetTrainingsLlmResponse>({
        queryKey: ['trainingsLlm']
      })
      queryClient.invalidateQueries(['pending-trainings-llm'])
      queryClient.invalidateQueries({ queryKey: 'approved-trainings-llm' })
      queryClient.invalidateQueries({ queryKey: 'reproved-trainings-llm' })

      queryClient.invalidateQueries({
        queryKey: ['trainingLlm', trainingId]
      })


      cachedTraining.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          llmTrainings: cachedData.llmTrainings.filter((llmTraining) => llmTraining.id !== trainingId)
        })
      })
    },
  })

  async function handleApproveTrainingLlm() {
    await promiseMessage(approveTrainingLlmFn({
      trainingLlmId: trainingLlm.id
    }), 'Treinamento Complementar Aprovado! 🎉')
  }

  async function handleReproveTrainingLlm() {
    await promiseMessage(reproveTrainingLlmFn({
      trainingLlmId: trainingLlm.id
    }), 'Treinamento Complementar Reprovado! ')
  }

  async function handleDeleteLlmTrainig() {
    await promiseMessage(deleteLlmTrainingFn({
      trainingId: trainingLlm.id
    }), 'Treinamento Complementar Excluído! ')
  }



  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleTrainingLlmDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {trainingLlm?.collaborator?.firstname} {trainingLlm?.collaborator?.lastname}
        </Text>

        {userCanDeleteLlmTraining && (
          <Circle
            size="5"
            color="white"
            onClick={(e) => {
              e.stopPropagation()

              handleDeleteLlmTrainig()
            }}
          >
            <Icon as={FaTrash} boxSize={"3.5"} color='blue.900' />
          </Circle>
        )}

      </HStack>


      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaTag} />
            <Text fontSize="xs" fontWeight="bold">TREINAMENTO</Text>
          </HStack>
          <Text ml={5}>{trainingLlm?.training}</Text>
        </Box>

        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA LIMITE PARA REALIZAÇÃO</Text>
          </HStack>
          <Text ml={5}>{trainingLlm.due_date ? format(new Date(trainingLlm?.due_date), 'dd/MM/yyyy') : '-'}</Text>
        </Box>

      </VStack>
      <Flex align="center" gap="2" alignItems="flex-end" justifyContent="flex-end">

        {(trainingLlm.status === 'pending' && userCanApproveLlmTraining) && (
          <Button
            leftIcon={<FaArrowRight />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation()
              handleApproveTrainingLlm()
            }}

          >
            Aprovar
          </Button>
        )}
        {(trainingLlm.status === 'pending' && userCanReproveLlmTraining) && (
          <Button
            leftIcon={<FaTimes />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation()
              handleReproveTrainingLlm()
            }}
          >
            Reprovar
          </Button>
        )}

      </Flex>
      <Modal
        isOpen={isTrainingLlmDetailModalOpen}
        onClose={onToggleTrainingLlmDetailModal}
      >
        <ModalOverlay />
        <TrainingLlmDetail
          trainingLlmId={trainingLlm.id}
        />
      </Modal>
    </Box>
  )
}
