import { PatrimonyStatus } from "api/patrimonies/patrimony";
import { PatrimonyRequestStatus } from "api/patrimonies/patrimonyRequest";

export const patrimonyStatusMap: Record<PatrimonyStatus, string> = {
  available: 'Disponível',
  "in-possession": 'Em posse',
  disabled: 'Inutilizado'
}

export const requestPatrimonyStatusMap: Record<PatrimonyRequestStatus, string> = {
  requested: 'Solicitado',
  active: 'Ativo',
  returning: 'Em processo de devolução',
  returned: 'Devolvido',

}
