import { useForm, useWatch } from "react-hook-form";
import { StandardBackgroundForm } from "../../components/Form/StandardBackgroundForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Flex, Link, Spinner, Stack } from "@chakra-ui/react";
import { Select } from "../../components/Inputs/SelectInput";
import { GeneralContentLoading } from "../../components/Loading/GeneralContentLoading";
import { Input } from "../../components/Inputs/Input";
import { api } from "../../services/api";
import { useMutation } from "react-query";
import { useSwal } from "../../hooks/swal/useSwal";
import { useAuth } from "../../hooks/auth/useAuth";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { set } from "date-fns";
import { negative_positive } from "utils/customLists";
import { useDownloadCustomerAccountCteZipFunction } from "hooks/customerAccount/useDownloadCustomerAccountCteZipFunction";
import { verifyIfIsInputedDateValid } from "utils/verifyIfIsInputedDateValid";
import { useCustomerAccountCsv } from "hooks/customerAccount/useCustomerAccountCsv";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { useCustomers } from "hooks/customer/useCustomers";

interface IGenerateCustomerAccountFormData {
  customer_id: string;
  is_business: string;
  start_date: string;
  end_date: string;
}

const generateCustomerAccountFormValidationSchema = yup.object().shape({
  customer_id: yup.string().required('Campo Obrigatório'),
  is_business: yup.string().required('Campo Obrigatório'),
  start_date: yup.date().typeError('Campo Obrigatório').required('Campo Obrigatório'),
  end_date: yup.date().typeError('Campo Obrigatório').required('Campo Obrigatório'),
})

const expressServicesHeaders = [
  { key: 'protocol', label: 'PROTOCOLO' },
  { key: 'startFilter', label: 'DATA INICIAL DO FILTRO' },
  { key: 'endFilter', label: 'DATA FINAL DO FILTRO' },
  { key: 'customer', label: 'CLIENTE' },
  { key: 'date', label: 'DATA' },
  { key: 'sourceCities', label: 'ORIGEM' },
  { key: 'destinationCities', label: 'DESTINO' },
  { key: 'cteLoglife', label: 'CT-e' },
  { key: 'price', label: 'PREÇO' },
  { key: 'franchising', label: 'PESO FRANQUIA' },
  { key: 'taxedWeight', label: 'PESO TAX.' },
  { key: 'boardVolume', label: 'VOL.' },
  { key: 'sourceAddressesQuantity', label: 'QTDE COLETAS' },
  { key: 'totalAdditionalCollects', label: 'VALOR TOTAL COLETA(S) ADICIONAIS' },
  { key: 'destinationAddressesQuantity', label: 'QTDE ENTREGAS' },
  { key: 'totalAdditionalDeliveries', label: 'VALOR TOTAL ENTREGAS(S) ADICIONAIS' },
  { key: 'extraKgQuantity', label: 'QTDE' },
  { key: 'totalExtraKGPrice', label: 'VALOR' },
  { key: 'customerExtraMaterials', label: 'DESCRIÇÃO MATERIAIS EXTRA' },
  { key: 'customerExtraMaterialsTotalCost', label: 'VALOR TOTAL MATERIAIS EXTRA' },
  { key: 'complementaryCte', label: 'CTE COMPLEMENTAR' },
  { key: 'total', label: 'TOTAL GERAL' },
  { key: 'extras', label: 'EXTRAS' },
  { key: 'discounts', label: 'DESCONTOS' },
]

const businessServicesHeaders = [
  { key: 'startFilter', label: 'DATA INICIAL DO FILTRO' },
  { key: 'endFilter', label: 'DATA FINAL DO FILTRO' },
  { key: 'customer', label: 'CLIENTE' },
  { key: 'vehicle', label: 'VEÍCULO' },
  { key: 'total', label: 'TOTAL GERAL' },
  { key: 'extras', label: 'EXTRAS' },
  { key: 'discounts', label: 'DESCONTOS' },
]

export function GenerateCustomerAccountPage() {
  const [
    isDownloadCustomerAccountCsvEnabled,
    setIsDownloadCustomerAccountCsvEnabled
  ] = useState(false)

  const [
    isDownloadCustomerAccountCteEnabled,
    setIsDownloadCustomerAccountCteEnabled
  ] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: {
      errors,
      isSubmitting,
    } } = useForm<IGenerateCustomerAccountFormData>({
      resolver: yupResolver(generateCustomerAccountFormValidationSchema),
      defaultValues: {
        customer_id: '',
        is_business: 'NÃO',
        start_date: '',
        end_date: '',
      }
    })

  async function generateCustomerAccountReqFunction({
    customer_id,
    start_date,
    end_date,
    is_business
  }: IGenerateCustomerAccountFormData): Promise<ArrayBuffer> {

    const endDateWithDefaultHours = (set(new Date(end_date), {
      hours: 23,
      minutes: 59,
      seconds: 59
    }))

    const isBusinessService = is_business === "SIM"

    try {
      const { data } = await api.post("/accounts/customer", {
        customer_id,
        is_business: isBusinessService,
        start_date,
        end_date: endDateWithDefaultHours,
      }, { responseType: 'arraybuffer' })

      return data;
    } catch (error: any) {
      throw new Error("Erro ao gerar fatura de cliente!")
    }
  }

  const { errorMessage, successMessage } = useSwal()
  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes("request-customer-account")) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const isUserLoggedCustomer = userLogged?.user_type === 'CLIENTE'
  useEffect(() => {
    if (isUserLoggedCustomer) {
      if (userLogged?.user_type === 'CLIENTE') {
        setValue('customer_id', userLogged?.customer_id)
        return
      }

    }
  }, [userLogged, isUserLoggedCustomer, setValue])

  const {
    mutateAsync: generateCustomerAccount,
    data: customerAccountData
  } = useMutation(
    generateCustomerAccountReqFunction, {
    onError: (error: any) => {
      errorMessage(error.message || 'Erro Inesperado!')
    },
    onSuccess: () => {
      successMessage("Fatura disponibilizada para download!")
    },
  })

  const [
    customerSelected,
    startDate,
    endDate,
    isBusiness
  ] = useWatch({
    control,
    name: ['customer_id', 'start_date', 'end_date', 'is_business']
  })

  const {
    data: customerAccountCsvData,
    isFetching: isFetchingCustomerAccountCsvData
  } = useCustomerAccountCsv({
    queryOptions: {
      enabled: isDownloadCustomerAccountCsvEnabled
    },
    queryParams: {
      customer_id: customerSelected,
      end_filter: endDate,
      is_business: isBusiness,
      start_filter: startDate
    }
  })

  const handleSetIsDownloadCustomerAccountCsvEnabled = () => {
    setIsDownloadCustomerAccountCsvEnabled(true)
    setIsDownloadCustomerAccountCsvEnabled(false)
  }

  const csvReportFilename = `fatura-cliente-csv-${startDate}-${endDate}`

  const csvReportData = customerAccountCsvData?.csv_data.map((csvData) => {

    const extras = csvData.extras.map((extra) => {
      return `
      Data: ${extra.date},
      Descrição: ${extra.description},
      Valor: ${extra.value}
      `
    })

    const discounts = csvData.discounts.map((discount) => {
      return `
      Data: ${discount.date},
      Descrição: ${discount.description},
      Valor: ${discount.value}
      `
    })

    return {
      ...csvData,
      extras,
      discounts
    }
  })

  const csvReportProps = {
    data: csvReportData ?? [],
    headers: isBusiness === 'SIM' ? businessServicesHeaders : expressServicesHeaders,
    filename: csvReportFilename,
  }

  useEffect(() => {
    const isValidStartDate = verifyIfIsInputedDateValid(startDate)
    const isValidEndDate = verifyIfIsInputedDateValid(endDate)
    if (isValidStartDate && isValidEndDate) {
      const debounce = setTimeout(() => {
        setIsDownloadCustomerAccountCteEnabled(true)
        setIsDownloadCustomerAccountCteEnabled(false)
      }, 1000)

      return () => clearTimeout(debounce)
    }
  }, [startDate, endDate])

  const {
    downloadCustomerAccountCteZipFile: {
      data: customerAccountCteZipFile,
      isFetching: isDownloadCustomerAccountCteZipFileFetching
    }
  } = useDownloadCustomerAccountCteZipFunction({
    queryParams: {
      customer_id: customerSelected,
      start_date: startDate,
      end_date: endDate
    },
    queryOptions: {
      enabled: isDownloadCustomerAccountCteEnabled
    }
  })


  const {
    data: customers,
    isFetching: isCustomersDataFetching
  } = useCustomers()

  const customersSelectOptions = customers?.map((customer) => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  })

  const attendanceResponsibleCustomerSelected = `${customers?.find(customer => customer.id === customerSelected)?.attendanceResponsibleIDCustomer?.firstname} ${customers?.find(customer => customer.id === customerSelected)?.attendanceResponsibleIDCustomer?.lastname}`


  if (isCustomersDataFetching) {
    return <GeneralContentLoading />
  }

  const bytes = customerAccountData ? new Uint8Array(customerAccountData) : null

  const url = bytes ? URL.createObjectURL(new Blob([bytes], { type: 'application/pdf' })) : null

  const zipUrl = customerAccountCteZipFile ? URL.createObjectURL(new Blob([customerAccountCteZipFile], { type: 'application/zip' })) : null

  async function handleGenerateCustomerAccount(data: IGenerateCustomerAccountFormData) {
    await generateCustomerAccount(data)
    handleSetIsDownloadCustomerAccountCsvEnabled()
  }


  return (
    <StandardBackgroundForm title="Gerar Fatura de Cliente" onSubmit={handleSubmit(handleGenerateCustomerAccount)}>
      <Stack direction="column" spacing={6}>
        <Stack direction={['column', 'column', 'row']} spacing={6}>
          <Select
            {...register("customer_id")}
            name="customer_id"
            label="Cliente"
            placeholder="Selecione uma opção..."
            options={customersSelectOptions}
            error={errors.customer_id}
            isDisabled={isUserLoggedCustomer}
            required
          />

          {customerSelected && (
            <Select
              name="attendance_responsible"
              label="Responsável pelo atendimento"
              placeholder={attendanceResponsibleCustomerSelected ?? '-'}
              isDisabled={true}
            />
          )}
        </Stack>

        <Select
          {...register("is_business")}
          name="is_business"
          label="Serviço business?"
          placeholder="Selecione uma opção..."
          negative_positive={negative_positive}
          error={errors.is_business}
          required
        />

        <Stack direction={['column', 'column', 'row']} spacing={6}>
          <Input
            {...register("start_date")}
            name="start_date"
            label="Data inicial"
            type="date"
            error={errors.start_date}
            required
          />
          <Input
            {...register("end_date")}
            name="end_date"
            label="Data final"
            type="date"
            error={errors.end_date}
            required
          />
        </Stack>
      </Stack>
      <Flex mt={4} gap={2} justifyContent={['center', 'center', 'flex-end']} alignItems="center">
        <Button
          colorScheme="blue"
          w={['full', 'full', 'full', '200px']}
          isLoading={isSubmitting}
          type="submit"
        >
          Gerar Fatura
        </Button>
        {url && (
          <Flex
            height={10}
            borderRadius="8px"
            border="1px"
            transition="0.3s"
            borderColor="blue.400"
            _hover={{ bg: 'blue.300', color: 'white' }}
            alignItems="center"
            textAlign="center"
          >
            <Link href={url} textDecoration="none" px={2} download>
              Download Fatura
            </Link>
          </Flex>
        )}
        {zipUrl && (
          <>
            {isDownloadCustomerAccountCteZipFileFetching ? (
              <Spinner />
            ) : (
              <Flex
                height={10}
                borderRadius="8px"
                border="1px"
                transition="0.3s"
                borderColor="blue.400"
                _hover={{ bg: 'blue.300', color: 'white' }}
                alignItems="center"
                textAlign="center"
              >
                <Link href={zipUrl} textDecoration="none" px={2} download>
                  Download Ctes .zip
                </Link>
              </Flex>
            )}
          </>
        )}

        {!isFetchingCustomerAccountCsvData && customerAccountCsvData && (
          <GenerateExcelReportButton mt={-4} csvReportProps={csvReportProps} />
        )}
      </Flex>
    </StandardBackgroundForm>
  );
}
