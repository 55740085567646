import { IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { Price } from "api/prices/_types/Price";
import { useAuth } from "hooks/auth/useAuth";
import { FaSearch } from "react-icons/fa";
import { captalize } from "utils/captalize";
import { IRequestedMaterialsProps } from "../ConsultPrices";
import { PriceDetails } from "./PriceDetails";

interface ConsultPriceTableRowProps {
  price: Price & {
    airPrices: {
      vehicle: string
      price: number
    }[]
    highwayPrices: {
      vehicle: string
      price: number
    }[]
    priceId: string
  },
  materialsTotalCost: number
  priceGeloSeco: number
  materialsTotalPrice: number
  materialsToConsultTable: IRequestedMaterialsProps[]
  airTransferBudgetValue: number
  highwayTransferBudgetValue: number
  taxedWeight: number
}

interface VehiclePrice {
  vehicle: string
  price: number
}


function formatVehiclePrices(vehicle: string, prices: VehiclePrice[], shippingType: string, taxedWeight: number, extraKg: number) {
  const vehiclePrices = prices?.filter(price => price.vehicle === vehicle)

  const doesHasVehiclePrices = Boolean(vehiclePrices?.length)

  if (doesHasVehiclePrices) {
    return vehiclePrices?.map((price) => {

      if (taxedWeight > 2 && shippingType === 'air') {
        const totalPrice = (extraKg * (taxedWeight - 2)) + price.price
        return totalPrice
      }

      if (taxedWeight > 10 && shippingType === 'highway') {
        const totalPrice = (extraKg * (taxedWeight - 10)) + price.price
        return totalPrice
      }

      return price.price

    })
  }

  return []
}

export function ConsultPriceTableRow({
  price,
  materialsTotalCost,
  priceGeloSeco,
  materialsTotalPrice,
  materialsToConsultTable,
  airTransferBudgetValue,
  highwayTransferBudgetValue,
  taxedWeight
}: ConsultPriceTableRowProps) {

  const { userLogged } = useAuth()

  const isUserLoggedCustomer = userLogged?.user_type === 'CLIENTE' || userLogged?.user_type === 'REMETENTE'


  const doesExistMotorcycleHighwayPrices = Boolean(price.highwayPrices.find(
    (highwayPrice) => (highwayPrice.price !== null && highwayPrice.price !== 0) && highwayPrice.vehicle === 'MOTO'
  ))

  const doesExistMotorcycleAirPrices = Boolean(price.airPrices.find(
    (airPrice) => (airPrice.price !== null && airPrice.price !== 0) && airPrice.vehicle === 'MOTO'
  ))

  const doesExistCarAirPrices = Boolean(price.airPrices.find(
    (airPrice) => (airPrice.price !== null && airPrice.price !== 0) && airPrice.vehicle === 'CARRO'
  ))

  const doesExistCarHighwayPrices = Boolean(price.highwayPrices.find(
    (highwayPrice) => (highwayPrice.price !== null && highwayPrice.price !== 0) && highwayPrice.vehicle === 'CARRO'
  ))

  const {
    isOpen: isPriceDetailModalOpen,
    onToggle: onTogglePriceDetailModal,
  } = useDisclosure()


  return (
    <Tr>
      <Td>
        {price?.priceId && (
          <IconButton
            aria-label="Visualizar Preço"
            icon={<FaSearch />}
            size="sm"
            variant="ghost"
            onClick={onTogglePriceDetailModal}
          />
        )}
        <Modal
          isOpen={isPriceDetailModalOpen}
          onClose={onTogglePriceDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <PriceDetails
            priceId={price?.priceId}
          />
        </Modal>
      </Td>
      <Td>
        {captalize(price.sourceCity.name)}
      </Td>
      <Td>
        {captalize(price.destinationCity.name)}
      </Td>
      <Td>{price.category}</Td>
      <Td>
        {doesExistMotorcycleAirPrices ? (
          formatVehiclePrices('MOTO', price.airPrices, 'air', taxedWeight, price.airExtraKg).map(
            (price) => {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(price + materialsTotalCost + priceGeloSeco)
            })
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {doesExistCarAirPrices ? (
          formatVehiclePrices('CARRO', price.airPrices, 'air', taxedWeight, price.airExtraKg).map(
            (price) => {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(price + materialsTotalCost + priceGeloSeco)
            })
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {price.airExtraKg !== null ? new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(price.airExtraKg) : '-'}
      </Td>
      <Td>
        {price.airDeadline !== null
          ? `D + ${price.airDeadline}`
          : '-'
        }
      </Td>
      <Td>
        {doesExistMotorcycleHighwayPrices ? (
          formatVehiclePrices('MOTO', price.highwayPrices, 'highway', taxedWeight, price.highwayExtraKg).map(
            (price) => {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(price + materialsTotalCost + priceGeloSeco)
            })
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {doesExistCarHighwayPrices ? (
          formatVehiclePrices('CARRO', price.highwayPrices, 'highway', taxedWeight, price.highwayExtraKg).map(
            (price) => {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(price + materialsTotalCost + priceGeloSeco)
            })
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {price.highwayExtraKg !== null
          ? price.highwayExtraKg
          : '-'}
      </Td>
      <Td>
        {price.highwayDeadline !== null
          ? `D + ${price.highwayDeadline}`
          : '-'
        }
      </Td>
      <Td>{
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(materialsTotalCost)
      }</Td>
      <Td>{
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(priceGeloSeco)
      }</Td>
      <Td>{
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(materialsTotalPrice)
      }</Td>
      {materialsToConsultTable?.map((material) => (
        <Td key={material.material}>
          {`R$ ${material.totalPrice}`}
        </Td>
      ))}
      {userLogged?.loglife_employee === true && !isUserLoggedCustomer && (
        <>
          <Td>
            {
              new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(airTransferBudgetValue)
            }
          </Td>
          <Td>
            {
              new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(highwayTransferBudgetValue)
            }
          </Td>
        </>
      )}

      <Td>
        {price?.shippings?.length !== 0 ? price?.shippings
          ?.map((shipping) => shipping?.shipping?.trading_name)
          .filter(Boolean)
          .join(', ')
          .split(', ')
          .map((name, index) => (
            <span key={index}>
              {name}
              {index !== price.shippings.length - 1 && ', '}
            </span>
          )) : '-'}
      </Td>
    </Tr>
  )
}
