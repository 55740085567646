import { Box, Button, Flex, FormLabel, HStack, Icon, IconButton, Input, Link, Progress, Text, Tooltip, VStack } from "@chakra-ui/react";
import { useAttachmentFunctions } from "hooks/attachment/AttachmentFunctions";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { ChangeEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FaExternalLinkAlt, FaTimes } from "react-icons/fa";
import { FaFile, FaImage, FaPlus } from "react-icons/fa6";
import { SendCltDriverDismissDocumentationSchema, SetTerminationDocumentAttachmentIdAction } from "./SendCltDriverDismissDocumentation";
import { MouseEvent } from "react"
import { useToastify } from "hooks/toastify/useToastify";

interface TerminationDocumentTableRowFieldProps {
  index: number
  isFilledField: boolean
  onAppendField: () => void
  onRemoveField: () => void
  onSetTerminationDocumentAttachmentId: (action: SetTerminationDocumentAttachmentIdAction) => void
}

function switchIconByFileType(fileType: string) {
  if (fileType?.startsWith('image')) return FaImage

  return FaFile
}

export function TerminationDocumentTableRowField({
  index,
  isFilledField,
  onRemoveField,
  onAppendField,
  onSetTerminationDocumentAttachmentId
}: TerminationDocumentTableRowFieldProps) {

  const { mutation: createAttachmentMutation, uploadProggress } = useAttachmentMutation()
  const { deleteAttachment } = useAttachmentFunctions()
  const { warnMessage } = useToastify()

  const { register, control } = useFormContext<SendCltDriverDismissDocumentationSchema>()

  async function handleSendAttachment(event: ChangeEvent<HTMLInputElement>) {
    const fileInput = event.target
    const file = fileInput.files?.[0]

    if (!file) return

    const MAX_FILE_SIZE = 25 * 1024 * 1024
    if (file.size > MAX_FILE_SIZE) {
      warnMessage("O arquivo deve ter no máximo 25MB.")

      fileInput.value = ""

      return
    }


    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    const data = await createAttachmentMutation.mutateAsync(formData)

    onSetTerminationDocumentAttachmentId({
      type: 'ADD',
      payload: {
        attachmentId: data.attachment.id
      }
    })

    onAppendField()

  }

  async function handleRemoveAttachment({
    event,
    attachmentId
  }: {
    attachmentId: string,
    event: MouseEvent<HTMLButtonElement>
  }) {
    event.preventDefault()
    event.stopPropagation()

    await deleteAttachment.mutateAsync(attachmentId)

    onSetTerminationDocumentAttachmentId({
      type: 'DELETE',
      payload: { attachmentId }
    })

    onRemoveField()
  }

  const terminationDocumentFile = useWatch({
    control,
    name: `terminationDocuments.${index}.file`
  })

  const fileName = terminationDocumentFile
    ? terminationDocumentFile[0]?.name
    : ''

  const fileType = terminationDocumentFile
    ? terminationDocumentFile[0]?.type
    : ''

  return (
    <VStack
      w="full"
      align="start"
    >

      {isFilledField && (
        <Flex
          as={Link}
          align="center"
          w="full"
          role="group"
          justify="space-between"
          href={createAttachmentMutation?.data?.attachment?.link}
          _hover={{
            textDecoration: "none",
            bg: 'blackAlpha.50',
          }}
          py={2}
          px={1}
          rounded="md"
          position="relative"
          isExternal
        >
          <HStack spacing={2}>
            <Box
              position="relative"
              w="8"
              h="8"
              rounded="md"
              bg="blue.500"
              display="flex"
              alignItems="center"
              justifyContent="center"
              _groupHover={{
                opacity: '1.8'
              }}
            >
              <Icon
                position="absolute"
                as={switchIconByFileType(fileType)}
                color="white"
                h="5"
                w="5"
              />

              <Box
                bg="black"
                opacity={0}
                _groupHover={{
                  opacity: '0.3'
                }}
                position="absolute"
                h="full"
                w="full"
                rounded="md"
                transition="all 0.2s"
                display="flex"
                justifyContent="center"
                alignItems="center"
              />

              <Icon
                as={FaExternalLinkAlt}
                color="white"
                display="none"
                _groupHover={{
                  display: "inline-block",

                }}
                transition="all 0.3s"
                fontSize="xs"
                position="absolute"
              />


            </Box>
            <VStack align="start" justify="start">
              <Tooltip
                fontSize="xs"
                label={fileName}
              >
                <Box as={Text} fontSize="sm" textDecoration="none" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxW="350px">
                  {fileName}
                </Box>
              </Tooltip>
              {uploadProggress && (
                <Progress size="sm" w="200px" rounded="md" value={uploadProggress} />
              )}
            </VStack>
          </HStack>

          <Tooltip
            fontSize="xs"
            label="Excluir anexo"
          >
            <IconButton
              aria-label=""
              icon={<FaTimes />}
              size="xs"
              variant="ghost"
              rounded="full"
              position="absolute"
              right={2}
              opacity={0}
              _groupHover={{
                opacity: 1
              }}
              transition="all 0.4s"
              onClick={(event) => handleRemoveAttachment({
                event,
                attachmentId: createAttachmentMutation.data.attachment.id
              })}
            />
          </Tooltip>
        </Flex>
      )}

      <Input
        {...register(`terminationDocuments.${index}.file`)}
        id={`terminationDocuments.${index}.file`}
        type="file"
        hidden
        onChangeCapture={handleSendAttachment}
      />

      {!isFilledField && !createAttachmentMutation.isLoading && (
        <Button
          as={FormLabel}
          htmlFor={`terminationDocuments.${index}.file`}
          leftIcon={<FaPlus />}
          variant="ghost"
          size="sm"
        >
          Adicionar arquivos
        </Button>
      )}
    </VStack>
  )
}
