import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { SupportType } from "utils/supportTypes";

interface SupportsTableFiltersSchema {
  type: SupportType | 'all'
  protocol: string
  subject: string
  createdByUser: string
}

export function SupportsTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()


  const type = searchParams.get('type') ?? 'all'
  const protocol = searchParams.get('protocol')
  const subject = searchParams.get('subject')
  const createdByUser = searchParams.get('createdByUser')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<SupportsTableFiltersSchema>({
    defaultValues: {
      type: type as SupportType | 'all',
      protocol,
      subject,
      createdByUser
    }
  })

  async function handleFilterSupports(data: SupportsTableFiltersSchema) {
    if (data.type) {
      searchParams.set('type', data.type)
    } else {
      searchParams.delete('type')
    }
    if (data.subject) {
      searchParams.set('subject', data.subject)
    } else {
      searchParams.delete('subject')
    }
    if (data.protocol) {
      searchParams.set('protocol', data.protocol)
    } else {
      searchParams.delete('protocol')
    }
    if (data.createdByUser) {
      searchParams.set('createdByUser', data.createdByUser)
    } else {
      searchParams.delete('createdByUser')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('type')
    searchParams.delete('protocol')
    searchParams.delete('subject')
    searchParams.delete('createdByUser')

    reset({
      type: 'all',
      protocol: '',
      subject: '',
      createdByUser: ''
    })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }


  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterSupports)}
    >
      <Input
        {...register("protocol")}
        placeholder="Protocolo"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Input
        {...register("subject")}
        placeholder="Assunto do atendimento"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Input
        {...register("createdByUser")}
        placeholder="Solicitado por"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Select
        {...register('type')}
        name="type"
        size="sm"
        rounded="md"
        w={{ base: 'full', lg: '250px' }}
      >
        <option value="all">Todos</option>
        {Object.keys(SupportType).filter((key) => isNaN(Number(key))).map((key) => {
          return <option value={key} key={key}>{key}</option>
        })}
      </Select>

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
