import { Box, Button, Flex, FormControl, FormLabel, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import { ExternalSupportForm } from "./ExternalSupportForm";
import { PartnerSupportForm } from "./PartnerSupportForm";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { FormDataItem } from 'api/supports/createSupport'
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { InternalSupportForm } from "./InternalSupportForm";
import { useEffect, useRef, useState } from "react";
import { createSupportSchema } from "../validation/supportSchema";
import { SupportType } from "utils/supportTypes";
import { useAuth } from "hooks/auth/useAuth";
import { useCollectors } from "hooks/collector/useCollectors";
import { useSearchParams } from "hooks/useSearchParams";
import { useHistory } from "react-router-dom";
import { getSupportSubjectsByCategory } from "api/supports/getSupportSubjectsByCategory";
import { getSupport } from "api/supports/getSupport";
import { format } from "date-fns";
import { editSupport } from "api/supports/editSupport";
import { FaExternalLinkAlt, FaTrash } from "react-icons/fa";


interface EditSupportProps {
  onClose: () => void
  required?: boolean
  supportId: string
}

export const supportTypeOptions = [
  'Motoristas', 'Coletadores', 'Funcionários LLM'
]

export interface EditSupportSchema {
  type: 'Motoristas' | 'Coletadores' | 'Funcionários LLM'
  subject: string
  requestAttachmentId?: FileList
  bank?: string
  agency?: string
  accountType?: string
  accountOwner?: string
  cpf?: string
  accountNumber?: string
  description?: string
  collectorId?: string
  category?: string
  formData?: FormDataItem
  attachments: {
    file: FileList
  }[]
}

interface AttachmentSupport {
  attachmentId: string
  title: string
  link: string
}


function removeEmptyFields(obj: FormDataItem): FormDataItem {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value.value)
  );
}


export function EditSupport({
  onClose,
  required = false,
  supportId
}: EditSupportProps) {
  const { userLogged } = useAuth()

  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const isUserLoggedDriverOrCollector = ['COLETADOR', 'MOTORISTA'].includes(userLogged?.user_type)

  const {
    data: supportResult,
    isFetching: isFetchingSupportResult,
  } = useQuery({
    queryKey: ['support', supportId],
    queryFn: () => getSupport({ supportId }),
    refetchOnWindowFocus: false,
  });



  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const [subjectSelected, setSubjectSelected] = useState('')



  const formMethods = useForm<EditSupportSchema>({
    resolver: yupResolver(createSupportSchema),
    defaultValues: {
      attachments: [{ file: undefined }],
    },
    context: {
      subject: subjectSelected,
      editSupport: true
    }
  })

  const { handleSubmit, register, control, unregister, setValue, reset, formState: { isSubmitting, errors } } = formMethods


  const [type, subject, category] = useWatch({
    control,
    name: ['type', 'subject', 'category', 'formData']
  })

  const isExternalSupport = type === 'Motoristas'
  const isPartnerSupport = type === 'Coletadores'
  const isInternalSupport = type === 'Funcionários LLM'


  useEffect(() => {
    if (!supportResult || !supportId) return;

    reset({
      type: supportResult?.support?.type,
      category: isInternalSupport ? supportResult?.support?.subject?.category : null,
      subject: supportResult?.support?.subject?.id || '',
      bank: isExternalSupport ? supportResult?.support?.externalSupport?.bank : isPartnerSupport ? supportResult?.support?.partnerSupport?.bank : null,
      agency: isExternalSupport ? String(supportResult?.support?.externalSupport?.agency) : isPartnerSupport ? String(supportResult?.support?.partnerSupport?.agency) : null,
      accountType: isExternalSupport ? supportResult?.support?.externalSupport?.accountType : null,
      accountOwner: isExternalSupport ? supportResult?.support?.externalSupport?.accountOwner : null,
      cpf: isExternalSupport ? supportResult?.support?.externalSupport?.cpf : null,
      accountNumber: isExternalSupport ? String(supportResult?.support?.externalSupport?.accountNumber) : isPartnerSupport ? String(supportResult?.support?.partnerSupport?.accountNumber) : null,
      description: supportResult?.support?.description || '',
      collectorId: isPartnerSupport ? supportResult?.support?.partnerSupport?.collectorId : null,
      formData: Object.entries(supportResult?.support?.internSupport?.formData || {}).reduce(
        (acc, [field, { label, value, type }]) => {
          if (field === 'attachments') {
            return acc;
          }
          acc[field] = {
            label,
            value: type === 'date' ? format(new Date(value), 'yyyy-MM-dd') : value,
            type,
          };
          return acc;
        },
        {} as FormDataItem
      ),
      attachments: [{ file: undefined }],
    });
  }, [supportId, supportResult, reset, isFetchingSupportResult, isExternalSupport, isPartnerSupport, isInternalSupport]);


  const { mutation: uploadRequestAttachmentFn, uploadProggress: uploadProggressRequestAttachment } = useAttachmentMutation()



  const {
    data: collectorsResult,
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO',
    }
  })

  const {
    data: supportsSubjectsResult
  } = useQuery({
    queryKey: ['subjects', category, type],
    queryFn: () => getSupportSubjectsByCategory({
      type: type as SupportType | 'all',
      category: category ?? null
    }),
    enabled: !!type,
    refetchOnWindowFocus: false,
  })

  const subjectName = supportsSubjectsResult?.supportsSubjects?.find(supportSubject => supportSubject.id === subject)


  useEffect(() => {
    if (isUserLoggedDriverOrCollector && type === 'Coletadores' && Boolean(collectorsResult)) {
      setValue('collectorId', userLogged?.collector_id)
    }
  }, [type, setValue, userLogged?.collector_id, collectorsResult, isUserLoggedDriverOrCollector])

  const prevSubjectRef = useRef(subject);

  useEffect(() => {
    setSubjectSelected(subjectName?.name)
  }, [subject, subjectName?.name])

  useEffect(() => {
    if (prevSubjectRef.current && (prevSubjectRef.current !== subject) && subject) {
      unregister('formData');
    }
    prevSubjectRef.current = subject;
  }, [subject, unregister]);


  const [attachments, setAttachments] = useState<AttachmentSupport[]>([]);

  useEffect(() => {
    if (supportResult?.support?.type === "Funcionários LLM") {
      const attachmentsData = supportResult?.support?.internSupport?.formData?.attachments?.value;


      const parsedAttachments = attachmentsData
        ? JSON.parse(attachmentsData).filter((item: AttachmentSupport) => item.attachmentId && item.title)
        : [];

      setAttachments(parsedAttachments);
    }
  }, [supportResult]);


  const handleRemoveAttachment = (attachmentId: string) => {
    setAttachments((prev) => prev.filter((att) => att.attachmentId !== attachmentId));
  };



  const { mutateAsync: editSupportFn } = useMutation({
    mutationFn: editSupport,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: 'analyzing-supports' })
      if (required) {
        searchParams.set('supportRequested', 'true')
        replace({ search: searchParams.toString() })
      }
      onClose()
    }
  })




  async function handleEditSupport(values: EditSupportSchema) {
    const formDataAttachments = values.formData?.attachments?.value
      ? JSON.parse(values.formData.attachments.value)
      : [];

    const allAttachments: AttachmentSupport[] = [
      ...attachments,
      ...formDataAttachments,
    ]


    const uniqueAttachments = allAttachments.reduce((acc, curr) => {
      if (!acc.some((attachment) => attachment.attachmentId === curr.attachmentId)) {
        acc.push(curr)
      }
      return acc
    }, [])


    const updatedFormData: FormDataItem = removeEmptyFields(values.formData)


    if (uniqueAttachments.length > 0 || values.formData?.attachments) {
      updatedFormData.attachments = {
        ...values.formData.attachments,
        value: JSON.stringify(uniqueAttachments),
      };
    }

    await promiseMessage(editSupportFn({
      body: {
        subjectId: values.subject,
        type: values.type,
        description: values.description,
        externalSupport: {
          accountNumber: values.accountNumber ? Number(values.accountNumber.replace('-', '')) : null,
          accountOwner: values.accountOwner,
          accountType: values.accountType,
          agency: values.agency ? Number(values.agency.replace('-', '')) : null,
          bank: values.bank,
          cpf: values.cpf,
        },
        partnerSupport: {
          accountNumber: values.accountNumber ? Number(values.accountNumber.replace('-', '')) : null,
          agency: values.agency ? Number(values.agency.replace('-', '')) : null,
          bank: values.bank,
          collectorId: values.collectorId,
        },
        internSupport: {
          category: values.category,
          formData: updatedFormData
        },
        requestAttachmentId: values.requestAttachmentId ? uploadRequestAttachmentFn?.data?.attachment?.id : null,
      },
      supportId
    }), 'Atendimento editado com sucesso!')
  }


  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Editar Atendimento
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <FormProvider {...formMethods}>
          <Box
            as="form"
            onSubmit={handleSubmit(handleEditSupport)}
            maxH='600px'
            overflowY='scroll'
            p={3}
          >
            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
              mt="3"
            >
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors.type}>
                  <FormLabel fontSize="sm">
                    Tipo do atendimento
                    <Text as="sup" color="red.500">*</Text>
                  </FormLabel>

                  <Select
                    {...register('type')}
                    name="type"
                    placeholder="Selecione..."
                    rounded="md"
                    size='sm'
                  >
                    {supportTypeOptions.map((type) => {
                      if (isUserLoggedDriverOrCollector && type !== 'Interno') {
                        return <option key={type} value={type}>{type}</option>
                      }

                      return <option key={type} value={type}>{type}</option>
                    })}


                  </Select>
                </FormControl>
              </Stack>
            </Stack>

            {isInternalSupport && (
              <InternalSupportForm />
            )}

            <FormControl isInvalid={!!errors?.subject} mt={3}>
              <FormLabel fontSize="sm">
                Motivo do atendimento
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Select
                {...register('subject')}
                name="subject"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {supportsSubjectsResult?.supportsSubjects?.map((subject) => {
                  return <option key={subject.id} value={subject.id}>{subject.name}</option>
                })}


              </Select>
            </FormControl>


            {isExternalSupport && (
              <ExternalSupportForm
                uploadProggressRequestAttachment={uploadProggressRequestAttachment}
                uploadRequestAttachmentFn={uploadRequestAttachmentFn}
              />
            )}
            {isPartnerSupport && (
              <PartnerSupportForm
                uploadProggressRequestAttachment={uploadProggressRequestAttachment}
                uploadRequestAttachmentFn={uploadRequestAttachmentFn}
                collectors={collectorsResult}
              />
            )}

            {(supportResult?.support?.type === 'Funcionários LLM' ? Object.entries(supportResult?.support?.internSupport?.formData)
              .find(([_, { type }]) => type === 'attachments') : false || (supportResult?.support?.requestAttachments?.length !== 0)) && (
                <TableContainer w="full">
                  <Table size="sm" mt={4}>
                    <Thead>
                      <Tr>
                        <Th>Anexos Cadastrados</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {attachments?.length > 0 &&
                        attachments?.map((attachment) => (
                          <Tr key={attachment.attachmentId}>

                            <Td>{attachment.title.length > 20
                              ? attachment.title.slice(0, 20) + "..."
                              : attachment.title}</Td>
                            <Td isNumeric>
                              <IconButton
                                key={attachment.link}
                                aria-label="Abrir anexo"
                                as={Link}
                                href={attachment.link}
                                icon={<FaExternalLinkAlt />}
                                size="sm"
                                isExternal
                                ml={2} />

                              <IconButton
                                aria-label="Remover anexo"
                                icon={<FaTrash />}
                                size="sm"
                                onClick={() => handleRemoveAttachment(attachment.attachmentId)}
                                ml={2} />
                            </Td>
                          </Tr>
                        ))}
                      {supportResult?.support?.requestAttachments.length !== 0 && supportResult?.support?.requestAttachments.map((attachment) => {
                        return (
                          <Tr key={attachment.id}>
                            <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Anexo da solicitação</Td>
                            <Td isNumeric>
                              <IconButton
                                aria-label="Abrir anexo"
                                as={Link}
                                href={attachment?.attachment?.link}
                                icon={<FaExternalLinkAlt />}
                                size="sm"
                                isExternal
                              />
                            </Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            <Flex
              mt="6"
              w="full"
              justify="flex-end"
            >
              <Button
                type="submit"
                size="sm"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Editar
              </Button>
            </Flex>
          </Box>
        </FormProvider>

      </ModalBody>
    </ModalContent>
  )
}
