import axios from "axios";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { SupportType } from "utils/supportTypes";
import { Support, SupportStatus } from "./_types/Support";
import { SupportSubject } from "./_types/SupportSubject";

export interface GetSupportsParams {
  pageSize: number
  currentPage: number
  status: SupportStatus | 'all',
  userCanViewAllSupports: boolean
  type?: SupportType | 'all'
  protocol?: string
  subject?: string
  createdByUser?: string
}

export interface GetSupportsResponse {
  supports: Array<Support & {
    subject: SupportSubject & {
      responsibles: {
        user: User
      }[]
    }
  }>
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
    count: number
  }
}

export async function getSupports({
  currentPage,
  pageSize,
  status,
  userCanViewAllSupports = false,
  protocol,
  subject,
  type,
  createdByUser
}: GetSupportsParams) {
  try {
    const response = await api.get<GetSupportsResponse>('/supports', {
      params: {
        pageSize,
        currentPage,
        status,
        userCanViewAllSupports,
        protocol,
        subject,
        type,
        createdByUser
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
