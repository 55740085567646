import { Box, Flex, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { getCarrier } from "api/carriers/getCarrier";
import { format } from "date-fns";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { captalize } from "utils/captalize";
import { CarrierStatus } from "./CarrierStatus";

interface CarrierDetailProps {
  carrierId: string
}

export function CarrierDetail({
  carrierId
}: CarrierDetailProps) {

  const {
    data: carrierResult,
  } = useQuery({
    queryKey: ['carrier', carrierId],
    queryFn: () => getCarrier({ carrierId })
  })

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Detalhes do portador
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody
        maxH='600px'
        overflowY='scroll'
      >
        <Box w="full" maxH="800px" overflowY="scroll" p={2}>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td>Nome</Td>
                <Td isNumeric>{carrierResult?.carrier.name}</Td>
              </Tr>
              <Tr>
                <Td>CPF</Td>
                <Td isNumeric>{carrierResult?.carrier.cpf}</Td>
              </Tr>
              <Tr>
                <Td>Email</Td>
                <Td isNumeric>{carrierResult?.carrier.email}</Td>
              </Tr>
              <Tr>
                <Td>Status</Td>
                <Td isNumeric>
                  <Flex justify="end">
                    <CarrierStatus status={carrierResult?.carrier.status} />
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td>Veículo</Td>
                <Td isNumeric>{carrierResult?.carrier.vehicle ?? '-'}</Td>
              </Tr>
              {carrierResult?.carrier.collector && (
                <Tr>
                  <Td>Coletador</Td>
                  <Td isNumeric>{captalize(carrierResult?.carrier?.collector?.trading_name)}</Td>
                </Tr>
              )}
              <Tr>
                <Td color={carrierResult?.carrier?.dtVaccineDoseAttachment?.expired ? 'red.400' : undefined}>Cartão de vacina - dose DT (difteria e tétano)</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier?.dtVaccineDoseAttachment?.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Data de vencimento - Cartão de vacina - dose DT (difteria e tétano)</Td>
                <Td isNumeric>{carrierResult?.carrier?.dtVaccineDoseAttachment?.document_validity ? format(new Date(carrierResult?.carrier?.dtVaccineDoseAttachment?.document_validity), 'dd/MM/yyyy') : '-'}</Td>
              </Tr>
              <Tr>
                <Td color={carrierResult?.carrier?.hepatiteBVaccineDoseAttachment?.expired ? 'red.400' : undefined}>Cartão de vacina - dose hepatite B</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier?.hepatiteBVaccineDoseAttachment?.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Data de vencimento - Cartão de vacina - dose hepatite B</Td>
                <Td isNumeric>{carrierResult?.carrier?.hepatiteBVaccineDoseAttachment?.document_validity ? format(new Date(carrierResult?.carrier?.hepatiteBVaccineDoseAttachment?.document_validity), 'dd/MM/yyyy') : '-'}</Td>
              </Tr>
              <Tr>
                <Td color={carrierResult?.carrier?.tripliceViralVaccineDoseAttachment?.expired ? 'red.400' : undefined}>Cartão de vacina - dose tríplice viral</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier?.tripliceViralVaccineDoseAttachment?.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Data de vencimento - Cartão de vacina - dose tríplice viral</Td>
                <Td isNumeric>{carrierResult?.carrier?.tripliceViralVaccineDoseAttachment?.document_validity ? format(new Date(carrierResult?.carrier?.tripliceViralVaccineDoseAttachment?.document_validity), 'dd/MM/yyyy') : '-'}</Td>
              </Tr>
              <Tr>
                <Td color={carrierResult?.carrier?.cnhAttachment?.expired ? 'red.400' : undefined}>CNH atual</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier?.cnhAttachment?.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Data de vencimento - CNH atual</Td>
                <Td isNumeric>{carrierResult?.carrier?.cnhAttachment?.document_validity ? format(new Date(carrierResult?.carrier?.cnhAttachment?.document_validity), 'dd/MM/yyyy') : '-'}</Td>
              </Tr>
              <Tr>
                <Td color={carrierResult?.carrier?.crlvAttachment?.expired ? 'red.400' : undefined}>CRLV atual</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier?.crlvAttachment?.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Data de vencimento - CRLV atual</Td>
                <Td isNumeric>{carrierResult?.carrier?.crlvAttachment?.document_validity ? format(new Date(carrierResult?.carrier?.crlvAttachment?.document_validity), 'dd/MM/yyyy') : '-'}</Td>
              </Tr>
              <Tr>
                <Td color={carrierResult?.carrier?.anttMotorFreightAttachment?.expired ? 'red.400' : undefined}>Curso de motofrete/ANTT</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier?.anttMotorFreightAttachment?.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Data de vencimento - Curso de motofrete/ANTT</Td>
                <Td isNumeric>{carrierResult?.carrier?.anttMotorFreightAttachment?.document_validity ? format(new Date(carrierResult?.carrier?.anttMotorFreightAttachment?.document_validity), 'dd/MM/yyyy') : '-'}</Td>
              </Tr>
              <Tr>
                <Td color={carrierResult?.carrier?.vehicleSidePhotoAttachment?.expired ? 'red.400' : undefined}>Foto da lateral da moto/carro</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier?.vehicleSidePhotoAttachment?.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Data de vencimento - Foto da lateral da moto/carro</Td>
                <Td isNumeric>{carrierResult?.carrier?.vehicleSidePhotoAttachment?.document_validity ? format(new Date(carrierResult?.carrier?.vehicleSidePhotoAttachment?.document_validity), 'dd/MM/yyyy') : '-'}</Td>
              </Tr>
              <Tr>
                <Td color={carrierResult?.carrier?.vehicleRearPhotoAttachment?.expired ? 'red.400' : undefined}>Foto da traseira da moto/carro</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier?.vehicleRearPhotoAttachment?.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Data de vencimento - Foto da traseira da moto/carro</Td>
                <Td isNumeric>{carrierResult?.carrier?.vehicleRearPhotoAttachment?.document_validity ? format(new Date(carrierResult?.carrier?.vehicleRearPhotoAttachment?.document_validity), 'dd/MM/yyyy') : '-'}</Td>
              </Tr>
              <Tr>
                <Td color={carrierResult?.carrier?.vehicleInternPhotoAttachment?.expired ? 'red.400' : undefined}>Foto interna do baú/carroceria</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier?.vehicleInternPhotoAttachment?.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Data de vencimento - Foto interna do baú/carroceria</Td>
                <Td isNumeric>{carrierResult?.carrier?.vehicleInternPhotoAttachment?.document_validity ? format(new Date(carrierResult?.carrier?.vehicleInternPhotoAttachment?.document_validity), 'dd/MM/yyyy') : '-'}</Td>
              </Tr>
              <Tr>
                <Td color={carrierResult?.carrier?.trainingCertificateAttachmentId?.expired ? 'red.400' : undefined}>Certificado de treinamento</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier?.trainingCertificateAttachmentId?.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Data de vencimento - Certificado de treinamento</Td>
                <Td isNumeric>{carrierResult?.carrier?.trainingCertificateAttachmentId?.document_validity ? format(new Date(carrierResult?.carrier?.trainingCertificateAttachmentId?.document_validity), 'dd/MM/yyyy') : '-'}</Td>
              </Tr>
              {carrierResult?.carrier?.pendencies && (
                <Tr>
                  <Td>Pendências</Td>
                  <Td isNumeric>
                    {carrierResult?.carrier?.pendencies.map(pendency => {
                      return (
                        <Text fontSize="sm">{pendency}</Text>
                      )
                    })}
                  </Td>
                </Tr>
              )}
              {carrierResult?.carrier.has_loglife_university_register !== null && (
                <Tr>
                  <Td>Cadastro universidade Loglife</Td>
                  <Td isNumeric>{carrierResult?.carrier.has_loglife_university_register ? 'Sim' : 'Não'}</Td>
                </Tr>
              )}

              {carrierResult?.carrier.has_aso_register !== null && (
                <Tr>
                  <Td>Cadastro de ASO</Td>
                  <Td isNumeric>{carrierResult?.carrier.has_aso_register ? 'Sim' : 'Não'}</Td>
                </Tr>
              )}
              {carrierResult?.carrier.observations && (
                <Tr>
                  <Td>Observações</Td>
                  <Td isNumeric>{carrierResult?.carrier.observations}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      </ModalBody>
    </ModalContent>
  )
}
