import { Box, Flex } from "@chakra-ui/react";
import { CltIntercurrenceReportStatus as CltIntercurrenceReportStatusTypes } from "api/cltIntercurrences/types/CltIntercurrence";
import { cltIntercurrenceReportStatusMap } from "utils/cltIntercurrenceMappers";


interface CltIntercurrenceReportStatusProps {
  status: CltIntercurrenceReportStatusTypes
}

export function CltIntercurrenceReportStatus({ status }: CltIntercurrenceReportStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'requested' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'approved' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'reproved' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}


      {cltIntercurrenceReportStatusMap[status]}
    </Flex>
  )
}
