import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Modal, ModalOverlay, Radio, RadioGroup, Text, useDisclosure } from "@chakra-ui/react";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { registerInternCltOnPontomais } from "api/internClts/registerInternCltOnPontomais";
import { FaPlus } from "react-icons/fa";
import { CreateSupport } from "pages/Support/components/CreateSupport";



interface RegisterInternCltOnPontoMaisProps {
  internCltId: string
}

export interface RegisterInternCltOnPontoMaisSchema {
  isWithPontomaisRegister: string
}

const registerInternCltOnPontoMaisSchema = yup.object({
  isWithPontomaisRegister: yup.string().required(),
})


export function RegisterInternCltOnPontoMais({ internCltId }: RegisterInternCltOnPontoMaisProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    register,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<RegisterInternCltOnPontoMaisSchema>({
    resolver: yupResolver(registerInternCltOnPontoMaisSchema)
  })

  const { mutateAsync: registerInternCltOnPontomaisFn } = useMutation({
    mutationFn: registerInternCltOnPontomais,
    onSuccess(_data, { body, internCltId }) {
      queryClient.invalidateQueries({ queryKey: 'intern-clts' })
      queryClient.invalidateQueries({ queryKey: 'pontomais-registering-intern-clts' })
      queryClient.invalidateQueries({ queryKey: 'sending-bank-details-intern-clts' })
      queryClient.invalidateQueries({ queryKey: ['intern-clt', internCltId] })
    }
  })

  const {
    isOpen: isCreateSupportOpen,
    onOpen: onOpenCreateSupport,
    onClose: onCloseCreateSupport
  } = useDisclosure()

  async function handleRegisterInternCltOnPontomais(values: RegisterInternCltOnPontoMaisSchema) {
    await promiseMessage(registerInternCltOnPontomaisFn({
      body: values,
      internCltId
    }), 'Registro no iFractal realizado!')
  }


  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleRegisterInternCltOnPontomais)}
    >
      <Heading letterSpacing="tight" size='sm'>Registro iFractal</Heading>
      <FormControl isInvalid={!!errors.isWithPontomaisRegister} mt={3}>
        <FormLabel fontSize="sm">
          Cadastro efetuado no iFractal ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <RadioGroup >
          <Radio
            {...register('isWithPontomaisRegister')}
            name="isWithPontomaisRegister"
            size='sm'
            rounded='md'
            mr='7'
            value='Sim'>
            Sim
          </Radio>
          <Radio
            {...register('isWithPontomaisRegister')}
            name="isWithPontomaisRegister"
            size='sm'
            rounded='md'
            mr='7'
            value='N/A'>
            N/A
          </Radio>

        </RadioGroup>

      </FormControl>
      <Flex mt={3} alignItems="baseline" justifyContent="space-between">
        <FormLabel fontSize='sm'>
          Solicitar acessos
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <IconButton
          aria-label="Abrir atendimento"
          icon={<FaPlus />}
          colorScheme="blue"
          size="sm"
          onClick={onOpenCreateSupport} />


      </Flex>
      <Modal
        isOpen={isCreateSupportOpen}
        onClose={onCloseCreateSupport}
        isCentered
        size="2xl"
      >
        <ModalOverlay />
        <CreateSupport
          onClose={onCloseCreateSupport}
          required={true} />
      </Modal>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
