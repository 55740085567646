import { usePagination } from "@ajna/pagination";
import { IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { ExtraDiscount, getAggregateExtraDiscountLogs } from "api/aggregates/getAggregateExtraDiscountLogs";
import { Pagination } from "components/Pagination/Pagination";
import { format, isValid, parseISO } from "date-fns";
import { useAggregates } from "hooks/aggregate/useAggregates";

import { useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";

interface AggregateExtraDiscountLogsProps {
  extraDiscountId: string
}

function fieldsMapper(field: keyof ExtraDiscount): string {
  switch (field) {
    case 'type': return 'Tipo'
    case 'aggregate_id': return 'Agregado'
    case 'service_date': return 'Data do serviço'
    case 'value': return 'Valor'
    case 'description': return 'Descrição'
    case 'service_route': return 'Rota'
    case 'attachments': return 'Anexos'
    case 'services': return 'Protocolos'
    case 'additional_descriptions': return 'Descrições adicionais'

    default:
      return field
  }
}


export function AggregateExtraDiscountLogs({
  extraDiscountId
}: AggregateExtraDiscountLogsProps) {

  const [paginationPage, setPaginationPage] = useState(1)

  const { data: aggregates, isFetching: isFetchingAggregates } = useAggregates({
    queryOptions: {
      enabled: !!extraDiscountId
    }
  })
  const {
    data: aggregateExtraDiscountLogsResult,
    isLoading: isLoadingAggregateExtraDiscountLogsResult
  } = useQuery({
    queryKey: ['aggregate-extra-discount-logs', extraDiscountId, paginationPage],
    queryFn: () => getAggregateExtraDiscountLogs({
      pageSize: 10,
      currentPage: paginationPage,
      extraDiscountId
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: aggregateExtraDiscountLogsResult?.meta?.totalPages,
    initialState: {
      currentPage: paginationPage,
      pageSize: 10
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    setPaginationPage(page)
  }


  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Logs de edição extras e descontos
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Table
          size="sm"
        >
          <Thead>
            <Tr>
              <Th color="blackAlpha.600" fontSize="sm" fontWeight="medium" letterSpacing="wide" fontFamily="Roboto" textTransform="none">Data</Th>
              <Th color="blackAlpha.600" fontSize="sm" fontWeight="medium" letterSpacing="wide" fontFamily="Roboto" textTransform="none">Detalhes</Th>
              <Th color="blackAlpha.600" fontSize="sm" fontWeight="medium" letterSpacing="wide" fontFamily="Roboto" textTransform="none">Atualizado por</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoadingAggregateExtraDiscountLogsResult || isFetchingAggregates ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={String(index)}>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>

                  </Tr>
                )
              })
            ) : (
              aggregateExtraDiscountLogsResult?.logs?.map((log) => {
                return (
                  <Tr key={log.id}>
                    <Td>{log.createdAt ? format(new Date(log.createdAt), "dd/MM/yyyy '-' HH:mm") : '-'}</Td>
                    <Td>
                      {Object.entries(log.logDetail).map(([key, value]) => {
                        let prevValue = value.prev
                        let newValue = value.new


                        if (key === 'value') {

                          prevValue = new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(Number(value.prev) / 100);
                          newValue = new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(Number(value.new) / 100);

                        }

                        if (key.toLowerCase().includes("date") && typeof prevValue === "string" && typeof newValue === "string") {
                          const prevDate = parseISO(prevValue)
                          const newDate = parseISO(newValue)

                          prevValue = isValid(prevDate) ? format(prevDate, "dd/MM/yyyy") : "-";
                          newValue = isValid(newDate) ? format(newDate, "dd/MM/yyyy") : "-";
                        }


                        if (Array.isArray(value.prev)) {
                          prevValue = value.prev.length > 0 ? value.prev.map(String).join(' - ') : '-';
                        } else {
                          prevValue = value.prev === null ? '-' : prevValue;
                        }

                        if (Array.isArray(value.new)) {
                          newValue = value.new.length > 0 ? value.new.map(String).join(' - ') : '-';
                        } else {
                          newValue = value.new === null ? '-' : newValue;
                        }

                        if (key === 'aggregate_id') {
                          const aggregatePreviousById = aggregates?.find(aggregate => aggregate.id === prevValue)

                          const aggregateNewById = aggregates?.find(aggregate => aggregate.id === newValue)

                          prevValue = `${aggregatePreviousById?.driver?.firstname} ${aggregatePreviousById?.driver?.lastname}`

                          newValue = `${aggregateNewById?.driver?.firstname} ${aggregateNewById?.driver?.lastname}`

                        }


                        if (key === "attachments") {
                          return (
                            <Text key={key}>
                              {fieldsMapper(key as keyof ExtraDiscount)}:{" "}
                              <Text as="span" display="inline-flex" gap={2}>
                                {Array.isArray(value.prev) && value.prev.length > 0
                                  ? (value.prev as { attachmentId: string; link: string }[])?.map((attachment) => (
                                    <IconButton
                                      key={attachment.attachmentId}
                                      as={Link}
                                      href={attachment.link}
                                      aria-label="Abrir anexo anterior"
                                      icon={<FaExternalLinkAlt />}
                                      size="sm"
                                      variant="ghost"
                                      colorScheme="blue"
                                    />
                                  ))
                                  : "-"}
                              </Text>{" "}
                              ➡️{" "}
                              <Text as="span" display="inline-flex" gap={2}>
                                {Array.isArray(value.new) && value.new.length > 0
                                  ? (value.new as { attachmentId: string; link: string }[])?.map((attachment) => (
                                    <IconButton
                                      key={attachment.attachmentId}
                                      as={Link}
                                      href={attachment.link}
                                      aria-label="Abrir anexo novo"
                                      icon={<FaExternalLinkAlt />}
                                      size="sm"
                                      variant="ghost"
                                      colorScheme="green"
                                    />
                                  ))
                                  : "-"}
                              </Text>
                            </Text>
                          );
                        }

                        return (
                          <Text key={key}>
                            {fieldsMapper(key as keyof ExtraDiscount)}: {prevValue} ➡️ {newValue}
                          </Text>
                        )

                      })}

                    </Td>
                    <Td>{log?.updatedById?.firstname ? `${log?.updatedById?.firstname} ${log?.updatedById?.lastname}` : '-'}</Td>
                  </Tr>
                )
              })
            )}
          </Tbody>
        </Table>
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </ModalBody>
    </ModalContent>
  )
}
