import { useAuth } from "hooks/auth/useAuth"
import { useSwal } from "hooks/swal/useSwal"
import { useMutation, useQueryClient } from "react-query"
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors"
import { api } from "services/api"

interface CollectorContacts {
  name: string
  email: string
  phone: string
  description: string
  type: string[]
}


interface CollectorInput {
  situation: string
  company_name: string
  trading_name: string
  cnpj: string
  hub_list: string[]
  operational_email: string
  financial_email: string
  delay_cost: number
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  state: string
  city: string
  municipal_register: string
  payment_type: string
  day_expiration: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  has_driver_assign: boolean
  observation: string
  state_register: string
  responsible_name: string
  responsible_email: string
  responsible_cpf: string
  documents?: {
    name: string
    dueDate: string
    attachmentId: string
  }[]
  doesUseSystem: boolean
  contacts: CollectorContacts[]
}

interface UpdateCollector {
  collector_id: string
  input: CollectorInput
}

interface UpdateCollectorAttachment {
  collector_id: string
  input: FormData
}

interface DeleteCollectorAttachment {
  collectorId: string
  filename: string
}


async function createCollectorReqFunction(input: CollectorInput) {
  try {
    const { data } = await api.post("/collector", input)
    return data;
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function updateCollectorReqFunction({ input, collector_id }: UpdateCollector) {
  try {
    await api.put(`/collector/${collector_id}`, input)

  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function updateCollectorAttachmentReqFunction({ input, collector_id }: UpdateCollectorAttachment) {
  try {
    await api.patch(`/collector/attachment/${collector_id}`, input)
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function deleteCollectorReqFunction(collector_id: string) {
  try {
    await api.delete(`/collector/${collector_id}`)

  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function deleteCollectorAttachmentReqFunction({
  collectorId,
  filename
}: DeleteCollectorAttachment) {
  try {
    await api.patch(`/collector/${collectorId}/attachment/${filename}/delete`)
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

interface CreateCollectorDispatchStockProposalReqFunctionProps {
  collectorId: string
  input: {
    proposalInputs: {
      inputId: string
      sendQuantity: number
    }[]
  }
}

async function createCollectorDispatchStockProposalReqFunction({
  collectorId,
  input
}: CreateCollectorDispatchStockProposalReqFunctionProps) {
  try {
    await api.post(`/collectors/${collectorId}/proposals`, input)
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

interface UpdateCollectorDispatchStockProposalReqFunctionProps {
  dispatchStockProposalId: string
  inputId: string
  input: {
    sendQuantity: number
  }
}

async function updateCollectorDispatchStockProposalReqFunction({
  dispatchStockProposalId,
  inputId,
  input
}: UpdateCollectorDispatchStockProposalReqFunctionProps) {
  try {
    await api.patch(`/dispatch-stock/proposals/${dispatchStockProposalId}/inputs/${inputId}/quantity`, input)
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

interface CreateCollectorExtraDiscountInput {
  type: 'EXTRA' | 'DESCONTO'
  serviceDate: string
  value: number
  description: string
  serviceType: 'EXPRESS' | 'BUSINESS'
  serviceRoute: string
  attachmentsIds: string[]
  servicesProtocols: number[]
}

interface CreateCollectorExtraDiscountReqFunctionProps {
  collectorId: string
  input: CreateCollectorExtraDiscountInput
}

async function createCollectorExtraDiscountReqFunction({
  collectorId,
  input
}: CreateCollectorExtraDiscountReqFunctionProps) {
  try {
    await api.post(`/collectors/${collectorId}/extras-discounts/`, input)
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

type UpdateCollectorExtraDiscountInput = CreateCollectorExtraDiscountInput & {
  additionalDescriptions: string[] | null
}

interface UpdateCollectorExtraDiscountReqFunctionProps {
  collectorId: string
  extraDiscountId: string
  input: UpdateCollectorExtraDiscountInput
}

async function updateCollectorExtraDiscountReqFunction({
  collectorId,
  extraDiscountId,
  input
}: UpdateCollectorExtraDiscountReqFunctionProps) {
  try {
    await api.put(`/collectors/${collectorId}/extras-discounts/${extraDiscountId}`, input)
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function approveCollectorExtraDiscountReqFunction(
  collectorExtraDiscountId: string
) {
  try {
    await api.patch(`/collectors/extras-discounts/${collectorExtraDiscountId}/approve`)
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function effectiveCollectorExtraDiscountReqFunction(
  collectorExtraDiscountId: string
) {
  try {
    await api.patch(`/collectors/extras-discounts/${collectorExtraDiscountId}/effective`)
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function rejectCollectorExtraDiscountReqFunction(
  collectorExtraDiscountId: string
) {
  try {
    await api.patch(`/collectors/extras-discounts/${collectorExtraDiscountId}/reject`)
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function deleteCollectorExtraDiscountReqFunction(
  collectorExtraDiscountId: string
) {
  try {
    await api.delete(`/collectors/extras-discounts/${collectorExtraDiscountId}`)
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

export function useCollectorsFunctions() {
  const { successMessage, errorMessage } = useSwal()
  const { handleLogout } = useAuth()
  const queryClient = useQueryClient()

  async function onSuccessRequest(message: string) {
    await queryClient.invalidateQueries("collectors")
    await queryClient.invalidateQueries("collector")
    return successMessage(message)
  }

  async function onErrorRequest(message: string, status: number) {
    await errorMessage(message)

    if (status === 401) await handleLogout()
  }

  const createCollector = useMutation('createCollector', createCollectorReqFunction, {
    onSuccess: (data) => {
      onSuccessRequest("Coletador criado com sucesso");
      return data
    },
    onError: async (error: RequestError) => onErrorRequest(error.message, error.httpCode)
  })
  const updateCollector = useMutation('updateCollector', updateCollectorReqFunction, {
    onSuccess: () => onSuccessRequest("Coletador atualizado com sucesso"),
    onError: async (error: RequestError) => onErrorRequest(error.message, error.httpCode)
  })
  const updateCollectorAttachment = useMutation('updateCollectorAttachment', updateCollectorAttachmentReqFunction)

  const deleteCollector = useMutation('deleteCollector', deleteCollectorReqFunction, {
    onSuccess: () => onSuccessRequest("Coletador excluído com sucesso"),
    onError: async (error: RequestError) => onErrorRequest(error.message, error.httpCode)
  })

  const deleteCollectorAttachment = useMutation('deleteCollectorAttachment', deleteCollectorAttachmentReqFunction, {
    onError: async (error: RequestError) => onErrorRequest(error.message, error.httpCode)
  })

  const createCollectorExtraDiscount = useMutation(
    'createCollectorExtraDiscount', createCollectorExtraDiscountReqFunction
  )

  const updateCollectorExtraDiscount = useMutation(
    'updateCollectorExtraDiscount', updateCollectorExtraDiscountReqFunction
  )

  const approveCollectorExtraDiscount = useMutation(
    'approveCollectorExtraDiscount', approveCollectorExtraDiscountReqFunction
  )

  const effectiveCollectorExtraDiscount = useMutation(
    'effectiveCollectorExtraDiscount', effectiveCollectorExtraDiscountReqFunction
  )

  const rejectCollectorExtraDiscount = useMutation(
    'rejectCollectorExtraDiscount', rejectCollectorExtraDiscountReqFunction
  )

  const deleteCollectorExtraDiscount = useMutation(
    'deleteCollectorExtraDiscount', deleteCollectorExtraDiscountReqFunction
  )

  const createCollectorDispatchStockProposal = useMutation(
    'createCollectorDispatchStockProposal', createCollectorDispatchStockProposalReqFunction
  )

  const updateCollectorDispatchStockProposal = useMutation(
    'updateCollectorDispatchStockProposal', updateCollectorDispatchStockProposalReqFunction
  )

  return {
    createCollector,
    updateCollector,
    updateCollectorAttachment,
    deleteCollector,
    deleteCollectorAttachment,
    approveCollectorExtraDiscount,
    effectiveCollectorExtraDiscount,
    rejectCollectorExtraDiscount,
    deleteCollectorExtraDiscount,
    createCollectorExtraDiscount,
    updateCollectorExtraDiscount,
    createCollectorDispatchStockProposal,
    updateCollectorDispatchStockProposal
  }
}
