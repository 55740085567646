import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { ExperiencePeriodStatus } from "./types/ExperiencePeriod";

interface EditInvoiceStatusParams {
  experiencePeriodId: string
  status: ExperiencePeriodStatus
}

export async function editExperiencePeriodStatus({
  experiencePeriodId,
  status
}: EditInvoiceStatusParams) {
  try {
    await api.patch(`/experience-periods/${experiencePeriodId}/status`, {
      status
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
