export enum SupportType {
  Motoristas = 'Motoristas',
  'Funcionários LLM' = 'Funcionários LLM',
  Coletadores = 'Coletadores'
}


export const SUPPORT_TYPE_CATEGORIES: Record<SupportType, string[] | null> = {
  [SupportType.Coletadores]: null,
  [SupportType.Motoristas]: null,
  [SupportType["Funcionários LLM"]]: ['Compras e Almoxarifado', 'Contas a pagar', 'Contas a receber', 'TI', 'Gente', 'Jurídico', 'Qualidade']
}
