import { Box, Flex, Heading, IconButton } from "@chakra-ui/react"
import { getExperiencePeriods } from "api/experiencePeriods/getExperiencePeriods"
import { useAuth } from "hooks/auth/useAuth"
import { useSearchParams } from "hooks/useSearchParams"
import { useEffect } from "react"
import { FaExchangeAlt } from "react-icons/fa"
import { useInfiniteQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { ExperiencePeriodsKanbanColumn } from "./components/ExperiencePeriodsKanbanColumn"
import { ExperiencePeriodTableFilters } from "./components/ExperiencePeriodTableFilters"

export function ExperiencePeriodsKanban() {

  const history = useHistory()
  const { userLogged } = useAuth()
  const searchParams = useSearchParams()

  const userCanViewExperiencePeriods = userLogged?.permissions?.includes('view-experience-periods')


  useEffect(() => {
    if (!userCanViewExperiencePeriods) history.push('/')
  }, [history, userCanViewExperiencePeriods])

  const name = searchParams.get('name')

  const {
    data: initialExperiencePeriodsResult,
    isLoading: isInitialExperiencePeriodsResultLoading,
    hasNextPage: hasNextPageInitialExperiencePeriods,
    fetchNextPage: fetchNextPageInitialExperiencePeriods,
    isFetchingNextPage: isFetchingNextPageInitialExperiencePeriods,
  } = useInfiniteQuery({
    queryKey: ['initial-experience-periods', name],
    queryFn: ({ pageParam = 1 }) => getExperiencePeriods({
      currentPage: pageParam,
      pageSize: '10',
      status: 'initial-experience-period',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: validating45DaysExperiencePeriodsResult,
    isLoading: isValidating45DaysExperiencePeriodsResultLoading,
    hasNextPage: hasNextPageValidating45DaysExperiencePeriods,
    fetchNextPage: fetchNextPageValidating45DaysExperiencePeriods,
    isFetchingNextPage: isFetchingNextPageValidating45DaysExperiencePeriods,
  } = useInfiniteQuery({
    queryKey: ['validating-45-days-experience-periods', name],
    queryFn: ({ pageParam = 1 }) => getExperiencePeriods({
      currentPage: pageParam,
      pageSize: '10',
      status: 'validating-45-days',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: finalExperiencePeriodsResult,
    isLoading: isFinalExperiencePeriodsResultLoading,
    hasNextPage: hasNextPageFinalExperiencePeriods,
    fetchNextPage: fetchNextPageFinalExperiencePeriods,
    isFetchingNextPage: isFetchingNextPageFinalExperiencePeriods,
  } = useInfiniteQuery({
    queryKey: ['final-experience-periods', name],
    queryFn: ({ pageParam = 1 }) => getExperiencePeriods({
      currentPage: pageParam,
      pageSize: '10',
      status: 'final-experience-period',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: validating90DaysExperiencePeriodsResult,
    isLoading: isValidating90DaysExperiencePeriodsResultLoading,
    hasNextPage: hasNextPageValidating90DaysExperiencePeriods,
    fetchNextPage: fetchNextPageValidating90DaysExperiencePeriods,
    isFetchingNextPage: isFetchingNextPageValidating90DaysExperiencePeriods,
  } = useInfiniteQuery({
    queryKey: ['validating-90-days-experience-periods', name],
    queryFn: ({ pageParam = 1 }) => getExperiencePeriods({
      currentPage: pageParam,
      pageSize: '10',
      status: 'validating-90-days',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })


  const {
    data: activeExperiencePeriodsResult,
    isLoading: isActiveExperiencePeriodsResultLoading,
    hasNextPage: hasNextPageActiveExperiencePeriods,
    fetchNextPage: fetchNextPageActiveExperiencePeriods,
    isFetchingNextPage: isFetchingNextPageActiveExperiencePeriods,
  } = useInfiniteQuery({
    queryKey: ['active-experience-periods', name],
    queryFn: ({ pageParam = 1 }) => getExperiencePeriods({
      currentPage: pageParam,
      pageSize: '10',
      status: 'active',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: dismissingExperiencePeriodsResult,
    isLoading: isDismissingExperiencePeriodsResultLoading,
    hasNextPage: hasNextPageDismissingExperiencePeriods,
    fetchNextPage: fetchNextPageDismissingExperiencePeriods,
    isFetchingNextPage: isFetchingNextPageDismissingExperiencePeriods,
  } = useInfiniteQuery({
    queryKey: ['dismissing-experience-periods', name],
    queryFn: ({ pageParam = 1 }) => getExperiencePeriods({
      currentPage: pageParam,
      pageSize: '10',
      status: 'dismissing',
      name
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })



  const columnsExperiencePeriods = [
    {
      title: 'Período de experiência inicial',
      experiencePeriods: initialExperiencePeriodsResult?.pages.flatMap((page) => page.experiencePeriods) || [],
      experiencePeriodsCount: initialExperiencePeriodsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageInitialExperiencePeriods,
      fetchNextPage: fetchNextPageInitialExperiencePeriods,
      isFetchingNextPage: isFetchingNextPageInitialExperiencePeriods,
      isLoading: isInitialExperiencePeriodsResultLoading,
    },
    {
      title: 'À validar 45 dias',
      experiencePeriods: validating45DaysExperiencePeriodsResult?.pages.flatMap((page) => page.experiencePeriods) || [],
      experiencePeriodsCount: validating45DaysExperiencePeriodsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageValidating45DaysExperiencePeriods,
      fetchNextPage: fetchNextPageValidating45DaysExperiencePeriods,
      isFetchingNextPage: isFetchingNextPageValidating45DaysExperiencePeriods,
      isLoading: isValidating45DaysExperiencePeriodsResultLoading,
    },
    {
      title: 'Período de experiência final',
      experiencePeriods: finalExperiencePeriodsResult?.pages.flatMap((page) => page.experiencePeriods) || [],
      experiencePeriodsCount: finalExperiencePeriodsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageFinalExperiencePeriods,
      fetchNextPage: fetchNextPageFinalExperiencePeriods,
      isFetchingNextPage: isFetchingNextPageFinalExperiencePeriods,
      isLoading: isFinalExperiencePeriodsResultLoading,
    },
    {
      title: 'À validar 90 dias',
      experiencePeriods: validating90DaysExperiencePeriodsResult?.pages.flatMap((page) => page.experiencePeriods) || [],
      experiencePeriodsCount: validating90DaysExperiencePeriodsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageValidating90DaysExperiencePeriods,
      fetchNextPage: fetchNextPageValidating90DaysExperiencePeriods,
      isFetchingNextPage: isFetchingNextPageValidating90DaysExperiencePeriods,
      isLoading: isValidating90DaysExperiencePeriodsResultLoading,
    },
    {
      title: 'Ativos',
      experiencePeriods: activeExperiencePeriodsResult?.pages.flatMap((page) => page.experiencePeriods) || [],
      experiencePeriodsCount: activeExperiencePeriodsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageActiveExperiencePeriods,
      fetchNextPage: fetchNextPageActiveExperiencePeriods,
      isFetchingNextPage: isFetchingNextPageActiveExperiencePeriods,
      isLoading: isActiveExperiencePeriodsResultLoading,
    },
    {
      title: 'Demissão solicitada',
      experiencePeriods: dismissingExperiencePeriodsResult?.pages.flatMap((page) => page.experiencePeriods) || [],
      experiencePeriodsCount: dismissingExperiencePeriodsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageDismissingExperiencePeriods,
      fetchNextPage: fetchNextPageDismissingExperiencePeriods,
      isFetchingNextPage: isFetchingNextPageDismissingExperiencePeriods,
      isLoading: isDismissingExperiencePeriodsResultLoading,
    },

  ]

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Periodos de Experiência</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/periodos-experiencia')}
          />

        </Flex>

      </Flex>
      <ExperiencePeriodTableFilters
        hasStatusFilter={false}
      />
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsExperiencePeriods?.map((experiencePeriod, index) => {
          return (
            <ExperiencePeriodsKanbanColumn
              key={index}
              experiencePeriod={experiencePeriod}
            />
          )
        })}
      </Flex>
    </Box>
  )

}
