import { Box, Heading, VStack, Flex, Text, Spinner } from '@chakra-ui/react'
import { Refund } from 'api/refunds/_types/Refund'
import { useEffect, useRef } from 'react';
import { KanbanRefundCard } from './KanbanRefundCard'

interface RefundKanban {
  title: string
  refunds: Refund[]
  color: string
  hasNextPage?: boolean
  fetchNextPage?: () => void
  isFetchingNextPage?: boolean
  isLoading?: boolean
  refundsCount?: number
}

type KanbanRefundColumnProps = {
  refund: RefundKanban
};

export function KanbanRefundColumn({
  refund
}: KanbanRefundColumnProps) {

  const {
    title,
    refunds,
    color,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refundsCount,
    isLoading
  } = refund

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollContainerRef.current || !hasNextPage || isFetchingNextPage) return;

      const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;

      if (scrollTop + clientHeight >= scrollHeight * 0.9) {

        fetchNextPage?.();
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <Box
      minWidth="350px"
      bg="blackAlpha.100"
      p={4}
      boxShadow="md"
      borderTop="8px solid"
      borderColor={color}
      rounded="md"
      height="76vh"
    >
      <Flex
        justifyContent="space-between"
        mb={4}
        position="sticky"
        top={0}
      >
        <Heading size="sm">
          {title}
        </Heading>
        <Box
          bg="blackAlpha.200"
          h={5}
          w={6}
          alignItems='center'
          justifyContent='center'
          display='flex'
          boxShadow="sm"
          rounded='md'
          borderRight="1px"
          borderColor='blackAlpha.300'
        >
          <Text fontSize="sm" color="gray.800">
            {refundsCount}
          </Text>
        </Box>
      </Flex>
      <VStack
        spacing={4}
        ref={scrollContainerRef}
        overflowY="auto"
        height="calc(100% - 40px)"
      >
        {isLoading && (
          <Flex justifyContent="center" mt={4}>
            <Spinner />
          </Flex>
        )}
        {refunds.map((refund) => {
          return (
            <KanbanRefundCard
              key={refund.id}
              refund={refund}
            />
          )
        })}
        {isFetchingNextPage && (
          <Flex justifyContent="center" mt={4}>
            <Spinner />
          </Flex>
        )}
      </VStack>

    </Box>
  );
}
