import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface EditPeriodsVacationBody {
  periods?: {
    period: number
    startDate: Date
    endDate: Date
  }[] | null
}

interface EditPeriodsVacationProps {
  vacationId: string
  body: EditPeriodsVacationBody
}

export async function editPeriodsVacation({
  vacationId,
  body
}: EditPeriodsVacationProps) {
  try {
    await api.patch(`/period-vacation/${vacationId}`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
