import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createPatrimonyType } from "api/patrimonies/createPatrimonyType";
import { editPatrimonyType } from "api/patrimonies/editPatrimonyType";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"

interface CreateEditPatrimonyTypeProps {
  onCloseModal: () => void
  slug: 'create' | 'edit'
  patrimonyTypeId?: string
  patrimonyType?: string
  isControlledByIdentifier?: boolean
}

interface CreateEditPatrimonyTypeSchema {
  type: string
  isControlledByIdentifier: string
}

const createEditPatrimonyTypeSchema = yup.object({
  type: yup.string().required(),
  isControlledByIdentifier: yup.string().required()
})

export function CreateEditPatrimonyType({
  onCloseModal,
  slug,
  patrimonyTypeId,
  patrimonyType,
  isControlledByIdentifier
}: CreateEditPatrimonyTypeProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const isCreatePatrimonyType = slug === 'create'
  const titleModalPatrimonyType = slug === 'create' ? 'Criar tipo de Patrimônio' : 'Editar tipo de Patrimônio'

  const { mutateAsync: createPatrimonyTypeFn } = useMutation({
    mutationFn: createPatrimonyType,
    onSuccess() {
      queryClient.invalidateQueries(['patrimonyTypes'])
      onCloseModal()
    }
  })

  const { mutateAsync: editPatrimonyTypeFn } = useMutation({
    mutationFn: editPatrimonyType,
    onSuccess() {
      queryClient.invalidateQueries(['patrimonyTypes'])
      onCloseModal()
    }
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateEditPatrimonyTypeSchema>({
    resolver: yupResolver(createEditPatrimonyTypeSchema)
  })

  useEffect(() => {
    if (slug === 'edit') {
      setValue('type', patrimonyType)
      setValue('isControlledByIdentifier', isControlledByIdentifier ? 'yes' : 'no')
    }
  }, [isControlledByIdentifier, patrimonyType, setValue, slug])

  async function handleCreatePatrimonyType({ type, isControlledByIdentifier }: CreateEditPatrimonyTypeSchema) {
    await promiseMessage(createPatrimonyTypeFn({
      body: {
        type,
        isControlledByIdentifier: isControlledByIdentifier === 'yes'
      }
    }), 'Tipo de patrimônio criado! 🎉')
  }

  async function handleEditPatrimonyType({ type, isControlledByIdentifier }: CreateEditPatrimonyTypeSchema) {
    await promiseMessage(editPatrimonyTypeFn({
      body: {
        type,
        isControlledByIdentifier: isControlledByIdentifier === 'yes'
      },
      patrimonyTypeId: patrimonyTypeId
    }), 'Tipo de patrimônio editado! 🎉')
  }

  return (
    <ModalContent>
      <ModalHeader>{titleModalPatrimonyType}</ModalHeader>

      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={isCreatePatrimonyType ?
          handleSubmit(handleCreatePatrimonyType) :
          handleSubmit(handleEditPatrimonyType)
        }
        maxH='600px'
        overflowY='scroll'
      >

        <FormControl isInvalid={!!errors?.type} mt={3}>
          <FormLabel fontSize="sm">
            Tipo de Patrimônio
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('type')}
            name="type"
            size="sm"
            rounded="md"
          />
        </FormControl>
        <FormControl isInvalid={!!errors.isControlledByIdentifier} mt={3}>
          <FormLabel fontSize="sm">
            Controlado por número de patrimônio?
          </FormLabel>
          <Select
            {...register('isControlledByIdentifier')}
            name="isControlledByIdentifier"
            placeholder="Selecione..."
            size="sm"
            rounded="md"
          >
            <option value='yes'>Sim</option>
            <option value='no'>Não</option>
          </Select>
        </FormControl>


        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            {isCreatePatrimonyType ? 'Criar' : 'Editar'}
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
