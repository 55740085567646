import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { VacationStatus } from "./_types/Vacation";

interface EditVacationStatusParams {
  vacationId: string
  status: VacationStatus
}

export async function editVacationStatus({
  vacationId,
  status
}: EditVacationStatusParams) {
  try {
    await api.patch(`vacations/${vacationId}/status`, {
      status
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
