import {
  Button,
  Flex,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Link as ChakraLink,
  FormLabel,
  IconButton
} from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { InputMaskCustom } from "components/Inputs/InputMask";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";


import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";

import { handleSwitchMask } from "utils/handleChangeMask";

import { payment_type } from "utils/customLists";
import { useCurrency } from "react-hook-currency";

import { IIbgeApiCityProps, IIbgeApiStatesProps } from 'services/ibgeApiservice/IIbgeApi';
import { IDriverProps } from "services/getFunctions/driver/getDrivers";
import { FaEye, FaFilePdf, FaPlus, FaTrash } from "react-icons/fa";
import { daysOfWeekOptions } from "utils/CustomLists/daysOfWeekOptions";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { useToastify } from "hooks/toastify/useToastify";
import { useQueryClient } from "react-query";
import { useAggregateFunctions } from "hooks/aggregate/useAggregateFunctions";
import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions";
import { CitiesProps } from "services/getFunctions/city/getCity";
import { statesWithUfList } from "utils/CustomLists/statesWithUfList";

interface DayOfWork {
  day: 0 | 1 | 2 | 3 | 4 | 5 | 6,
  price: string
}

interface AggregateFormProps {
  slug: string
  aggregateId?: string
  defaultDaysOfWork?: DayOfWork[]
  ibgeStates?: IIbgeApiStatesProps[]
  ibgeCities?: IIbgeApiCityProps[]
  aggregateAttachments?: string[]
  driversState: {
    drivers?: IDriverProps[],
    isLoading: boolean
  }
  cities: CitiesProps[]
}

export interface AggregateFormInputs {
  company_name: string
  trading_name: string
  cnpj: string
  driver_id: string
  rg: string
  cellphone: string
  birth_date: string
  cep: string
  street: string
  state: string
  city: string
  neighborhood: string
  number: number
  complement: string
  contract_start_date: string
  payment_type: string
  due_date: number
  price: string
  days_of_work: DayOfWork[],
  attachments: FileList
  rider_backup: 'yes' | 'no'
  observation: string
  bank: string
  type: 'cnpj' | 'cpf'
  cpf: string
  account_type: 'checking-account' | 'savings-account'
  agency: string
  account: string
  cities: {
    cityId: string
    uf: string
    motorcycleValueInCents: string
    carValueInCents: string
  }[]
  route_description: string
}

const typeOptions = [
  { key: '0', value: 'cpf', showOption: 'CPF' },
  { key: '1', value: 'cnpj', showOption: 'CNPJ' },
]

const accountTypeOptions = [
  { key: '0', value: 'checking-account', showOption: 'Conta corrente' },
  { key: '1', value: 'savings-account', showOption: 'Conta poupança' },
]

export function AggregateForm({
  ibgeCities,
  ibgeStates,
  slug,
  defaultDaysOfWork,
  aggregateAttachments,
  aggregateId,
  driversState,
  cities
}: AggregateFormProps) {

  const {
    register,
    control,
    formState: {
      errors,
    }
  } = useFormContext<AggregateFormInputs>()

  const [
    cepForm,
    stateSelected,
    driverSelected,
    attachments,
    type
  ] = useWatch({
    control,
    name: ['cep', 'state', 'driver_id', 'attachments', 'type']
  })

  const {
    fields: daysOfWorkFields,
    append: appendDayOfWorkField,
    remove: removeDayOfWorkField
  } = useFieldArray({
    control,
    name: 'days_of_work'
  })

  const {
    fields: cityFields,
    append: appendCityField,
    remove: removeCityField
  } = useFieldArray({
    control,
    name: 'cities'
  })

  const daysOfWorkSelected = useWatch({
    control,
    name: 'days_of_work'
  })

  const ufsStatesSelected = useWatch({
    control,
    name: 'cities'
  })

  function handleAppendDayOfWorkField() {
    if (daysOfWorkSelected.length && daysOfWorkSelected[0].day !== undefined) {
      const dayOfWorkWitMaxDayOfWeek = daysOfWorkSelected?.reduce((acc, curr) => {
        return Number(acc.day) > Number(curr.day) ? acc : curr
      })

      appendDayOfWorkField({ day: Number(dayOfWorkWitMaxDayOfWeek.day) + 1 as any, price: '0,00' })

    } else {
      appendDayOfWorkField({ day: 1, price: '0,00' })
    }

  }

  function handleRemoveDayOfWorkField(index: number) {
    removeDayOfWorkField(index)
  }

  const driversFilteredActive = driversState.drivers?.filter(driver => driver.situation === 'ATIVO')

  const { onChange, format: currencyFormat } = useCurrency({ style: 'decimal' })

  const isViewPage = slug === "visualizar"

  const selectedDriverInfo = driversState.drivers?.find(driver => {
    return driver.id === driverSelected
  })
  const daysOfWeekOptionsWithDisabledStatus = daysOfWeekOptions?.map(day => {
    const dayOfWorkAlreadySelected = daysOfWorkSelected?.find(dayOfWork => {
      return Number(dayOfWork.day) === day.value
    })

    if (dayOfWorkAlreadySelected) {
      return { ...day, disabled: true };
    }

    return day
  })

  const {
    deleteAggregateAttachment: {
      mutateAsync: deleteAggregateAttachment
    }
  } = useAggregateFunctions()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleDeleteAggregateAttachment(
    aggregateId: string,
    filename: string
  ) {
    await promiseMessage(deleteAggregateAttachment({
      aggregateId,
      filename
    }), 'Anexo removido com sucesso!')

    await queryClient.invalidateQueries(['aggregate'])
  }

  return (
    <Flex direction="column" gap={6} align="center">
      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Input
          {...register('company_name')}
          name="company_name"
          label="Razão Social"
          error={errors.company_name}
          isDisabled={isViewPage}
          required
        />
        <Input
          {...register('trading_name')}
          name="trading_name"
          label="Nome Fantasia"
          error={errors.trading_name}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >

        <Stack
          direction={["column", "column", "row"]}
          spacing={6}
          w="full"
        >
          <Controller
            control={control}
            name="cnpj"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('cnpj')}
                name="cnpj"
                defaultValue={value}
                onChange={onChange}
                mask={handleSwitchMask('cnpj')}
                label="CNPJ"
                error={errors.cnpj}
                isDisabled={isViewPage}
                required
              />
            )}
          />
          <Controller
            control={control}
            name="rg"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('rg')}
                name="rg"
                defaultValue={value}
                onChange={onChange}
                mask={handleSwitchMask('rg')}
                label="RG"
                error={errors.rg}
                isDisabled={isViewPage}
                required
              />
            )}
          />

        </Stack>
        <Controller
          control={control}
          name="cellphone"
          render={({ field: { onChange, value } }) => (
            <InputMaskCustom
              mask={
                handleSwitchMask('cellphone')

              }
              maskChar=""
              alwaysShowMask={false}
              defaultValue={value}
              onChange={onChange}
              name="cellphone"
              label="Celular"
              error={errors.cellphone}
              isDisabled={isViewPage}
              required

            />
          )}
        />
      </Stack>

      <Stack
        direction={["column", "column", "row"]}
        spacing={6}
        w="full"
      >
        <Select
          {...register('driver_id')}
          name="driver_id"
          placeholder="Selecione uma opção..."
          label="Motorista"
          error={errors.driver_id}
          drivers={driversFilteredActive}
          isDisabled={isViewPage || driversState.isLoading}
          required
        />
        <Input
          {...register("birth_date")}
          name="birth_date"
          label="Data de nascimento"
          error={errors.birth_date}
          type="date"
          isDisabled={isViewPage}
          required
        />
      </Stack>
      {driverSelected &&
        <Stack
          direction={["column", "column", "row"]}
          spacing={6}
          w="full"
        >
          <Input
            name="name"
            defaultValue={`${selectedDriverInfo?.firstname} ${selectedDriverInfo?.lastname}`}
            label="Nome"
            isDisabled={true}
            required
          />
          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <Input
              name="email"
              defaultValue={`${selectedDriverInfo?.email ?? '-'}`}
              label="Email"
              isDisabled={true}
              required />
            <Input
              name="cpf"
              defaultValue={`${selectedDriverInfo?.cpf} `}
              label="CPF"
              isDisabled={true}
              required />
          </Stack>
        </Stack>
      }
      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >

        <Controller
          control={control}
          name="cep"
          render={({ field: { onChange, value } }) => (
            <InputMaskCustom
              {...register('cep')}
              name="cep"
              label="CEP"
              error={errors.cep}

              defaultValue={value}
              onChange={onChange}
              mask={handleSwitchMask('cep')}
              isDisabled={isViewPage}
              required
            />
          )}
        />
        <Stack
          w='full'
          spacing={6}
          direction={['column', 'column', 'row']}
        >
          <Select
            {...register("state")}
            name="state"
            label="Estado"
            error={errors.state}
            placeholder="Selecione uma opção..."
            states={ibgeStates}
            isDisabled={isViewPage}
            required

          />
          {cepForm && stateSelected && (
            <Select
              {...register("city")}
              name="city"
              label="Cidade"
              citiesIBGE={ibgeCities}
              placeholder="Selecione uma opção..."
              error={errors.city}
              isDisabled={isViewPage}
              required

            />
          )}
        </Stack>

      </Stack>
      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Input
          {...register('street')}
          name="street"
          label="Rua"
          error={errors.street}
          isDisabled={isViewPage}
          required
        />
        <Input
          {...register('number')}
          name="number"
          label="Número"
          error={errors.number}
          isDisabled={isViewPage}
          required
        />

      </Stack>
      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Input
          {...register("complement")}
          name="complement"
          label="Complemento"
          error={errors.complement}
          isDisabled={isViewPage}
        />
        <Input
          {...register("neighborhood")}
          name="neighborhood"
          label="Bairro"
          error={errors.neighborhood}
          isDisabled={isViewPage}
          required
        />
      </Stack>
      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Input
          {...register("contract_start_date")}
          name="contract_start_date"
          label="Data inicial do contrato"
          error={errors.contract_start_date}
          type="date"
          isDisabled={isViewPage}
          required
        />
        <Select
          {...register('payment_type')}

          name="payment_type"
          label="Tipo de pagamento"
          placeholder="Selecione uma opção..."
          options={payment_type}
          error={errors.payment_type}
          isDisabled={isViewPage}
          required
        />
      </Stack>
      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Input
          {...register("due_date")}
          name="due_date"
          label="Data de vencimento"
          error={errors.due_date}
          type="number"
          isDisabled={isViewPage}
          required
        />
        <Select
          {...register('rider_backup')}
          name='rider_backup'
          options={positiveNegativeOptions}
          label='Motoboy backup?'
          placeholder='Selecione uma opção...'
          error={errors.rider_backup}
          isDisabled={isViewPage}
          required
        />

      </Stack>

      {daysOfWorkFields?.map((field, index) => {
        return (
          <Stack w='full' key={field.id} direction={['column', 'column', 'row']} spacing={6}>
            <Select
              {...register(`days_of_work.${index}.day`)}
              name={`days_of_work.${index}.day`}
              options={daysOfWeekOptionsWithDisabledStatus}
              label='Dia da semana'
              placeholder='Selecione uma opção...'
              error={
                errors?.days_of_work
                  ? errors?.days_of_work[index]?.day
                  : undefined
              }
              isDisabled={isViewPage}
              required
            />
            <Stack w='full' direction={['column', 'column', 'row']}>
              <Input
                {...register(`days_of_work.${index}.price`)}
                name={`days_of_work.${index}.price`}
                label='Preço'
                onChange={onChange}
                defaultValue={currencyFormat('000')}
                placeholder='Selecione uma opção...'
                error={
                  errors?.days_of_work
                    ? errors?.days_of_work[index]?.price
                    : undefined
                }
                isDisabled={isViewPage}
                required
              />

              <Stack>
                <Button
                  mt='8'
                  h='48px'
                  isDisabled={isViewPage}
                  colorScheme='red'
                  onClick={() => handleRemoveDayOfWorkField(index)}
                >
                  <Icon as={FaTrash} />
                </Button>
              </Stack>
            </Stack>
          </Stack>
        )
      })}
      <Stack w='full'>
        <Button
          alignSelf='flex-start'
          isDisabled={isViewPage}
          leftIcon={<Icon as={FaPlus} />}
          onClick={handleAppendDayOfWorkField}
        >
          Adicionar dia
        </Button>
        <Text fontSize='sm' color='red.500'>{errors?.days_of_work
          ? 'Campo obrigatório'
          : undefined}</Text>
      </Stack>
      <Flex
        border="1px solid"
        borderColor="gray.200"
        p="4"
        rounded="lg"
        direction="column"
        w='full'
      >

        {cityFields?.map((field, index) => {
          const citiesByUf = ufsStatesSelected ? cities
            ?.filter(city => city.state === ufsStatesSelected[index]?.uf.toUpperCase()) : []

          return (
            <Stack
              key={field.id}
              spacing={6}
              direction={["column", "column", "row"]}
              w="full"
              mt={5}
            >
              <Stack
                spacing={6}
                direction={["column", "column", "row"]}
                w="full"
              >
                <Select
                  {...register(`cities.${index}.uf`)}
                  name={`cities.${index}.uf`}
                  label="UF "
                  statesWithUf={statesWithUfList}
                  placeholder="Selecione uma opção..."
                  error={errors.cities
                    ? errors?.cities[index]?.uf
                    : undefined
                  }
                  required
                  isDisabled={isViewPage}
                />
                {!!ufsStatesSelected && (
                  <Select
                    {...register(`cities.${index}.cityId`)}
                    name={`cities.${index}.cityId`}
                    label="Cidade Atendida"
                    citiesBack={citiesByUf}
                    placeholder="Selecione uma opção..."
                    error={errors.cities
                      ? errors?.cities[index]?.cityId
                      : undefined
                    }
                    required
                    isDisabled={isViewPage}
                  />
                )}
              </Stack>
              <Stack
                spacing={6}
                direction={["column", "column", "row"]}
                w="full"
              >

                <Input
                  {...register(`cities.${index}.carValueInCents`)}
                  name={`cities.${index}.carValueInCents`}
                  label='Valor carro'
                  onChange={onChange}
                  defaultValue={currencyFormat('000')}
                  placeholder='Selecione uma opção...'
                  error={
                    errors?.cities
                      ? errors?.cities[index]?.carValueInCents
                      : undefined
                  }
                  isDisabled={isViewPage}
                  required
                />
                <Input
                  {...register(`cities.${index}.motorcycleValueInCents`)}
                  name={`cities.${index}.motorcycleValueInCents`}
                  label='Valor moto'
                  onChange={onChange}
                  defaultValue={currencyFormat('000')}
                  placeholder='Selecione uma opção...'
                  error={
                    errors?.cities
                      ? errors?.cities[index]?.motorcycleValueInCents
                      : undefined
                  }
                  isDisabled={isViewPage}
                  required
                />
                {cityFields.length > 1 && (
                  <Flex
                    alignItems='end'
                  >
                    <IconButton
                      aria-label="Remover cidade "
                      icon={<FaTrash />}
                      variant="ghost"
                      onClick={() => removeCityField(index)}
                      size='lg'
                    />
                  </Flex>
                )}
              </Stack>

            </Stack>
          )
        })}
        <Button
          size="sm"
          leftIcon={<FaPlus />}
          colorScheme="blue"
          onClick={() => appendCityField({ uf: '', cityId: '', carValueInCents: '0,00', motorcycleValueInCents: '0,00' })}
          mt="3"
          alignSelf="end"
          isDisabled={isViewPage}
        >
          Adicionar
        </Button>
      </Flex>

      {aggregateAttachments && aggregateAttachments.length > 0 && (
        <TableContainer w='full'>
          <Table size='sm' mt={4}>
            <Thead>
              <Tr>
                <Th>Anexos Cadastrados</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {aggregateAttachments.map((attachment) => {
                return (
                  <Tr key={attachment}>
                    <Th>{attachment} </Th>
                    <Th isNumeric>
                      <Button
                        as={ChakraLink}
                        href={handleChangeUrl(attachment)}
                        variant='ghost'
                        isExternal
                      >
                        <Icon as={FaEye} />
                      </Button>
                      {slug === 'editar' && (
                        <Button
                          variant='ghost'
                          onClick={() => {
                            handleDeleteAggregateAttachment(
                              aggregateId,
                              attachment
                            )
                          }}
                        >
                          <Icon as={FaTrash} />
                        </Button>
                      )}
                    </Th>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register('attachments')}
          name="attachments"
          label="Anexos (Opcional)"
          type="file"
          multiple
          accept=".pdf"
          isDisabled={slug === 'visualizar'}
          hidden

        >

          <Flex w="full" align="center" justify="flex-start">
            <Flex align="center">
              <Button
                isDisabled={slug === 'visualizar'}
                as={FormLabel}
                htmlFor='attachments'
                _hover={{
                  cursor: 'pointer'
                }}
                mr="2"
                leftIcon={<Icon as={FaFilePdf} />}
              >
                Upload
              </Button>
            </Flex>
          </Flex>
        </Input>
      </Stack>

      {attachments && (
        <TableContainer w='full'>
          <Table>
            <Thead>
              <Tr>
                <Th>Anexos</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(attachments).map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Th >{file.name} </Th>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Input
        {...register("bank")}
        name="bank"
        label="Banco"
        error={errors.bank}
        isDisabled={slug === 'visualizar'}
        required
      />

      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Select
          {...register("type")}
          name="type"
          label="Tipo"
          options={typeOptions}
          placeholder="Selecione um tipo..."
          error={errors.type}
          isDisabled={slug === 'visualizar'}
          required
        />

        {type === 'cpf' && (
          <Controller
            control={control}
            name="cpf"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                isDisabled={slug === 'visualizar'}
                name="cpf"
                label='CPF'
                mask={handleSwitchMask('cpf')}
                defaultValue={value}
                onChange={onChange}
                error={errors.cpf}
                required
              />
            )}
          />
        )}
      </Stack>

      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Select
          {...register("account_type")}
          name="account_type"
          label="Tipo de conta"
          options={accountTypeOptions}
          placeholder="Selecione um tipo de conta..."
          error={errors.account_type}
          isDisabled={slug === 'visualizar'}
          required
        />

        <Stack
          spacing={6}
          direction={["column", "column", "row"]}
          w="full"
        >
          <Input
            {...register("agency")}
            name="agency"
            label="Agência"
            error={errors.agency}
            isDisabled={slug === 'visualizar'}
            required
          />
          <Input
            {...register("account")}
            name="account"
            label="Conta"
            error={errors.account}
            isDisabled={slug === 'visualizar'}
            required
          />
        </Stack>
      </Stack>
      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <TextArea
          {...register("route_description")}
          name="route_description"
          label="Descrição da rota"
          error={errors.route_description}
          isDisabled={isViewPage}
        />
      </Stack>

      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >

        <TextArea
          {...register("observation")}
          name="observation"
          label="Observações"
          isDisabled={isViewPage}
        />
      </Stack>
    </Flex>
  )

}


