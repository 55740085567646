import { api } from "services/api"
import axios from "axios"
import { RequestError } from "utils/errors/RequestErrors"
import { ServiceProps } from "contexts/ServiceContext"

interface GetServiceByProtocolParams {
  protocol: number
}

export interface GetServiceByProtocolResponse {

}

export async function getServiceByProtocol({
  protocol
}: GetServiceByProtocolParams) {
  try {
    const { data } = await api.get<ServiceProps[]>(`/consult/service`, {
       params: {
        protocol: protocol
       }
    })

    return data[0]
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }

}
