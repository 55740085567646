import { api } from '../../../../services/api'

interface DestinationAddressesInputAssociationProps {
  address_id: string
  quantity: number
}

export interface GeloSecoAddressesProps {
  address_id: string
}

export type RequestServiceModalOptions = 'AÉREO' | 'RODOVIÁRIO'
export type RequestServiceVehicleOptions = 'MOTO' | 'CARRO' | 'CAMINHÃO'
export type RequestServiceDeadlineOptions = 0 | 1 | 2 | 3 | 4 | 5;
export type RequestServiceServiceTypeOptions = 'FRACIONADO' | 'DEDICADO'

interface RequestServiceInputProps {
  customer_id: string;
  budget_id: string;
  source_address_id: string[]
  destination_address_id: string[]
  deadline: RequestServiceDeadlineOptions
  service_type: RequestServiceServiceTypeOptions
  modal: RequestServiceModalOptions
  vehicle: RequestServiceVehicleOptions
  franchising: number
  caixa_termica: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  embalagem_secundaria: number
  collect_date: Date
  collect_hour_start: Date
  collect_hour_end: Date
  delivery_date: Date
  delivery_hour: Date
  material_type: string
  destination_addresses_input?: DestinationAddressesInputAssociationProps[]
  gelo_seco_addresses?: GeloSecoAddressesProps[]
  owner: string;
  requested_collect_addresses_observations?: {
    address_id: string
    observation: string
  }[]
  observations_for_validation_planning?: string
  observation?: string
}

type ValidateAddressToCollectInputProps = {
  address_id: string
  has_validate: boolean
}

export type AddAddressesToCollectInputProps = {
  service_id: string
  input:{
    collect_add_addresses: string[]
    addressesObservations?: {
      address_id: string
      observation: string
    }[]
  }
}

export type ValidateAddressesToCollectReqProps = {
  service_id: string
  validateAddressToCollectInput: ValidateAddressToCollectInputProps
}

const addAddressToCollectReqFunction = ({
  service_id,
  input
}: AddAddressesToCollectInputProps) =>
  api
    .put(`/requested-service/add-addresses/${service_id}`,
      input
    )
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

const validateAddressToCollectReqFunction = ({
  service_id,
  validateAddressToCollectInput,
}: ValidateAddressesToCollectReqProps) =>
  api
    .put(
      `/requested-service/validate-addresses/${service_id}`,
      validateAddressToCollectInput,
    )
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

async function requestServiceReqFunction(input: RequestServiceInputProps) {
  try {
    const {data} = await api.post('/requested-service', input)
    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export { addAddressToCollectReqFunction, validateAddressToCollectReqFunction, requestServiceReqFunction }
