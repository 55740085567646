import { Button, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deleteActiveFeedback } from "api/feedbacks/deleteActiveFeedback";
import { ActiveFeedback } from "api/feedbacks/_types/ActiveFeedback";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaSearch, FaTrash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { ActiveFeedbackDetails } from "./ActiveFeedbackDetails";

import { ActiveFeedbackStatus } from "./ActiveFeedbackStatus";
import { feedbackQueryKeys } from "./KanbanActiveFeedbackCard";

interface ActiveFeedbackTableRowProps {
  activeFeedback: ActiveFeedback
}

export function ActiveFeedbackTableRow({
  activeFeedback }: ActiveFeedbackTableRowProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()
  const { userLogged } = useAuth()

  const userCanDeleteActiveFeedback = userLogged?.permissions.includes(
    'delete-active-feedback'
  )

  const {
    isOpen: isActiveFeedbackDetailModalOpen,
    onToggle: onToggleActiveFeedbackDetailModal,
  } = useDisclosure()

  const { mutateAsync: deleteActiveFeedbackFn } = useMutation({
    mutationFn: deleteActiveFeedback,
    onSuccess(_data) {
      queryClient.invalidateQueries({ queryKey: 'actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: feedbackQueryKeys[activeFeedback?.status] })
      queryClient.invalidateQueries({ queryKey: ['active-feedback', activeFeedback?.id] })
    },
  })

  async function handleDeleteActiveFeedback() {
    await promiseMessage(deleteActiveFeedbackFn({
      activeFeedbackId: activeFeedback?.id,
    }), 'Feedback excluído com sucesso!')
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Feedback"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onToggleActiveFeedbackDetailModal}
        />
        <Modal
          isOpen={isActiveFeedbackDetailModalOpen}
          onClose={onToggleActiveFeedbackDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <ActiveFeedbackDetails
            activeFeedbackId={activeFeedback.id}
          />
        </Modal>
      </Td>
      <Td> {
        activeFeedback?.cltDriverId ? activeFeedback?.cltDriver?.driver?.firstname : activeFeedback?.internClt ? activeFeedback?.internClt?.name : '-'}</Td>
      <Td>
        <ActiveFeedbackStatus
          status={activeFeedback?.status}
        />
      </Td>
      <Td>{format(new Date(activeFeedback?.deadline), 'dd/MM/yyyy')}</Td>
      <Td>
        {userCanDeleteActiveFeedback && (
          <Button
            size="xs"
            rightIcon={<FaTrash />}
            onClick={handleDeleteActiveFeedback}
            lineHeight={1}
            variant="ghost"
          >
            Excluir
          </Button>
        )}
      </Td>
    </Tr>
  )
}
