import { Box, Divider, Grid, GridItem, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Skeleton, Table, TableContainer, Tbody, Td, Textarea, Th, Thead, Tr } from "@chakra-ui/react"
import { editDocumentField } from "api/documents/editDocumentField"
import { getDocument } from "api/documents/getDocument"
import { GetDocumentsResponse } from "api/documents/getDocuments"
import { format, set } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { FaExternalLinkAlt } from "react-icons/fa"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { documentRequestType } from "utils/documentMappers"
import { documentTypes } from "./CreateDocumentRequest"
import { DocumentStatus } from "./DocumentStatus"
import { documentClassifications, EvaluateDocumentRequest } from "./EvaluateDocumentRequest"
import { ReviewDocumentRequest } from "./ReviewDocumentRequest"

interface DocumentDetailProps {
  documentId: string
}

export function DocumentDetail({ documentId }: DocumentDetailProps) {
  const { userLogged } = useAuth()
  const userCanEditDocument = userLogged?.permissions?.includes('edit-document')

  const userCanReviewDocument = userLogged?.permissions?.includes('review-document')
  const userCanEvaluateDocument = userLogged?.permissions?.includes('evaluate-document')

  const {
    data: documentResult,
    isLoading: isLoadingDocumentResult
  } = useQuery({
    queryKey: ['document', documentId],
    queryFn: () => getDocument({ routeParams: { documentId } })
  })

  const queryClient = useQueryClient()

  const { mutateAsync: editDocumentFieldFn } = useMutation({
    mutationFn: editDocumentField,
    onSuccess: (_data, { routeParams, body }) => {
      queryClient.invalidateQueries({ queryKey: ['document', routeParams.documentId] })
      queryClient.invalidateQueries({ queryKey: 'documents-menu' })

      const cachedAdvertences = queryClient.getQueriesData<GetDocumentsResponse>({
        queryKey: ['documents']
      })

      cachedAdvertences.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          documents: cachedValue.documents.map(document => {
            if (document.id === routeParams.documentId) {
              document[body.field] = body.value

              return { ...document }
            }

            return document
          })
        })
      })
    }
  })

  async function handleEditDocumentFieldFn({
    field,
    value
  }: {
    field: string
    value: string | boolean
  }) {
    await editDocumentFieldFn({
      body: {
        field,
        value
      },
      routeParams: {
        documentId
      }
    })
  }

  const sortedAttachments = documentResult?.document?.documentAttachments?.slice().sort((a, b) => {
    if (a.status === "active" && b.status !== "active") {
      return -1;
    }
    if (a.status !== "active" && b.status === "active") {
      return 1;
    }
    return 0;
  });

  if (isLoadingDocumentResult) {
    return (
      <ModalContent>
        <ModalHeader>
          <Skeleton w="150px" />
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
        </ModalBody>
      </ModalContent>
    )
  }

  return (
    <ModalContent>
      <ModalHeader>
        Detalhes do Documento
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Grid
          templateColumns={
            (userCanEvaluateDocument || userCanReviewDocument) && ['non-evaluated', 'expired'].includes(documentResult?.document.status)
              ? "1fr auto 1fr"
              : "1fr"
          }
          gap={3}
        >
          <GridItem>
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Nome</Td>
                    <Td isNumeric>
                      <Input
                        size="sm"
                        rounded="md"
                        onBlur={async (event) => {
                          if (event.target.value !== documentResult?.document?.name) {
                            await handleEditDocumentFieldFn({
                              field: 'name',
                              value: event.target.value
                            })
                          }
                        }}
                        defaultValue={documentResult?.document?.name}
                        isDisabled={!userCanEditDocument}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo de solicitação</Td>
                    <Td isNumeric>
                      <Select
                        size="sm"
                        rounded="md"
                        onBlur={async (event) => {
                          if (event.target.value !== documentResult?.document?.request_type) {
                            await handleEditDocumentFieldFn({
                              field: 'request_type',
                              value: event.target.value
                            })
                          }
                        }}
                        defaultValue={documentResult?.document?.request_type}
                        isDisabled={!userCanEditDocument}
                      >
                        {Object.entries(documentRequestType).map(([key, value]) => {
                          return (
                            <option key={key} value={key}>{value}</option>
                          )
                        })}
                      </Select>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo do documento</Td>
                    <Td isNumeric>
                      <Select
                        size="sm"
                        rounded="md"
                        onBlur={async (event) => {
                          if (event.target.value !== documentResult?.document?.document_type) {
                            await handleEditDocumentFieldFn({
                              field: 'document_type',
                              value: event.target.value
                            })
                          }
                        }}
                        defaultValue={documentResult?.document?.document_type}
                        isDisabled={!userCanEditDocument}
                      >
                        {Object.entries(documentTypes).map(([key, value]) => {
                          return (
                            <option key={key} value={value}>{value}</option>
                          )
                        })}
                      </Select>
                    </Td>
                  </Tr>

                  {documentResult?.document.classification && (
                    <Tr>
                      <Td>Classificação</Td>
                      <Td isNumeric>
                        <Select
                          size="sm"
                          rounded="md"
                          onBlur={async (event) => {
                            if (event.target.value !== documentResult?.document?.classification) {
                              await handleEditDocumentFieldFn({
                                field: 'classification',
                                value: event.target.value
                              })
                            }
                          }}
                          defaultValue={documentResult?.document?.classification}
                          isDisabled={!userCanEditDocument}
                        >
                          {Object.entries(documentClassifications).map(([key, value]) => {
                            return (
                              <option key={key} value={value}>{value}</option>
                            )
                          })}
                        </Select>
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>Status</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <DocumentStatus status={documentResult?.document.status} />
                    </Td>
                  </Tr>
                  {documentResult?.document.alteration_description && (
                    <Tr>
                      <Td>Descrição da alteração</Td>
                      <Td isNumeric>
                        <Textarea
                          size="sm"
                          rounded="md"
                          onBlur={async (event) => {
                            if (event.target.value !== documentResult?.document?.alteration_description) {
                              await handleEditDocumentFieldFn({
                                field: 'alteration_description',
                                value: event.target.value
                              })
                            }
                          }}
                          defaultValue={documentResult?.document?.alteration_description}
                          isDisabled={!userCanEditDocument}
                        />
                      </Td>
                    </Tr>
                  )}
                  {documentResult?.document?.updatedBy && (
                    <Tr>
                      <Td>Atualizado por</Td>
                      <Td isNumeric>{documentResult?.document?.updatedBy?.firstname} {documentResult?.document?.updatedBy?.lastname}</Td>
                    </Tr>
                  )}
                  {documentResult?.document.update_date && (
                    <Tr>
                      <Td>Data de atualização</Td>
                      <Td isNumeric>
                        <Input
                          type="date"
                          size="sm"
                          rounded="md"
                          onBlur={async (event) => {
                            const [year, month, day] = event.target.value?.split('-').map(Number)
                            const value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

                            if (event.target.value !== documentResult?.document?.update_date) {
                              await handleEditDocumentFieldFn({
                                field: 'update_date',
                                value: value
                              })
                            }
                          }}
                          defaultValue={String(format(new Date(documentResult?.document?.update_date), 'yyyy-MM-dd'))}
                          isDisabled={!userCanEditDocument}
                        />
                      </Td>
                    </Tr>
                  )}
                  {documentResult?.document.next_review_date && (
                    <Tr>
                      <Td>Data da próxima revisão</Td>
                      <Td isNumeric>
                        <Input
                          type="date"
                          size="sm"
                          rounded="md"
                          onBlur={async (event) => {
                            const [year, month, day] = event.target.value?.split('-').map(Number)
                            const value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

                            if (event.target.value !== documentResult?.document?.next_review_date) {
                              await handleEditDocumentFieldFn({
                                field: 'next_review_date',
                                value: value
                              })
                            }
                          }}
                          defaultValue={String(format(new Date(documentResult?.document?.next_review_date), 'yyyy-MM-dd'))}
                          isDisabled={!userCanEditDocument}
                        />
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>URL da página relacionada ao documento</Td>
                    <Td isNumeric>
                      <Input
                        type="text"
                        size="sm"
                        rounded="md"
                        onBlur={async (event) => {
                          const inputedPathname = event.target.value.replace(/\/[a-f0-9-]{36}/, '/:id').replace(/\/\d+/, '/:id')


                          if (inputedPathname !== documentResult?.document?.related_page_pathname) {
                            await handleEditDocumentFieldFn({
                              field: 'related_page_pathname',
                              value: inputedPathname
                            })
                          }
                        }}
                        defaultValue={documentResult?.document.related_page_pathname}
                        isDisabled={!userCanEditDocument}
                      />
                    </Td>
                  </Tr>

                </Tbody>
              </Table>
            </TableContainer>
            {documentResult?.document.documentAttachments?.length > 0 && (
              <TableContainer mt={5}>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Nome do Anexo</Th>
                      <Th>Versão</Th>
                      <Th>Status</Th>
                      <Th>Link</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {sortedAttachments?.map((attachment) => (
                      <Tr key={attachment.id}>
                        <Td>
                          {attachment.attachment.title.length > 20
                            ? attachment.attachment.title.slice(0, 20) + "..."
                            : attachment.attachment.title}
                        </Td>
                        <Td>{attachment.version}</Td>
                        <Td>
                          <DocumentStatus status={attachment.status} />
                        </Td>
                        <Td>
                          <IconButton
                            aria-label="Abrir anexo"
                            as={Link}
                            href={attachment?.attachment.link}
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            isExternal
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </GridItem>
          {(userCanEvaluateDocument || userCanReviewDocument) && (
            <>
              <Box>
                <Divider orientation="vertical" />
              </Box>
              <GridItem>
                {documentResult?.document.status === 'non-evaluated' && (
                  <EvaluateDocumentRequest
                    documentId={documentId}
                  />
                )}
                {documentResult?.document.status === 'expired' && (
                  <ReviewDocumentRequest
                    documentId={documentId}
                  />
                )}
              </GridItem>
            </>
          )}
        </Grid>

      </ModalBody>
    </ModalContent>
  )

}
