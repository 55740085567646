import { usePagination } from "@ajna/pagination"
import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { getRecurrentServiceLogs, RecurrentService } from "api/service/getRecurrentServiceLogs"
import { Pagination } from "components/Pagination/Pagination"
import { BranchProps } from "contexts/BranchContext"
import { format } from "date-fns"
import { Address } from "hooks/address/dtos/Address"
import { Collector } from "hooks/collector/dtos/Collector"
import { useState } from "react"
import { useQuery } from "react-query"

interface RecurrentServiceLogsProps {
  recurrentServiceId: string
  collectors: Collector[]
  branches: BranchProps[]
  addresses: Address[]
}

function fieldsMapper(field: keyof RecurrentService): string {
  switch (field) {
    case 'situation': return 'Situação'
    case 'budgetId': return 'Orçamento'
    case 'customerId': return 'Cliente'
    case 'daysOfWeek': return 'Dias da semana'
    case 'sourceAddressId': return 'Endereço de origem'
    case 'destinationAddressId': return 'Endereço de destino'
    case 'sourceCollectorId': return 'Coletador de origem'
    case 'destinationCollectorId': return 'Coletador de destino'
    case 'providerId': return 'Fornecedor'
    case 'sourceBranchId': return 'Base de origem'
    case 'destinationBranchId': return 'Base de destino'
    case 'deadline': return 'Prazo'
    case 'serviceType': return 'Tipo de serviço'
    case 'franchising': return 'Franquia'
    case 'modal': return 'Modal'
    case 'vehicle': return 'Veículo'
    case 'caixaTermica': return 'Caixa térmica'
    case 'embalagemSecundaria': return 'Embalagem secundária'
    case 'geloSeco': return 'Gelo seco'
    case 'gelox': return 'Gelox'
    case 'isopor3l': return 'Isopor 3L'
    case 'isopor7l': return 'Isopor 7L'
    case 'terciaria3l': return 'Terciária 3L'
    case 'terciaria8l': return 'Terciária 8L'
    case 'collectDate': return 'Data da coleta'
    case 'collectHourStart': return 'Horário inicial da coleta'
    case 'collectHourEnd': return 'Horário final da coleta'
    case 'deliveryDate': return 'Data de entrega'
    case 'deliveryHour': return 'Horário de entrega'
    case 'availabilityForecastDeadline': return 'Prazo previsto para disponibilidade'
    case 'availabilityForecastDay': return 'Data prevista para disponibilidade'
    case 'availabilityForecastTime': return 'Horário previsto para disponibilidade'
    case 'boardHour': return 'Horário do embarque'
    case 'boardDate': return 'Data do embarque'
    case 'plannedFlight': return 'Viagem planejada'
    case 'materialType': return 'Tipo de material'
    case 'destinationAddressesInput': return 'Endereços de destino'
    case 'driverAddressAssign': return 'Endereço do motorista atribuído'
    case 'driverProviderAssign': return 'Provedor do motorista atribuído'
    case 'observation': return 'Observação'
    default:
      return field
  }
}

const daysOfWeek = {
  0: 'Domingo',
  1: 'Segunda-feira',
  2: 'Terça-feira',
  3: 'Quarta-feira',
  4: 'Quinta-feira',
  5: 'Sexta-feira',
  6: 'Sábado',
};

const adjustHour = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  const date = new Date()
  date.setHours(hours - 3, minutes, 0, 0)
  return format(date, "HH:mm")
};
export function RecurrentServiceLogs({
  recurrentServiceId,
  collectors,
  branches,
  addresses
}: RecurrentServiceLogsProps) {
  const [paginationPage, setPaginationPage] = useState(1)


  const {
    data: recurrentServiceLogsResult,
    isLoading: isLoadingRecurrentServiceLogsResult
  } = useQuery({
    queryKey: ['recurrent-service-logs', recurrentServiceId, paginationPage],
    queryFn: () => getRecurrentServiceLogs({
      pageSize: 10,
      currentPage: paginationPage,
      recurrentServiceId
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: recurrentServiceLogsResult?.meta?.totalPages,
    initialState: {
      currentPage: paginationPage,
      pageSize: 10
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    setPaginationPage(page)
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Logs de edição de serviço recorrente
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Table
          size="sm"
        >
          <Thead>
            <Tr>
              <Th color="blackAlpha.600" fontSize="sm" fontWeight="medium" letterSpacing="wide" fontFamily="Roboto" textTransform="none">Data</Th>
              <Th color="blackAlpha.600" fontSize="sm" fontWeight="medium" letterSpacing="wide" fontFamily="Roboto" textTransform="none">Detalhes</Th>
              <Th color="blackAlpha.600" fontSize="sm" fontWeight="medium" letterSpacing="wide" fontFamily="Roboto" textTransform="none">Atualizado por</Th>
            </Tr>
          </Thead>

          <Tbody>
            {isLoadingRecurrentServiceLogsResult ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={String(index)}>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>

                  </Tr>
                )
              })
            ) : (
              recurrentServiceLogsResult?.logs?.map((log) => {
                return (
                  <Tr key={log.id}>
                    <Td>{format(new Date(log.createdAt), "dd/MM/yyyy '-' HH:mm")}</Td>
                    <Td>
                      {Object.entries(log.logDetail).map(([key, value]) => {
                        if (!['destinationAddressesInput', 'protocol', 'address_id', 'driver_id'].includes(key)) {
                          let prevValue = value.prev
                          let newValue = value.new

                          if (key === 'daysOfWeek' && Array.isArray(value.prev) && Array.isArray(value.new)) {
                            prevValue = value.prev.map((day) => daysOfWeek[day]).join(', ')
                            newValue = value.new.map((day) => daysOfWeek[day]).join(', ')
                          }

                          if (['collectHourStart', 'collectHourEnd', 'deliveryHour', 'boardHour'].includes(key)) {
                            prevValue = adjustHour(String(value.prev))
                            newValue = adjustHour(String(value.new))

                          }
                          if (['sourceCollectorId', 'destinationCollectorId'].includes(key)) {
                            prevValue = collectors?.find((collector) => collector.id === value.prev)?.trading_name
                            newValue = collectors?.find((collector) => collector.id === value.new)?.trading_name
                          }
                          if (['sourceBranchId', 'destinationBranchId'].includes(key)) {
                            prevValue = `${branches?.find((branch) => branch.id === value.prev)?.nickname} - ${branches?.find((branch) => branch.id === value.prev)?.shippingIDBranch?.company_name}`
                            newValue = `${branches?.find((branch) => branch.id === value.new)?.nickname} - ${branches?.find((branch) => branch.id === value.new)?.shippingIDBranch?.company_name}`
                          }
                          if (['sourceAddressId', 'destinationAddressId'].includes(key)) {
                            const prevIds = Array.isArray(value.prev) ? value.prev : [value.prev]
                            const newIds = Array.isArray(value.new) ? value.new : [value.new]

                            const prevAddresses = prevIds
                              .map((prevId) => {
                                const address = addresses?.find((addr) => addr.id === prevId)
                                return address
                                  ? `${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
           ${address.complement ?? '-'} | ${address.reference_point ?? '-'} | ${address.cityIDAddress.name} | ${address.cep}`
                                  : null
                              })
                              .filter(Boolean)
                              .join(', ');

                            const newAddresses = newIds
                              .map((newId) => {
                                const address = addresses?.find((addr) => addr.id === newId)
                                return address
                                  ? `${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
           ${address.complement ?? '-'} | ${address.reference_point ?? '-'} | ${address.cityIDAddress.name} | ${address.cep}`
                                  : null
                              })
                              .filter(Boolean)
                              .join(', ')

                            prevValue = prevAddresses
                            newValue = newAddresses
                          }

                          return (
                            <Text key={key}>
                              {fieldsMapper(key as keyof RecurrentService)}: {prevValue} ➡️ {newValue}
                            </Text>
                          )
                        }
                        return null
                      })}

                    </Td>
                    <Td>{log?.updatedBy?.firstname ? `${log?.updatedBy?.firstname} ${log?.updatedBy?.lastname}` : '-'}</Td>
                  </Tr>
                )
              })
            )}
          </Tbody>
        </Table>

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />

      </ModalBody>
    </ModalContent>
  )
}
