import { Flex, Select, Button, Icon, Text, Input } from "@chakra-ui/react";
import { CltIntercurrenceStatus } from "api/cltIntercurrences/types/CltIntercurrence";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface CltIntercurrenceTableFiltersProps {
  hasStatusFilter?: boolean
}

interface CltIntercurrenceTableFilterSchema {
  status: CltIntercurrenceStatus | 'all'
  startDate: string
  endDate: string
  type: string
  collaboratorName: string
}

export function CltIntercurrenceTableFilters({
  hasStatusFilter = true
}: CltIntercurrenceTableFiltersProps) {
  const { replace } = useHistory()
  const searchParams = useSearchParams()

  const status = searchParams.get('status') ?? 'all'
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')
  const type = searchParams.get('startDate')
  const collaboratorName = searchParams.get('endDate')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<CltIntercurrenceTableFilterSchema>({
    defaultValues: {
      status: status as CltIntercurrenceStatus | 'all',
      startDate,
      endDate,
      collaboratorName,
      type
    }
  })

  function handleSearchPmcs({
    status,
    startDate,
    endDate,
    collaboratorName,
    type
  }: CltIntercurrenceTableFilterSchema) {
    if (status) {
      searchParams.set('status', status)
    } else {
      searchParams.delete('status')
    }

    if (startDate) {
      searchParams.set('startDate', startDate)
    } else {
      searchParams.delete('startDate')
    }

    if (endDate) {
      searchParams.set('endDate', endDate)
    } else {
      searchParams.delete('endDate')
    }
    if (collaboratorName) {
      searchParams.set('collaboratorName', collaboratorName)
    } else {
      searchParams.delete('collaboratorName')
    }
    if (type) {
      searchParams.set('type', type)
    } else {
      searchParams.delete('type')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('protocol')
    searchParams.delete('status')
    searchParams.delete('startDate')
    searchParams.delete('endDate')
    searchParams.delete('collaboratorName')
    searchParams.delete('type')

    reset({ status: 'all' })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      as="form"
      align="center"
      direction={["column", "column", "row"]}
      gap="2"
      mt="4"
      onSubmit={handleSubmit(handleSearchPmcs)}
    >
      <Text alignSelf={["flex-start", "flex-start", "center"]} fontSize="sm">
        Filtros:
      </Text>

      <Input
        {...register("collaboratorName")}
        placeholder="Colaborador"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Input
        {...register("startDate")}
        type="date"
        size="sm"
        rounded="md"
        w={{ base: 'full', md: 'fit-content' }}
      />

      <Input
        {...register("endDate")}
        type="date"
        size="sm"
        rounded="md"
        w={{ base: 'full', md: 'fit-content' }}
      />
      <Select
        {...register('type')}
        size="sm"
        borderRadius="md"
        w={["full", "full", "200px"]}
        placeholder='Todos os tipos...'
      >
        <option value="work-accident">Acidente de trabalho</option>
        <option value="miss-work">Falta</option>
        <option value="vacation">Férias</option>
        <option value="home-office">Home office</option>
        <option value="patrimony-without-return">Patrimônio sem devolução</option>
        <option value="extra-hours">HE autorizada</option>
        <option value="turn-change">Troca de turno</option>
        <option value="late-or-early-departure">Atraso ou saída antecipada</option>
        <option value="ticket-log-additional-value">Valor adicional ticket log</option>
        <option value="other">Outra</option>
      </Select>

      {hasStatusFilter && (
        <Select
          {...register('status')}
          defaultValue="all"
          size="sm"
          borderRadius="md"
          w={["full", "full", "200px"]}
        >
          <option value="all">Todos status</option>
          <option value="requested">Solicitado</option>
          <option value="approved">Aprovado</option>
          <option value="rejected">Rejeitado</option>
        </Select>
      )}


      <Button
        type="submit"
        size="sm"
        leftIcon={<Icon as={FaSearch} />}
        w={['full', 'full', 'min']}
      >
        Filtrar resultados
      </Button>

      <Button
        type="submit"
        size="sm"
        variant="outline"
        leftIcon={<Icon as={FaTimes} />}
        w={['full', 'full', 'min']}
        onClick={handleRemoveFilters}
      >
        Remover filtros
      </Button>


    </Flex>
  )
}
