import { Box, Button, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, Link, Select, Stack, Table, TableContainer, Tbody, Text, Textarea, Th, Thead, Tr } from "@chakra-ui/react";
import { getServiceByProtocol } from "api/service/getServiceByProtocol";
import { CustomerProps } from "contexts/CustomerContext";
import { format } from "date-fns";
import { BusinessBugdetRoutesResponse } from "hooks/businessBudget/dtos/BusinessBudgetRoutes";
import { useEffect } from "react";
import { useCurrency } from "react-hook-currency";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FaFileImport } from "react-icons/fa";
import { useQuery } from "react-query";

import { handleChangeUrl } from "utils/handleChangeUrl";

export interface ExtraAndDiscountShema {
  customer_id: string
  type: 'EXTRA' | 'DESCONTO'
  service_type: 'EXPRESS' | 'BUSINESS'
  protocol: number
  route: string
  description: string
  value: string
  attachment: FileList
  occurrence_date: string
  additionalDescriptions: {
    description: string
  }[]
}

interface CustomerExtraAndDiscountFormProps {
  customers: CustomerProps[]
  businessBudgetRoutesData: BusinessBugdetRoutesResponse
  slug: string
  attachmentPreview?: string
}


export function CustomerExtraAndDiscountForm({
  customers,
  businessBudgetRoutesData,
  slug,
  attachmentPreview
}: CustomerExtraAndDiscountFormProps) {

  const isViewPage = slug === "view"
  const isEditPage = slug === 'edit'

  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<ExtraAndDiscountShema>()

  const {
    format: currencyFormat, onChange: onCurrencyChange,
  } = useCurrency({
    style: 'decimal'
  })

  const [serviceType, attachment, protocol] = useWatch({
    control,
    name: ['service_type', 'attachment', 'protocol']
  })

  const isExpressServiceType = serviceType === 'EXPRESS'
  const isBusinessServiceType = serviceType === 'BUSINESS'

  const {
    data: serviceResult
  } = useQuery(['service-by-protocol'], () => getServiceByProtocol({
    protocol: Number(protocol),
  }), {
    enabled: !!protocol,
  })

  useEffect(() => {
    if (Boolean(serviceResult)) {
      const collectDate = format(new Date(serviceResult?.serviceIDRequested?.collect_date), 'yyyy-MM-dd')
      setValue('occurrence_date', collectDate)
    }
  }, [isExpressServiceType, serviceResult, setValue])





  const {
    fields: additionalDescriptionFields,
    append: appendAdditionalDescriptionField
  } = useFieldArray({
    name: 'additionalDescriptions',
  })

  useEffect(() => {
    if (additionalDescriptionFields.length === 0) {
      appendAdditionalDescriptionField({ description: '' });
      appendAdditionalDescriptionField({ description: '' });
    }
  }, [additionalDescriptionFields, appendAdditionalDescriptionField]);

  return (
    <Box w='full'>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.customer_id}>
            <FormLabel fontSize="sm">
              Cliente
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('customer_id')}
              name="customer_id"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
            >
              {customers?.sort((a, b) =>
                a.trading_firstname.localeCompare(b.trading_firstname),
              )?.map(customer => (
                <option value={customer.id} key={customer.id}>
                  {customer.trading_firstname}
                </option>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.type}>
            <FormLabel fontSize="sm">
              Tipo
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('type')}
              name="type"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
            >
              <option value='EXTRA' >EXTRA</option>
              <option value='DESCONTO' >DESCONTO</option>

            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.service_type}>
            <FormLabel fontSize="sm">
              Tipo de serviço
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('service_type')}
              name="service_type"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
            >
              <option value='EXPRESS'>EXPRESS</option>
              <option value='BUSINESS'>BUSINESS</option>

            </Select>
          </FormControl>
        </Stack>

        <Stack
          direction="row"
          w="full"
          spacing="0.25"
          gap={3}
        >
          {isExpressServiceType && (
            <FormControl isInvalid={!!errors.protocol}>
              <FormLabel fontSize="sm">
                Protocolo
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('protocol')}
                name='protocol'
                rounded="md"
                size="sm"
              />
            </FormControl>
          )}
          {serviceType && (
            <FormControl isInvalid={!!errors?.occurrence_date}>
              <FormLabel fontSize="sm">
                {isBusinessServiceType ? 'Data de ocorrência' : 'Data da Coleta'}
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('occurrence_date')}
                name="occurrence_date"
                type="date"
                rounded="md"
                size='sm'
              />
            </FormControl>
          )}
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        {isBusinessServiceType && (
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.route}>
              <FormLabel fontSize="sm">
                Rota
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('route')}
                name="route"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {businessBudgetRoutesData?.routes?.map(route => (
                  <option value={route.route_nickname} key={route.id}>
                    {route.route_nickname}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.value}>
            <FormLabel fontSize="sm">
              Valor
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="value"
              control={control}
              render={({ field }) => {
                return (
                  <InputGroup
                    size="sm"
                  >
                    <InputLeftAddon
                      borderTopLeftRadius="md"
                      borderBottomLeftRadius="md"
                    >
                      R$
                    </InputLeftAddon>
                    <Input
                      {...register("value")}
                      size="sm"
                      rounded="md"
                      w="full"
                      name={field.name}
                      defaultValue={currencyFormat('000')}
                      onChange={e => field.onChange(onCurrencyChange(e))}
                    />
                  </InputGroup>
                )
              }}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.description} mt={3}>
        <FormLabel fontSize="sm">
          Descrição

        </FormLabel>
        <Textarea
          {...register('description')}
          name='description'
          size="sm"
          rounded="md"
          w="full"
        />
      </FormControl>

      {isEditPage && additionalDescriptionFields?.map((field, index) => {
        return (
          <FormControl key={field.id || index} isInvalid={!!errors.description} mt={3}>
            <FormLabel fontSize="sm">
              Descrição
            </FormLabel>
            <Textarea
              {...register(`additionalDescriptions.${index}.description`)}
              name={`additionalDescriptions.${index}.description`}
              size="sm"
              rounded="md"
              w="full"
            />
          </FormControl>

        )
      })}
      <Stack
        direction="column"
        w="full"
        mt="3"
      >
        <Button
          as={FormLabel}
          htmlFor="attachment"
          lineHeight="1"
          leftIcon={<FaFileImport />}
          size="sm"
          w="min"
          cursor="pointer"
          border={!!errors?.attachment && '2px solid'}
          borderColor={!!errors?.attachment && 'red.500'}
        >
          Anexar
        </Button>
        <FormControl isInvalid={!!errors?.attachment}>
          <Input
            {...register('attachment')}
            name="attachment"
            id="attachment"
            type="file"
            hidden
          />
        </FormControl>
      </Stack>


      {Boolean(attachment?.length) && (
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Anexo</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(attachment)?.map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Th >{file.name} </Th>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {(isViewPage || isEditPage) && attachmentPreview && (
        <Link
          href={handleChangeUrl(attachmentPreview)}
          isExternal
        >
          <Text as='b'>Visualizar anexo</Text>
        </Link>
      )}
    </Box>
  )
}
