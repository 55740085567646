import { Flex, FormControl, FormLabel, IconButton, Input, InputGroup, InputLeftAddon, Modal, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { CreateSupport } from "pages/Support/components/CreateSupport";
import { useCurrency } from "react-hook-currency";
import { useFormContext } from "react-hook-form";
import { FaPlus } from "react-icons/fa";

interface PatrimonyWithoutReturnCltIntercurrenceFormSchema {
  discountTotalValue: string
}

export function PatrimonyWithoutReturnCltIntercurrenceForm() {

  const {
    register,
    formState: {
      errors,
    }
  } = useFormContext<PatrimonyWithoutReturnCltIntercurrenceFormSchema>()

  const { format: currencyFormat, onChange: onCurrencyChange } = useCurrency({
    style: 'decimal'
  })

  const {
    isOpen: isCreateSupportOpen,
    onOpen: onOpenCreateSupport,
    onClose: onCloseCreateSupport
  } = useDisclosure()

  return (
    <>
      <FormControl isInvalid={!!errors.discountTotalValue}>
        <FormLabel>
          Valor total do desconto
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <InputGroup>
          <InputLeftAddon>R$</InputLeftAddon>
          <Input
            {...register('discountTotalValue')}
            onChange={onCurrencyChange}
            defaultValue={currencyFormat('000')}
          />
        </InputGroup>
      </FormControl>
      <Flex mt={3} alignItems="baseline" justifyContent="space-between">
        <FormLabel fontSize='sm'>
          Patrimônio sem devolução
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <IconButton
          aria-label="Abrir atendimento"
          icon={<FaPlus />}
          colorScheme="blue"
          size="sm"
          onClick={onOpenCreateSupport} />
      </Flex>
      <Modal
        isOpen={isCreateSupportOpen}
        onClose={onCloseCreateSupport}
        isCentered
        size="2xl"
      >
        <ModalOverlay />
        <CreateSupport
          onClose={onCloseCreateSupport}
          required={true} />
      </Modal>
    </>
  )
}
