import { Box, Button, Divider, Grid, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Skeleton, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react"
import { editExperiencePeriodStatus } from "api/experiencePeriods/editExperiencePeriodStatus"
import { getExperiencePeriod } from "api/experiencePeriods/getExperiencePeriod"
import { addHours, format } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useToastify } from "hooks/toastify/useToastify"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { standardizationMap } from "utils/experiencePeriodMappers"
import { EvaluateExperiencePeriod } from "./EvaluateExperiencePeriod"
import { ExperiencePeriodStatus } from "./ExperiencePeriodStatus"

interface ExperiencePeriodDetailProps {
  experiencePeriodId: string
}

export function ExperiencePeriodDetail({ experiencePeriodId }: ExperiencePeriodDetailProps) {
  const { userLogged } = useAuth()
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const userCanEvaluateExperiencePeriods = userLogged?.permissions?.includes('evaluate-experience-period')
  const userCanEditExperiencePeriodStatus = userLogged?.permissions?.includes('edit-experience-period-status')

  const {
    data: experiencePeriodResult, isLoading: isLoadingExperiencePeriodResult
  } = useQuery({
    queryKey: ['experience-period', experiencePeriodId],
    queryFn: () => getExperiencePeriod({ routeParams: { experiencePeriodId: experiencePeriodId } })
  })

  const userTimezone = new Date().getTimezoneOffset() / 60

  const { mutateAsync: editExperiencePeriodStatusFn } = useMutation({
    mutationFn: editExperiencePeriodStatus,
    onSuccess(_data, { experiencePeriodId }) {
      queryClient.invalidateQueries({ queryKey: ['experience-period', experiencePeriodId] })
      queryClient.invalidateQueries({ queryKey: 'active-experience-periods' })
      queryClient.invalidateQueries({ queryKey: 'experience-periods' })
      queryClient.invalidateQueries({ queryKey: 'dismissing-experience-periods' })

    }
  })

  async function handleEditExperiencePeriodStatus() {
    await promiseMessage(editExperiencePeriodStatusFn({
      status: 'dismissing',
      experiencePeriodId
    }), 'Demissão Solicitada com sucesso')
  }

  if (isLoadingExperiencePeriodResult) {
    return (
      <ModalContent>
        <ModalHeader>
          <Skeleton w="150px" />
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
        </ModalBody>
      </ModalContent>
    )
  }

  return (
    <ModalContent maxW='850px'>
      <ModalHeader>
        Detalhes do Periodo de Experiência
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Grid
          templateColumns={
            userCanEvaluateExperiencePeriods && ['validating-45-days', 'validating-90-days'].includes(experiencePeriodResult?.experiencePeriod?.status)
              ? "1fr auto 1fr"
              : "1fr"
          }
          gap={6}
        >
          <GridItem>
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Nome</Td>
                    <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Data de Admissão</Td>
                    <Td isNumeric>{format(addHours(new Date(experiencePeriodResult?.experiencePeriod?.admission_date), userTimezone), 'dd/MM/yyy')}</Td>
                  </Tr>
                  {experiencePeriodResult?.experiencePeriod?.approved_at_45_days && (
                    <Tr>
                      <Td>Aprovação do período inicial</Td>
                      <Td isNumeric>{format(addHours(new Date(experiencePeriodResult?.experiencePeriod?.approved_at_45_days), userTimezone), 'dd/MM/yyy')}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>45 dias</Td>
                    <Td isNumeric>{format(addHours(new Date(experiencePeriodResult?.experiencePeriod?.period_45_days_end_date), userTimezone), 'dd/MM/yyy')}</Td>
                  </Tr>
                  {experiencePeriodResult?.experiencePeriod?.approved_at_90_days && (
                    <Tr>
                      <Td>Aprovação do período final</Td>
                      <Td isNumeric>{format(addHours(new Date(experiencePeriodResult?.experiencePeriod?.approved_at_90_days), userTimezone), 'dd/MM/yyy')}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>90 dias</Td>
                    <Td isNumeric>{format(addHours(new Date(experiencePeriodResult?.experiencePeriod?.period_90_days_end_date), userTimezone), 'dd/MM/yyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Regional</Td>
                    <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.regional}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <ExperiencePeriodStatus status={experiencePeriodResult?.experiencePeriod.status} />
                    </Td>
                  </Tr>
                  {experiencePeriodResult?.experiencePeriod?.delays_without_sick_note && (
                    <Tr>
                      <Td>Atrasos sem atestado</Td>
                      <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.delays_without_sick_note}</Td>
                    </Tr>
                  )}
                  {experiencePeriodResult?.experiencePeriod?.fouls_with_sick_note && (
                    <Tr>
                      <Td>Faltas com atestado</Td>
                      <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.fouls_with_sick_note}</Td>
                    </Tr>
                  )}
                  {experiencePeriodResult?.experiencePeriod?.has_unjustified_absense && (
                    <Tr>
                      <Td>Falta injustificada</Td>
                      <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.has_unjustified_absense ? 'SIM' : 'NÃO'}</Td>
                    </Tr>
                  )}
                  {experiencePeriodResult?.experiencePeriod?.standardization && (
                    <Tr>
                      <Td>Padronização</Td>
                      <Td isNumeric>{standardizationMap[experiencePeriodResult?.experiencePeriod?.standardization]}</Td>
                    </Tr>
                  )}
                  {experiencePeriodResult?.experiencePeriod?.has_rh_pending_document && (
                    <Tr>
                      <Td>Possui pendência de documentação da qualidade ou RH ? </Td>
                      <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.has_rh_pending_document ? 'SIM' : 'NÃO'}</Td>
                    </Tr>
                  )}
                  {experiencePeriodResult?.experiencePeriod?.has_behavioral_problems && (
                    <Tr>
                      <Td>Teve reclamação do cliente ou problema de postura/atendimento sobre o colaborador ? </Td>
                      <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.has_behavioral_problems ? 'SIM' : 'NÃO'}</Td>
                    </Tr>
                  )}
                  {experiencePeriodResult?.experiencePeriod?.has_behavioral_problems && (
                    <Tr>
                      <Td>Descrição porque a pessoa deve ou não continuar pós período de experiência</Td>
                      <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.considerations}</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </GridItem>
          {userCanEvaluateExperiencePeriods && ['validating-45-days', 'validating-90-days'].includes(experiencePeriodResult?.experiencePeriod?.status) && (
            <>
              <Box>
                <Divider orientation="vertical" />
              </Box>
              <GridItem>
                <EvaluateExperiencePeriod
                  experiencePeriodId={experiencePeriodId}
                />
              </GridItem>
            </>
          )}
        </Grid>
      </ModalBody>
      {experiencePeriodResult?.experiencePeriod?.status === 'active' && userCanEditExperiencePeriodStatus && (
        <ModalFooter>
          <Button
            size="sm"
            colorScheme='blue'
            onClick={handleEditExperiencePeriodStatus}
          >
            Demissão solicitada
          </Button>
        </ModalFooter>
      )}

    </ModalContent>
  )
}
