import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, HStack, IconButton, Modal, ModalOverlay, Skeleton, SkeletonText, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { Regional } from "api/regionals/_types/Regional";
import { getSporadics } from "api/sporadics/getSporadics";
import { Sporadic, SporadicStatus } from "api/sporadics/_types/Sporadic";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { sporadicStatusMap } from "utils/sporadicMappers";
import { CreateSporadic } from "./components/CreateSporadic";
import { SporadicsTableFilter } from "./components/SporadicsTableFilter";
import { SporadicTableRow } from "./components/SporadicTableRow";

const headers = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'SOLICITANTE', key: 'applicant' },
  { label: 'REGIONAL', key: 'regional.name' },
  { label: 'STATUS', key: 'status' },
  { label: 'DATA INICIAL PARA REALIZAÇÃO', key: 'startDate' },
  { label: 'DATA FINAL PARA REALIZAÇÃO', key: 'finalDate' },
  { label: 'VEÍCULO', key: 'vehicle' },
  { label: 'TIPO DE SERVIÇO', key: 'serviceType' },
  { label: 'MOTIVO', key: 'reason' },
  { label: 'CENTRO DE CUSTO', key: 'costCenter' },
  { label: 'E-MAIL DA SOLICITAÇÃO', key: 'emailRequestAttachment' },
  { label: 'RESPONSÁVEL', key: 'responsible' },

]


function formatValuesToReport(
  values: Array<Sporadic & {
    regional: Regional
    emailRequestAttachment: Attachment | null
  }>
) {
  return values?.map((sporadic) => ({
    ...sporadic,
    startDate: sporadic.startDate
      ? format(new Date(sporadic.startDate), 'dd/MM/yyyy')
      : '-',
    finalDate: sporadic.finalDate
      ? format(new Date(sporadic.finalDate), 'dd/MM/yyyy')
      : '-',

    status: sporadicStatusMap[sporadic?.status],
    emailRequestAttachment: Boolean(sporadic?.emailRequestAttachment) ? sporadic?.emailRequestAttachment?.link : '-'
  })
  )
}



export function SporadicsTable() {

  const { userLogged } = useAuth()
  const { replace, push: redirect } = useHistory()

  const userCanViewSporadics = userLogged?.permissions?.includes(
    'view-sporadics'
  )

  const userCanCreateSporadic = userLogged?.permissions?.includes(
    'create-sporadic'
  )

  useEffect(() => {
    if (!userCanViewSporadics) redirect('/')
  }, [redirect, userCanViewSporadics])

  const {
    isOpen: isCreateSporadicOpen,
    onOpen: onOpenCreateSporadic,
    onClose: onCloseCreateSporadic
  } = useDisclosure()



  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const protocol = searchParams.get('protocol')
  const startDate = searchParams.get('startDate')
  const finalDate = searchParams.get('finalDate')

  const {
    data: sporadicsResult,
    isLoading: isLoadingSporadicsResult
  } = useQuery({
    queryKey: [
      'sporadics',
      page,
      status,
      protocol,
      startDate,
      finalDate
    ],
    queryFn: () => getSporadics({
      currentPage: Number(page),
      pageSize: 10,
      status: status as SporadicStatus | 'all',
      protocol,
      startDate,
      finalDate
    }),
    keepPreviousData: true
  })

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: sporadicsDateReportResult,
    isLoading: isLoadingSporadicsDateReportResult
  } = useQuery({
    queryKey: ['sporadics-report',
      page,
      status,
      protocol,
      startDate,
      finalDate,],
    queryFn: () => getSporadics({
      currentPage: 1,
      pageSize: sporadicsResult?.meta?.count,
      protocol,
      startDate,
      finalDate,
      status: ["in-billing", "billed", "canceled"].includes(status) ? status as SporadicStatus : 'all'
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: sporadicsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: sporadicsResult?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const csvReportProps = {
    data: sporadicsDateReportResult ? formatValuesToReport(sporadicsDateReportResult?.sporadics) : [],
    headers,
    filename: `esporadicos.csv`,
  }


  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Esporádicos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/esporadicos/kanban')}
          />
          {userCanCreateSporadic && (
            <IconButton
              aria-label="Criar esporadico"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateSporadic}
            />

          )}

        </Flex>

        <Modal
          isOpen={isCreateSporadicOpen}
          onClose={onCloseCreateSporadic}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreateSporadic onClose={onCloseCreateSporadic} />
        </Modal>
      </Flex>

      <SporadicsTableFilter />
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th></Th>
              <Th color="white">Protocolo</Th>
              <Th color="white">Data Inicial Realização</Th>
              <Th color="white">Data Final Realização</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Status</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoadingSporadicsResult ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={String(index)}>
                    <Td>
                      <HStack spacing={3}>
                        <Skeleton w={8} h={8} />
                        <Skeleton w={8} h={8} />
                      </HStack>
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                  </Tr>
                )
              })
            ) : (
              sporadicsResult?.sporadics?.map((sporadic) => {
                return (
                  <SporadicTableRow key={sporadic?.id} sporadic={sporadic} />
                )
              })
            )}
            { }
          </Tbody>
        </Table>
      </TableContainer>
      {!sporadicsDateReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingSporadicsDateReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
