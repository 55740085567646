import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  Icon,
  Image,
  Alert,
  AlertIcon,
  useMediaQuery,
  FormLabel,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import { FiFile, FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { format } from 'date-fns'

import { ServiceProps } from '../../../contexts/ServiceContext'

import { useService } from '../../../hooks/services/service'
import { useDriver } from '../../../hooks/driver/useDriver'
import { useBranch } from '../../../hooks/branch/useBranch'

import BoardAccordion from '../../../components/Accordions/Service/BoardAccordion/BoardAccordion'


import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { TextArea } from '../../../components/Inputs/TextInput'
import { Select } from '../../../components/Inputs/SelectInput'
import { Input } from '../../../components/Inputs/Input'
import { finishBoardServiceFormatDataToBack } from '../../../utils/ServiceFunctions/Board/boardServiceFormatDataToBack'
import { FaList, FaSave } from 'react-icons/fa'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useBoardServiceFunctions } from '../../../hooks/services/board/useBoardServiceFunctions'
import { useAuth } from '../../../hooks/auth/useAuth'
import { BoardServiceProps } from '../../../services/getFunctions/getBoardServiceFunctions'
import { useAddresses } from '../../../hooks/address/useAddresses'
import { supportedFileFormats, validateHasFile } from 'utils/imageValidation'
import { ExternalComunications } from 'components/ExternalComunications'
import { validateHaveDuplicateFiles } from 'utils/validateHaveDuplicateFiles'
import { useGeolocation } from 'hooks/useGeolocation'
import { toast } from 'react-toastify'

interface FormInputProps {
  branch_id: string
  service_id: string
  driver_id: string
  collector_id: string
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  operational_number: string
  board_volume: number
  cte_photo: FileList
  box_photo: FileList
  is_unsuccess: string
  departure_latitude: string
  departure_longitude: string
  departure_timestamp: string
  board_observation: string
  service_group_id: string
  unsuccess_reason: string
  unsuccess_photo: FileList
}

interface DriverProps {
  id: string
  situation: string
  collector_id: string
  collectorIDDriver: {
    company_name: string
  }
  firstname: string
  lastname: string
  cpf: string
  email: string
  observation: string
}

interface BranchProps {
  id: string
  nickname: string
  shipping_id: string
  email: string
  hub_id: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  observation: string
  shippingIDBranch: {
    id: string
    company_name: string
    trading_name: string
    modal: string
  }
  hubIDBranch: {
    name: string
  }
}

interface QueryParams {
  service_id: string
  slug: string
}

interface DriverFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
}

function validateFileType(file: FileList) {
  if (file?.length) {
    return supportedFileFormats.includes(file[0].type)
  }

  return true
}

const boardFirstSchema = yup.object().shape({
  driver_id: yup.string().required('Motorista inválido'),
})

const userSchema = yup.object().shape({
  driver_id: yup.string().required('Motorista inválido'),
  branch_id: yup.string().required('Campo Obrigatório'),
  is_unsuccess: yup.string().required('Campo obrigatório'),
  board_volume: yup.mixed().when('is_unsuccess', {
    is: 'no',
    then: yup
      .number()
      .positive('Insira um valor positivo!')
      .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
      .typeError('Insira um valor válido!')
      .required('Campo Obrigatório.')
      .typeError('Insira um valor válido!')
      .required('Campo Obrigatório.'),
  }),
  operational_number: yup.string().when('is_unsuccess', {
    is: 'no',
    then: yup
      .string()
      .matches(/^[A-Za-z0-9]+$/, 'Insira somente letras ou números')
      .required('Campo Obrigatório'),
  }),
  cte_photo: yup.mixed().when('is_unsuccess', {
    is: 'no',
    then: yup.mixed().required('Foto Obrigatória')
  }).test("duplicateFiles", "Não é permitido envio de arquivos iguais", async function (value) {
    const filesToCheck = [];

    if (!Array.isArray(value)) {
      filesToCheck.push(Object.assign({}, { attachment: value }));
    }

    if (!Array.isArray(this.parent.box_photo)) {
      filesToCheck.push(Object.assign({}, { attachment: this.parent.box_photo }))
    }

    return !validateHaveDuplicateFiles(...filesToCheck)
  }),
  photo_box: yup.mixed()
    .test("type", "São suportados apenas os formatos de imagem: .jpeg, .pjpeg, jpg, png", value => validateFileType(value)),
  unsuccess_reason: yup.string().when('is_unsuccess', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório')
  }),
  unsuccess_photo: yup.mixed().when('is_unsuccess', {
    is: 'yes',
    then: yup.mixed().test('minLength', 'Campo obrigatório', value => {
      return validateHasFile(value)
    })
  }),
  box_photo: yup.mixed().when('is_unsuccess', {
    is: 'no',
    then: yup.mixed().test('minLength', 'Campo obrigatório', value => {
      return validateHasFile(value)
    }).test("duplicateFiles", "Não é permitido envio de arquivos iguais", async function (value) {
      const filesToCheck = [];

      if (!Array.isArray(value)) {
        filesToCheck.push(Object.assign({}, { attachment: value }));
      }

      if (!Array.isArray(this.parent.cte_photo)) {
        filesToCheck.push(Object.assign({}, { attachment: this.parent.cte_photo }));
      }
      return !validateHaveDuplicateFiles(...filesToCheck)
    })
  }),
  board_observation: yup.string(),
})

const isUnsuccessOptions = [
  { key: '0', value: 'yes', showOption: 'SIM' },
  { key: '1', value: 'no', showOption: 'NÃO' }
]

const unsuccessReasonOptions = [
  {
    key: '0',
    value: 'FALTA DE DOCUMENTAÇÃO / CAIXA FORA DO PADRÃO',
    showOption: 'FALTA DE DOCUMENTAÇÃO / CAIXA FORA DO PADRÃO',
  },
  {
    key: '1',
    value: 'MATERIAL RECUSADO PELA COMPANHIA',
    showOption: 'MATERIAL RECUSADO PELA COMPANHIA',
  },
  {
    key: '2',
    value: 'LOCAL DE EMBARQUE FECHADO',
    showOption: 'LOCAL DE EMBARQUE FECHADO',
  },
]

async function alertGeolocationError(error: GeolocationPositionError) {
  if (error.code === 1) {
    toast.error("Para iniciar/finalizar o embarque, é necessário que a localização esteja habilitada!")
  }
  if (error.code === 2) {
    toast.error("Localização não está disponível! Tente recarregar a página ou verifique as configurações do dispositivo.")
  }
}

export function BoardedServiceSelected({
  id,
  isDisabled = false,
  href,
  title,
  action,
}: DriverFormProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const [sourceBranch, setSourceBranch] = useState<BranchProps[]>([])
  const [destinationBranch, setDestinationBranch] = useState<BranchProps[]>([])
  const [boardServiceStep, setBoardServiceStep] = useState<string[]>([])
  const [sourceDrivers, setSourceDrivers] = useState<DriverProps[]>([])

  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [boardServiceFilteredById, setBoardServiceFilteredById] = useState<
    BoardServiceProps[]
  >([])
  const [serviceFilteredById, setServiceFilteredById] = useState<
    ServiceProps[]
  >([])

  const { userLogged } = useAuth()
  const { service_id }: QueryParams = useParams()
  const { confirmMessage, standardMessage } = useSwal()
  const { push: redirectTo } = useHistory()
  const location = useLocation()
  const state = location.state as { path?: string }
  const hasPathRedirect = state?.path !== undefined && state?.path !== null

  const { position, error: geolocationError } = useGeolocation()

  const {
    createBoardService: { mutateAsync: createBoardService },
    finishBoardService: { mutateAsync: finishBoardService },
    setUnsuccessBoardService: { mutateAsync: setUnsuccessBoardService }
  } = useBoardServiceFunctions()

  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false, false)
  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)
  const {
    drivers: { data: drivers, isLoading: isDriverLoading },
  } = useDriver(null, true, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    defaultValues: {
      is_unsuccess: 'no'
    },
    resolver:
      boardServiceStep.length === 0
        ? yupResolver(boardFirstSchema)
        : yupResolver(userSchema),
  })


  const isDriverSelected = watch('driver_id')
  const branchId = watch('branch_id')

  const boxPhoto = useWatch({
    control,
    name: 'box_photo'
  })

  const ctePhoto = useWatch({
    control,
    name: 'cte_photo'
  })

  const boxPhotoPreview = boxPhoto
    ? Object.entries(boxPhoto).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const ctePhotoPreview = ctePhoto
    ? Object.entries(ctePhoto).map(([key, value]) => URL.createObjectURL(value))[0]
    : null


  const serviceHasCrossdocking = serviceById?.serviceIDRequested?.crossdocking_collector_id !== null

  const crossdockingBoardAlreadyFinished = serviceHasCrossdocking ? Boolean(serviceById?.serviceIDBoard.find(board => {
    return board.step === 'DONE' && board.collector_id === serviceById?.serviceIDRequested.crossdocking_collector_id
  })) : false



  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        const serviceFiltered = []
        serviceFiltered.push(serviceById)
        setServiceFilteredById(serviceFiltered)

        if (serviceById.serviceIDBoard !== null) {
          setBoardServiceFilteredById(serviceById.serviceIDBoard)
        }
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    function run() {
      if (
        !isServiceLoading &&
        !isDriverLoading &&
        !isBranchLoading &&
        !isAddressesLoading
      ) {
        setIsLoadingPage(false)
      }
    }
    run()
  }, [isServiceLoading, isDriverLoading, isBranchLoading, isAddressesLoading])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('add-boarding-service')) {
            redirectTo('/')
          }
        }
      } catch {
        redirectTo('/')
      }
    }
    run()
  }, [redirectTo, userLogged])


  useEffect(() => {
    async function run() {
      if (branchId && serviceFilteredById) {
        const boardServiceStepByBranchId = boardServiceFilteredById
          .filter((boardService) => boardService.branch_id === branchId)
          .filter((boardService) => boardService.step !== 'DONE')
          .map((boardService) => boardService.step)

        const boardServiceByBranchId = boardServiceFilteredById.filter(
          (boardService) => boardService.branch_id === branchId,
        )

        setBoardServiceStep(boardServiceStepByBranchId)


        if (!boardServiceStepByBranchId[0]) {
          setValue(
            'arrival_timestamp',
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
          )
          setValue('service_id', service_id)

          if (geolocationError) {
            return alertGeolocationError(geolocationError)
          }

          if (position) {
            setValue('arrival_latitude', String(position?.coords.latitude))
            setValue('arrival_longitude', String(position?.coords.longitude))
          }

        } else {
          if (boardServiceStepByBranchId[0] === 'GOING' && drivers) {
            const arrivalLongitude = String(
              boardServiceByBranchId.map((service) => service.arrival_longitude),
            )
            const arrivalTimestamp = String(
              boardServiceByBranchId.map((service) => service.arrival_timestamp),
            )
            const arrivalLatitude = String(
              boardServiceByBranchId.map((service) => service.arrival_latitude),
            )
            const driverBySelectedBoardService = String(
              boardServiceByBranchId.map((service) => service.driver_id),
            )

            const driverFiltered = String(
              drivers
                .filter((driver) => driver.id === driverBySelectedBoardService)
                .map((driver) => driver.id),
            )

            if (sourceDrivers.length > 0) {
              setValue('driver_id', driverFiltered)
            }

            setValue('arrival_longitude', arrivalLongitude)
            setValue('arrival_timestamp', arrivalTimestamp)
            setValue('arrival_latitude', arrivalLatitude)
            setValue('service_id', service_id)
            setValue(
              'departure_timestamp',
              format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
            )
            if (geolocationError) {
              return alertGeolocationError(geolocationError)
            }
            if (position) {
              setValue('departure_longitude', String(position?.coords.longitude))
              setValue('departure_latitude', String(position?.coords.latitude))
            }

          }
        }
      }
    }
    run()
  }, [
    boardServiceFilteredById,
    serviceFilteredById,
    branchId,
    service_id,
    sourceDrivers,
    setValue,
    drivers,
    position,
    geolocationError
  ])

  useEffect(() => {
    function run() {
      const hasCrossdockingCollectorId = serviceFilteredById.map(
        (service) => service.serviceIDRequested.crossdocking_collector_id,
      )[0]
      const sourceBranchID = serviceFilteredById.map(
        (service) => service.serviceIDRequested.source_branch_id,
      )[0]
      const destinationBranchID = serviceFilteredById.map(
        (service) => service.serviceIDRequested.destination_branch_id,
      )[0]
      const sourceCrossdockingBranchID = serviceFilteredById.map(
        (service) => service.serviceIDRequested.source_crossdocking_branch_id,
      )[0]
      if (!hasCrossdockingCollectorId) {
        const sourceBranchFiltered = branches
          ? branches.filter((branch) => branch.id === sourceBranchID)
          : []
        const destinationBranchFiltered = branches
          ? branches.filter((branch) => branch.id === destinationBranchID)
          : []
        const branchId = String(sourceBranchFiltered.map((branch) => branch.id))
        const sourceCollectorId = String(
          serviceFilteredById.map(
            (service) => service.serviceIDRequested.source_collector_id,
          ),
        )
        setSourceBranch(sourceBranchFiltered)
        setDestinationBranch(destinationBranchFiltered)
        setValue('branch_id', branchId)
        setValue('collector_id', sourceCollectorId)
        if (drivers) {
          if (userLogged?.user_type === 'MOTORISTA') {
            const driverSelected = drivers
              .filter((driver) => driver.collector_id === sourceCollectorId)
              .filter((driver) => driver.id === userLogged.driver_id)
            setSourceDrivers(driverSelected)
          } else {
            const driverSelected = drivers.filter(
              (driver) => driver.collector_id === sourceCollectorId,
            )
            setSourceDrivers(driverSelected)
          }
        }
      } else {
        // const boardServiceWithCrossdockingBranch =
        //   boardServiceFilteredById.filter(
        //     (boardService) => boardService.branch_id === sourceBranchID,
        //   )

        // const boardServiceCrossdockingBranchStep =
        //   boardServiceWithCrossdockingBranch.map((service) => service.step)

        const boardServiceWithCrossdocking = serviceById?.serviceIDBoard.filter(board => {
          return board.collector_id === serviceById?.serviceIDRequested.crossdocking_collector_id
        })

        const crossdockingBoardFinished = serviceById?.serviceIDBoard.find(board => {
          return board.step === 'DONE' && board.collector_id === serviceById?.serviceIDRequested.crossdocking_collector_id
        })

        if (
          boardServiceWithCrossdocking.length === 0 ||
          boardServiceWithCrossdocking[0].step === 'GOING'
        ) {
          const destinationCrossdockingBranchOfServiceID = String(
            serviceFilteredById.map(
              (service) =>
                service.serviceIDRequested.destination_crossdocking_branch_id,
            ),
          )
          const sourceBranch = branches
            ? branches.filter((branch) => branch.id === sourceBranchID)
            : []
          const destinationCrossdockingBranch = branches
            ? branches.filter(
              (branch) =>
                branch.id === destinationCrossdockingBranchOfServiceID,
            )
            : []
          const branchId = String(sourceBranch.map((branch) => branch.id))
          const crossdockingCollectorId = String(
            serviceFilteredById.map(
              (service) => service.serviceIDRequested.crossdocking_collector_id,
            ),
          )
          const sourceCollectorId = String(
            serviceFilteredById.map(
              (service) => service.serviceIDRequested.source_collector_id,
            ),
          )
          setSourceBranch(sourceBranch)
          setDestinationBranch(destinationCrossdockingBranch)
          setValue('branch_id', branchId)
          setValue('collector_id', crossdockingCollectorId)
          if (drivers) {
            if (userLogged?.user_type === 'MOTORISTA') {
              const driverSelected = drivers
                .filter((driver) => driver.collector_id === sourceCollectorId)
                .filter((driver) => driver.id === userLogged.driver_id)
              setSourceDrivers(driverSelected)
            } else {
              const driverSelected = drivers.filter(
                (driver) => driver.collector_id === sourceCollectorId,
              )
              setSourceDrivers(driverSelected)
            }
          }
        } else {
          const sourceCrossdockingBranchFiltered = branches
            ? branches.filter(
              (branch) => branch.id === sourceCrossdockingBranchID,
            )
            : []
          const destinationBranch = branches
            ? branches.filter((branch) => branch.id === destinationBranchID)
            : []
          const branchId = String(
            sourceCrossdockingBranchFiltered.map((branch) => branch.id),
          )
          const crossdockingCollectorId = String(
            serviceFilteredById.map(
              (service) => service.serviceIDRequested.crossdocking_collector_id,
            ),
          )
          const sourceCollectorId = String(
            serviceFilteredById.map(
              (service) => service.serviceIDRequested.source_collector_id,
            ),
          )
          setSourceBranch(sourceCrossdockingBranchFiltered)
          setDestinationBranch(destinationBranch)
          setValue('branch_id', branchId)
          setValue('collector_id', sourceCollectorId)
          setValue("board_volume", crossdockingBoardFinished?.board_volume)
          if (drivers) {
            if (userLogged?.user_type === 'MOTORISTA') {
              const driverSelected = drivers
                .filter(
                  (driver) => driver.collector_id === crossdockingCollectorId,
                )
                .filter((driver) => driver.id === userLogged.driver_id)
              setSourceDrivers(driverSelected)
            } else {
              const driverSelected = drivers.filter(
                (driver) => driver.collector_id === crossdockingCollectorId,
              )
              setSourceDrivers(driverSelected)
            }
          }
        }
      }
    }

    run()
  }, [setValue, branches, drivers, userLogged, serviceFilteredById, boardServiceFilteredById, crossdockingBoardAlreadyFinished, serviceById])


  async function handleCreateBoardService(values: FormInputProps) {
    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }

    const hasCreateBoardService = await confirmMessage({
      title: 'Deseja iniciar um embarque?',
    })

    const createBoardServiceValues = {
      ...values,
    }

    if (hasCreateBoardService) {
      setIsLoading(true)
      try {
        const createBoardServiceResponse = await createBoardService({
          ...createBoardServiceValues,

        })
        if (createBoardServiceResponse && userLogged) {
          redirectTo({
            pathname: `/servicos/embarques/${service_id}`,
            state: {
              path: state?.path
            }
          })
          setIsLoading(false)
        }
      } catch (err) {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleFinishBoardService(values: FormInputProps) {
    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }

    const hasEditBoardService = await confirmMessage({
      title: 'Deseja finalizar o embarque?',
    })

    if (hasEditBoardService) {
      setIsLoading(true)
      try {
        const formData = finishBoardServiceFormatDataToBack(values)
        const finishBoardServiceValues = {
          serviceID: service_id,
          finishBoardServiceInput: formData as any,
        }
        const editBoardedServiceResponse = await finishBoardService({
          ...finishBoardServiceValues,
        })

        if (editBoardedServiceResponse && userLogged) {
          if (hasPathRedirect) {
            redirectTo(state?.path)
          } else {
            redirectTo('/servicos/embarques')
          }
        }
      } catch (err) {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleSetUnsuccessBoardService(values: FormInputProps) {
    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }

    const hasSetUnsuccessBoardService = await confirmMessage({
      title: 'Deseja finalizar o embarque como sem sucesso?',
    })

    if (hasSetUnsuccessBoardService) {
      const formData = new FormData()
      formData.append('unsuccess_reason', values.unsuccess_reason)
      formData.append('unsuccess_photo', values.unsuccess_photo[0])
      formData.append('board_observation', values.board_observation)
      formData.append('departure_latitude', values.departure_latitude)
      formData.append('departure_longitude', values.departure_longitude)
      formData.append('departure_timestamp', values.departure_timestamp)

      await setUnsuccessBoardService({
        id: boardServiceFilteredById[0].id,
        formData
      }, {
        onSuccess: () => {
          if (hasPathRedirect) {
            redirectTo(state?.path)
          } else {
            redirectTo('/servicos/embarques')
          }
        }
      })
    }
  }

  const isUnsuccessBoard = useWatch({
    control,
    name: 'is_unsuccess'
  })

  const unsuccessPhoto = useWatch({
    control,
    name: 'unsuccess_photo'
  })

  const unsuccessPhotoPreview = unsuccessPhoto
    ? Object.entries(unsuccessPhoto).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const {
    isOpen: isExternalComunicationModalOpen,
    onOpen: onOpenExternalComunicationModal,
    onClose: onCloseExternalComunicationModal,
  } = useDisclosure()

  if (isLoading || isLoadingPage) {
    return <GeneralContentLoading />
  }

  if (boardServiceStep.length === 0) {
    return (
      <>
        {(userLogged?.user_type === 'COLETADOR' || userLogged?.user_type === 'MOTORISTA') && (
          <ExternalComunications.TriggerButton
            aria-label="help-me"
            onClick={onOpenExternalComunicationModal}
          />
        )}

        <ExternalComunications.Root
          isOpen={isExternalComunicationModalOpen}
          onClose={onCloseExternalComunicationModal}
        >
          <ExternalComunications.Content
            serviceId={service_id}
            onClose={onCloseExternalComunicationModal}
          />
        </ExternalComunications.Root>

        <Flex>
          <Box
            as="form"
            flex="1"
            borderRadius="8px"
            bg="white"
            p={['6', '8']}
            onSubmit={handleSubmit(handleCreateBoardService)}
            noValidate
          >
            <Flex
              align="center"
              justify="space-between"
              direction={['column', 'column', 'row']}
            >
              <Heading size="lg" fontFamily="poppins">
                Iniciar Embarque
              </Heading>
            </Flex>

            <Divider my="6" borderColor="gray.700" />

            {branchId && serviceById && addresses && branches && (
              <BoardAccordion
                addresses={addresses}
                serviceByID={serviceById}
                branchId={branchId}
                branches={branches}
              />
            )}

            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'column']}
            >
              <Stack>
                {sourceBranch.map((branch) => (
                  <Input
                    key={branch.id}
                    defaultValue={branch.nickname.toUpperCase()}
                    isDisabled={true}
                    name="source_branch_firstname"
                    label="Base Origem"
                  />
                ))}
                {destinationBranch.map((branch) => (
                  <Input
                    key={branch.id}
                    defaultValue={branch.nickname.toUpperCase()}
                    isDisabled={true}
                    name="destination_branch_firstname"
                    label="Base Destino"
                  />
                ))}
              </Stack>

              <Select
                {...register('driver_id')}
                name="driver_id"
                label="Motorista Responsável"
                error={errors.driver_id}
                drivers={sourceDrivers}
                isDisabled={isDisabled}
                placeholder="Selecione uma opção..."
                required
              />
              {isDriverSelected && (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                >
                  Iniciar Embarque
                </Button>
              )}
            </Stack>

            <Flex mt="8" justify="flex-end">
              <HStack>
                <Link to="/servicos/embarques">
                  <Button type="button" colorScheme="gray">
                    Lista de Serviços à Embarcar
                  </Button>
                </Link>
              </HStack>
            </Flex>
          </Box>
        </Flex>
      </>
    )
  } else {
    return (
      <>

        {(userLogged?.user_type === 'COLETADOR' || userLogged?.user_type === 'MOTORISTA') && (
          <ExternalComunications.TriggerButton
            aria-label="help-me"
            onClick={onOpenExternalComunicationModal}
          />
        )}

        <ExternalComunications.Root
          isOpen={isExternalComunicationModalOpen}
          onClose={onCloseExternalComunicationModal}
        >
          <ExternalComunications.Content
            serviceId={service_id}
            onClose={onCloseExternalComunicationModal}
          />
        </ExternalComunications.Root>

        <Flex>
          <Box
            as="form"
            flex="1"
            borderRadius="8px"
            bg="white"
            p={['6', '8']}
            onSubmit={handleSubmit(isUnsuccessBoard === 'yes' ? handleSetUnsuccessBoardService : handleFinishBoardService)}
            noValidate
          >
            <Heading size="lg" fontFamily="poppins">
              {title}
            </Heading>

            <Divider my="6" borderColor="gray.700" />

            {branchId && serviceById && addresses && branches && (
              <BoardAccordion
                addresses={addresses}
                serviceByID={serviceById}
                branchId={branchId}
                branches={branches}
              />
            )}

            <Alert status="info">
              <AlertIcon />
              Embarque Iniciado!
            </Alert>

            <Divider my="6" borderColor="gray.700" />

            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                {sourceBranch.map((branch) => (
                  <Input
                    key={branch.id}
                    defaultValue={branch.nickname.toUpperCase()}
                    isDisabled={true}
                    name="source_branch_firstname"
                    label="Base Origem"
                  />
                ))}
                {destinationBranch.map((branch) => (
                  <Input
                    key={branch.id}
                    defaultValue={branch.nickname.toUpperCase()}
                    isDisabled={true}
                    name="destination_branch_firstname"
                    label="Base Destino"
                  />
                ))}
              </Stack>

              <Select
                {...register('driver_id')}
                name="driver_id"
                label="Motorista Responsável"
                error={errors.driver_id}
                drivers={sourceDrivers}
                isDisabled={isDisabled}
                placeholder="Selecione uma opção..."
                required
              />
            </Stack>
            <Stack
              mt='4'
            >
              <Select
                {...register('is_unsuccess')}
                name='is_unsuccess'
                placeholder='Selecione uma opção...'
                label='Embarque sem sucesso?'
                options={isUnsuccessOptions}
                required
              />
            </Stack>

            {isUnsuccessBoard === 'no' ? (
              <>
                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  <Stack w="100%" spacing={6} direction={{ base: 'column', lg: 'row' }}>
                    <Input
                      {...register('board_volume')}
                      name="board_volume"
                      label="Volume do Serviço"
                      error={errors.board_volume}
                      isDisabled={crossdockingBoardAlreadyFinished}
                      required
                    />

                    <Input
                      name="collects_total_volumes"
                      label="Volume total de coletas"
                      defaultValue={serviceById?.collects_total_volumes ?? '-'}
                      isDisabled={true}
                      required
                    />
                  </Stack>
                  <Input
                    {...register('operational_number')}
                    name="operational_number"
                    label="Rastreador"
                    error={errors.operational_number}
                    isDisabled={isDisabled}
                    required
                  />
                </Stack>

                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  <Stack w="full" spacing="24px">
                    <Input
                      {...register("cte_photo")}
                      name="cte_photo"
                      type="file"
                      accept={supportedFileFormats.join(", ")}
                      hidden
                      label="Foto do CTE"
                      error={errors.cte_photo}
                      required
                      multiple={false}
                    >
                      <Button
                        leftIcon={<Icon as={FiFile} />}
                        as={FormLabel}
                        htmlFor="cte_photo"
                        _hover={{
                          cursor: "pointer"
                        }}
                      >
                        Upload
                      </Button>
                    </Input>
                    {!!ctePhotoPreview && (
                      <Image
                        objectFit="contain"
                        width="200px"
                        src={ctePhotoPreview}
                      />
                    )}
                  </Stack>
                  <Stack w="full" spacing="24px">
                    <Input
                      {...register("box_photo")}
                      name="box_photo"
                      type="file"
                      accept={supportedFileFormats.join(", ")}
                      hidden
                      label="Foto das caixas"
                      error={errors.box_photo}
                      required
                      multiple={false}
                    >
                      <Button
                        leftIcon={<Icon as={FiFile} />}
                        as={FormLabel}
                        htmlFor="box_photo"
                        _hover={{
                          cursor: "pointer"
                        }}
                      >Escolha uma imagem</Button>
                    </Input>
                    {boxPhotoPreview && (
                      <Image
                        objectFit="contain"
                        width="200px"
                        src={boxPhotoPreview}
                      />
                    )}
                  </Stack>

                </Stack>
              </>
            ) : (
              <>
                <Stack mt='4'>
                  <Select
                    {...register('unsuccess_reason')}
                    name='unsuccess_reason'
                    label='Motivo'
                    required
                    placeholder='Selecione uma opção...'
                    error={errors.unsuccess_reason}
                    options={unsuccessReasonOptions}
                  />
                </Stack>

                <Stack w="full" mt='4' spacing="24px">
                  <Input
                    {...register("unsuccess_photo")}
                    name="unsuccess_photo"
                    type="file"
                    accept={supportedFileFormats.join(", ")}
                    hidden
                    label="Recusa do embarque (foto)"
                    error={errors.unsuccess_photo}
                    required
                    multiple={false}
                  >
                    <Button
                      leftIcon={<Icon as={FiFile} />}
                      as={FormLabel}
                      htmlFor="unsuccess_photo"
                      _hover={{
                        cursor: "pointer"
                      }}
                    >Escolha uma imagem</Button>
                  </Input>
                  {unsuccessPhotoPreview && (
                    <Image
                      objectFit="contain"
                      width="300px"
                      src={unsuccessPhotoPreview}
                    />
                  )}
                </Stack>
              </>
            )}

            <TextArea
              {...register('board_observation')}
              name="board_observation"
              label="Observações"
              isDisabled={isDisabled}
            />

            <Flex mt="8" justify="flex-end">
              <Flex w="full" justifySelf="center">
                {/* {userLogged?.permissions.includes("add-occurrence") && ( */}
                <Link
                  to={{
                    pathname: '/ocorrencia/criar',
                    state: {
                      service_id,
                    },
                  }}
                >
                  <Button colorScheme="gray" rightIcon={<Icon as={FiPlus} />}>
                    {isWideVersion ? 'Adicionar Ocorrência' : 'Ocorrência'}
                  </Button>
                </Link>
                {/* )} */}
              </Flex>

              <Stack spacing="12px" direction="row">
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {isWideVersion ? 'Finalizar Embarque' : <Icon as={FaSave} />}
                </Button>

                <Link to="/servicos/embarques">
                  <Button type="button" colorScheme="gray">
                    {isWideVersion ? (
                      'Lista de Serviços à Embarcar'
                    ) : (
                      <Icon as={FaList} />
                    )}
                  </Button>
                </Link>
              </Stack>
            </Flex>
          </Box>
        </Flex>
      </>
    )
  }
}
