import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { getTrainings } from "api/trainings/getTrainings";
import { TrainingCollaboratorTypes, TrainingTypes } from "api/trainings/training";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateTraining } from "./components/CreateTraining";
import { TrainingsKanbanColumn } from "./components/TrainingsKanbanColumn";
import { TrainingsTableFilter } from "./components/TrainingsTableFilter";

export function TrainingsKanban() {
  const { push: redirect } = useHistory()


  const { userLogged } = useAuth()
  const searchParams = useSearchParams()

  const userHasViewTrainingsPermission = userLogged?.permissions.includes('view-trainings')

  const userHasCreateTrainingPermission = userLogged?.permissions.includes('create-training')

  const collaborator = searchParams.get('collaborator')
  const collaboratorType = searchParams.get('collaboratorType') ?? 'all'
  const type = searchParams.get('type') ?? 'all'

  useEffect(() => {
    if (!userHasViewTrainingsPermission) {
      redirect('/')
    }
  }, [userHasViewTrainingsPermission, redirect])

  const {
    isOpen: isCreateTrainingModalOpen,
    onOpen: onOpenCreateTrainingModal,
    onClose: onCloseCreateTrainingModal
  } = useDisclosure()


  const {
    data: pendingTrainingsResult,
    isLoading: isPendingTrainingsResultLoading,
    hasNextPage: hasNextPagePendingTrainings,
    fetchNextPage: fetchNextPagePendingTrainings,
    isFetchingNextPage: isFetchingNextPagePendingTrainings,
  } = useInfiniteQuery({
    queryKey: ['pending-trainings', collaborator, collaboratorType, type],
    queryFn: ({ pageParam = 1 }) => getTrainings({
      currentPage: pageParam,
      pageSize: '10',
      status: 'pending',
      collaborator,
      collaboratorType: collaboratorType as TrainingCollaboratorTypes | 'all',
      type: type as TrainingTypes | 'all'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: activeTrainingsResult,
    isLoading: isActiveTrainingsResultLoading,
    hasNextPage: hasNextPageActiveTrainings,
    fetchNextPage: fetchNextPageActiveTrainings,
    isFetchingNextPage: isFetchingNextPageActiveTrainings,
  } = useInfiniteQuery({
    queryKey: ['active-trainings', collaborator, collaboratorType, type],
    queryFn: ({ pageParam = 1 }) => getTrainings({
      currentPage: pageParam,
      pageSize: '10',
      status: 'active',
      collaborator,
      collaboratorType: collaboratorType as TrainingCollaboratorTypes | 'all',
      type: type as TrainingTypes | 'all'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: inactiveTrainingsResult,
    isLoading: isInactiveTrainingsResultLoading,
    hasNextPage: hasNextPageInactiveTrainings,
    fetchNextPage: fetchNextPageInactiveTrainings,
    isFetchingNextPage: isFetchingNextPageInactiveTrainings,
  } = useInfiniteQuery({
    queryKey: ['inactive-trainings', collaborator, collaboratorType, type],
    queryFn: ({ pageParam = 1 }) => getTrainings({
      currentPage: pageParam,
      pageSize: '10',
      status: 'inactive',
      collaborator,
      collaboratorType: collaboratorType as TrainingCollaboratorTypes | 'all',
      type: type as TrainingTypes | 'all'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const columnsTrainings = [
    {
      title: 'Pendentes',
      trainings: pendingTrainingsResult?.pages.flatMap((page) => page.trainings) || [],
      trainingsCount: pendingTrainingsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPagePendingTrainings,
      fetchNextPage: fetchNextPagePendingTrainings,
      isFetchingNextPage: isFetchingNextPagePendingTrainings,
      isLoading: isPendingTrainingsResultLoading,
    },
    {
      title: 'Ativos',
      trainings: activeTrainingsResult?.pages.flatMap((page) => page.trainings) || [],
      trainingsCount: activeTrainingsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageActiveTrainings,
      fetchNextPage: fetchNextPageActiveTrainings,
      isFetchingNextPage: isFetchingNextPageActiveTrainings,
      isLoading: isActiveTrainingsResultLoading,
    },
    {
      title: 'Inativos',
      trainings: inactiveTrainingsResult?.pages.flatMap((page) => page.trainings) || [],
      trainingsCount: inactiveTrainingsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageInactiveTrainings,
      fetchNextPage: fetchNextPageInactiveTrainings,
      isFetchingNextPage: isFetchingNextPageInactiveTrainings,
      isLoading: isInactiveTrainingsResultLoading,
    },
  ]


  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Treinamentos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/controle/treinamentos')}
          />
          {userHasCreateTrainingPermission && (
            <IconButton
              aria-label="Criar Treinamento"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateTrainingModal}
            />
          )}
          <Modal
            isOpen={isCreateTrainingModalOpen}
            onClose={onCloseCreateTrainingModal}
            size="2xl"
            isCentered
          >
            <ModalOverlay />
            <CreateTraining onCloseModal={onCloseCreateTrainingModal} />
          </Modal>
        </Flex>
      </Flex>
      <TrainingsTableFilter
        hasStatusFilter={false}
      />
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsTrainings?.map((training, index) => {
          return (
            <TrainingsKanbanColumn
              key={index}
              training={training}
            />
          )
        })}
      </Flex>
    </Box>
  )
}
