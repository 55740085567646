import { IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { getPatrimonyRequest } from "api/patrimonies/getPatrimonyRequest";
import { addHours, format } from "date-fns";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { PatrimonyRequestStatus } from "./PatrimonyRequestStatus";
import { PatrimonyStatus } from "./PatrimonyStatus";

interface PatrimonyRequestDetailsProps {
  patrimonyRequestId: string
  patrimonyId: string
}

export function PatrimonyRequestDetails({ patrimonyRequestId, patrimonyId }: PatrimonyRequestDetailsProps) {


  const userTimezone = new Date().getTimezoneOffset() / 60

  const { data: patrimonyRequestData, isLoading: isPatrimonyRequestDataLoading } = useQuery({
    queryKey: ['patrimonyRequest', patrimonyRequestId],
    queryFn: () => getPatrimonyRequest({ patrimonyRequestId })
  })

  const patrimonyRequest = patrimonyRequestData?.patrimonyRequest?.patrimonies.find(patrimonyRequest => patrimonyRequest.patrimony_id === patrimonyId)


  return (
    <ModalContent maxW={'550px'}>
      <ModalHeader letterSpacing="tight">Detalhes da Solicitação de Patrimônio</ModalHeader>
      <ModalCloseButton />
      {isPatrimonyRequestDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </ModalBody>
      ) : (
        <ModalBody
          maxH='500px'
          overflowY='scroll'
        >
          <TableContainer>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td>Regional</Td>
                  <Td isNumeric>{patrimonyRequestData?.patrimonyRequest.regional}</Td>
                </Tr>
                <Tr>
                  <Td>Nome do Responsável</Td>
                  <Td isNumeric>{patrimonyRequestData?.patrimonyRequest.responsible_name}</Td>
                </Tr>
                <Tr>
                  <Td>Data Limite</Td>
                  <Td isNumeric>
                    {format(addHours(new Date(patrimonyRequestData?.patrimonyRequest.limit_date), userTimezone), 'dd/MM/yyyy')}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Setor</Td>
                  <Td isNumeric>{patrimonyRequestData?.patrimonyRequest.sector}</Td>
                </Tr>
                <Tr>
                  <Td>Tipo do Patrimônio</Td>
                  <Td isNumeric>{patrimonyRequest?.patrimonyType.type}</Td>
                </Tr>
                <Tr>
                  <Td>Quantidade</Td>
                  <Td isNumeric>{patrimonyRequest?.quantity}</Td>
                </Tr>

                <Tr>
                  <Td>Status da Solicitação</Td>
                  <Td display="flex" justifyContent="flex-end">
                    <PatrimonyRequestStatus status={patrimonyRequest?.status} />
                  </Td>
                </Tr>
                {patrimonyRequest?.patrimony && (
                  <>
                    <Tr>
                      <Td>Status do Patrimônio</Td>
                      <Td display="flex" justifyContent="flex-end">
                        <PatrimonyStatus status={patrimonyRequest?.patrimony?.status} />
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>Valor do patrimônio</Td>
                      <Td display="flex" justifyContent="flex-end">
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        }).format(patrimonyRequest?.patrimony.value_in_cents / 100)}
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>Marca do patrimônio</Td>
                      <Td display="flex" justifyContent="flex-end">
                        {patrimonyRequest?.patrimony.brand}
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>Número do patrimônio</Td>
                      <Td display="flex" justifyContent="flex-end">
                        {patrimonyRequest?.patrimony?.identifier ?? '-'}
                      </Td>
                    </Tr>

                    {patrimonyRequest?.receipt && (
                      <Tr>
                        <Td>Comprovante de recebimento</Td>
                        <Td isNumeric>
                          <IconButton
                            aria-label="Abrir anexo"
                            as={Link}
                            href={patrimonyRequest?.receipt?.link}
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            isExternal
                          />
                        </Td>
                      </Tr>
                    )}
                  </>
                )}
                {patrimonyRequest?.is_defective_or_broken && (
                  <Tr>
                    <Td>Apresentou defeito ou quebra</Td>
                    <Td isNumeric>{patrimonyRequest?.is_defective_or_broken ? 'Sim' : 'Não'}</Td>
                  </Tr>
                )}
                {patrimonyRequest?.defective_or_broken_description && (
                  <Tr>
                    <Td>Descrição do defeito/quebra</Td>
                    <Td isNumeric>{patrimonyRequest?.defective_or_broken_description}</Td>
                  </Tr>
                )}
                {patrimonyRequest?.devolution_limit_date && (
                  <Tr>
                    <Td>Data limite devolução</Td>
                    <Td isNumeric>
                      {format(addHours(new Date(patrimonyRequest.devolution_limit_date), userTimezone), 'dd/MM/yyyy')}
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      )}
    </ModalContent>
  )
}
