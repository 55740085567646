import { Button, IconButton, Modal, ModalOverlay, Portal, Td, Tr, useDisclosure } from "@chakra-ui/react"
import { deleteRequestPatrimony } from "api/patrimonies/deleteRequestPatrimony"
import { PatrimonyRequest } from "api/patrimonies/patrimonyRequest"
import { addHours, format } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useToastify } from "hooks/toastify/useToastify"
import { FaArrowRight, FaPen, FaSearch, FaTrash } from "react-icons/fa"
import { useMutation, useQueryClient } from "react-query"
import { ActivateRequestedPatrimony } from "./ActivateRequestedPatrimony"
import { DisablePatrimonyRequest } from "./DisablePatrimonyRequest"
import { EditRequestPatrimony } from "./EditRequestPatrimony"
import { PatrimonyRequestDetails } from "./PatrimonyRequestDetails"
import { PatrimonyRequestStatus } from "./PatrimonyRequestStatus"
import { ProceedRequestedPatrimonyToDevolution } from "./ProceedRequestedPatrimonyToDevolution"
import { ReturnRequestedPatrimony } from "./ReturnRequestedPatrimony"

interface PatrimonyRequestTableRowProps {
  patrimonyRequest: PatrimonyRequest
}

export function PatrimonyRequestTableRow({ patrimonyRequest }: PatrimonyRequestTableRowProps) {

  const userTimezone = new Date().getTimezoneOffset() / 60

  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const userCanDisablePatrimonyRequest = userLogged?.permissions.includes('disable-patrimony-request')

  const userCanReturnPatrimonyRequest = userLogged?.permissions.includes('return-patrimony-request')

  const userCanProceedRequestedPatrimonyToDevolution = userLogged?.permissions.includes('proceed-requested-patrimony-devolution')

  const userCanActivatePatrimonyRequest = userLogged?.permissions.includes('activate-patrimony-request')

  const userCanEditPatrimonyRequest = userLogged?.permissions.includes('edit-patrimony-request')

  const userCanDeleteRequestPatrimony = userLogged?.permissions.includes('delete-request-patrimony')

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  const {
    isOpen: isEditPatrimonyRequestModalOpen,
    onToggle: onToggleEditPatrimonyRequestModal,
  } = useDisclosure()


  const {
    isOpen: isActivatePatrimonyRequestModalOpen,
    onOpen: onOpenActivatePatrimonyRequestModal,
    onClose: onCloseActivatePatrimonyRequestModal
  } = useDisclosure()

  const {
    isOpen: isDisablePatrimonyRequestModalOpen,
    onOpen: onOpenDisablePatrimonyRequestModal,
    onClose: onCloseDisablePatrimonyRequestModal
  } = useDisclosure()

  const {
    isOpen: isReturnPatrimonyRequestModalOpen,
    onOpen: onOpenReturnPatrimonyRequestModal,
    onClose: onCloseReturnPatrimonyRequestModal
  } = useDisclosure()

  const {
    isOpen: isProceedRequestedPatrimonyToDevolutionModalOpen,
    onOpen: onOpenProceedRequestedPatrimonyToDevolutionModal,
    onClose: onCloseProceedRequestedPatrimonyToDevolutionModal
  } = useDisclosure()

  const { mutateAsync: deleteRequestPatrimonyFn } = useMutation({
    mutationFn: deleteRequestPatrimony,
    onSuccess() {
      queryClient.invalidateQueries(['requestsPatrimony'])
    },
  })

  async function handleDeleteRequestPatrimony() {
    await promiseMessage(deleteRequestPatrimonyFn({
      patrimonyTypeId: patrimonyRequest?.patrimonyType?.id,
      patrimonyRequestId: patrimonyRequest?.patrimony_request_id
    }), 'Solicitação de patrimônio excluída!')
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Solicitação de Patrimônio"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
        >
          <ModalOverlay />
          <PatrimonyRequestDetails
            patrimonyRequestId={patrimonyRequest?.patrimony_request_id}
            patrimonyId={patrimonyRequest?.patrimony_id}
          />

        </Modal>
        {userCanEditPatrimonyRequest && (
          <IconButton
            aria-label="Editar solicitação de patrimônio"
            icon={<FaPen />}
            size="sm"
            variant="ghost"
            onClick={onToggleEditPatrimonyRequestModal}
          />
        )}
        <Modal
          isOpen={isEditPatrimonyRequestModalOpen}
          onClose={onToggleEditPatrimonyRequestModal}
          isCentered
        >
          <ModalOverlay />
          <EditRequestPatrimony
            patrimonyRequestId={patrimonyRequest?.patrimony_request_id}
            onCloseModal={onToggleEditPatrimonyRequestModal}
            patrimonyId={patrimonyRequest?.patrimony_id}
          />
        </Modal>
      </Td>
      <Td>{patrimonyRequest?.patrimonyRequest.protocol}</Td>
      <Td>{patrimonyRequest?.patrimonyRequest.regional}</Td>
      <Td>{patrimonyRequest?.patrimonyRequest.responsible_name}</Td>
      <Td>{patrimonyRequest?.patrimonyType.type}</Td>
      <Td>{format(addHours(new Date(patrimonyRequest?.patrimonyRequest.limit_date), userTimezone), 'dd/MM/yyy')}</Td>
      <Td>{patrimonyRequest?.patrimonyRequest.sector}</Td>
      <Td>{patrimonyRequest?.patrimony?.identifier ?? '-'}</Td>
      <Td>
        <PatrimonyRequestStatus status={patrimonyRequest?.status} />
      </Td>
      <Td>
        {userCanProceedRequestedPatrimonyToDevolution && patrimonyRequest?.status === 'active' && !!patrimonyRequest?.patrimonyType?.isControlledByIdentifier && (
          <>
            <Modal
              isOpen={isProceedRequestedPatrimonyToDevolutionModalOpen}
              onClose={onCloseProceedRequestedPatrimonyToDevolutionModal}
              isCentered
            >
              <ModalOverlay />
              <ProceedRequestedPatrimonyToDevolution
                onCloseModal={onCloseProceedRequestedPatrimonyToDevolutionModal}
                patrimonyRequestId={patrimonyRequest.patrimony_request_id}
                patrimonyId={patrimonyRequest?.patrimony_id}
              />
            </Modal>
            <Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              onClick={onOpenProceedRequestedPatrimonyToDevolutionModal}
              mr={2}
            >
              Processo de Devolução
            </Button>
          </>
        )}

        {userCanReturnPatrimonyRequest && patrimonyRequest?.status === 'returning' && (
          <>
            <Modal
              isOpen={isReturnPatrimonyRequestModalOpen}
              onClose={onCloseReturnPatrimonyRequestModal}
              isCentered
            >
              <ModalOverlay />
              <ReturnRequestedPatrimony
                onCloseModal={onCloseReturnPatrimonyRequestModal}
                patrimonyRequestId={patrimonyRequest.patrimony_request_id}
                patrimonyId={patrimonyRequest?.patrimony_id} />

            </Modal><Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              onClick={onOpenReturnPatrimonyRequestModal}
              mr={2}
            >
              Concluído
            </Button>
          </>
        )}
        {userCanDisablePatrimonyRequest && patrimonyRequest?.status === 'active' && patrimonyRequest?.patrimony?.status === 'in-possession' && (
          <>
            <Modal
              isOpen={isDisablePatrimonyRequestModalOpen}
              onClose={onCloseDisablePatrimonyRequestModal}
              isCentered
            >
              <ModalOverlay />
              <DisablePatrimonyRequest
                onCloseModal={onCloseDisablePatrimonyRequestModal}
                patrimonyRequestId={patrimonyRequest.patrimony_request_id}
                patrimonyId={patrimonyRequest?.patrimony_id}
              />
            </Modal>
            <Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              onClick={onOpenDisablePatrimonyRequestModal}
              mr={2}
            >
              Inutilizado
            </Button>
          </>
        )}

        {userCanActivatePatrimonyRequest && patrimonyRequest?.status === 'requested' && (
          <>
            <Modal
              isOpen={isActivatePatrimonyRequestModalOpen}
              onClose={onCloseActivatePatrimonyRequestModal}
            >
              <ModalOverlay />

              <Portal>
                <ActivateRequestedPatrimony
                  patrimonyType={patrimonyRequest?.patrimonyType?.type}
                  patrimonyRequestId={patrimonyRequest?.id}
                  onClose={onCloseActivatePatrimonyRequestModal}
                  isControlledByIdentifier={patrimonyRequest?.patrimonyType?.isControlledByIdentifier}
                />
              </Portal>

            </Modal>

            <Button
              leftIcon={<FaArrowRight />}
              lineHeight="1"
              size="xs"
              onClick={onOpenActivatePatrimonyRequestModal}
              mr={2}
            >
              Ativar
            </Button>
          </>
        )}
        {userCanDeleteRequestPatrimony && (
          <Button
            lineHeight="1"
            leftIcon={<FaTrash />}
            size="xs"
            onClick={handleDeleteRequestPatrimony}
          >
            Excluir
          </Button>
        )}
      </Td>
    </Tr>
  )
}
