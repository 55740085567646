import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Training } from "./training";

interface GetTrainingParams {
  trainingId: string
}

interface GetTrainingResponse {
  training: Training & {
    regional: Regional & {
      responsible: User
    } | null
    certificateAttachment: Attachment | null
    trainingAttachment: Attachment | null
  }
}

export async function getTraining({ trainingId }: GetTrainingParams) {
  try {
    const response = await api.get<GetTrainingResponse>(`/trainings/${trainingId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
