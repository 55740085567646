import { usePagination } from "@ajna/pagination";
import { Box, Heading, Skeleton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getCltIntercurrencesReport } from "api/cltIntercurrences/getCltIntercurrencesReport";
import { CltIntercurrenceReportStatus as CltIntercurrenceReportStatusTypes, CltIntercurrenceReportTypes, CltIntercurrenceType } from "api/cltIntercurrences/types/CltIntercurrence";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";

import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { cltIntercurrenceBooleanWithNAType, cltIntercurrencePartnerTypeMap, cltIntercurrenceReportStatusMap, cltIntercurrenceTypeMap } from "utils/cltIntercurrenceMappers";
import { CltIntercurrenceReportStatus } from "./components/CltIntercurrenceReportStatus";
import { CltIntercurrencesReportFilters } from "./components/CltIntercurrencesReportFilters";

const headers = [
  { label: 'Colaborador', key: 'collaborator_name' },
  { label: 'Regional', key: 'regional' },
  { label: 'Tipo de Intercorrência', key: 'type' },
  { label: 'Tipo de Parceiro', key: 'partner_type' },
  { label: 'Data da intercorrência', key: 'date' },
  { label: 'Vai gerar extra ou desconto?', key: 'has_generate_extra_discount' },
  { label: 'Aluguel de moto', key: 'with_motorcycle_rental' },
  { label: 'Telefonia', key: 'with_telephony' },
  { label: 'Combustível', key: 'with_fuel' },
  { label: 'VA/VR', key: 'with_va_vr' },
  { label: 'VT', key: 'with_vt' },
  { label: 'Pedágio', key: 'with_toll' },
  { label: 'Extras', key: 'with_extras' },
  { label: 'Folha de pagamento', key: 'with_payroll' },
  { label: 'Descrição', key: 'description' },
  { label: 'Status', key: 'status' },

]

export function CltIntercurrencesReport() {
  const searchParams = useSearchParams()
  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewCltIntercurrencesReport = userLogged?.permissions?.includes('view-clt-intercurrences-report')

  useEffect(() => {
    if (!userCanViewCltIntercurrencesReport) history.push('/')
  }, [history, userCanViewCltIntercurrencesReport])


  const status = searchParams.get('status') ?? 'all'
  const initialDate = searchParams.get('initialDate')
  const finalDate = searchParams.get('finalDate')
  const benefits = searchParams.get('benefits')
  const type = searchParams.get('type')

  const {
    data: cltIntercurrencesReportResult,
    isLoading: isLoadingCltIntercurrencesReportResult
  } = useQuery({
    queryKey: [
      'clt-intercurrences-report',
      benefits,
      initialDate,
      finalDate,
      status,
      type
    ],
    queryFn: () => getCltIntercurrencesReport({
      initialDate,
      finalDate,
      status: status as CltIntercurrenceReportStatusTypes,
      benefits: benefits as CltIntercurrenceReportTypes,
      type: type as CltIntercurrenceType
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!status && !!initialDate && !!finalDate
  })

  const csvProps = {
    data: cltIntercurrencesReportResult ? cltIntercurrencesReportResult?.map(cltIntercurrence => {
      return {
        ...cltIntercurrence,
        date: cltIntercurrence.date ? format(new Date(cltIntercurrence.date), 'dd/MM/yyyy') : '-',
        regional: cltIntercurrence.regional.toUpperCase(),
        partner_type: cltIntercurrencePartnerTypeMap[cltIntercurrence.partner_type],
        type: cltIntercurrenceTypeMap[cltIntercurrence.type],
        status: cltIntercurrenceReportStatusMap[cltIntercurrence.status],
        has_generate_extra_discount: cltIntercurrenceBooleanWithNAType[cltIntercurrence.has_generate_extra_discount],
        with_motorcycle_rental: cltIntercurrence.with_motorcycle_rental ? 'SIM' : 'NÃO',
        with_telephony: cltIntercurrence.with_telephony ? 'SIM' : 'NÃO',
        with_fuel: cltIntercurrence.with_fuel ? 'SIM' : 'NÃO',
        with_va_vr: cltIntercurrence.with_va_vr ? 'SIM' : 'NÃO',
        with_vt: cltIntercurrence.with_vt ? 'SIM' : 'NÃO',
        with_toll: cltIntercurrence.with_toll ? 'SIM' : 'NÃO',
        with_extras: cltIntercurrence.with_extras ? 'SIM' : 'NÃO',
        with_payroll: cltIntercurrence.with_payroll ? 'SIM' : 'NÃO',
      }
    })
      : [],
    headers,
    filename: `relatório-clt-intercurrencias-${new Date().toISOString()}.csv`,
  }

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
    pagesCount: cltIntercurrencesReportResult ? Math.ceil(cltIntercurrencesReportResult?.length / 10) : 0
  })

  const handleChangePage = (page: number) => setCurrentPage(page)


  return (
    <Box
      p="6"
      bg="white"
      rounded="md"
    >
      <Heading letterSpacing="tight" >Relatório de Intercorrências CLT</Heading>

      <CltIntercurrencesReportFilters csvProps={csvProps} />


      {isLoadingCltIntercurrencesReportResult ? (
        <Skeleton mt="6" h="400px" rounded="md" w="full" />
      ) : (
        <TableContainer mt="6" rounded="md">
          <Table
            size="sm"
          >
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th color="white">Colaborador</Th>
                <Th color="white">Regional</Th>
                <Th color="white">Tipo de Intercorrência</Th>
                <Th color="white">Tipo de Parceiro</Th>
                <Th color="white">Data</Th>
                <Th color="white">Status</Th>

              </Tr>
            </Thead>
            <Tbody>
              {cltIntercurrencesReportResult?.slice(offset, offset + 10)?.map((cltIntercurrence) => {
                return (
                  <Tr key={cltIntercurrence.id}>
                    <Td>{cltIntercurrence?.collaborator_name}</Td>
                    <Td>{cltIntercurrence?.regional?.toUpperCase()}</Td>
                    <Td>{cltIntercurrenceTypeMap[cltIntercurrence?.type]}</Td>
                    <Td>{cltIntercurrencePartnerTypeMap[cltIntercurrence?.partner_type]}</Td>
                    <Td>{cltIntercurrence.date ? format(new Date(cltIntercurrence.date), 'dd/MM/yyyy') : '-'}</Td>
                    <Td>
                      <CltIntercurrenceReportStatus status={cltIntercurrence?.status} />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>


          </Table>
        </TableContainer>
      )}

      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </Box>
  )
}
