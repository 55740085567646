import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { useAuth } from "hooks/auth/useAuth"
import { useEffect } from "react"
import { FaExchangeAlt, FaPlus } from "react-icons/fa"
import { useInfiniteQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { CreateTrainingLlm } from "./components/CreateTrainingLlm"
import { getTrainingsLlm } from "api/trainingLlm/getTrainingsLlm";
import { TrainingsLlmKanbanColumn } from "./components/TrainingsLlmKanbanColumn"

export function TrainingsLlmKanban(){
  const {  push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userHasViewTrainingsLlmPermission = userLogged?.permissions.includes('view-trainings-llm')

  const userHasCreateTrainingLlmPermission = userLogged?.permissions.includes('create-llm-training')

  useEffect(() => {
    if (!userHasViewTrainingsLlmPermission) {
      redirect('/')
    }
  }, [userHasViewTrainingsLlmPermission, redirect])


  const {
    isOpen: isCreateTrainingLlmModalOpen,
    onOpen: onOpenCreateTrainingLlmModal,
    onClose: onCloseCreateTrainingLlmModal
  } = useDisclosure()


  const {
    data: pendingTrainingsLlmResult,
    isLoading: isPendingTrainingsLlmResultLoading,
    hasNextPage: hasNextPagePendingTrainingsLlm,
    fetchNextPage: fetchNextPagePendingTrainingsLlm,
    isFetchingNextPage: isFetchingNextPagePendingTrainingsLlm,
  } = useInfiniteQuery({
    queryKey: ['pending-trainings-llm'],
    queryFn: ({ pageParam = 1 }) => getTrainingsLlm({
      currentPage: pageParam,
      pageSize: '10',
      status: 'pending',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: approvedTrainingsLlmResult,
    isLoading: isApprovedTrainingsLlmResultLoading,
    hasNextPage: hasNextPageApprovedTrainingsLlm,
    fetchNextPage: fetchNextPageApprovedTrainingsLlm,
    isFetchingNextPage: isFetchingNextPageApprovedTrainingsLlm,
  } = useInfiniteQuery({
    queryKey: ['approved-trainings-llm'],
    queryFn: ({ pageParam = 1 }) => getTrainingsLlm({
      currentPage: pageParam,
      pageSize: '10',
      status: 'approved',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: reprovedTrainingsLlmResult,
    isLoading: isReprovedTrainingsLlmResultLoading,
    hasNextPage: hasNextPageReprovedTrainingsLlm,
    fetchNextPage: fetchNextPageReprovedTrainingsLlm,
    isFetchingNextPage: isFetchingNextPageReprovedTrainingsLlm,
  } = useInfiniteQuery({
    queryKey: ['reproved-trainings-llm'],
    queryFn: ({ pageParam = 1 }) => getTrainingsLlm({
      currentPage: pageParam,
      pageSize: '10',
      status: 'reproved',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const columnsTrainingsLlm = [
    {
      title: 'Pendentes',
      trainingsLlm: pendingTrainingsLlmResult?.pages.flatMap((page) => page.llmTrainings) || [],
      trainingsLlmCount: pendingTrainingsLlmResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPagePendingTrainingsLlm,
      fetchNextPage: fetchNextPagePendingTrainingsLlm,
      isFetchingNextPage: isFetchingNextPagePendingTrainingsLlm,
      isLoading: isPendingTrainingsLlmResultLoading,
    },
    {
      title: 'Aprovadas',
      trainingsLlm: approvedTrainingsLlmResult?.pages.flatMap((page) => page.llmTrainings) || [],
      trainingsLlmCount: approvedTrainingsLlmResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageApprovedTrainingsLlm,
      fetchNextPage: fetchNextPageApprovedTrainingsLlm,
      isFetchingNextPage: isFetchingNextPageApprovedTrainingsLlm,
      isLoading: isApprovedTrainingsLlmResultLoading,
    },
    {
      title: 'Reprovadas',
      trainingsLlm: reprovedTrainingsLlmResult?.pages.flatMap((page) => page.llmTrainings) || [],
      trainingsLlmCount: reprovedTrainingsLlmResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageReprovedTrainingsLlm,
      fetchNextPage: fetchNextPageReprovedTrainingsLlm,
      isFetchingNextPage: isFetchingNextPageReprovedTrainingsLlm,
      isLoading: isReprovedTrainingsLlmResultLoading,
    },
  ]
  return(
<Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Treinamentos Complementares</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/controle/treinamentos/llm')}
          />
          {userHasCreateTrainingLlmPermission && (
            <IconButton
              aria-label="Criar Treinamento"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateTrainingLlmModal}
            />
          )}
        </Flex>
        <Modal
            isOpen={isCreateTrainingLlmModalOpen}
            onClose={onCloseCreateTrainingLlmModal}
            isCentered
          >
            <ModalOverlay />
            <CreateTrainingLlm onCloseModal={onCloseCreateTrainingLlmModal} />
          </Modal>
      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsTrainingsLlm?.map((trainingLlm, index) => {
          return (
            <TrainingsLlmKanbanColumn
              key={index}
              trainingLlm={trainingLlm}
            />
          )
        })}
      </Flex>
    </Box>
  )
}
